import { ReactComponent as BrushIcon } from '@/assets/icons/brush.svg';
import { ReactComponent as ArrowRightIcon } from '@/assets/icons/arrow_right.svg';
import useAuth from '@/hooks/useAuth';

const DesignWidget: React.FC = () => {
  const { user } = useAuth();

  if (user?.last_design_order?.total === 0) return null;

  return (
    <>
      <div className="border-t border-gray-200 my-6 mx-3" />
      <div className="flex mx-3 p-3 bg-brand-50 rounded-tl-2xl rounded-br-2xl rounded-tr-sm rounded-bl-sm">
        <div className="mr-3">
          <BrushIcon />
        </div>
        <div>
          <div className="font-medium text-sm text-brand-900">
            You have {user?.last_design_order?.total} waiting!
          </div>
          <div className="my-2 font-normal text-sm text-brand-900">
            Please approve or create your card design.
          </div>
          <div>
            <a
              className="flex"
              target="_blank"
              href={`${import.meta.env.VITE_CUSTOMISER_BASE_URL}/order/${
                user?.last_design_order.uuid
              }`}
            >
              Let's go&nbsp;
              <ArrowRightIcon />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesignWidget;
