import { Link } from 'react-router-dom';
import feedData from './data';
import ArrowRightIcon from '../Icons/ArrowRightIcon';
import ContactSent from '../Icons/ContactSent';
import ExchangeIcon from '../Icons/ExchangeIcon';
import Pencil from '../Icons/Pencil';
import Received from '../Icons/Received';
import EyeIcon from '../Icons/Eye';
import useAuth from '@/hooks/useAuth';
import {
  IOrganisationUpdates,
  IOrganisationUpdateEvent,
} from '@/types/IOrganisation';

export function getIcon(event: IOrganisationUpdateEvent) {
  return (
    {
      [IOrganisationUpdateEvent.received_contact]: <Received />,
      [IOrganisationUpdateEvent.sent_contact]: <ContactSent />,
      [IOrganisationUpdateEvent.view_contact]: <EyeIcon />,
      [IOrganisationUpdateEvent.edit_profile]: <Pencil />,
      [IOrganisationUpdateEvent.details_exchange]: <ExchangeIcon />,
    }[event] || null
  );
}

export function getMessageText(feed: IOrganisationUpdates) {
  return (
    {
      [IOrganisationUpdateEvent.received_contact]: ' received a contact from ',
      [IOrganisationUpdateEvent.sent_contact]: ' sent a contact to ',
      [IOrganisationUpdateEvent.view_contact]: ' profile was viewed by ',
      [IOrganisationUpdateEvent.edit_profile]: ' updated their profile',
      [IOrganisationUpdateEvent.details_exchange]: ' exchanged details with ',
    }[feed.event] || null
  );
}

export function getNameText(feed: IOrganisationUpdates) {
  return (
    {
      [IOrganisationUpdateEvent.received_contact]: `${
        feed.profile.first_name
      } ${feed.profile.last_name?.charAt(0) ?? ''}`,
      [IOrganisationUpdateEvent.sent_contact]: `${feed.profile.first_name}  ${
        feed.profile.last_name?.charAt(0) ?? ''
      }`,
      [IOrganisationUpdateEvent.view_contact]: `${feed.profile.first_name}
        ${feed.profile.last_name?.charAt(0) ?? ''}'s`,
      [IOrganisationUpdateEvent.edit_profile]: `${feed.profile.first_name} ${
        feed.profile.last_name ?? ''
      }`,
      [IOrganisationUpdateEvent.details_exchange]: `${
        feed.profile.first_name
      } ${feed.profile.last_name?.charAt(0) ?? ''}`,
    }[feed.event] || null
  );
}

const FeedRestricted = () => {
  const { user } = useAuth();
  return (
    <div>
      <div className="blur-filter">
        <div>
          <h4 className="text-2xl font-medium leading-8 text-grey-900">
            Recent updates
          </h4>
          <ul className="pr-6 mt-3 overflow-auto h-[25.938rem] scrolledDiv">
            {feedData.map((feed, index) => (
              <li key={index}>
                <Link
                  to="/"
                  className="relative flex items-end justify-between py-4 border-b border-gray-200"
                >
                  <div className="relative flex items-start space-x-4">
                    <div className="relative">
                      <img
                        className="flex items-center justify-center w-10 h-10 bg-gray-400 rounded-full ring-8 ring-white"
                        alt=""
                        src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                      />
                      <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                        {getIcon(feed.event)}
                      </span>
                    </div>
                    <div className="flex items-center flex-1 min-w-0">
                      <div>
                        <span className="text-xs font-normal text-right text-gray-400">
                          2h ago
                        </span>
                        <div className="text-sm font-normal leading-5 text-gray-500">
                          <span className="font-medium text-gray-900">
                            {feed.profile.first_name} {feed.profile.last_name}
                            {feed.event ===
                            IOrganisationUpdateEvent.view_contact
                              ? "'s"
                              : ''}
                          </span>
                          {getMessageText(feed)}
                          {user?.first_name && (
                            <span className="font-medium text-gray-900">
                              {feed.event === 'edit:profile'
                                ? ''
                                : user.first_name}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ArrowRightIcon />
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <span className="absolute inset-0 flex items-center justify-center w-full h-full text-xl font-medium leading-6 text-pdfview-text">
        <span className="text-brand-500">tapt+</span> feature only
      </span>
    </div>
  );
};

export default FeedRestricted;
