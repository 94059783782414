const COLORS = {
  organisation: {
    loadingProfile: {
      backgroundColor: '#FFFFFF',
      buttonBackgroundColor: '#873CFF',
      buttonTextColor: '#FFFFFF',
      textColor: '#0A0A0A',
    },
    defaultProfile: {
      backgroundColor: '#FFFFFF',
      buttonBackgroundColor: '#873CFF',
      buttonTextColor: '#FFFFFF',
      textColor: '#0A0A0A',
    },
  },
  profile: {
    footer: {
      copyrightMessage: `© ${new Date().getFullYear()} Tapt by Hatch`,
      colorTextLight: '#FFFFFF',
      colorTextDark: '#000000',
    },
  },
};

export { COLORS as default, COLORS };
