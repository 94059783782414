import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon, QrcodeIcon } from '@heroicons/react/solid';
import { AxiosResponse } from 'axios';
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import {
  ExclamationIcon,
  UserIcon,
  EyeIcon,
  RefreshIcon,
  PrinterIcon,
  ChatAltIcon as OrderDetailsIcon,
  ExclamationCircleIcon,
  ExternalLinkIcon as HeroExternalLinkIcon,
} from '@heroicons/react/outline';
import moment from 'moment-timezone';
import {
  LocationMarkerIcon,
  ChatAltIcon,
  ShoppingBagIcon,
  DownloadIcon,
  ExternalLinkIcon,
  PriorityIcon,
} from '@/components/Icons';
import adminAPI from '@/api/admin';
import filesAPI from '@/api/files';
import ordersAPI from '@/api/ordersV2';
import Badge, { BADGE_KIND } from '@/components/Badge';
import Button, { BUTTON_KIND, BUTTON_SIZE } from '@/components/Button';
import Spinner from '@/components/Icons/Spinner';
import { ITableData } from '@/components/OrdersPage/shared/TableData';
import classNames from '@/helpers/classNames';
import { orderHandler } from '@/helpers/orderHandler';
import IFile from '@/types/IFile';
import { printLabel } from '@/api/starshipit';
import useAuth from '@/hooks/useAuth';
import ErrorAlert from '@/components/ErrorAlert';
import calculatePackageDimensions from '@/constants/orders';
import Modal from '@/components/Modal';
import { OrderOrigin, OrderPausedBy, OrderTag } from '@/types/IOrderV2';

const allowOrderNewFeature =
  import.meta.env.VITE_ALLOW_ORDER_NEW_FEATURE === 'true';

const OrderTypeSetup: {
  id: string;
  orderType: OrderOrigin;
  title: string;
}[] = [
  {
    id: '1',
    orderType: 'draft',
    title: 'Draft',
  },
  {
    id: '2',
    orderType: 'web_app',
    title: 'Reorder',
  },
  {
    id: '3',
    orderType: 'shop',
    title: 'Strapi',
  },
];

const options = [
  {
    id: 0,
    label: 'Linked Org',
    icon: (
      <UserIcon className="stroke-1.3 h-6 w-6 text-gray-500 mr-4 group-hover:text-brand-400" />
    ),
  },
  {
    id: 1,
    label: 'Change Org',
    icon: <RefreshIcon className="stroke-1.3 h-6 w-6 text-gray-400 mr-4" />,
  },
  {
    id: 2,
    label: 'Link to Preview',
    icon: (
      <EyeIcon className="stroke-1.3 h-6 w-6 text-gray-500 mr-4 group-hover:text-brand-400" />
    ),
  },
  {
    id: 3,
    label: 'Move to Ready for Print',
    icon: (
      <PrinterIcon className="stroke-1.3 h-6 w-6 text-gray-500 mr-4 group-hover:text-brand-400" />
    ),
  },
  {
    id: 4,
    label: 'Order Details',
    icon: (
      <OrderDetailsIcon className="stroke-1.3 h-6 w-6 text-gray-500 mr-4 group-hover:text-brand-400" />
    ),
  },
  {
    id: 5,
    label: 'Make high priority',
    icon: (
      <ExclamationCircleIcon className="stroke-1.3 h-6 w-6 text-gray-500 mr-4 group-hover:text-brand-400" />
    ),
  },
];

interface IDesignSpec {
  id?: number;
  order_id: number;
  content?: string;
  setup?: string;
  user_id?: number;
  design_spec_files?: IFile[];
}

export interface IOrderTableData {
  orderStatus: string;
  setIsOpenOrderDetailModal: Dispatch<SetStateAction<boolean>>;
  orderNumber: string;
  numberOfProfiles: number;
  orderType: OrderOrigin | undefined;
  setChangeRequestFile: any;
  setChangeRequest: Dispatch<SetStateAction<string>>;
  setIsChangeRequest: Dispatch<SetStateAction<boolean>>;
  submission_date: string;
  orderUrl: string;
  setArrFile: any;
  setIsOpenMessageDesignSpecs: Dispatch<SetStateAction<boolean>>;
  setDesignSpects: Dispatch<SetStateAction<IDesignSpec>>;
  designSpects: IDesignSpec;
  setError: Dispatch<SetStateAction<boolean>>;
  setSuccess: Dispatch<SetStateAction<string>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  handleOrderStatusChange: () => void;
  id: number;
  designFile: any;
  setDownloading: Dispatch<SetStateAction<boolean>>;
  downloading: boolean;
  setIsDropFile: Dispatch<SetStateAction<boolean>>;
  orgId: number;
  setIsMoveOrder: Dispatch<SetStateAction<boolean>>;
  setIsPriority: Dispatch<SetStateAction<boolean>>;
  isPriority: boolean;
  notifications_sent: string | undefined;
  is_priority: boolean | undefined;
  tag: OrderTag;
  customer_name: string;
  customer_email: string;
  paused_by: OrderPausedBy | undefined;
  uuid: string;
  shipping_country: string | null;
  inserted_at: string;
  has_qr_codes: boolean;
}

const OrderTableData = ({
  orderStatus,
  is_priority,
  setIsOpenOrderDetailModal,
  orderNumber,
  numberOfProfiles,
  tag,
  customer_name,
  customer_email,
  shipping_country,
  orderType,
  setChangeRequestFile,
  setChangeRequest,
  setIsChangeRequest,
  notifications_sent,
  submission_date,
  paused_by,
  orderUrl,
  setArrFile,
  setIsOpenMessageDesignSpecs,
  setDesignSpects,
  designSpects,
  setError,
  setSuccess,
  setIsLoading,
  handleOrderStatusChange,
  id,
  designFile,
  setDownloading,
  downloading,
  setIsDropFile,
  orgId,
  setIsMoveOrder,
  uuid,
  setIsPriority,
  isPriority,
  inserted_at,
  has_qr_codes,
}: IOrderTableData): ITableData[] => {
  const { user } = useAuth();

  const [isDownloadLabelModalOpen, setIsDownloadLabelModalOpen] =
    useState(false);
  const currentDate = moment().format('DD/MM/YYYY');
  const endDate = moment(currentDate, 'DD/MM/YYYY');
  const dayOfStagedOrder = endDate.diff(
    moment(submission_date, 'DD/MM/YYYY'),
    'days',
  );
  const dayOfPlacedOrder = endDate.diff(
    moment(inserted_at, 'DD/MM/YYYY'),
    'days',
  );

  const [starshipitError, setStarshipitError] = useState<string>();

  const handlerRequest = async () => {
    const res = await ordersAPI.showChangeRequest(id);
    setChangeRequestFile(res.data.data[0].change_request_file);

    if (res?.data?.data[0]?.change_request?.note) {
      res?.data?.data[0]?.change_request?.note;
    } else {
      setChangeRequest('Dont have any change request');
    }
    setIsChangeRequest(true);

    return res;
  };

  const exportCSV = useCallback(async () => {
    const response = await adminAPI.exportOrderProfilesCSV(orderNumber);
    const blob = new Blob([response.data]);
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'profiles_order.csv';
    a.click();
  }, [orderNumber]);

  const downloadQrCodes = useCallback(() => {
    ordersAPI.downloadQrCodes(id).then((response) => {
      const href = window.URL.createObjectURL(response.data);
      const anchorElement = document.createElement('a');
      anchorElement.href = href;
      anchorElement.download = `${orderNumber}_qr_codes.zip`;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    });
  }, [id]);

  const {
    isLoading: isGetDesignSpecByOrderId,
    mutate: getDesignSpecByOrderId,
  } = useMutation(
    ({ order_id }: any): any => ordersAPI.show_design_spec(order_id),
    {
      onSuccess: (res: AxiosResponse) => {
        const design_spec = res?.data;

        if (!design_spec?.id) {
          setArrFile([]);
          setIsOpenMessageDesignSpecs(true);
          return;
        }

        const designSpecParam = res.data;
        setArrFile([
          ...design_spec?.design_spec_files?.map((el: any) => {
            return {
              id: Math.random(),
              file: el.file,
            };
          }),
        ]);
        setIsOpenMessageDesignSpecs(true);
        setDesignSpects(designSpecParam.id ? designSpecParam : designSpects);
      },
      onError: () => {
        setError(true);
      },
    },
  );

  const handleOpenMessageDesignSpecs = () => {
    getDesignSpecByOrderId({ order_id: id });
  };

  const handlerOrderPriority = useCallback(
    async (order_id: number, isPriority: boolean) => {
      try {
        const rep = await ordersAPI.updateOrderPriority(order_id, isPriority);

        if (rep) {
          setSuccess('Order Priority Change');
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
        handleOrderStatusChange();
      }
    },
    [handleOrderStatusChange, setError, setIsLoading, setSuccess],
  );

  const download = async () => {
    setDownloading(true);

    try {
      const response = await filesAPI.downloadFile(designFile?.original_url);

      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([response.data]));
      a.download = designFile?.file?.file_name;
      a.click();

      setSuccess(
        `File ${designFile?.file?.file_name} downloaded successfully!`,
      );
    } catch (error) {
      setError(true);
    } finally {
      setDownloading(false);
    }
  };

  const handleClickLinkedOrg = (orgId: number) => {
    if (!orgId) {
      setError(true);
      return;
    }
    window.open(`/?scope=user&uOrgID=${orgId}`);
  };

  const { mutate: changeStage } = useMutation(
    ({ order_id, stage }: any) => ordersAPI.updateOrder({ order_id, stage }),
    {
      onSuccess: () => {
        setSuccess('Order has been moved to ready for print');
        handleOrderStatusChange();
      },
      onError: (err) => {
        setError(true);
      },
    },
  );

  const handleChangeStageToReady = () => {
    changeStage({ order_id: id, stage: 'ready_for_print' });
  };

  const labelIsPrintable = () => {
    const dimensions = calculatePackageDimensions(numberOfProfiles);

    if (shipping_country !== null && shipping_country !== 'Australia')
      return false;
    if (dimensions === null) return false;

    return true;
  };

  const canSeeLabel = () => {
    return user?.role === 'design' || user?.email === 'elon@tapt.io';
  };

  return [
    {
      style: { width: '2%' },
      className: 'py-3 px-0',
      children: (
        <div className="flex flex-row items-start w-full justify-end">
          <div className="h-full flex justify-start items-start w-full">
            <PriorityIcon className={classNames(is_priority ? '' : 'hidden')} />
          </div>
        </div>
      ),
    },
    {
      style: { width: '10%' },
      className: 'py-3 px-6 pr-[0.7em]',
      children: (
        <div className="flex flex-row items-start justify-end w-full">
          <div className="flex flex-col items-center justify-end">
            <div className="flex items-center justify-end w-full">
              <button
                className="text-sm font-medium text-gray-900 flex items-center justify-end w-full ml-1 cursor-pointer"
                onClick={() => setIsOpenOrderDetailModal(true)}
              >
                #{orderNumber}
              </button>
            </div>
            <div className={classNames('flex items-center justify-end w-full')}>
              <p className="text-sm text-gray-500 font-medium flex items-center justify-end ml-1">
                <span>{numberOfProfiles}</span>
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      style: { width: '9.6%' },
      className: classNames(orderHandler.stageTabHandler(orderStatus, 'TAG')),
      children: (
        <div className="flex flex-row justify-center items-center">
          <Badge
            text={orderHandler.orderBadge.handleTagBadge.tagText(tag)}
            kind={orderHandler.orderBadge.handleTagBadge.tagKindColor(tag)}
          />
        </div>
      ),
    },
    {
      className: 'lg:table-cell hidden',
      style: { maxWidth: '50px' },
      children: (
        <div
          className="flex items-start flex-col justify-start h-full w-full pl-10 cursor-pointer"
          onClick={() => setIsOpenOrderDetailModal(true)}
        >
          <p
            className="hidden lg:block text-sm font-medium text-gray-900 truncate"
            style={{ maxWidth: '216px' }}
          >
            {customer_name}
          </p>
          <p
            className="hidden text-sm font-normal leading-5 text-gray-500 truncate lg:block"
            style={{ maxWidth: '216px' }}
          >
            {customer_email}
          </p>
        </div>
      ),
    },
    {
      style: { width: '9.2%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'ORDER TYPE'),
        'text-xs font-semibold text-gray-900 text-center',
      ),
      children: (
        <div className="flex items-center justify-center">
          <p className="text-sm font-medium text-gray-900">
            {
              OrderTypeSetup.find((element) => element.orderType === orderType)
                ?.title
            }
          </p>
        </div>
      ),
    },
    {
      style: { width: '11%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'NOTIFICATIONS'),
      ),
      children: (
        <div className="flex items-center justify-center">
          <button>
            <div className="flex items-center justify-center border-stone-300 border-2 rounded w-9 h-9">
              {notifications_sent ? <p>{notifications_sent}</p> : <p>0</p>}
            </div>
          </button>
        </div>
      ),
    },
    {
      style: { width: '9.2%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'WARNING'),
        'lg:table-cell hidden',
      ),
      children: (
        <div className="flex items-center justify-center">
          {String(notifications_sent) === '3' ? (
            <ExclamationIcon className="h-6 w-6 text-gray-300" />
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      style: { width: '9.2%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'CHANGES'),
        'text-[color:var(--grey-900,#111827)] text-sm not-italic font-normal leading-[150%]',
      ),
      children: (
        <div className="flex items-center justify-center">
          <button onClick={handlerRequest}>
            <ChatAltIcon />
          </button>
        </div>
      ),
    },
    {
      style: { width: '9.2%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'QR CODE'),
        'text-[color:var(--grey-900,#111827)] text-sm not-italic font-normal leading-[150%] pl-[5px]',
      ),
      children: (
        <div className="flex items-center justify-center">
          {!has_qr_codes && <Badge text="Unavailable" kind={BADGE_KIND.RED} />}

          {has_qr_codes && (
            <Button
              icon={<QrcodeIcon />}
              buttonText="Export"
              size={BUTTON_SIZE.SMALL}
              onClick={downloadQrCodes}
            />
          )}
        </div>
      ),
    },
    {
      style: { width: '11%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'EXPORT PROFILES'),
        'lg:table-cell hidden',
      ),
      children: (
        <div className="items-center justify-center lg:flex hidden">
          <button onClick={exportCSV}>
            <ExternalLinkIcon className="w-6 h-6 text-gray-300" />
          </button>
        </div>
      ),
    },
    {
      style: { width: '9.2%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'PROOF'),
        'text-[color:var(--grey-900,#111827)] text-sm not-italic font-normal leading-[150%] pl-[5px]',
      ),
      children: (
        <div className="flex items-center justify-center">
          <button
            onClick={() => {
              if (!uuid) {
                setError(true);
                return false;
              }
              return window.open(`preview/${uuid}`);
            }}
          >
            <HeroExternalLinkIcon className="h-6 w-6" />
          </button>
        </div>
      ),
    },
    {
      style: { width: '9.2%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'LABEL'),
        'text-sm leading-5 font-medium text-gray-900',
      ),
      children: canSeeLabel() ? (
        <div className="flex items-center justify-center">
          {starshipitError && (
            <ErrorAlert
              message={starshipitError}
              className="rounded-md bg-red-50 p-4 mb-5 fixed top-7 right-12"
            />
          )}
          {labelIsPrintable() ? (
            <div>
              <Button
                buttonText=""
                kind={BUTTON_KIND.COLOR_OMITTED_NO_BORDER}
                icon={<DownloadIcon />}
                onClick={() => setIsDownloadLabelModalOpen(true)}
              />
              <Modal
                isOpen={isDownloadLabelModalOpen}
                setIsOpen={setIsDownloadLabelModalOpen}
                dialogTitle="Confirm label download"
                dialogDescription={
                  <>
                    Are you sure you want to download the shipping label for{' '}
                    {customer_name}
                    {'\u2018'}s order?
                    <br />
                  </>
                }
                onSuccess={async () => {
                  setStarshipitError(undefined);
                  const dimensions =
                    calculatePackageDimensions(numberOfProfiles);

                  // In some cases we don't want to generate a label
                  if (dimensions === null) {
                    setStarshipitError(
                      'Your order contains more than 60 cards',
                    );
                    return;
                  }

                  const response = await printLabel({
                    orderNumber,
                    packages: [dimensions],
                  });
                  const pdf = response.data.labels?.[0];

                  if (response.data.success === false || !pdf) {
                    setStarshipitError(
                      'Order had not come through to Starshipit yet',
                    );

                    return;
                  }

                  const linkSource = `data:application/pdf;base64,${pdf}`;
                  const downloadLink = document.createElement('a');
                  const fileName = `${orderNumber}-label.pdf`;
                  downloadLink.href = linkSource;
                  downloadLink.download = fileName;
                  downloadLink.click();
                }}
                successButtonText="Confirm"
              />
            </div>
          ) : (
            <Badge text="Unavailable" kind={BADGE_KIND.RED} />
          )}
        </div>
      ) : (
        <></>
      ),
    },
    {
      style: { width: '11%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'SUBMISSION DATE'),
        'text-sm leading-5 font-medium text-gray-900',
      ),
      children: (
        <div className="flex items-center justify-center">
          {submission_date}
        </div>
      ),
    },
    {
      style: { width: '11%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'PAUSED BY'),
        'text-sm leading-5 font-medium text-gray-900 text-center',
      ),
      children: (
        <Badge
          text={paused_by === 'tapt_design' ? 'Tapt Design' : 'Client'}
          kind="red"
          backgroundColor={
            paused_by === 'tapt_design' ? 'bg-brand-200' : 'bg-gray-400'
          }
          textColor={
            paused_by === 'tapt_design' ? 'text-brand-500' : 'text-white'
          }
        />
      ),
    },
    {
      style: { width: '9.2%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'SHOPIFY LINK'),
        'lg:table-cell hidden',
      ),
      children: (
        <div className="flex items-center justify-center">
          {orderUrl && (
            <a href={orderUrl} target="_blank" rel="noreferrer">
              <ShoppingBagIcon />
            </a>
          )}
        </div>
      ),
    },
    {
      style: { width: '9.2%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'SHIPPING'),
        'lg:table-cell hidden',
      ),
      children: (
        <div className="flex items-center justify-center w-full">
          <a href={orderUrl} target="_blank" rel="noreferrer">
            <LocationMarkerIcon className="h-6 w-6 text-gray-300" />
          </a>
        </div>
      ),
    },
    {
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'Define Guidelines'),
        'w-1/6',
      ),
      children: (
        <div className="w-full flex items-center justify-center">
          <Button
            kind={BUTTON_KIND.WHITE}
            loading={isGetDesignSpecByOrderId}
            buttonText="Design Specs"
            className="px-4 py-2.5 font-medium text-base text-gray-900"
            onClick={handleOpenMessageDesignSpecs}
          />
        </div>
      ),
    },
    {
      style: { width: '9.2%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'DESIGN FILE'),
        'lg:table-cell hidden',
      ),
      children: (
        <div className="flex items-center justify-center">
          {designFile?.file && (
            <button onClick={download}>
              {downloading ? (
                <Spinner className="h-7 w-7" />
              ) : (
                <DownloadIcon className="h-6 w-6 text-gray-300" />
              )}
            </button>
          )}
        </div>
      ),
    },
    {
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'ORDER DETAILS'),
        'w-1/6',
      ),
      children: (
        <div className="w-full flex items-center justify-center">
          <Button
            kind={BUTTON_KIND.WHITE}
            size={BUTTON_SIZE.SMALL}
            buttonText="View"
            className="px-4 py-2.5 font-medium text-base text-gray-900 border-gray-300 rounded-md"
            onClick={() => {
              setIsOpenOrderDetailModal(true);
            }}
          />
        </div>
      ),
    },
    {
      style: { width: '16%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'UPLOAD DESIGN'),
      ),
      children: (
        <div className="w-full flex items-center justify-center">
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Upload"
            className="px-4 py-2.5 font-medium text-base text-gray-900"
            onClick={() => {
              setIsDropFile(true);
            }}
          />
        </div>
      ),
    },
    {
      style: { width: '11%' },
      className: classNames(
        orderHandler.stageTabHandler(
          orderStatus,
          'EXPORT PROFILES FOR AWAITING CLIENT',
        ),
        'lg:table-cell hidden',
      ),
      children: (
        <div className="flex items-center justify-center">
          <button onClick={exportCSV}>
            <ExternalLinkIcon className=" h-9 w-9 text-gray-300" />
          </button>
        </div>
      ),
    },
    {
      style: { width: '9.2%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'STAGE'),
        'text-[color:var(--grey-900,#111827)] text-sm font-normal leading-[150%] pl-[12px]',
      ),
      children: (
        <div className="flex items-center justify-center">
          <div>{dayOfStagedOrder} days</div>
        </div>
      ),
    },
    {
      style: { width: '9.2%' },
      className: classNames(
        orderHandler.stageTabHandler(orderStatus, 'PLACED'),
        'text-[color:var(--grey-900,#111827)] text-sm font-normal leading-[150%] pl-[5px]',
      ),
      children: (
        <div className="flex items-center justify-center">
          <div>{dayOfPlacedOrder} days</div>
        </div>
      ),
    },
    {
      className: 'w-1/12 pl-[12px]',
      children: (
        <div className="flex justify-center items-center">
          <Menu as="div" className="relative inline-block text-left">
            <div className="flex items-center">
              <Menu.Button className="appearance-none">
                <DotsVerticalIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                <Menu.Item>
                  <button
                    className="text-gray-700 w-full text-left px-4 py-2 text-sm flex items-center justify-start group"
                    onClick={() => handleClickLinkedOrg(orgId)}
                  >
                    {options[0]?.icon}
                    <p className="group-hover:text-brand-400">
                      {options[0]?.label}
                    </p>
                  </button>
                </Menu.Item>

                <Menu.Item>
                  <button
                    className="text-gray-700 w-full text-left px-4 py-2 text-sm flex items-center justify-start group"
                    onClick={() => setIsMoveOrder(true)}
                  >
                    <div className="relative ">
                      <UserIcon
                        className="stroke-2 absolute w-3 h-4 z-10 text-gray-500 left-2/4 top-2/4 group-hover:text-brand-400"
                        style={{ transform: `translate(-50%, -50%)` }}
                      />
                      <div className="transform rotate-135">
                        <RefreshIcon className="relaive stroke-1.3 z-20 h-6 w-6 text-gray-500 group-hover:text-brand-400" />
                      </div>
                    </div>
                    <p className="ml-4 group-hover:text-brand-400">
                      {options[1].label}
                    </p>
                  </button>
                </Menu.Item>

                {!!designFile?.file && (
                  <Menu.Item>
                    <button
                      className="text-gray-700 w-full text-left px-4 py-2 text-sm flex items-center justify-start group"
                      onClick={() => {
                        if (!uuid) {
                          setError(true);
                          return false;
                        }
                        return window.open(`preview/${uuid}`);
                      }}
                    >
                      {options[2]?.icon}
                      <p className="group-hover:text-brand-400">
                        {options[2]?.label}
                      </p>
                    </button>
                  </Menu.Item>
                )}

                <Menu.Item>
                  <button
                    className="text-gray-700 w-full text-left px-4 py-2 text-sm flex items-center justify-start group"
                    onClick={() => {
                      return handleChangeStageToReady();
                    }}
                  >
                    {options[3]?.icon}
                    <p className="group-hover:text-brand-400">
                      {options[3]?.label}
                    </p>
                  </button>
                </Menu.Item>
                {allowOrderNewFeature && (
                  <>
                    <Menu.Item>
                      <button
                        className="text-gray-700 w-full text-left px-4 py-2 text-sm flex items-center justify-start group"
                        onClick={() => {
                          setIsOpenOrderDetailModal(true);
                        }}
                      >
                        {options[4]?.icon}
                        <p className="group-hover:text-brand-400">
                          {options[4]?.label}
                        </p>
                      </button>
                    </Menu.Item>
                    <Menu.Item>
                      <button
                        className="text-gray-700 w-full text-left px-4 py-2 text-sm flex items-center justify-start group"
                        onClick={() => {
                          setIsPriority(!isPriority);
                          handlerOrderPriority(id, !isPriority);
                        }}
                      >
                        {options[5]?.icon}
                        <p className="group-hover:text-brand-400">
                          {options[5]?.label}
                        </p>
                      </button>
                    </Menu.Item>
                  </>
                )}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      ),
    },
  ];
};

export default OrderTableData;
