import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useLocalStorage } from 'usehooks-ts';
import { useHistory } from 'react-router-dom';
import {
  CurrencyDollarIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  XIcon,
} from '@heroicons/react/outline';
import classNames from '@/helpers/classNames';
import TandCs from '@/assets/documents/Website Terms and Conditions - Tapt By Hatch.pdf';
import Button, { BUTTON_KIND } from '@/components/Button';
import useAppState, { SettingsTab } from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';
import SubscriptionStatus from '@/types/SubscriptionStatus';
import accountsAPI from '@/api/accounts';
import {
  generateCheckoutBaseUrl,
  getTierByTotalProfiles,
} from '@/helpers/sealSubscriptions';

interface QuestionProps {
  icon: React.ReactNode;
  question: string;
  answer: React.ReactNode;
}

const Question: React.FC<QuestionProps> = ({ icon, question, answer }) => (
  <>
    <div className="flex self-start mt-3">
      {icon}
      <div className="ml-2 flex-auto text-gray-900 font-medium">{question}</div>
    </div>
    <div className="ml-8 mt-1 leading-5 text-sm text-gray-600 max-md:max-w-full">
      {answer}
    </div>
  </>
);

const questions: QuestionProps[] = [
  {
    icon: (
      <CurrencyDollarIcon className="shrink-0 w-6 aspect-square text-brand-500" />
    ),
    question: 'What is the platform fee?',
    answer: (
      <>
        <p>
          We&apos;ve introduced an annual Platform Utilisation Fee for all our
          clients. This fee is tiered depending on how many cards you have with
          us, and kicks off when you reach 5 cards (before that, the cost is
          $0).
        </p>
        <br />
        Don&apos;t worry, we&apos;re not rushing this change. As an existing
        client, you have until the 1st of June before this fee comes into
        effect. If you&apos;d like to activate the fee before that, you can!
        Just head to Manage Plan to get started.
      </>
    ),
  },
  {
    icon: (
      <SparklesIcon className="shrink-0 w-6 aspect-square text-brand-500" />
    ),
    question: 'What features do I get?',
    answer: (
      <>
        <p>
          In line with this change, we've made our premium Dashboard features
          available to everybody giving you access to:
        </p>
        <br />
        <ul className="list-disc pl-4">
          <li>Advanced analytics</li>
          <li>CRM Integration</li>
          <li>Profile Grouping</li>
          <li>So much more!</li>
        </ul>
        <br />
        Make sure you have a look around!
      </>
    ),
  },
  {
    icon: (
      <QuestionMarkCircleIcon className="shrink-0 w-6 aspect-square text-brand-500" />
    ),
    question: 'Need more help?',
    answer: (
      <>
        <p>
          Want more information about these changes? We've emailed you! You can
          also find more information under 'Manage Plan' on your Dashboard, or
          on our website in our{' '}
          <a
            href={TandCs}
            className="font-normal underline text-gray-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          .
        </p>
        <br />
        If you have any questions, please reach out to{' '}
        <a
          href="mailto:accounts@tapt.io"
          className="font-normal underline text-gray-500"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          accounts@tapt.io{' '}
        </a>
        .
      </>
    ),
  },
];

export default function PlatformFeeAnnouncementModal(): JSX.Element {
  const history = useHistory();
  const { selectSettingsTab } = useAppState();
  const { orgID, user, platformSubscriptionStatus } = useAuth();
  const hasInactivePlan =
    platformSubscriptionStatus === SubscriptionStatus.INACTIVE;
  const [isOpen, setIsOpen] = useState(false);
  const [isDontShowAgain, setIsDontShowAgain] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState<string>('');
  const [hidePlatformFeeModal, setHidePlatformFeeModal] = useLocalStorage(
    `hidePlatformFeeModal-${orgID}`,
    false,
  );

  const doNotShowAgainCheckBoxRef = useRef(null);

  const onClose = () => {
    if (isDontShowAgain) {
      setHidePlatformFeeModal(true);
    }
    setIsOpen(false);
  };
  const onSuccess = () => {
    selectSettingsTab(SettingsTab.MANAGE_PLAN);
    onClose();
    history.push(`/settings`);
  };

  const generateCheckoutUrl = (totalProfiles: number) => {
    const tier = getTierByTotalProfiles(totalProfiles);
    if (!tier || !user) return '';

    const attributes = {
      'checkout[email]': user.email,
      'checkout[billing_address][first_name]': user.first_name,
      'checkout[billing_address][last_name]': user.last_name,
    };

    return generateCheckoutBaseUrl(tier.variant_id, attributes);
  };

  useEffect(() => {
    if (hidePlatformFeeModal) return;

    if (hasInactivePlan) {
      setIsOpen(true);

      if (orgID) {
        accountsAPI.getSubscription(orgID).then((response) => {
          setCheckoutUrl(generateCheckoutUrl(response.data.total_profiles));
        });
      }
    }
  }, []);

  if (hidePlatformFeeModal) {
    return <></>;
  }

  if (!hasInactivePlan) {
    return <></>;
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={doNotShowAgainCheckBoxRef}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-40" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                'inline-block my-8 text-left align-middle transition-all transform bg-white shadow-xl w-full md:w-686 p-5 rounded-xl',
              )}
            >
              <div className="absolute right-0 top-0 pr-4 pt-5">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => setIsOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <section className="flex flex-col self-stretch">
                <header className="w-full text-2xl font-medium leading-8 text-gray-900 max-md:max-w-full">
                  Hi there! Tapt has recently updated its offerings.
                </header>
                <main>
                  {questions.map((question) => (
                    <Question key={question.question} {...question} />
                  ))}
                </main>
              </section>
              <div className="flex flex-col justify-between mt-5 space-y-5">
                <div className="w-full flex justify-center md:justify-end space-x-2">
                  <Button
                    kind={BUTTON_KIND.WHITE}
                    className="grow"
                    buttonText="Activate Now"
                    external
                    externalBlankTarget
                    href={checkoutUrl}
                  />

                  <Button
                    kind={BUTTON_KIND.PRIMARY}
                    className="grow"
                    onClick={onSuccess}
                    buttonText="Visit Manage Plan"
                  />
                </div>

                <div className="flex items-center justify-center w-full">
                  <input
                    id="model_checkbox"
                    type="checkbox"
                    className="w-4 h-4 border-gray-300 rounded focus:ring-brand-500 text-brand-500"
                    defaultChecked={isDontShowAgain}
                    onClick={() => {
                      setIsDontShowAgain(!isDontShowAgain);
                    }}
                    ref={doNotShowAgainCheckBoxRef}
                  />
                  <label className="text-sm ml-3" htmlFor="model_checkbox">
                    Don’t show me this again
                  </label>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
