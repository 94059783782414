import IDesign from '@/types/IDesign';
import IFile from '@/types/IFile';
import { ILinkedActivationKey } from './IActivationKey';
import { IOrganisationSettings } from './IOrganisation';

export interface IProfileQuickLink {
  order: number;
  title: string;
  value: string;
  title_from?: string | null;
  value_from?: string | null;
  title_to?: string | null;
  value_to?: string | null;
}

export interface IProfileSocialLink {
  order: number;
  type: string;
  value: string;
  personal: boolean;
  type_from?: string | null;
  value_from?: string | null;
  type_to?: string | null;
  value_to?: string | null;
}

export interface IProfileFileLink {
  file_id: number;
  file?: IFile;
  file_from?: IFile;
  order: number;
  title: string;
  title_from?: string | null;
  file_id_from?: number | null;
  title_to?: string | null;
  file_id_to?: number | null;
}

export interface IProfileQuickFileLink {
  file?: IFile;
  file_from?: IFile;
  file_id?: number;
  order: number;
  title: string;
  value?: string;
  title_from?: string | null;
  file_id_from?: number | null;
  value_from?: string | null;
  title_to?: string | null;
  file_id_to?: number | null;
  value_to?: string | null;
}

export interface IProfileLink {
  label: string;
  order: number;
  href: string;
  linkType: 'file' | 'link';
}

export enum IProfileAppAccess {
  not_connected = 'not_connected',
  pending = 'pending',
  connected = 'connected',
}

export default interface IProfile {
  city: string | null;
  company_name: string | null;
  company_phone_number: string | null;
  extra_company_phone_numbers: string[] | null;
  company_website: string | null;
  contact_exchange_enabled: boolean;
  country: string | null;
  design_id?: number;
  design?: IDesign;
  email: string | null;
  extra_emails: string[] | null;
  first_name: string | null;
  id: number;
  inserted_at: string | null;
  is_editable: boolean;
  job_title: string | null;
  last_name: string | null;
  pronouns: string | null;
  linked_card_activation?: ILinkedActivationKey[];
  linked_card_id?: number[];
  middle_name: string | null;
  mobile_number: string | null;
  extra_mobile_numbers: string[] | null;
  note: string | null;
  order_uuid: string | null;
  organisation_id: number;
  organisation_name: string;
  photo_file_id: number | null;
  photo: IFile;
  post_code: string | null;
  profile_file_links: IProfileQuickFileLink[];
  profile_hash: string;
  profile_page_url: string;
  profile_public_url: string | null;
  profile_quick_links: IProfileQuickFileLink[];
  profile_social_links: IProfileSocialLink[];
  state: string | null;
  status: string;
  street_address: string | null;
  type: string | null;
  group_id: number | null;
  group_name: string | null;
  group_subheading: string | null;
  replacement_profile_id: number | null;
  replacement_profile_hash: string | null;
  app_access: IProfileAppAccess;
  map_enabled: boolean;
  location: [lat: number, lng: number] | null;
  editable: boolean;
}

export interface IProfilePublic {
  analytics_view_count: number;
  city: string | null;
  company_name: string | null;
  company_phone_number: string | null;
  extra_company_phone_numbers: string[] | null;
  company_website: string | null;
  contact_exchange_enabled: boolean;
  country: string | null;
  design_id?: number;
  design?: IDesign;
  email: string | null;
  extra_emails: string[] | null;
  first_name: string | null;
  id?: number;
  inserted_at?: string | null;
  is_editable: boolean;
  job_title: string | null;
  last_name: string | null;
  pronouns: string | null;
  linked_card_id?: number[];
  middle_name: string | null;
  mobile_number: string | null;
  extra_mobile_numbers: string[] | null;
  note: string | null;
  organisation_id: number;
  organisation_name: string;
  photo_file_id?: number | null;
  photo?: IFile;
  post_code: string | null;
  profile_file_links: IProfileFileLink[];
  profile_hash?: string | null;
  profile_public_url?: string | null;
  profile_quick_links: IProfileQuickLink[];
  profile_social_links: IProfileSocialLink[];
  settings: IOrganisationSettings;
  state: string | null;
  status: string | null;
  street_address: string | null;
  type?: string | null;
  vcf_url: string;
  add_contact_page: string;
  group_id: number | null;
  group_name: string | null;
  group_subheading: string | null;
}
