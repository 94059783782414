import { ReactNode } from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';

interface IInfoPanelContainer {
  alert?: ReactNode;
  footerContent?: () => JSX.Element | boolean;
  hint?: string;
  information?: string | JSX.Element;
  isRadio?: boolean;
  loading?: boolean;
  title: string | JSX.Element;
  children: ReactNode;
  border?: string | false;
  padding?: string | false;
  marginTop?: string;
  additionalContents?: JSX.Element;
  footerContentClassName?: string;
}

const InfoPanelContainer: React.FC<IInfoPanelContainer> = ({
  alert,
  children,
  footerContent,
  hint,
  information,
  isRadio,
  title,
  additionalContents,
  border = 'rounded-md border border-gray-300',
  padding = 'p-4 sm:p-6',
  marginTop = '',
  footerContentClassName = 'px-4 py-3 border-t border-gray-200 text-right sm:px-6 space-x-3 flex justify-end',
}) => {
  return (
    <div className={marginTop}>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {title}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {information && information}
            </p>
            {hint && (
              <p className="mt-1 text-sm text-gray-500">
                <b>Hint:</b>
                {` ${hint}`}
              </p>
            )}
            {additionalContents && (
              <div className="mt-3">{additionalContents}</div>
            )}
            {alert && (
              <div className="rounded-md bg-brand-100 text-white p-4 mt-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon
                      className="h-5 w-5 text-gray-900"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">{alert}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          {!isRadio && (
            <div className={border || ''}>
              <div className={`${padding || ''} rounded-md bg-white`}>
                {children}
              </div>
              {footerContent && (
                <div className={footerContentClassName}>{footerContent()}</div>
              )}
            </div>
          )}
          {isRadio && children}
        </div>
      </div>
    </div>
  );
};

export default InfoPanelContainer;
