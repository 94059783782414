const ExchangeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33301 14.1667L6.66634 10.8333M6.66634 5.83333H16.6663H6.66634ZM16.6663 5.83333L13.333 2.5L16.6663 5.83333ZM16.6663 5.83333L13.333 9.16667L16.6663 5.83333ZM13.333 14.1667H3.33301H13.333ZM3.33301 14.1667L6.66634 17.5L3.33301 14.1667Z"
        stroke="#D1D5DB"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExchangeIcon;
