import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Layout from '@/components/Layout';
import Pagination from '@/components/Pagination';
import Sort from '@/components/Sort';
import Search from '@/components/Search';
import useAuth from '@/hooks/useAuth';
import Button, { BUTTON_KIND } from '@/components/Button';
import SuccessAlert from '@/components/SuccessAlert';
import MESSAGES from '@/constants/messages-en';
import organisationsAPI from '@/api/organisations';
import LoadingAnimation from '@/components/LoadingAnimation';
import ProfilesGroupsPseudoTabs from '@/components/ProfilesGroupsPseudoTabs';
import { useQuery } from 'react-query';
import { StarIcon } from '@heroicons/react/outline';
import {
  ProfileGroupCard,
  ProfileGroupItemRow,
} from '@/components/GroupListItem';
import clsx from 'clsx';

interface LocationState {
  success: string;
  // other properties...
}

function GroupListPage() {
  const history = useHistory();
  const { orgID, user, userRole } = useAuth();
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');

  const { data: groups } = useQuery(
    ['listGroups', page, pageSize, sort, order, search],
    () => {
      return listGroups();
    },
    {
      enabled: orgID !== undefined && user !== undefined,
    },
  );

  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [success, setSuccess] = useState<string | undefined>(undefined);

  const state = location.state as LocationState;

  async function listGroups(
    newPage: number = page,
    newPageSize: number = pageSize,
    newSort: string = sort,
    newOrder: string = order,
    newSearch: string = search,
  ) {
    const { data: groups } = await organisationsAPI.listOrganisationGroups({
      orgID,
      page: newPage,
      pageSize: newPageSize,
      sort: newSort,
      order: newOrder,
      search: newSearch,
    });

    return groups;
  }

  useEffect(() => {
    if (state) {
      setSuccess(state.success);
    }
  }, [state]);

  return (
    <Layout pageName="My Profiles" className="bg-gray-50">
      <div className="space-y-8">
        <div>
          <ProfilesGroupsPseudoTabs activeTab="groups" />
          {success && <SuccessAlert message={success} />}
          <div className="flex flex-col xl:flex-row justify-between space-y-4 xl:items-center xl:space-y-0 pb-4 lg:py-4">
            <div className="flex flex-row p-2 bg-brand-50 border border-brand-200 rounded-md text-brand-900 text-sm font-medium gap-2">
              <StarIcon className="w-5 h-5 flex-shrink-0 text-brand-500" />
              <div className="flex flex-col lg:flex-row gap-2">
                <div className="font-medium text-sm text-brand-900 truncate">
                  Grouping has been updated!
                </div>
                <a
                  href="https://help.tapt.io/en/articles/9624566-profile-grouping"
                  className="underline"
                  target="_blank"
                >
                  Learn more.
                </a>
              </div>
            </div>
            <div className="flex flex-col xl:flex-row xl:space-x-3 justify-end xl:items-center space-y-4 xl:space-y-0">
              {groups && groups.data.length > 0 && (
                <Search
                  id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}`}
                  search={search}
                  setSearch={setSearch}
                  fetchQuery={(searchQuery) =>
                    listGroups(page, pageSize, sort, order, searchQuery)
                  }
                />
              )}
              <div className="flex flex-row space-x-3 justify-end">
                {groups && groups.data.length > 0 && (
                  <Sort
                    id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}`}
                    options={[
                      {
                        sort: 'date',
                        order: 'desc',
                        label: 'Newest first',
                      },
                      {
                        sort: 'date',
                        order: 'asc',
                        label: 'Oldest first',
                      },
                      {
                        sort: 'first_name',
                        order: 'asc',
                        label: 'Name (A-Z)',
                      },
                      {
                        sort: 'first_name',
                        order: 'desc',
                        label: 'Name (Z-A)',
                      },
                    ]}
                    sort={sort}
                    setSort={setSort}
                    order={order}
                    setOrder={setOrder}
                    fetchQuery={(sortQuery, orderQuery) =>
                      listGroups(page, pageSize, sort, sortQuery, orderQuery)
                    }
                  />
                )}
                {userRole === 'org_admin' && (
                  <Button
                    kind={BUTTON_KIND.PRIMARY}
                    buttonText="Create Group"
                    onClick={() => history.push(`/create-group`)}
                  />
                )}
              </div>
            </div>
          </div>
          {groups ? (
            groups.data.length > 0 ? (
              <>
                <div className="hidden xl:block relative my-6 border border-solid rounded-lg overflow-hidden">
                  <table
                    className={clsx('min-w-full', {
                      'opacity-40': isPaginationLoading,
                    })}
                  >
                    <thead className="bg-gray-100 border-b border-gray-200">
                      <tr className="uppercase text-gray-900 text-sm">
                        <th
                          scope="col"
                          className="font-medium py-3 px-6 text-left"
                        >
                          Group id
                        </th>
                        <th
                          scope="col"
                          className="font-medium py-3 px-6 w-1/2 text-left"
                        >
                          Name
                        </th>
                        <th scope="col" className="font-medium py-3 px-6">
                          Managers
                        </th>
                        <th scope="col" className="font-medium py-3 px-6">
                          Profiles
                        </th>
                        <th
                          scope="col"
                          className="font-medium py-3 px-6 text-left"
                        >
                          Edit
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {groups.data.map((group, index) => (
                        <ProfileGroupItemRow key={index} group={group} />
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="block xl:hidden space-y-4">
                  <div className="border border-gray-300 bg-gray-100 text-gray-900 text-sm uppercase rounded-md text-center p-2 font-medium">
                    Name, group id, manager &amp; profiles
                  </div>
                  {groups &&
                    groups.data.map((group, index) => (
                      <ProfileGroupCard
                        key={index}
                        groupHash={group.group_hash}
                        noOfEditors={group.editors.length}
                        noOfProfiles={group.profiles_count}
                        groupName={group.name}
                      />
                    ))}
                </div>
                {isPaginationLoading && (
                  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                  </div>
                )}
              </>
            ) : (
              <div className="flex flex-col items-center py-32 space-y-2">
                <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                  {MESSAGES.groups.list.empty.heading}
                </h3>
                <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                  {userRole === 'org_admin'
                    ? MESSAGES.groups.list.empty.description.admin
                    : MESSAGES.groups.list.empty.description.editor}
                </p>
                <a
                  href="https://tapt.io/pages/how-to-use"
                  rel="noreferrer"
                  target="_blank"
                  className="underline"
                >
                  Learn more about grouping.
                </a>
              </div>
            )
          ) : (
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
            </div>
          )}
        </div>
        <Pagination
          id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}`}
          className="bg-gray-50"
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          fetchQuery={(pageQuery, pageSizeQuery) => {
            return listGroups(pageQuery, pageSizeQuery, sort, order, search);
          }}
          setIsLoading={setIsPaginationLoading}
        />
      </div>
    </Layout>
  );
}

export default GroupListPage;
