import { ReactNode } from 'react';
import classNames from '@/helpers/classNames';

interface IInfoPanelFooter {
  children: ReactNode;
  className?: string;
  wrapClassName?: string;
  childrenClassName?: string;
  fullWidth?: boolean;
}

export default function InfoPanelFooter({
  children,
  className = '',
  wrapClassName = 'fixed left-0 bottom-0 w-full lg:w-footer bg-white border-t border-gray-300 shadow-sm transition transition-bottom',
  childrenClassName = 'px-4 py-3.5 w-full lg:w-auto',
  fullWidth = false,
}: IInfoPanelFooter) {
  return (
    <div
      className={classNames(
        wrapClassName,
        fullWidth ? '' : 'border-l lg:left-64',
        className,
      )}
    >
      <div
        className={classNames(
          childrenClassName,
          fullWidth ? 'max-w-7xl mx-auto' : '',
        )}
      >
        {children}
      </div>
    </div>
  );
}
