import { Fragment, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import SidebarNavigation from '@/components/SidebarNavigation';
import Header from '@/components/Header';

interface ILayout {
  pageName: string;
  formattedPageName?: React.ReactNode;
  showPageName?: boolean;
  showPageNameBorder?: boolean;
  description?: string;
  location?: {
    fromName: string;
    fromURL: string;
  };
  children: React.ReactNode;
  className?: string;
  wrapClassName?: string;
  contentClassName?: string;
  rightTitle?: React.ReactNode;
  descriptionClassName?: string;
  getHeaderHeight?: (e: any) => void;
}

const Layout: React.FC<ILayout> = ({
  children,
  description,
  descriptionClassName = 'max-w-3xl mt-5 text-base leading-6 text-gray-500',
  location,
  pageName,
  formattedPageName,
  showPageName = true,
  showPageNameBorder = false,
  className = '',
  wrapClassName = 'flex-grow flex flex-col',
  contentClassName = '',
  rightTitle = <></>,
  getHeaderHeight,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const headerRef = useRef<HTMLDivElement>(null);

  const debounce = useRef<any>(null);

  const handleResize = () => {
    if (debounce?.current) {
      clearTimeout(debounce?.current);
    }
    debounce.current = setTimeout(() => {
      const node = headerRef?.current;
      const height = node?.getBoundingClientRect()?.height || 0;
      const nodeStyle = node && window.getComputedStyle(node);
      const slideMarginBottom =
        (nodeStyle &&
          nodeStyle.getPropertyValue('margin-bottom')?.replaceAll('px', '')) ||
        0;

      const currentHeight = height + Number(slideMarginBottom);

      if (currentHeight) {
        getHeaderHeight && getHeaderHeight(currentHeight);
      }
    }, 200);
  };

  useEffect(() => {
    getHeaderHeight && handleResize();
  }, []);

  getHeaderHeight && window.addEventListener('resize', handleResize);

  return (
    <div
      className="h-screen flex overflow-hidden bg-white"
      data-component="layout"
    >
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 lg:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="flex flex-col w-64">
              <SidebarNavigation
                pageName={pageName}
                setSidebarOpen={setSidebarOpen}
              />
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden bg-white lg:flex lg:flex-shrink-0 border-r border-gray-200">
        <div className="flex flex-col w-64 justify-between">
          <SidebarNavigation
            pageName={pageName}
            setSidebarOpen={setSidebarOpen}
          />
        </div>
      </div>

      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <Header setSidebarOpen={setSidebarOpen} />
        <main
          className={`
          ${
            pageName === 'HomePage'
              ? 'flex-1 relative z-0 focus:outline-none flex flex-col overflow-y-auto bg-gray-50'
              : `flex-1 overflow-x-hidden relative z-0 overflow-y-scroll focus:outline-none flex flex-col ${className}`
          }`}
        >
          <div className={`${showPageName && 'py-8'}  ${wrapClassName}`}>
            <div
              className={clsx(
                pageName === 'Dashboard' &&
                  'max-w-8xl 2xl:max-w-7xl md:px-13 2xl:md:px-8',
                !['Dashboard', ''].includes(pageName) &&
                  'max-w-[90%] 2xl:max-w-7xl md:px-8',
                `w-full mx-auto flex-grow ${contentClassName}`,
              )}
            >
              {location && (
                <div className="pb-3 md:pb-5 md:mb-8 md:border-b border-gray-200">
                  <Link
                    to={location.fromURL}
                    className="text-gray-700 hover:text-gray-700 flex items-center"
                  >
                    <ArrowNarrowLeftIcon className="h-4 w-4 mr-2" />
                    {`Back to ${location.fromName}`}
                  </Link>
                </div>
              )}
              {(showPageName || description) && (
                <div
                  ref={headerRef}
                  className={clsx(
                    'mb-6 md:mb-12 w-full',
                    ['Shopify Orders', 'Shop', 'Settings'].includes(pageName) &&
                      'relative',
                  )}
                >
                  <div
                    className={`flex justify-between
                    ${showPageNameBorder && 'border-b-2 pb-5'}
                    ${rightTitle ? 'items-start' : 'items-center'}
                    `}
                  >
                    {showPageName && (
                      <h2
                        className="text-3xl md:text-4xl leading-10 font-medium text-gray-900"
                        id="layout-title"
                      >
                        {formattedPageName || pageName}
                      </h2>
                    )}
                    {rightTitle}
                  </div>
                  {description && (
                    <p className={descriptionClassName}>{description}</p>
                  )}
                </div>
              )}
              {children}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export { Layout as default };
