import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/outline';
import { Box } from '@material-ui/core';
import Button, { BUTTON_KIND, BUTTON_SIZE } from '@/components/Button';
// eslint-disable-next-line import/no-cycle
import CommentBox from './CommentBox';
// eslint-disable-next-line import/no-cycle
import { IOrderFeedbackItem } from './OrderDetails';
import adminAPI from '@/api/admin';
import IOrder from '@/types/IOrderV2';

interface IOrderDetailCommentBox {
  orderComment: IOrderFeedbackItem[];
  newComment: string;
  setNewComment: Dispatch<SetStateAction<string>>;
  newCommentFile: File[];
  setNewCommentFile: Dispatch<SetStateAction<File[]>>;
  onFileSelected: (files: FileList) => void;
  setOrderDesignFile: Dispatch<SetStateAction<File | undefined>>;
  handleSendOrderFeedback: (files: File[]) => void;
  orderDesignFile: File | undefined;
  orderId: number;
  isRerender: boolean;
  setIsReRender: Dispatch<SetStateAction<boolean>>;
  order: IOrder;
}

export default function OrderDetailCommentBox({
  orderComment,
  newComment,
  setNewComment,
  newCommentFile,
  setNewCommentFile,
  onFileSelected,
  handleSendOrderFeedback,
  orderDesignFile,
  setOrderDesignFile,
  orderId,
  isRerender,
  setIsReRender,
  order,
}: IOrderDetailCommentBox) {
  const [updateCommentValue, setUpdateCommentValue] = useState<
    string | undefined
  >();
  const [updateCommentId, setUpdateCommentId] = useState<number>();

  const handleUpdateComment = () => {
    updateCommentValue &&
      updateCommentId &&
      adminAPI
        .updateChangeRequestComment(orderId, updateCommentId, {
          note: updateCommentValue,
        })
        .then(() => {
          setUpdateCommentId(undefined);
          setUpdateCommentValue(undefined);
          setIsReRender(!isRerender);
        })
        .catch((err) => {
          console.log(err);
        });
  };
  return (
    <Box className="h-full">
      <CommentBox
        order={order}
        orderComment={orderComment}
        newComment={newComment}
        newCommentFile={newCommentFile}
        resetNewComment={() => {
          setNewComment('');
          setNewCommentFile([]);
        }}
        orderDesignFile={orderDesignFile}
        resetOrderDesignFile={() => setOrderDesignFile(undefined)}
        orderId={orderId}
        isRerender={isRerender}
        setIsReRender={setIsReRender}
        updateCommentValue={updateCommentValue}
        setUpdateCommentValue={setUpdateCommentValue}
        setUpdateCommentId={setUpdateCommentId}
      />
      <div className="w-full border rounded-b-md border-gray-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
        <div className="px-5 py-3 bg-gray-50 rounded-t-lg dark:bg-gray-800 h-28">
          <textarea
            id="comment"
            className="w-full px-0 text-sm text-gray-900 bg-gray-50 border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400 "
            placeholder="Write text here ..."
            value={updateCommentValue || newComment}
            onChange={(e) => {
              updateCommentValue
                ? setUpdateCommentValue(e.target.value)
                : setNewComment(e.target.value);
            }}
          />
        </div>
        <div className="flex items-center justify-between px-3 py-4 border-t dark:border-gray-600 bg-gray-50 ">
          <Button
            kind={BUTTON_KIND.PRIMARY}
            size={BUTTON_SIZE.XSMALL}
            icon={<PaperAirplaneIcon />}
            buttonText={updateCommentValue ? 'Edit message' : 'Send message'}
            onClick={() => {
              updateCommentValue
                ? handleUpdateComment()
                : handleSendOrderFeedback(newCommentFile);
            }}
          />
          <div className="flex space-x-1 relative">
            <button
              type="button"
              className="inline-flex justify-center p-2 text-gray-500 rounded  hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Attach file</span>
            </button>
            <input
              type="file"
              className="absolute top-0 left-0 w-full h-full opacity-0 "
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!e?.target?.files || !e.target?.files) return;
                onFileSelected(e.target.files);
              }}
              multiple
            />
          </div>
        </div>
      </div>
    </Box>
  );
}
