const CorrectIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={13}
      viewBox="0 0 17 13"
      fill="none"
    >
      <path
        d="M1.37598 7.66382L5.37598 11.6638L15.376 1.66382"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CorrectIcon;
