import { InformationCircleIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import PurchasingAdditionalCardModal from '@/components/SettingsPage/ManagePlan/PurchasingAdditionalCardModal';

export default function FreePlanExistingOrgDetail() {
  const [
    isPurchasingAdditionalCardModalOpen,
    setIsPurchasingAdditionalCardModalOpen,
  ] = useState(false);

  const planLabel = '1-4 profiles';

  return (
    <div className="w-full">
      <article className="flex flex-col justify-center p-6 text-base bg-white rounded-lg border border-gray-200 max-md:px-5 max-md:max-w-full">
        <header className="text-xl font-medium text-gray-900 max-md:max-w-full">
          Plan
        </header>
        <section className="flex flex-wrap gap-1.5 justify-between content-center mt-6 max-md:max-w-full">
          <p className="text-gray-900 grow leading-6 whitespace-nowrap">
            Plan type: <span className="text-gray-500">{planLabel}</span>
          </p>
          <div className="flex gap-1.5 justify-between text-right leading-[150%] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
            <InformationCircleIcon className="w-6 aspect-square text-brand-500" />
            <a
              href="#"
              onClick={() => setIsPurchasingAdditionalCardModalOpen(true)}
              className="flex-auto underline text-gray-500"
            >
              Purchasing additional cards
            </a>
          </div>
        </section>
        <section className="mt-3 leading-6 max-md:max-w-full">
          <p>
            Status: <span className="text-emerald-400">Active</span>
          </p>
        </section>
      </article>
      <div className="flex flex-col justify-center p-6 mt-6 bg-white rounded-lg border border-solid border-gray-200 max-md:px-5 max-md:max-w-full">
        <div className="text-xl font-medium text-gray-900 max-md:max-w-full">
          Items
        </div>
        <div className="flex gap-5 justify-between mt-6 max-md:flex-wrap max-md:max-w-full">
          <div className="flex-auto text-base leading-6 text-gray-900">
            Platform maintenance fee ({planLabel})
          </div>
          <div className="text-lg font-medium leading-6 text-gray-500">
            Free
          </div>
        </div>
      </div>

      <PurchasingAdditionalCardModal
        isOpen={isPurchasingAdditionalCardModalOpen}
        setIsOpen={setIsPurchasingAdditionalCardModalOpen}
      />
    </div>
  );
}
