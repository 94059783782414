import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PlusIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';
import Layout from '@/components/Layout';
import { IProfileQuickFileLink, IProfileSocialLink } from '@/types/IProfile';
import profilesAPI from '@/api/profiles';
import designsAPI from '@/api/designs';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import EditSocialLink from '@/components/EditSocialLink';
import EditQuickLink from '@/components/EditQuickLink';
import EditFileLink from '@/components/EditFileLink';
import Modal from '@/components/Modal';
import ModalNoSuccess from '@/components/ModalNoSuccess';
import ToggleField from '@/components/ToggleField';
import useAuth from '@/hooks/useAuth';
import Button, { BUTTON_KIND } from '@/components/Button';
import useAppState from '@/hooks/useAppState';
import ErrorAlert from '@/components/ErrorAlert';
import CardDesign from '@/components/CardDesign';
import CompanyInfoForm from '@/containers/CompanyInfoForm';
import DesignTemplate from '@/assets/documents/Tapt Design Template.pdf';
import IFile from '@/types/IFile';
import MESSAGES from '@/constants/messages-en';
import validateUrl from '@/helpers/validateUrl';

export default function EditSharedProfilePage({
  match,
}: {
  match: { params: { profile_status: string } };
}): JSX.Element {
  const { orgID } = useAuth();
  const profileStatus = match.params.profile_status;
  const { multipleProfiles } = useAppState();
  const history = useHistory();
  const [error, setError] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState('Click to edit or clear');
  const [companyNameError, setCompanyNameError] = useState(false);
  const [companyWebsite, setCompanyWebsite] = useState(
    'Click to edit or clear',
  );
  const [companyWebsiteError, setCompanyWebsiteError] = useState(false);
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState(
    'Click to edit or clear',
  );
  const [companyPhoneNumberError, setCompanyPhoneNumberError] = useState(false);
  const [extraCompanyPhoneNumbers, setExtraCompanyPhoneNumbers] = useState<
    string[]
  >([]);
  const [country, setCountry] = useState('Click to edit or clear');
  const [streetAddress, setStreetAddress] = useState('Click to edit or clear');
  const [streetAddressError, setStreetAddressError] = useState(false);
  const [city, setCity] = useState('Click to edit or clear');
  const [stateProvince, setStateProvince] = useState('Click to edit or clear');
  const [postCode, setPostCode] = useState('Click to edit or clear');
  const [profileSocialLinks, setProfileSocialLinks] = useState<
    IProfileSocialLink[]
  >([]);
  const [socialLinkErrorIndex, setSocialLinkErrorIndex] = useState(-1);
  const [profileQuickFileLinks, setProfileQuickFileLinks] = useState<
    IProfileQuickFileLink[]
  >([]);
  const [companyNameToggle, setCompanyNameToggle] = useState<boolean>(false);
  const [companyPhoneNumberToggle, setCompanyPhoneNumberToggle] =
    useState<boolean>(false);
  const [companyWebsiteToggle, setCompanyWebsiteToggle] =
    useState<boolean>(false);
  const [streetAddressToggle, setStreetAddressToggle] =
    useState<boolean>(false);
  const [primaryC, setPrimaryC] = useState<string>('');
  const [primaryM, setPrimaryM] = useState<string>('');
  const [primaryY, setPrimaryY] = useState<string>('');
  const [primaryK, setPrimaryK] = useState<string>('');
  const [secondaryC, setSecondaryC] = useState<string>('');
  const [secondaryM, setSecondaryM] = useState<string>('');
  const [secondaryY, setSecondaryY] = useState<string>('');
  const [secondaryK, setSecondaryK] = useState<string>('');
  const [designFile, setDesignFile] = useState<IFile>();
  const [logoFile, setLogoFile] = useState<IFile>();
  const [editCardDesign, setEditCardDesign] = useState<boolean>(false);
  const [cardDesign, setCardDesign] = useState<number>(1);
  const [cardDesignError, setCardDesignError] = useState<boolean>(false);
  const [editContactExchangeEnabled, setEditContactExchangeEnabled] =
    useState<boolean>(false);
  const [contactExchangeEnabled, setContactExchangeEnabled] =
    useState<boolean>(false);
  const [editCardholderEditingEnabled, setEditCardholderEditingEnabled] =
    useState<boolean>(false);
  const [cardholderEditingEnabled, setCardholderEditingEnabled] =
    useState<boolean>(false);
  const [designNote, setDesignNote] = useState<string>('');

  const [isEnableEditingOpen, setIsEnableEditingOpen] =
    useState<boolean>(false);
  const [locationLatLng, setLocationLatLng] =
    useState<google.maps.LatLngLiteral>();
  const [mapEnabled, setMapEnabled] = useState<boolean>(false);

  const designFileID = designFile ? designFile.id : null;
  const logoFileID = logoFile ? logoFile.id : null;

  const [isDisplayOpen, setIsDisplayOpen] = useState(false);

  const showSharedProfile = useCallback(() => {
    if (orgID) {
      profilesAPI
        .showProfilesShared(orgID, multipleProfiles)
        .then((res) => {
          const data = res?.data;

          if (data?.data?.company_name) setCompanyName(data.data.company_name);
          if (data?.data?.company_website)
            setCompanyWebsite(data.data.company_website);
          if (data?.data?.company_phone_number)
            setCompanyPhoneNumber(data.data.company_phone_number);
          if (data?.data?.extra_company_phone_numbers)
            setExtraCompanyPhoneNumbers(data.data.extra_company_phone_numbers);
          if (data?.data?.country) setCountry(data.data.country);
          if (data?.data?.street_address)
            setStreetAddress(data.data.street_address);
          if (data?.data?.city) setCity(data.data.city);
          if (data?.data?.state) setStateProvince(data.data.state);
          if (data?.data?.post_code) setPostCode(data.data.post_code);

          if (data?.data?.contact_exchange_enabled) {
            setEditContactExchangeEnabled(true);
            setContactExchangeEnabled(data.data.contact_exchange_enabled);
          }

          if (data?.data?.profile_social_links?.length > 0)
            setProfileSocialLinks(
              data.data.profile_social_links.map((socialLink, index) => ({
                ...socialLink,
                order: 9990 + index,
                type: socialLink.type_from || '',
                value: socialLink.value_from || '',
              })),
            );

          let newLinksArray: IProfileQuickFileLink[] = [];
          if (data?.data?.profile_quick_links?.length > 0) {
            newLinksArray = data.data.profile_quick_links.map(
              (quickLink, index) => ({
                ...quickLink,
                order: 9990 + index,
                title: quickLink.title_from || '',
                value: quickLink.value_from || '',
              }),
            );
          }

          if (data?.data?.profile_file_links?.length > 0) {
            newLinksArray = [
              ...newLinksArray,
              ...data.data.profile_file_links.map((fileLink, index) => ({
                ...fileLink,
                order: 9990 + index,
                title: fileLink.title_from || '',
                file: fileLink.file_from || undefined,
                file_id: fileLink.file_id_from || undefined,
              })),
            ];
          }

          newLinksArray.sort(
            (a: IProfileQuickFileLink, b: IProfileQuickFileLink) => {
              return a.order - b.order;
            },
          );
          setProfileQuickFileLinks(
            newLinksArray.map((item: IProfileQuickFileLink, index: number) => ({
              ...item,
              order: index,
            })),
          );
        })
        .finally(() => {});
    }
  }, [orgID, multipleProfiles]);

  const updateProfilesShared = useCallback(
    (submitForPrint = false, emailProfileEditing?: boolean) => {
      if (orgID) {
        let sharedProfileStatus = profileStatus;
        if (
          cardholderEditingEnabled &&
          (profileStatus === 'active' ||
            (profileStatus === 'unprinted' && submitForPrint))
        ) {
          sharedProfileStatus = 'editing';
        } else if (submitForPrint) {
          sharedProfileStatus = 'active';
        }

        // TODO: Add extraEmails, extraPhoneNumbers, extraCompanyPhoneNumbers
        profilesAPI
          .updateProfilesSharedNew({
            orgID,
            body: {
              ids: multipleProfiles,
              email_profile_editing: emailProfileEditing,
              profile: {
                ...(companyName !== 'Click to edit or clear'
                  ? { company_name: companyName }
                  : {}),
                ...(companyWebsite !== 'Click to edit or clear'
                  ? { company_website: companyWebsite }
                  : {}),
                ...(companyPhoneNumber !== 'Click to edit or clear'
                  ? { company_phone_number: companyPhoneNumber }
                  : {}),
                ...(country !== 'Click to edit or clear' ? { country } : {}),
                ...(streetAddress !== 'Click to edit or clear'
                  ? { street_address: streetAddress.trim() }
                  : {}),
                ...(city !== 'Click to edit or clear' ? { city } : {}),
                ...(stateProvince !== 'Click to edit or clear'
                  ? { state: stateProvince }
                  : {}),
                ...(postCode !== 'Click to edit or clear'
                  ? { post_code: postCode }
                  : {}),
                ...(editContactExchangeEnabled
                  ? { contact_exchange_enabled: contactExchangeEnabled }
                  : {}),
                ...(editCardholderEditingEnabled
                  ? { is_editable: cardholderEditingEnabled }
                  : {}),
                profile_social_links: profileSocialLinks.map((socialLink) => ({
                  ...socialLink,
                  ...(socialLink.order !== -1
                    ? {
                        type_to: socialLink.type,
                        value_to: socialLink.value,
                      }
                    : {}),
                })),
                profile_quick_links: profileQuickFileLinks
                  .filter((item) => !!item.value)
                  .map((quickLink) => ({
                    ...quickLink,
                    ...(quickLink.order !== -1
                      ? {
                          title_to: quickLink.title,
                          value_to: quickLink.value,
                        }
                      : {}),
                  })),
                profile_file_links: profileQuickFileLinks
                  .filter((item) => !!item.file_id)
                  .map((fileLink) => ({
                    ...fileLink,
                    ...(fileLink.order !== -1
                      ? {
                          title_to: fileLink.title,
                          file_id_to: fileLink.file_id,
                        }
                      : {}),
                  })),
                status: sharedProfileStatus,
                location:
                  locationLatLng?.lat && locationLatLng?.lng
                    ? [locationLatLng?.lat, locationLatLng?.lng]
                    : null,
                map_enabled: mapEnabled,
              },
            },
          })
          .then(() => {
            if (!editCardDesign)
              history.push('/', {
                success: MESSAGES.profile.edit.successMultiple,
              });

            const design = {
              organisation_id: orgID,
              ...(primaryC || primaryM || primaryY || primaryK
                ? {
                    primary_color: [
                      primaryC ? Number(primaryC) : Number('0'),
                      primaryM ? Number(primaryM) : Number('0'),
                      primaryY ? Number(primaryY) : Number('0'),
                      primaryK ? Number(primaryK) : Number('0'),
                    ],
                  }
                : {}),
              ...(secondaryC || secondaryM || secondaryY || secondaryK
                ? {
                    secondary_color: [
                      secondaryC ? Number(secondaryC) : Number('0'),
                      secondaryM ? Number(secondaryM) : Number('0'),
                      secondaryY ? Number(secondaryY) : Number('0'),
                      secondaryK ? Number(secondaryK) : Number('0'),
                    ],
                  }
                : {}),
              description: designNote,
              design_file_id: designFileID,
              logo_file_id: logoFileID,
              flags: {
                company_name: companyNameToggle,
                company_phone_number: companyPhoneNumberToggle,
                company_website: companyWebsiteToggle,
                street_address: streetAddressToggle,
              },
            };

            if (cardDesign !== 1) {
              designsAPI
                .createDesign({
                  orgID,
                  body: {
                    design,
                  },
                })
                .then((res) => {
                  profilesAPI
                    .updateProfilesSharedNew({
                      orgID,
                      body: {
                        ids: multipleProfiles,
                        profile: {
                          design_id: res.data?.data?.id,
                        },
                      },
                    })
                    .then(() =>
                      history.push('/', {
                        success: MESSAGES.profile.edit.successMultiple,
                      }),
                    )
                    .catch(() => setError(true))
                    .finally(() => {});
                })
                .then(() =>
                  history.push('/', {
                    success: MESSAGES.profile.edit.successMultiple,
                  }),
                )
                .catch(() => setError(true))
                .finally(() => {});
            } else {
              history.push('/', {
                success: MESSAGES.profile.edit.successMultiple,
              });
            }
          })
          .catch(() => setError(true))
          .finally(() => {});
      }
    },
    [
      cardDesign,
      cardholderEditingEnabled,
      city,
      companyName,
      companyNameToggle,
      companyPhoneNumber,
      companyPhoneNumberToggle,
      companyWebsite,
      companyWebsiteToggle,
      contactExchangeEnabled,
      country,
      designFileID,
      designNote,
      editCardDesign,
      editCardholderEditingEnabled,
      editContactExchangeEnabled,
      history,
      logoFileID,
      multipleProfiles,
      orgID,
      postCode,
      mapEnabled,
      locationLatLng,
      primaryC,
      primaryK,
      primaryM,
      primaryY,
      profileQuickFileLinks,
      profileSocialLinks,
      profileStatus,
      secondaryC,
      secondaryK,
      secondaryM,
      secondaryY,
      stateProvince,
      streetAddress,
      streetAddressToggle,
    ],
  );

  const handleClick = () => {
    // set errors back to false
    setSocialLinkErrorIndex(-1);

    if (
      profileSocialLinks.findIndex((item) => !validateUrl(item.value)) !== -1
    ) {
      setSocialLinkErrorIndex(
        profileSocialLinks.findIndex((item) => !validateUrl(item.value)),
      );
    } else if (cardholderEditingEnabled && profileStatus === 'active') {
      setIsEnableEditingOpen(true);
    } else {
      updateProfilesShared();
    }
  };

  const handleSubmitForPrint = () => {
    // set errors back to false
    setCompanyNameError(false);
    setCompanyWebsiteError(false);
    setCompanyPhoneNumberError(false);
    setStreetAddressError(false);
    setSocialLinkErrorIndex(-1);
    setCardDesignError(false);

    if (companyNameToggle && companyName === '') {
      setCompanyNameError(true);
    } else if (companyWebsiteToggle && companyWebsite === '') {
      setCompanyWebsiteError(true);
    } else if (companyPhoneNumberToggle && companyPhoneNumber === '') {
      setCompanyPhoneNumberError(true);
    } else if (streetAddressToggle && streetAddress === '') {
      setStreetAddressError(true);
    } else if (
      profileSocialLinks.findIndex((item) => !validateUrl(item.value)) !== -1
    ) {
      setSocialLinkErrorIndex(
        profileSocialLinks.findIndex((item) => !validateUrl(item.value)),
      );
    } else if (
      profileStatus === 'unprinted' &&
      ((cardDesign === 0 && !designFile) ||
        (cardDesign === 2 &&
          !(
            logoFile ||
            primaryC !== '' ||
            primaryM !== '' ||
            primaryY !== '' ||
            primaryK !== '' ||
            secondaryC !== '' ||
            secondaryM !== '' ||
            secondaryY !== '' ||
            secondaryK !== ''
          )))
    ) {
      setCardDesignError(true);
    } else {
      updateProfilesShared(true);
    }
  };

  const handleAddSocialLinkClick = () => {
    setProfileSocialLinks([
      ...profileSocialLinks,
      {
        order: profileSocialLinks.length
          ? profileSocialLinks[profileSocialLinks.length - 1].order + 1
          : 9990,
        type: 'facebook',
        value: '',
        type_from: null,
        value_from: null,
        personal: false,
      },
    ]);
  };

  const handleAddQuickLinkClick = () => {
    setProfileQuickFileLinks([
      ...profileQuickFileLinks,
      {
        order: profileQuickFileLinks.length
          ? profileQuickFileLinks[profileQuickFileLinks.length - 1].order + 1
          : 9990,
        title: '',
        value: '',
        title_from: null,
        value_from: null,
      },
    ]);
  };

  const handleAddFileLinkClick = () => {
    setProfileQuickFileLinks([
      ...profileQuickFileLinks,
      {
        order: profileQuickFileLinks.length
          ? profileQuickFileLinks[profileQuickFileLinks.length - 1].order + 1
          : 9990,
        title: '',
        file_id: 1,
        title_from: null,
        file_id_from: null,
      },
    ]);
  };

  if (!multipleProfiles || multipleProfiles.length < 1) {
    history.push('/');
  }

  useEffect(() => {
    showSharedProfile();
  }, [showSharedProfile]);

  return (
    <Layout
      pageName={
        profileStatus === 'unprinted'
          ? 'Set up shared information'
          : 'Edit shared information'
      }
      description={`${multipleProfiles.length} profiles selected`}
    >
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      <InfoPanelContainer title="Company Information">
        <CompanyInfoForm
          companyName={companyName}
          companyNameError={companyNameError}
          companyWebsite={companyWebsite}
          companyWebsiteError={companyWebsiteError}
          companyPhoneNumber={companyPhoneNumber}
          companyPhoneNumberError={companyPhoneNumberError}
          extraCompanyPhoneNumbers={extraCompanyPhoneNumbers}
          country={country}
          streetAddress={streetAddress}
          streetAddressError={streetAddressError}
          city={city}
          stateProvince={stateProvince}
          postCode={postCode}
          locationLatLng={locationLatLng}
          mapEnabled={mapEnabled}
          setCompanyName={setCompanyName}
          setCompanyWebsite={setCompanyWebsite}
          setCompanyPhoneNumber={setCompanyPhoneNumber}
          setExtraCompanyPhoneNumbers={setExtraCompanyPhoneNumbers}
          setCountry={setCountry}
          setStreetAddress={setStreetAddress}
          setCity={setCity}
          setStateProvince={setStateProvince}
          setPostCode={setPostCode}
          setLocationLatLng={setLocationLatLng}
          setMapEnabled={setMapEnabled}
          shared
        />
      </InfoPanelContainer>

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Social Media Links"
        information="These will be displayed as icons on the profiles."
      >
        {socialLinkErrorIndex !== -1 && (
          <ErrorAlert message={MESSAGES.error.url} />
        )}
        {orgID && profileSocialLinks.length > 0 && (
          <ul
            key={`ProfileSocialLinks-${profileSocialLinks.length}`}
            className="space-y-3 pb-3"
          >
            {profileSocialLinks
              .filter((socialLink) => socialLink.order !== -1)
              .map((socialLink, index) => (
                <EditSocialLink
                  key={`SocialLink-${socialLink.order}`}
                  setProfileSocialLinks={setProfileSocialLinks}
                  profileSocialLinks={profileSocialLinks}
                  socialLink={socialLink}
                  index={index}
                  shared
                  error={index === socialLinkErrorIndex}
                />
              ))}
          </ul>
        )}
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-4">
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Add new social link"
              icon={<PlusIcon />}
              onClick={() => handleAddSocialLinkClick()}
            />
          </div>
        </div>
      </InfoPanelContainer>

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Quick Links and Files"
        information="Links to lead generation forms or downloadable files are a great use of quick links."
      >
        {orgID && profileQuickFileLinks?.length > 0 && (
          <ul
            key={`ProfileQuickFileLinks-${profileQuickFileLinks.length}`}
            className="space-y-3 pb-3"
          >
            {profileQuickFileLinks
              .filter((quickFileLink) => quickFileLink.order !== -1)
              .map((quickFileLink, index) => {
                if (quickFileLink.file_id) {
                  return (
                    <EditFileLink
                      key={`FileLink-${quickFileLink.order}`}
                      orgID={orgID}
                      setProfileFileLinks={setProfileQuickFileLinks}
                      profileFileLinks={profileQuickFileLinks}
                      fileLink={quickFileLink}
                      index={index}
                      shared
                    />
                  );
                }
                return (
                  <EditQuickLink
                    key={`QuickLink-${quickFileLink.order}`}
                    setProfileQuickLinks={setProfileQuickFileLinks}
                    profileQuickLinks={profileQuickFileLinks}
                    quickLink={quickFileLink}
                    index={index}
                    shared
                  />
                );
              })}
          </ul>
        )}
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-4 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Add new quick link"
              icon={<PlusIcon />}
              onClick={() => handleAddQuickLinkClick()}
            />
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Add new File link"
              icon={<PlusIcon />}
              onClick={() => handleAddFileLinkClick()}
            />
          </div>
        </div>
      </InfoPanelContainer>

      <InfoPanelDivider />

      <div className="hidden lg:block">
        <InfoPanelContainer
          title="Contact exchange form"
          information="Choose whether you would like to enable a lead capture form on these profile pages. You can edit the form fields in your settings."
          alert={
            <div>
              <h3 className="text-sm leading-5 font-medium text-gray-900">
                {MESSAGES.contact.trial.heading}
              </h3>
              <p className="mt-2 text-sm leading-5 text-gray-500">
                {MESSAGES.contact.trial.description}
              </p>
              <a
                href="https://help.tapt.io/en/articles/8449601-editing-and-enabling-two-way-contact-exchange-form"
                target="_blank"
                rel="noreferrer"
                className="mt-2 text-sm leading-5 font-medium text-brand-500 flex w-full"
              >
                Learn more
                <ArrowNarrowRightIcon className="ml-2 w-5 h-5" />
              </a>
            </div>
          }
        >
          {editContactExchangeEnabled ? (
            <ToggleField
              label="Enable form"
              enabled={contactExchangeEnabled}
              setter={setContactExchangeEnabled}
              description="Choose whether you would like these profiles to recieve contact details from other people."
            />
          ) : (
            <button
              type="button"
              onClick={() => {
                setEditContactExchangeEnabled(true);
              }}
              className="appearance-none text-sm leading-5 font-medium text-brand-500"
            >
              Edit settings
            </button>
          )}
        </InfoPanelContainer>
      </div>
      {/*  -------------------------------MOBILE------------------------------- */}
      <div className="lg:hidden pt-6">
        <ModalNoSuccess
          isOpen={isDisplayOpen}
          setIsOpen={setIsDisplayOpen}
          buttonTitle="i"
          buttonSize="xsmall"
          dialogTitle="Contact Exchange Form"
          dialogDescription="This will allow the people to share their contact details back with you. This will help you capture leads using Tapt!"
        />

        <ToggleField
          label="Enable contact exchange form"
          enabled={contactExchangeEnabled}
          setter={setContactExchangeEnabled}
          description="Choose whether you would like to recieve contact details from people."
        />
      </div>

      {profileStatus === 'unprinted' &&
        (editCardDesign ? (
          <>
            <InfoPanelDivider />

            <InfoPanelContainer
              title="Card Design"
              information={
                <span>
                  Choose how you would like to design the card which belongs to
                  this profile.
                  <br />
                  <br />
                  <b>Note</b>: Tapt will always email design proofs before we
                  start printing.
                  <br />
                  <br />
                  If you would like to have a go at designing your own card
                  template, download this Adobe Illustrator file:{' '}
                  <a
                    href={DesignTemplate}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download card design template.
                  </a>
                </span>
              }
              isRadio
            >
              {cardDesignError && (
                <>
                  {cardDesign === 0 && (
                    <ErrorAlert message={MESSAGES.design.errorUpload} />
                  )}
                  {cardDesign === 2 && (
                    <ErrorAlert message={MESSAGES.design.errorDesign} />
                  )}
                </>
              )}
              {orgID && (
                <CardDesign
                  orgID={orgID}
                  primaryC={primaryC}
                  setPrimaryC={setPrimaryC}
                  primaryM={primaryM}
                  setPrimaryM={setPrimaryM}
                  primaryY={primaryY}
                  setPrimaryY={setPrimaryY}
                  primaryK={primaryK}
                  setPrimaryK={setPrimaryK}
                  secondaryC={secondaryC}
                  setSecondaryC={setSecondaryC}
                  secondaryM={secondaryM}
                  setSecondaryM={setSecondaryM}
                  secondaryY={secondaryY}
                  setSecondaryY={setSecondaryY}
                  secondaryK={secondaryK}
                  setSecondaryK={setSecondaryK}
                  designFile={designFile}
                  setDesignFile={setDesignFile}
                  logoFile={logoFile}
                  setLogoFile={setLogoFile}
                  cardDesign={cardDesign}
                  setCardDesign={setCardDesign}
                  designNote={designNote}
                  setDesignNote={setDesignNote}
                  error={cardDesignError}
                />
              )}
            </InfoPanelContainer>

            <InfoPanelDivider />

            <InfoPanelContainer
              title="Required printing information"
              information={MESSAGES.profile.edit.print}
            >
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div className="mb-4">
                  <ToggleField
                    label="Company name"
                    enabled={companyNameToggle}
                    setter={setCompanyNameToggle}
                    description="e.g. ACME Inc."
                  />
                </div>
                <div className="mb-4">
                  <ToggleField
                    label="Company website"
                    enabled={companyWebsiteToggle}
                    setter={setCompanyWebsiteToggle}
                    description="e.g. ACME.com"
                  />
                </div>
                <div className="mb-4">
                  <ToggleField
                    label="Company phone number"
                    enabled={companyPhoneNumberToggle}
                    setter={setCompanyPhoneNumberToggle}
                    description="e.g. (03) 9800 5800"
                  />
                </div>
                <div className="">
                  <ToggleField
                    label="Company street address"
                    enabled={streetAddressToggle}
                    setter={setStreetAddressToggle}
                    description="e.g. 123 Collins Street, Melbourne, 3000"
                  />
                </div>
              </div>
            </InfoPanelContainer>
          </>
        ) : (
          <>
            <InfoPanelDivider />

            <InfoPanelContainer
              title="Card Design"
              information="Choose how you would like to design the card which belongs to
            this profile."
            >
              <button
                type="button"
                onClick={() => {
                  setEditCardDesign(true);
                }}
                className="appearance-none text-sm leading-5 font-medium text-brand-500"
              >
                Edit settings
              </button>
            </InfoPanelContainer>
          </>
        ))}

      <InfoPanelDivider />

      <InfoPanelFooter>
        <div className="flex justify-end space-x-4">
          <Button
            buttonText="Discard changes"
            kind={BUTTON_KIND.WHITE}
            onClick={() => history.push('/')}
          />
          <Button buttonText="Save and close" onClick={() => handleClick()} />
        </div>
      </InfoPanelFooter>
      <Modal
        isOpen={isEnableEditingOpen}
        setIsOpen={setIsEnableEditingOpen}
        dialogTitle="Do you want to notify the cardholders?"
        dialogDescription="By clicking send emails, each person will receive an email notification to edit their profile."
        onSuccess={() => {
          setIsEnableEditingOpen(false);
          updateProfilesShared(false, true);
        }}
        onCancel={() => {
          setIsEnableEditingOpen(false);
          updateProfilesShared(false, false);
        }}
        successButtonText="Send Emails"
        cancelButtonText="Don't send"
      />
    </Layout>
  );
}
