import AnalyticsIcon from '../Icons/AnalyticsIcon';
import Bag from '../Icons/Bag';
import CardIcon from '../Icons/CardIcon';
import EditIcon from '../Icons/EditIcon';
import MobileIcon from '../Icons/MobileIcon';
import Phone from '../Icons/Phone';
import ProfileAdd from '../Icons/ProfileAdd';
import ProfileIcon from '../Icons/ProfileIcon';
import QuestionIcon from '../Icons/QuestionIcon';
import Support from '../Icons/Support';

const listData = [
  {
    id: 1,
    title: 'Download the app',
    icon: <MobileIcon />,
    url: '',
  },
  {
    id: 2,
    title: 'Ways to share your profile',
    icon: <ProfileIcon />,
    url: '#',
  },
  {
    id: 3,
    title: 'FAQs',
    icon: <QuestionIcon />,
    url: 'https://tapt.io/help/',
  },
  {
    id: 4,
    title: 'How to add additional admins',
    icon: <ProfileAdd />,
    url: 'https://help.tapt.io/en/articles/8449605-managing-user-roles-in-tapt-dashboard-account-administrator-vs-group-manager',
  },
  {
    id: 6,
    title: 'How to use your card',
    icon: (
      <div className="text-brand-500">
        <CardIcon />
      </div>
    ),
    url: 'https://tapt.io/how-to-use/',
  },
  {
    id: 7,
    title: 'How to bulk edit',
    icon: <EditIcon />,
    url: 'https://help.tapt.io/en/articles/8449603-profiles-management-from-the-tapt-dashboard',
  },
  {
    id: 8,
    title: 'How to order a replacement card',
    icon: <Bag />,
    url: 'https://help.tapt.io/en/articles/8449621-ordering-replacement-card-s',
  },
  {
    id: 9,
    title: 'Support',
    icon: <Support />,
    url: 'https://help.tapt.io/',
  },
  {
    id: 10,
    title: 'Sales',
    icon: <Phone />,
    url: 'https://tapt.io/pages/sales-enquiry',
  },
];

export default listData;
