import { useState } from 'react';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Input from '@/components/Input';
import organisationsAPI from '@/api/organisations';
import useAuth from '@/hooks/useAuth';

type InviteGroupManagerProps = {
  groupID: number | undefined;
  createGroupCallback?: (email: string) => void;
  setInviteSuccessMessage: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  callback?: () => void;
};

export function InviteGroupManager({
  groupID,
  callback,
  createGroupCallback,
  setInviteSuccessMessage,
}: InviteGroupManagerProps) {
  const { orgID } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  async function inviteUser() {
    if (groupID === undefined) {
      createGroupCallback?.(email);
    } else {
      if (orgID === undefined) {
        return;
      }

      try {
        setIsLoading(true);

        await organisationsAPI.inviteUser(orgID, {
          invite: {
            email,
            organisation_id: orgID,
            role: 'org_editor',
            group_ids: [groupID],
          },
        });

        setInviteSuccessMessage(
          `${email} was invited successfully to manage this group.`,
        );
        setIsModalOpen(false);
        callback?.();
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      <Button
        buttonText="Invite new user"
        onClick={() => setIsModalOpen(true)}
      />
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        dialogTitle="Add group manager"
        dialogDescription="Invite someone to become a group manager of this group."
        onSuccess={inviteUser}
        cancelButtonText="Go back"
        successButtonText="Send email invitation"
        isLoading={isLoading}
        isDisabled={email === ''}
      >
        <div className="pt-2 pb-6">
          <a
            href="https://help.tapt.io/en/articles/8449605-managing-user-roles-in-tapt-dashboard-account-administrator-vs-group-manager"
            className="underline"
            target="_blank"
          >
            What is a group manager?
          </a>
        </div>
        <Input
          type="text"
          label="Email"
          placeholder="Invite by email"
          value={email}
          onChange={setEmail}
          required
        />
      </Modal>
    </>
  );
}
