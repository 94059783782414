import { useEffect, useRef } from 'react';
import { XCircleIcon } from '@heroicons/react/solid';

interface ErrorAlterProps {
  message: string;
  className?: string;
}

export default function ErrorAlert({
  message,
  className = 'rounded-md bg-red-50 p-4 mb-4',
}: ErrorAlterProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref]);

  return (
    <div ref={ref} className={className}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{message}</h3>
        </div>
      </div>
    </div>
  );
}
