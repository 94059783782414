import {
  IOrganisationGroup,
  IOrganisationSettings,
} from '@/types/IOrganisation';
import { IProfilePublic } from '@/types/IProfile';
import COLORS from '@/constants/colors';

const loadingOrganisationSettings: IOrganisationSettings = {
  bg_color: COLORS.organisation.loadingProfile.backgroundColor,
  button_bg_color: COLORS.organisation.loadingProfile.buttonBackgroundColor,
  button_text_color: COLORS.organisation.loadingProfile.buttonTextColor,
  company_logo_default: true,
  is_contact_exchange_sms_enabled: true,
  is_cardholder_editing_enabled: true,
  company_logo: {
    file: {
      file_name: '',
      updated_at: '',
    },
    id: -1,
    large_url: '',
    medium_url: '',
    name: 'Default Background Image',
    original_url: '',
    blur_url: '',
    small_url: '',
    thumb_url: '',
    upload_url: '',
  },
  company_logo_file_id: null,
  company_consent_file: {
    file: {
      file_name: '',
      updated_at: '',
    },
    id: -1,
    large_url: '',
    medium_url: '',
    name: 'Consent Statement',
    original_url: '',
    blur_url: '',
    small_url: '',
    thumb_url: '',
    upload_url: '',
  },
  company_consent_file_id: null,
  contact_exchange: {
    company_name: false,
    email: false,
    job_title: false,
    mobile_number: false,
    name: true,
    company_consent: false,
  },
  font: '',
  id: -1,
  text_color: COLORS.organisation.loadingProfile.textColor,
};

const defaultOrganisationSettings: IOrganisationSettings = {
  bg_color: COLORS.organisation.defaultProfile.backgroundColor,
  button_bg_color: COLORS.organisation.defaultProfile.buttonBackgroundColor,
  button_text_color: COLORS.organisation.defaultProfile.buttonTextColor,
  company_logo_default: true,
  is_contact_exchange_sms_enabled: true,
  is_cardholder_editing_enabled: true,
  company_logo: {
    file: {
      file_name: '',
      updated_at: '',
    },
    id: -1,
    large_url: '',
    medium_url: '',
    name: 'Default Background Image',
    original_url: '',
    blur_url: '',
    small_url: '',
    thumb_url: '',
    upload_url: '',
  },
  company_logo_file_id: null,
  company_consent_file: {
    file: {
      file_name: '',
      updated_at: '',
    },
    id: -1,
    large_url: '',
    medium_url: '',
    name: 'Consent Statement',
    original_url: '',
    blur_url: '',
    small_url: '',
    thumb_url: '',
    upload_url: '',
  },
  company_consent_file_id: null,
  contact_exchange: {
    company_name: true,
    email: true,
    job_title: true,
    mobile_number: true,
    name: true,
    company_consent: true,
  },
  font: '',
  id: -1,
  text_color: COLORS.organisation.defaultProfile.textColor,
};

const defaultProfileData: IProfilePublic = {
  analytics_view_count: -1,
  city: null,
  company_name: null,
  company_phone_number: null,
  extra_company_phone_numbers: null,
  company_website: null,
  contact_exchange_enabled: true,
  country: null,
  email: null,
  extra_emails: null,
  first_name: null,
  id: -1,
  inserted_at: null,
  is_editable: true,
  job_title: null,
  last_name: null,
  middle_name: null,
  pronouns: null,
  mobile_number: null,
  extra_mobile_numbers: null,
  note: null,
  organisation_id: -1,
  organisation_name: '',
  photo_file_id: null,
  post_code: null,
  profile_file_links: [],
  profile_hash: null,
  profile_public_url: null,
  profile_quick_links: [],
  profile_social_links: [],
  settings: defaultOrganisationSettings,
  state: null,
  status: null,
  street_address: null,
  type: null,
  vcf_url: '',
  add_contact_page: '',
  group_id: -1,
  group_name: '',
  group_subheading: '',
};

const defaultGroupData: IOrganisationGroup = {
  description: null,
  id: -1,
  group_hash: '',
  name: '',
  organisation_id: -1,
  organisation_name: '',
  editors: [],
  group_logo_file_id: null,
  bg_color: '#FFFFFF',
  button_bg_color: '#873CFF',
  button_text_color: '#FFFFFF',
  text_color: '#0A0A0A',
  profiles_count: 0,
};

const footerMenuData = [
  {
    href: '/',
    id: 'link-home',
    text: 'Home',
    title: 'Visit Tapt Home',
  },
  {
    href: 'https://help.tapt.io',
    id: 'link-help',
    text: 'Help',
    title: 'Visit Tapt Help',
  },
  {
    href: 'https://tapt.io/pages/contact-us',
    id: 'link-contact',
    text: 'Contact',
    title: 'Contact us',
  },
  {
    href: 'https://tapt.io/policies/privacy-policy',
    id: 'link-privacy',
    text: 'Privacy',
    title: 'Visit Privacy Policy',
  },
  {
    href: 'https://tapt.io/policies/terms-of-service',
    id: 'link-terms',
    text: 'Terms',
    title: 'Visit Terms of Service',
  },
];

const headerMenuData = [
  {
    href: 'https://tapt.io/',
    id: 'link-website',
    text: 'Tapt website',
    title: 'Visit the Tapt Website',
  },
  {
    href: 'https://tapt.io/collections/all',
    id: 'link-shop',
    text: 'Shop Tapt',
    title: 'Visit the Tapt Shop',
  },
  {
    href: 'https://help.tapt.io',
    id: 'link-help',
    text: 'Help',
    title: 'Visit Tapt Help',
  },
];

const mockOrganisationData: IOrganisationSettings = {
  bg_color: '#ffffff',
  button_bg_color: '#ffffff',
  button_text_color: '#000000',
  is_contact_exchange_sms_enabled: true,
  is_cardholder_editing_enabled: true,
  company_logo: {
    file: {
      file_name: 'image.png',
      updated_at: '2021-09-30T01:40:30',
    },
    id: 164,
    blur_url: '',
    large_url:
      'https://tapt-dev-s3.s3.us-west-1.amazonaws.com/uploads/files/164/large.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3ZTUTA3OGENNXJ5K%2F20210930%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20210930T031314Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=bfb431bc14f2cc81b90f6a1ae931b0c92bece1f6d1da9f03577c27f045678ae8',
    medium_url:
      'https://tapt-dev-s3.s3.us-west-1.amazonaws.com/uploads/files/164/medium.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3ZTUTA3OGENNXJ5K%2F20210930%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20210930T031314Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=e59a6badd957f2bdd9a4857a9b85a38ab4e9bd44b4a5e45433904c7cad061433',
    name: 'image.png',
    original_url:
      'https://tapt-dev-s3.s3.us-west-1.amazonaws.com/uploads/files/164/original.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3ZTUTA3OGENNXJ5K%2F20210930%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20210930T031314Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=1f88e99198554a0ae7ccbdd6c61c240695adbb362f78be28789d97955fd6d73d',
    small_url:
      'https://tapt-dev-s3.s3.us-west-1.amazonaws.com/uploads/files/164/small.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3ZTUTA3OGENNXJ5K%2F20210930%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20210930T031314Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=ba928eb6bb95e665989718e0a0f9f12cae5e3fa7f3a30d44648df0e3224ac050',
    thumb_url:
      'https://tapt-dev-s3.s3.us-west-1.amazonaws.com/uploads/files/164/thumb.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3ZTUTA3OGENNXJ5K%2F20210930%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20210930T031314Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=f7ceeb48ac4609dc8c8415ec90918a208f5f60733592856dd199a565934bc98a',
    upload_url: '/api/files/164/upload',
  },
  contact_exchange: {
    company_name: true,
    email: true,
    job_title: true,
    mobile_number: true,
    name: true,
    company_consent: true,
  },
  font: 'Some font',
  id: 1,
  text_color: '#000000',
};

const mockProfileData: IProfilePublic = {
  analytics_view_count: 4,
  city: 'Melbourne',
  company_name: 'Sample Inc.',
  company_phone_number: '+61 3 0000 0000',
  extra_company_phone_numbers: ['+61 3 0000 0001'],
  company_website: 'https://sampleinc.com',
  contact_exchange_enabled: true,
  country: 'Australia',
  design: {
    description: null,
    design_file: null,
    flags: {
      company_name: false,
      company_phone_number: false,
      company_website: false,
      email: false,
      job_title: false,
      mobile_number: false,
      name: false,
      street_address: false,
    },
    id: 58,
    logo_file: null,
    organisation_id: 1,
    primary_color: null,
    secondary_color: null,
  },
  email: 'sam.sample@sampleinc.com',
  extra_emails: ['sam.sample@notsosample.com'],
  first_name: 'Sam',
  is_editable: true,
  job_title: 'Sales Director',
  last_name: 'Sample',
  middle_name: '',
  pronouns: '',
  mobile_number: '0412 345 678',
  extra_mobile_numbers: ['0412 555 700', '0412 874 720'],
  note: null,
  organisation_id: 1,
  organisation_name: '',
  photo: {
    file: {
      file_name: 'image.png',
      updated_at: '2021-09-30T01:34:59',
    },
    id: 162,
    blur_url: '',
    large_url: '',
    medium_url: '',
    name: 'image.png',
    original_url: '',
    small_url: '/images/mock-avatar.jpeg',
    thumb_url: '',
    upload_url: '/api/files/162/upload',
  },
  post_code: '3000',
  profile_file_links: [
    {
      file: {
        file: {
          file_name: '800x800.jpeg',
          updated_at: '2021-09-30T01:37:15',
        },
        id: 163,
        blur_url: '',
        large_url:
          'https://tapt-dev-s3.s3.us-west-1.amazonaws.com/uploads/files/163/large.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3ZTUTA3OGENNXJ5K%2F20210930%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20210930T014223Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=4ade483b1e323161301354d1f576a0c8e8454066cbbe6f945f03a345754915da',
        medium_url:
          'https://tapt-dev-s3.s3.us-west-1.amazonaws.com/uploads/files/163/medium.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3ZTUTA3OGENNXJ5K%2F20210930%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20210930T014223Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=8c3843848592c39fc1b0ec9eb67a471024de54f22ce1eff0d5df99d8feb1511f',
        name: '800x800.jpeg',
        original_url:
          'https://tapt-dev-s3.s3.us-west-1.amazonaws.com/uploads/files/163/original.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3ZTUTA3OGENNXJ5K%2F20210930%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20210930T014223Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=ac1398d1ff7a32ba11f5dc41e68a6586f936ae87f4f4583b291d22f9b950d944',
        small_url:
          'https://tapt-dev-s3.s3.us-west-1.amazonaws.com/uploads/files/163/small.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3ZTUTA3OGENNXJ5K%2F20210930%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20210930T014223Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=cb6cb2cf6087b7bbdead0b14efb00811c8f394df9d1c067097c5f65fcbe4f11e',
        thumb_url:
          'https://tapt-dev-s3.s3.us-west-1.amazonaws.com/uploads/files/163/thumb.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3ZTUTA3OGENNXJ5K%2F20210930%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20210930T014223Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=c5cd624514f446ebfe7b34710e8883a8bee7b501a8fcdea02d519e6b1bfe8f4e',
        upload_url: '/api/files/163/upload',
      },
      file_id: 163,
      order: 2,
      title: 'Download brochure',
    },
  ],
  profile_quick_links: [
    {
      order: 1,
      title: 'Explore the range',
      value: 'www.mock.com/explore-the-range',
    },
  ],
  profile_social_links: [
    {
      order: 1,
      type: 'linkedin',
      value: 'https://linkedin.com/',
      personal: false,
    },
    {
      order: 2,
      type: 'facebook',
      value: 'https://facebook.com/',
      personal: false,
    },
    {
      order: 3,
      type: 'instagram',
      value: 'https://instagram.com/',
      personal: false,
    },
    {
      order: 4,
      type: 'twitter',
      value: 'https://twitter.com/',
      personal: false,
    },
    {
      order: 5,
      type: 'pinterest',
      value: 'https://pinterest.com/',
      personal: false,
    },
    {
      order: 6,
      type: 'tiktok',
      value: 'https://tiktok.com/',
      personal: false,
    },
    {
      order: 7,
      type: 'youtube',
      value: 'https://youtube.com/',
      personal: false,
    },
    {
      order: 8,
      type: 'whatsapp',
      value: 'https://whatsapp.com/',
      personal: false,
    },
    {
      order: 9,
      type: 'wechat',
      value: 'https://wechat.com/',
      personal: false,
    },
    {
      order: 10,
      type: 'googlemybusiness',
      value: 'https://www.google.com/intl/en_au/business/',
      personal: false,
    },
  ],
  settings: mockOrganisationData,
  state: 'VIC',
  status: 'active',
  street_address: '23 sample street',
  vcf_url: 'https://testurl.com',
  add_contact_page: 'hello',
  group_id: 4,
  group_name: 'Group 1',
  group_subheading: 'Group 1 subheading',
};

export {
  defaultOrganisationSettings,
  defaultProfileData,
  footerMenuData,
  headerMenuData,
  loadingOrganisationSettings,
  mockOrganisationData,
  mockProfileData,
  defaultGroupData,
};
