// I am disabling eslint for this file because it is a mess and I don't want to clean it up.
/* eslint-disable */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Listbox, Tab, Transition } from '@headlessui/react';
import { AxiosError } from 'axios';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Layout from '@/components/Layout';
import cardsAPI from '@/api/cards';
import Pagination from '@/components/Pagination';
import Sort from '@/components/Sort';
import Search from '@/components/Search';
import Modal from '@/components/Modal';
import ProfileListItem from '@/components/ProfileListItem';
import useAuth from '@/hooks/useAuth';
import accountsAPI from '@/api/accounts';
import Button, { BUTTON_KIND } from '@/components/Button';
import InputCheckbox from '@/components/InputCheckbox';
import useAppState, { SettingsTab } from '@/hooks/useAppState';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import profilesAPI from '@/api/profiles';
import IProfile, { IProfileAppAccess } from '@/types/IProfile';
import SuccessAlert from '@/components/SuccessAlert';
import ErrorAlert from '@/components/ErrorAlert';
import classNames from '@/helpers/classNames';
import MESSAGES from '@/constants/messages-en';
import ActivationKeySearch from '@/components/ActivationKeySearch';
import useShopify from '@/hooks/useShopify';
import { InformationProps } from '@/components/DetailsForm';
import { IOrganisationGroup } from '@/types/IOrganisation';
import organisationsAPI from '@/api/organisations';
import TrialBadge, {
  TRIAL_BADGE_KIND,
} from '@/components/ProfilePage/TrialBadge';
import CsvFileUploadSteps from '@/components/CsvFileUploadSteps';
import DownloadCsv from '@/components/Icons/DownloadCsv';
import { useLocalStorage } from 'usehooks-ts';
import LoadingAnimation from '@/components/LoadingAnimation';
import PlatformFeeAnnouncementModal from '@/components/ProfilePage/PlatformFeeAnnouncementModal';
import ProfilesGroupsPseudoTabs from '@/components/ProfilesGroupsPseudoTabs';
import DesignBar from '@/components/DesignBar';
import { GroupFilterOption } from '@/components/Filter';
import clsx from 'clsx';
import { AddProfilesToGroupModal } from '@/components/ProfilePage/AddProfilesToGroupModal';
import {
  PencilIcon,
  QrcodeIcon,
  UserGroupIcon,
  DeviceMobileIcon,
} from '@heroicons/react/outline';
import { QRCodeFileSelectionModal } from '@/components/QRCode';

interface IProfileListPage {
  location: {
    state: {
      success: string;
      setupInfo: boolean;
      activationKey?: string;
      activationKeyFromLogin?: string;
      checkout?: string;
    };
  };
}

export const DEFAULT_GROUP_OPTION: GroupFilterOption = {
  id: 0,
  name: 'All groups',
};

function ProfileListPage(props: IProfileListPage) {
  const { location } = props;
  const { orgID, user, getCurrentUser, userRole } = useAuth();
  const { fetchCheckout } = useShopify();
  const history = useHistory();
  const {
    selectProfiles,
    profileStatus,
    selectProfileStatus,
    selectSettingsTab,
  } = useAppState();
  const [hideAppInviteModal, setHideAppInviteModal] = useLocalStorage(
    `hideAppInviteModal-${orgID}`,
    false,
  );

  const activationKeyFromSignUp = location?.state?.activationKey;
  const activationKeyFromLogin = location?.state?.activationKeyFromLogin;
  const setupInfo = location?.state?.setupInfo;
  const shopifyCheckoutId = new URLSearchParams(window.location.search).get(
    'checkout',
  );

  const [initialLoading, setInitialLoading] = useState(true);
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
  const [isAddToGroupModalOpen, setIsAddToGroupModalOpen] = useState(false);
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const [profiles, setProfiles] = useState<IProfile[] | undefined>(undefined);
  const [activeCount, setActiveCount] = useState<number | undefined>(undefined);
  const [editingCount, setEditingCount] = useState<number | undefined>(
    undefined,
  );
  const [allCount, setAllCount] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [loginTime, setLoginTime] = useState(user?.first_log_in_at);

  const [isSetupInfoOpen, setIsSetupInfoOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isEditViaCSVOpen, setIsEditViaCSVOpen] = useState(false);
  const [importCSV, setImportCSV] = useState<File>();
  const [isEditSharedOpen, setIsEditSharedOpen] = useState(false);
  const [isAppInviteOpen, setIsAppInviteOpen] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [isEditAfterActivateOpen, setIsEditAfterActivateOpen] = useState(false);
  const [activationKey, setActivationKey] = useState(
    activationKeyFromSignUp || '',
  );
  const [activationKeyType, setActivationKeyType] = useState('');
  const [profileSearch, setProfileSearch] = useState('');
  const [profileIDForActivation, setProfileIDForActivation] = useState<
    number | undefined
  >();
  const [showOptions, setShowOptions] = useState(false);
  const [profileDataForSearch, setProfileDataForSearch] =
    useState<IProfile[]>();
  const [checkedItems, setCheckedItems] = useState<IProfile[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [orgGroups, setOrgGroups] = useState<IOrganisationGroup[]>();
  const [filterOptions, setFilterOptions] = useState<GroupFilterOption[]>([]);

  const [status, setStatus] = useState('');
  const [success, setSuccess] = useState<string | undefined>(
    location?.state?.success,
  );
  const [recentlyInvitedIds, setRecentlyInvitedIds] = useState<number[]>([]);
  const [selected, setSelected] =
    useState<GroupFilterOption>(DEFAULT_GROUP_OPTION);

  const [error, setError] = useState(false);
  const [appInviteError, setAppInviteError] = useState<string | undefined>(
    undefined,
  );
  const [printError, setPrintError] = useState(false);
  const [notFoundError, setNotFoundError] = useState(false);
  const [usedKeyError, setUsedKeyError] = useState(false);

  const listProfiles = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newStatus: string = status,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      initial = false,
    ) => {
      const res = await profilesAPI.listProfiles({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        status: newStatus,
        order: newOrder,
        search: newSearch,
      });

      if (!initial) {
        setProfiles(res.data.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }

      if (newStatus === 'active') {
        setActiveCount(res.data.paging.total_entries);
      } else if (newStatus === 'editing') {
        setEditingCount(res.data.paging.total_entries);
      } else {
        setAllCount(res.data.paging.total_entries);
      }

      return res.data;
    },
    [order, orgID, page, pageSize, search, sort, status],
  );

  const listProfilesInGroup = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newStatus: string = status,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      newGroupID: number = selected.id,
    ) => {
      const res = await profilesAPI.listProfilesInGroup({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        status: newStatus,
        order: newOrder,
        search: newSearch,
        groupID: newGroupID,
      });

      setProfiles(res.data.data);
      setPage(newPage);
      setPageSize(newPageSize);
      setSort(newSort);
      setOrder(newOrder);

      return res.data;
    },
    [order, orgID, page, pageSize, search, sort, status, selected],
  );

  const listGroups = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
    ) => {
      const res = await organisationsAPI.listOrganisationGroups({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
      });

      setOrgGroups(res.data.data);
      setFilterOptions([
        DEFAULT_GROUP_OPTION,
        ...res.data.data.map((group) => ({
          id: group.id,
          name: group.name,
        })),
      ]);
    },
    [order, orgID, page, pageSize, search, sort],
  );

  const listProfilesForSearch = useCallback(
    async (newSearch: string = search) => {
      const res = await profilesAPI.listProfiles({
        orgID,
        search: newSearch,
      });

      setProfileDataForSearch(
        res.data.data.filter(
          (item) => item.status === 'active' || item.status === 'editing',
        ),
      );

      return res.data;
    },
    [orgID, search],
  );

  const countEditingProfiles = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      initial = false,
    ) => {
      const res = await profilesAPI.listProfiles({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        status: 'editing',
        order: newOrder,
        search: newSearch,
      });

      if (!initial) {
        setProfiles(res.data.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }
      setEditingCount(res.data.paging.total_entries);

      if (
        res.data?.paging.total_entries !== undefined &&
        res.data?.paging.total_entries > 0
      ) {
        setIsEditingModalOpen(true);
      }
    },
    [order, orgID, page, pageSize, search, sort],
  );

  const showActivatationKeyType = useCallback(
    async (newActivationKey: string = activationKey) => {
      const res = await cardsAPI.showActivatationKeyType(newActivationKey);

      if (res.data.data.type && activationKeyType !== res.data.data.type) {
        setActivationKeyType(res.data.data.type);
      }
    },
    [activationKey, activationKeyType],
  );

  const handleCardActivation = useCallback(() => {
    if (orgID === undefined) {
      return;
    }

    setIsLoading(true);
    setSuccess(undefined);
    setError(false);
    setNotFoundError(false);
    setUsedKeyError(false);

    cardsAPI
      .activateCards(activationKey, orgID, profileIDForActivation)
      .then((res) => {
        if (activationKeyFromSignUp && res.data?.data.profile?.id) {
          setProfileIDForActivation(res.data.data.profile.id);
          setIsEditAfterActivateOpen(true);
        }
        listProfiles(1, pageSize, '').finally(() => {});
        setSuccess('Profiles were added successfully');
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) {
          setNotFoundError(true);
        } else if (e.response?.status === 422) {
          setUsedKeyError(true);
        } else {
          setError(true);
        }
      })
      .then(() => setIsLoading(false))
      .finally(() => setIsOpen(false));
  }, [
    activationKey,
    activationKeyFromSignUp,
    profileIDForActivation,
    listProfiles,
    orgID,
    pageSize,
  ]);

  function handleCheckItem(profile: IProfile) {
    let arr = checkedItems;

    if (checkedItems.includes(profile)) {
      arr = arr.filter((e) => e !== profile);
    } else {
      arr = [...checkedItems, profile];
    }

    setCheckedItems(arr);
    selectProfiles(arr.map(({ id }) => id));
  }

  const showSetupInfo = useCallback(
    (override = false) => {
      const setupInfoShown = localStorage.getItem('setupInfo');
      if ((setupInfo || override) && !setupInfoShown) {
        setIsSetupInfoOpen(true);
        localStorage.setItem('setupInfo', 'true');
      }
    },
    [setupInfo],
  );

  const updateLoginStatus = () => {
    setSuccess(undefined);
    accountsAPI
      .updateCurrentUser({
        first_log_in_at: new Date(),
      })
      .then(() => getCurrentUser());
  };

  const handleEditViaCSV = useCallback(() => {
    if (orgID === undefined || importCSV === undefined) {
      return;
    }

    setIsLoading(true);
    setSuccess(undefined);
    setError(false);

    const formData = new FormData();
    formData.append('file', importCSV);
    formData.append('type', 'profile');

    profilesAPI
      .importProfilesCSV(orgID, formData)
      .then(() => setSuccess('CSV file was imported successfully'))
      .catch(() => setError(true))
      .then(() => setIsLoading(false))
      .then(() => listProfiles(1, pageSize))
      .finally(() => setIsEditViaCSVOpen(false));
  }, [orgID, importCSV, listProfiles, pageSize]);

  const exportCSV = useCallback(async () => {
    if (orgID === undefined) {
      return;
    }

    const response = await profilesAPI.exportProfilesCSV(orgID);
    const blobData = new Blob([response.data]);

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blobData);
    a.download = 'cards.csv';
    a.click();
  }, [orgID]);

  function handleCompletedShopifyCheckout(checkoutId: string) {
    fetchCheckout(atob(checkoutId))
      .then((checkout) => {
        if (checkout.completedAt === null) return;
        if (orgID === undefined) return;

        // Clear checkout id in query params to avoid reloading the page -> duplicating data
        history.replace('/');

        checkout.lineItems.forEach(async (item) => {
          const card_information = item.customAttributes.find(
            (attr) => attr.key === '_cardInformation',
          );
          if (!card_information) return;
          const profiles = await Promise.all(
            JSON.parse(card_information.value).map(
              async (infomation: InformationProps) => {
                let profile_data = {
                  organisation_id: orgID,
                  ...(infomation.email ? { email: infomation.email } : {}),
                  ...(infomation.mobileNumber
                    ? { mobile_number: infomation.mobileNumber }
                    : {}),
                  ...(infomation.companyWebsiteURL
                    ? { company_website: infomation.companyWebsiteURL }
                    : {}),
                  ...(infomation.companyAddress
                    ? { street_address: infomation.companyAddress }
                    : {}),
                  ...(infomation.companyPhoneNumber
                    ? { company_phone_number: infomation.companyPhoneNumber }
                    : {}),
                };

                if (infomation.name) {
                  const [first_name, middle_name, last_name] =
                    infomation.name.split(' ');

                  profile_data = {
                    ...profile_data,
                    ...(first_name ? { first_name } : {}),
                    ...(middle_name ? { middle_name } : {}),
                    ...(last_name ? { last_name } : {}),
                  };
                }

                const res = await profilesAPI.createProfile(orgID, {
                  profile: profile_data,
                });

                return { ...res.data.data };
              },
            ),
          );

          const profile_ids = profiles.map(function (profile: IProfile) {
            return profile.id;
          });

          if (profile_ids) {
            setIsLoading(true);
            profilesAPI
              .updateProfilesSharedNew({
                orgID,
                body: {
                  ids: profile_ids,
                  profile: {
                    status: 'editing',
                  },
                },
              })
              .then(() => {
                setStatus('editing');
                selectProfileStatus(1);
                listProfiles(1, pageSize, 'editing').finally(() => {});

                const cards = profile_ids.length === 1 ? 'card' : 'cards';
                setSuccess(
                  `Congrats! You've added ${profile_ids.length} ${cards}. Click 'Edit profile' below to edit a profile's contact details.`,
                );
              })
              .finally(() => setIsLoading(false));
          }
        });
      })
      .catch(() => {});
  }

  useEffect(() => {
    if (initialLoading && orgID) {
      listGroups();
      if (user?.first_log_in_at === null) {
        countEditingProfiles(1, 20, 'date', 'desc', '', true);
        setLoginTime(new Date());
        updateLoginStatus();
      }
      if (activationKeyFromSignUp) {
        handleCardActivation();
      } else if (activationKeyFromLogin) {
        setActivationKey(activationKeyFromLogin);
        setIsOpen(true);
      } else {
        showSetupInfo();
      }
      if (shopifyCheckoutId !== null) {
        handleCompletedShopifyCheckout(shopifyCheckoutId);
      }
      setInitialLoading(false);
    }
  }, [
    listProfiles,
    initialLoading,
    orgID,
    showSetupInfo,
    activationKeyFromSignUp,
    handleCardActivation,
    activationKeyFromLogin,
    countEditingProfiles,
    shopifyCheckoutId,
    handleCompletedShopifyCheckout,
    loginTime,
    updateLoginStatus,
  ]);

  const tobeInvitedAccounts = useMemo(
    () =>
      checkedItems.filter((i) => {
        const profile = profiles?.find((d) => d.id === i.id);
        return (
          profile?.app_access === IProfileAppAccess.not_connected &&
          profile.email
        );
      }),
    [checkedItems, profiles],
  );

  async function handleAccessButtonClick() {
    if (orgID === undefined) {
      return;
    }

    try {
      const numberOfInvitations = 50; // Number of invitations to send at once
      const invitedAccounts = tobeInvitedAccounts.slice(0, numberOfInvitations);

      if (invitedAccounts.length > 0) {
        await profilesAPI.inviteUsers(
          orgID,
          invitedAccounts.map(({ id }) => id),
        );
        setSuccess('App invite successfully sent');

        const remainingAccounts =
          tobeInvitedAccounts.slice(numberOfInvitations);

        setRecentlyInvitedIds((state) => [
          ...state.filter(
            (i) => !invitedAccounts.map(({ id }) => id).includes(i),
          ),
          ...invitedAccounts.map(({ id }) => id),
        ]);

        if (remainingAccounts.length > 0) {
          setAppInviteError('You can only send up to 50 invitations at once.');
        } else {
          setAppInviteError(undefined);
        }
      }

      setError(false);
      setIsAppInviteOpen(false);
      if (!hideAppInviteModal) {
        setHideAppInviteModal(checkbox);
      }
    } catch (err) {
      setAppInviteError(
        'Unable to invite as there is no email associated with this profile. Please add an email and try again.',
      );
    }
  }

  useEffect(() => {
    if (orgID === undefined) {
      return;
    }

    if (selected.id === 0) {
      listProfiles(page, pageSize);
    } else {
      listProfilesInGroup(page, pageSize, '', 'date', 'desc', '', selected.id);
    }
  }, [
    orgGroups,
    listGroups,
    listProfiles,
    listProfilesInGroup,
    orgID,
    page,
    pageSize,
    selected,
    userRole,
    initialLoading,
  ]);

  if (initialLoading) {
    return (
      <div className="flex flex-col justify-center min-h-screen py-6 bg-white sm:px-6 lg:px-8">
        <div>
          <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
        </div>
      </div>
    );
  }

  return (
    <Layout
      pageName="My Profiles"
      className="bg-gray-50"
      rightTitle={<TrialBadge kind={TRIAL_BADGE_KIND.SHORT} />}
    >
      <div className="hidden lg:block md:pb-8 md:flex md:items-center md:-mt-20">
        <div className="flex justify-end mt-3 space-x-4">
          <TrialBadge kind={TRIAL_BADGE_KIND.LONG} />
          <div className="hidden lg:block">
            <Modal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              buttonTitle="Activate products"
              dialogTitle={MESSAGES.profile.activate.heading}
              dialogDescription={
                <div className="mb-4 text-gray-500 text-sm font-normal leading-[150%]">
                  Enter the activation key from your Shopify order email or on
                  the screen when you tap your physical card on your phone.
                  <br />
                  <br />
                  Please note - You will only need to activate your product if
                  you've ordered a Tapt Lite or Tapt Mobile sticker. Tapt Custom
                  and Tapt Black cards will arrive active and ready to use. If
                  you're still having trouble, please
                  <a href="https://tapt.io/pages/sales-enquiry">
                    &nbsp;get in touch
                  </a>
                </div>
              }
              successButtonText="Activate"
              successButtonKind={BUTTON_KIND.PURPLE_SECONDARY_MD}
              onSuccess={() => handleCardActivation()}
              isLoading={isLoading}
              isDisabled={!activationKey}
            >
              <a
                href="https://tapt.io/pages/how-to-use"
                rel="noreferrer"
                target="_blank"
              >
                Learn more
              </a>
              <div className="mt-6">
                <ActivationKeySearch
                  id={`ActivationKey-${activationKey}`}
                  label="Card activation key"
                  search={activationKey}
                  setSearch={setActivationKey}
                  fetchQuery={(searchQuery) =>
                    showActivatationKeyType(searchQuery)
                  }
                />
                <div className="relative mt-6 mb-6">
                  <Search
                    id={`ProfileList-1-20-date-desc-${profileSearch}`}
                    label="Assign product to existing profile (optional)"
                    description="If you do not assign this product to an existing profile, we'll create a new one instead."
                    placeholder={
                      activationKeyType === 'card_generated'
                        ? 'Search for Profile ID or Name'
                        : 'Only available for card generated keys'
                    }
                    search={profileSearch}
                    disabled={activationKeyType !== 'card_generated'}
                    setSearch={(value) => {
                      if (value !== profileSearch) {
                        setProfileIDForActivation(undefined);
                        setShowOptions(true);
                      }
                      setProfileSearch(value);
                    }}
                    fetchQuery={(newSearch) => listProfilesForSearch(newSearch)}
                  />
                  {profileSearch && showOptions && (
                    <div className="absolute right-0 z-10 w-full mt-2 origin-top-left bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {profileDataForSearch &&
                        profileDataForSearch?.length ? (
                          profileDataForSearch?.map((item: IProfile) => (
                            <button
                              type="button"
                              key={item.id}
                              className="w-full px-3 py-2 text-left appearance-none cursor-pointer hover:bg-gray-200"
                              onClick={() => {
                                setProfileIDForActivation(item.id);
                                setShowOptions(false);
                                setProfileSearch(`${item.id}`);
                              }}
                            >
                              #{item.id}{' '}
                              <span className="text-gray-500">
                                {item.first_name} {item.last_name}
                              </span>
                            </button>
                          ))
                        ) : (
                          <li className="px-3 py-2 text-center">
                            No matching items found
                          </li>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Modal>
          </div>
          <Button className="hidden lg:block" href="/shop" buttonText="Shop" />
        </div>
      </div>
      {success && (
        <SuccessAlert
          className="p-4 rounded-md bg-emerald-50"
          message={success}
        />
      )}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      {notFoundError && (
        <ErrorAlert message={MESSAGES.profile.activate.notFound} />
      )}
      {usedKeyError && (
        <ErrorAlert message={MESSAGES.profile.activate.usedKey} />
      )}
      {appInviteError && <ErrorAlert message={appInviteError} />}
      <div>
        <Tab.Group
          key={`${profileStatus}-${status}`}
          defaultIndex={profileStatus}
          onChange={(index) => {
            setPage(1);
            setSuccess(undefined);
            setError(false);
            setPrintError(false);
            setSearch('');
            setStatus(status);
            selectProfileStatus(index);
            setSelectAll(false);
            setCheckedItems([]);
            selectProfiles([]);
            if (orgID && orgID !== -1) {
              listProfiles(1, pageSize, status).finally(() => {});
            }
          }}
        >
          <div className="mt-3">
            <ProfilesGroupsPseudoTabs activeTab="profiles" />
          </div>
          <div className="lg:pt-4 lg:pb-8">
            <div className="flex flex-col xl:flex-row items-start xl:items-center justify-between space-y-4 xl:space-y-0 pb-4 xl:pb-8">
              <DesignBar short />
              <div className="flex flex-col xl:flex-row space-y-4 xl:space-y-0 justify-end space-x-3 w-full">
                <Search
                  id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
                  search={search}
                  setSearch={setSearch}
                  fetchQuery={(searchQuery) =>
                    listProfiles(page, pageSize, '', sort, order, searchQuery)
                  }
                />
                <div className="flex flex-row space-x-3 justify-end">
                  <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        {filterOptions.length > 0 ? (
                          <div className="relative">
                            <Listbox.Button className="relative lg:max-w-52 max-w-44 w-full py-2 pl-3 pr-10 text-sm font-medium text-left text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none sm:text-sm">
                              <span className="block truncate" title={selected.name}>
                                {selected.name}
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <ChevronDownIcon
                                  className="w-5 h-5 ml-2"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute right-0 z-10 lg:max-w-52 max-w-44 overflow-auto py-1 mt-1 text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm divide-y divide-gray-200">
                                {filterOptions &&
                                  filterOptions.map((group, index) => (
                                    <Fragment key={index}>
                                      <Listbox.Option
                                        key={group.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-gray-100' : '',
                                            'relative cursor-default select-none p-3 text-gray-900 text-sm',
                                          )
                                        }
                                        value={group}
                                      >
                                        <span className="block truncate" title={group.name}>
                                          {group.name}
                                        </span>
                                      </Listbox.Option>
                                    </Fragment>
                                  ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        ) : null}
                      </>
                    )}
                  </Listbox>
                  <Sort
                    id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
                    options={[
                      {
                        sort: 'date',
                        order: 'desc',
                        label: 'Newest first',
                      },
                      {
                        sort: 'date',
                        order: 'asc',
                        label: 'Oldest first',
                      },
                      {
                        sort: 'first_name',
                        order: 'asc',
                        label: 'Name (A-Z)',
                      },
                      {
                        sort: 'first_name',
                        order: 'desc',
                        label: 'Name (Z-A)',
                      },
                    ]}
                    sort={sort}
                    setSort={setSort}
                    order={order}
                    setOrder={setOrder}
                    fetchQuery={(sortQuery, orderQuery) =>
                      listProfiles(
                        page,
                        pageSize,
                        '',
                        sortQuery,
                        orderQuery,
                        search,
                      )
                    }
                  />
                  <div className="hidden xl:block">
                    <CsvFileUploadSteps
                      buttonTitle="Edit via CSV"
                      buttonKind={BUTTON_KIND.PRIMARY}
                      dialogTitle={MESSAGES.profile.import.heading}
                      dialogDescription={MESSAGES.profile.import.description}
                      isLoading={isLoading}
                      onSuccess={handleEditViaCSV}
                      setImportCSV={setImportCSV}
                    >
                      <div className="flex items-center">
                        <span className="pr-2 text-sm font-normal leading-5 text-gray-500">
                          Don't have a CSV file?
                        </span>
                        &nbsp;
                        <button
                          type="button"
                          className="flex items-center text-sm font-medium leading-5 text-brand-500"
                          onClick={exportCSV}
                        >
                          <span className="pr-1">Export a CSV</span>
                          <DownloadCsv />
                        </button>
                      </div>
                    </CsvFileUploadSteps>
                  </div>
                </div>
              </div>
            </div>
            {printError && (
              <ErrorAlert message="Design requirements are not met. Please edit and update the profile to continue" />
            )}
            {profiles ? (
              profiles.length > 0 ? (
                <div className="flex flex-col relative w-full xl:border border-gray-200 xl:shadow-sm sm:rounded-md min-h-8">
                  <ul
                    className={clsx('xl:divide-y divide-gray-200', {
                      'opacity-40': isLoading,
                    })}
                  >
                    <li key="ProfileListAdminHeader">
                      <div className="bg-gray-100 hidden xl:block">
                        <div className="flex items-center px-4 py-2 sm:px-6">
                          <div className="flex items-start flex-1 min-w-0 md:items-center">
                            <div className="w-7">
                              <InputCheckbox
                                id="select-all"
                                label=""
                                value={selectAll}
                                onChange={(value) => {
                                  setSelectAll(value);
                                  if (value) {
                                    const arr = profiles.map(({ id }) => id);
                                    setCheckedItems(profiles);
                                    selectProfiles(arr);
                                  } else {
                                    setCheckedItems([]);
                                    selectProfiles([]);
                                  }
                                }}
                              />
                            </div>
                            <div className="hidden min-w-full pr-4 lg:grid lg:grid-cols-7 xl:grid-cols-10 uppercase">
                              <p className="text-sm font-medium text-gray-900 lg:col-span-2 xl:col-span-2 xl:pl-4 lg:block text-start">
                                Name &amp; profile id
                              </p>
                              <p className="hidden text-sm font-medium text-gray-900 xl:col-span-2 text-start md:mt-0 xl:block">
                                Contact information
                              </p>
                              <p className="text-sm font-medium text-gray-900 lg:col-span-2 text-start md:mt-0">
                                Group
                              </p>
                              <p className="hidden text-sm font-medium text-center text-gray-900 xl:col-span-1 md:mt-0 xl:block">
                                App access
                              </p>
                              <p className="hidden text-sm font-medium text-center text-gray-900 xl:col-span-1 md:mt-0 xl:block">
                                Edit
                              </p>
                              <p className="hidden text-sm font-medium text-center text-gray-900 xl:col-span-1 md:mt-0 xl:block">
                                View
                              </p>
                              <p className="hidden text-sm font-medium text-center text-gray-900 xl:col-span-1 md:mt-0 xl:block">
                                Other
                              </p>
                            </div>
                            <div className="w-24" />
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-100 flex xl:hidden flex-row border border-gray-300 rounded-md divide-x divide-gray-300">
                        <div className="pl-6 md:px-6 py-4">
                          <InputCheckbox
                            id="select-all"
                            label=""
                            value={selectAll}
                            onChange={(value) => {
                              setSelectAll(value);
                              if (value) {
                                const arr = profiles.map(({ id }) => id);
                                setCheckedItems(profiles);
                                selectProfiles(arr);
                              } else {
                                setCheckedItems([]);
                                selectProfiles([]);
                              }
                            }}
                          />
                        </div>
                        <div className="font-medium pl-4 md:px-6 py-4 uppercase">
                          Name, profile id, app access, contact information
                          &amp; group
                        </div>
                      </div>
                    </li>
                    {profiles.map((profile, index) => (
                      <ProfileListItem
                        key={index}
                        profile={profile}
                        selected={checkedItems.includes(profile)}
                        isLoading={isLoading}
                        setRecentlyInvitedIds={setRecentlyInvitedIds}
                        recentlyInvitedIds={recentlyInvitedIds}
                        checkItem={() => handleCheckItem(profile)}
                        setSuccess={setSuccess}
                        setAppInviteError={setAppInviteError}
                        refreshProfiles={listProfiles}
                      />
                    ))}
                  </ul>
                  {isLoading && (
                    <div className="absolute text-gray-500 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                      <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                    </div>
                  )}
                </div>
              ) : (
                <div className="py-32">
                  <h3 className="w-full text-2xl font-medium leading-8 text-center text-gray-900">
                    {MESSAGES.profile.list.empty.heading}
                  </h3>
                  <p className="w-full mt-2 text-sm leading-5 text-center text-gray-500">
                    {MESSAGES.profile.list.empty.description}
                  </p>
                </div>
              )
            ) : (
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
              </div>
            )}
          </div>
        </Tab.Group>
      </div>
      <Pagination
        id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
        className="bg-gray-50"
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(pageQuery, pageSizeQuery) => {
          return listProfiles(
            pageQuery,
            pageSizeQuery,
            status,
            sort,
            order,
            search,
          );
        }}
        setIsLoading={setIsLoading}
      />

      <InfoPanelFooter
        className={checkedItems.length > 1 ? 'sm:pl-4 sm:pr-8' : '!-bottom-56'}
      >
        <div className="flex flex-wrap items-center justify-end gap-4">
          <p className="hidden text-sm leading-5 text-gray-500 sm:block">
            {checkedItems.length} selected
          </p>
          <Button
            buttonText="Add to group"
            kind={BUTTON_KIND.WHITE}
            icon={<UserGroupIcon />}
            onClick={() => setIsAddToGroupModalOpen(true)}
          />
          <AddProfilesToGroupModal
            isOpen={isAddToGroupModalOpen}
            setIsOpen={setIsAddToGroupModalOpen}
            checkedProfiles={checkedItems}
            onSuccessCallback={() => {
              setCheckedItems([]);
              selectProfiles([]);
              listProfiles();
              setSuccess('Profiles were added to group successfully.');
            }}
          />
          <Button
            buttonText="Invite to app"
            kind={BUTTON_KIND.WHITE}
            onClick={() =>
              hideAppInviteModal
                ? handleAccessButtonClick()
                : setIsAppInviteOpen(true)
            }
            icon={<DeviceMobileIcon />}
            disabled={tobeInvitedAccounts.length < 1}
          />
          <Modal
            isOpen={isAppInviteOpen && !hideAppInviteModal}
            setIsOpen={setIsAppInviteOpen}
            dialogTitle="Send an app invite"
            dialogDescription={
              <>
                Pressing this button will send an email invite to members of
                your organisation, inviting them to download and join the tapt
                app.
                <br />
              </>
            }
            onSuccess={handleAccessButtonClick}
            successButtonText="Send"
            checkboxDescription="Don't show me again"
            isLoading={isLoading}
            checkbox={checkbox}
            setCheckbox={setCheckbox}
          />
          <Button
            buttonText="Export QR Codes"
            kind={BUTTON_KIND.WHITE}
            icon={<QrcodeIcon />}
            onClick={() => setIsQRCodeModalOpen(true)}
          />
          <QRCodeFileSelectionModal
            checkedProfiles={checkedItems.map(({ id }) => id)}
            isOpen={isQRCodeModalOpen}
            setIsOpen={setIsQRCodeModalOpen}
            onSuccessCallback={() => {
              setCheckedItems([]);
              selectProfiles([]);
              if (selectAll) {
                setSelectAll(false);
              }
            }}
          />
          <div>
            <Modal
              isOpen={isEditSharedOpen}
              setIsOpen={setIsEditSharedOpen}
              buttonTitle={
                status === 'unprinted'
                  ? 'Set up shared info'
                  : 'Edit shared info'
              }
              dialogTitle="Editing shared profile information"
              dialogDescription={
                <>
                  This feature enables you to add information to multiple
                  profiles at once.
                  <br />
                  <br />
                  <span className="font-medium">Important:</span> Changes you
                  make will overwrite existing information on the selected
                  profiles.
                </>
              }
              buttonKind={BUTTON_KIND.WHITE}
              icon={<PencilIcon />}
              onSuccess={() => history.push(`/edit-shared-profile`)}
              successButtonText="Ok got it"
              isLoading={isLoading}
            />
          </div>
        </div>
      </InfoPanelFooter>
      <Modal
        isOpen={isSetupInfoOpen}
        setIsOpen={setIsSetupInfoOpen}
        dialogHeroVideo="/images/customise-profile.mp4"
        dialogTitle="Start customising your digital profile"
        dialogDescription={
          <>
            Now your cards are all set up, start customising your digital
            profile to match your branding.{' '}
            <a
              href="https://help.tapt.io/en/articles/8449589-editing-your-tapt-profile-keeping-your-information-updated"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </>
        }
        onSuccess={() => {
          selectSettingsTab(SettingsTab.PROFILE);
          history.push(`/settings`);
        }}
        successButtonText="Go to profile settings"
        cancelButtonText="Close"
        isLoading={isLoading}
        large
      />
      <Modal
        isOpen={isEditAfterActivateOpen}
        setIsOpen={setIsEditAfterActivateOpen}
        dialogTitle="You've activated a new card!"
        dialogDescription="If you would like to edit this card's profile now, click the 'Edit profile' button below. Otherwise, you can click 'Edit profile' next to the new profile in the cards list to edit later."
        onSuccess={() =>
          history.push(`/edit-profile/${String(profileIDForActivation)}`)
        }
        successButtonText="Edit profile"
        cancelButtonText="I'll edit later"
      />

      <PlatformFeeAnnouncementModal />
    </Layout>
  );
}

export default ProfileListPage;

/* eslint-enable */
