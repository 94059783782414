const CardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={17}
      viewBox="0 0 21 17"
      fill="none"
    >
      <g clipPath="url(#clip0_11100_89)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.09961 14.41V4.10001C1.09961 2.44001 2.43961 1.10001 4.09961 1.10001H18.4096C18.7896 1.10001 19.0996 1.41001 19.0996 1.79001V12.1C19.0996 13.75 17.7496 15.1 16.0996 15.1H1.78961C1.40961 15.1 1.09961 14.79 1.09961 14.41Z"
          stroke="currentColor"
          strokeWidth="2.2"
          strokeMiterlimit={10}
        />
        <path
          d="M5.05957 11.1H8.63957"
          stroke="currentColor"
          strokeWidth="2.09"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11100_89">
          <rect width="20.2" height="16.2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CardIcon;
