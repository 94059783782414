import { UserIcon, DeviceMobileIcon } from '@heroicons/react/outline';
import CardIcon from '@/components/Icons/CardIcon';
import { formatNumber } from './Events';
import { AnalyticsResponse } from '@/types/IAnalytics';

type ProfileTypesProps = {
  data: AnalyticsResponse['profile_types'];
};

function ProfileTypes({ data }: ProfileTypesProps) {
  const placeholderData: AnalyticsResponse['profile_types'] = {
    card: {
      percent: 0,
      total: 0,
    },
    digital: {
      percent: 0,
      total: 0,
    },
    total: 0,
  };

  const dataPresent = data.total > 0;
  const currentData = dataPresent ? data : placeholderData;

  return (
    <div className="flex flex-col justify-between relative">
      <div className="flex font-poppins">
        <div className="grid grid-flow-col gap-2 text-gray-500">
          <div className="flex">
            <div className="flex flex-col">
              <div className="flex mb-1">
                <UserIcon className="h-6 w-6 mr-1" />
                Profiles
              </div>
              <div className="text-gray-700 font-medium text-2xl mb-4">
                {formatNumber(currentData.total)}
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col">
              <div className="flex mb-1">
                <div className="h-6 w-6 mr-1 flex items-center">
                  <CardIcon />
                </div>
                Card
              </div>
              <div className="text-gray-700 font-medium text-2xl mb-1">
                {currentData.card.percent}%
              </div>
              <div className="text-gray-500 font-light text-xs">
                {currentData.card.total} total
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col">
              <div className="flex mb-1">
                <DeviceMobileIcon className="h-6 w-6 mr-1" />
                Digital
              </div>
              <div className="text-gray-700 font-medium text-2xl mb-1">
                {currentData.digital.percent}%
              </div>
              <div className="text-gray-500 font-light text-xs">
                {currentData.digital.total} total
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="w-full bg-brand-200 rounded h-5">
          <div
            className="bg-brand-500 h-5 rounded"
            style={{
              width: `${currentData.card.percent}%`,
            }}
          />
        </div>
        <div className="flex justify-around text-xs mt-2 font-medium text-gray-500">
          <div className="grid grid-flow-col gap-1 items-center">
            <div className="h-2 w-2 rounded-full bg-brand-500" />
            <div>Card profiles</div>
          </div>
          <div className="grid grid-flow-col gap-1 items-center">
            <div className="h-2 w-2 rounded-full bg-brand-200" />
            <div>Digital profile only</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileTypes;
