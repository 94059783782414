import { useEffect, useState } from 'react';
import { fetchAPI, getStrapiURL } from '@/api/strapi';
import useShopify from '@/hooks/useShopify';
import Price from './Price';
import Badge, { BADGE_KIND } from '../Badge';
import accounts from '@/api/accounts';

interface StrapiMedia {
  attributes: {
    name: string;
    url: string;
    mime: string;
    height: number;
    width?: number;
  };
}

interface StrapiMediaData {
  data: StrapiMedia;
}

interface Listing {
  id: number;
  attributes: {
    name: string;
    slug: string;
    heading: string;
    description: string;
    media: {
      data: StrapiMedia[];
    };
    badge?: string;
    shopify?: ShopifyBuy.Product;
  };
}

export default function OrderMoreTab(): JSX.Element {
  const { products } = useShopify();
  const [listings, setListings] = useState<Listing[]>([]);
  const [shopSsoUrl, setShopSsoUrl] = useState<string>('');

  function getStrapiMedia(media: StrapiMediaData) {
    const { url } = media?.data?.attributes || {};
    if (!url) {
      return '';
    }

    const imageUrl = url.startsWith('/') ? getStrapiURL(url) : url;
    return imageUrl;
  }

  useEffect(() => {
    fetchAPI('/products', { sort: ['id:asc'] }).then((res) => {
      const data = res.data.map((listing) => {
        const product = products.find(
          ({ handle }) => handle === listing.attributes.slug,
        );

        return {
          ...listing,
          attributes: {
            ...listing.attributes,
            shopify: product,
          },
        };
      });

      setListings(data as unknown as Listing[]);
    });
  }, [products]);

  useEffect(() => {
    accounts.getShopifySsoLink().then((res) => {
      setShopSsoUrl(res.data.data.url);
    });
  }, []);

  return (
    <>
      <div className="font-brown mt-5 pb-12 md:pb-20 grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-5">
        {listings.map((listing) => {
          const media = listing.attributes.media?.data[0];
          const mediaHover = listing.attributes.media?.data[1];
          const variant = listing.attributes.shopify?.variants[0];

          if (!listing.attributes.shopify) return null;
          const { handle } = listing.attributes.shopify;
          const ssoRedirect = encodeURIComponent(`?redirect_endpoint=/products/${handle}`)
          const productUrl = `${shopSsoUrl}${ssoRedirect}`;

          return (
            <a
              key={listing.id}
              href={productUrl}
              className="relative w-full block"
              target="_blank"
              rel="noreferrer"
            >
              {variant?.compareAtPriceV2?.amount && (
                <Badge
                  kind={BADGE_KIND.BRAND}
                  className="absolute left-5 top-5 z-10"
                  text="On Sale"
                />

                // <span className="absolute left-5 top-5 bg-brand-100 text-sm text-brand-700 px-2 py-1 rounded border border-brand-500 z-10">
                //   On Sale
                // </span>
              )}
              {!variant?.compareAtPriceV2?.amount &&
                listing.attributes.badge && (
                  <Badge
                    kind={BADGE_KIND.BRAND}
                    className="absolute left-5 top-5 z-10"
                    text={listing.attributes.badge}
                  />
                )}
              <div className="relative w-full md:max-w-md">
                {media.attributes?.url && (
                  <div className="relative bg-gray-50 hover:hover">
                    <img
                      src={getStrapiMedia({
                        data: media,
                      })}
                      alt={media.attributes.name}
                      height={media.attributes.height}
                      width={media.attributes.width}
                    />
                    {mediaHover && (
                      <div className="product-hover">
                        <img
                          src={getStrapiMedia({
                            data: mediaHover,
                          })}
                          alt={mediaHover.attributes.name}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div className="py-6 pr-4">
                  <p className="font-bold text-purple-700">
                    {listing.attributes.name}
                  </p>
                  <h4 className="mt-1 font-bold text-lg md:text-2xl lg:text-2.5xl leading-5 md:leading-6.5 lg:leading-7.5 text-gray-900">
                    {listing.attributes.heading}
                  </h4>
                  <p className="mt-4 text-base leading-5 font-brown text-gray-700">
                    {listing.attributes.description}
                  </p>
                  {variant?.priceV2.amount && (
                    <p className="font-bold text-lg leading-5 text-gray-900 mt-4">
                      <Price variant={variant} />
                    </p>
                  )}
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </>
  );
}
