import { InformationCircleIcon } from '@heroicons/react/outline';
import PurchasingAdditionalCardModal from '@/components/SettingsPage/ManagePlan/PurchasingAdditionalCardModal';
import { useState } from 'react';
import { getTierByTotalProfiles } from '@/helpers/sealSubscriptions';

interface Props {
  totalProfiles: number;
}

export default function InactivePlanDetail(props: Props) {
  const { totalProfiles } = props;

  const plan = getTierByTotalProfiles(totalProfiles);

  const [
    isPurchasingAdditionalCardModalOpen,
    setIsPurchasingAdditionalCardModalOpen,
  ] = useState(false);

  return (
    <div className="w-full">
      <article className="flex flex-col justify-center p-6 text-base bg-white rounded-lg border border-gray-200 max-md:px-5 max-md:max-w-full">
        <header className="text-xl font-medium text-gray-900 max-md:max-w-full">
          Plan
        </header>
        <section className="flex flex-wrap gap-1.5 justify-between content-center mt-6 max-md:max-w-full">
          <p className="text-gray-900 grow leading-6 whitespace-nowrap">
            Plan type: <span className="text-gray-500">{plan?.label}</span>
          </p>
          <div className="flex gap-1.5 justify-between text-right leading-[150%] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
            <InformationCircleIcon className="w-6 aspect-square text-brand-500" />
            <a
              href="#"
              onClick={() => setIsPurchasingAdditionalCardModalOpen(true)}
              className="flex-auto underline text-gray-500"
            >
              Purchasing additional cards
            </a>
          </div>
        </section>
        <section className="mt-3 leading-6 max-md:max-w-full">
          <p>
            Status: <span className="text-red-400">Inactive</span>
          </p>
        </section>
      </article>
      <div className="flex flex-col justify-center p-6 mt-6 bg-white rounded-lg border border-solid border-gray-200 max-md:px-5 max-md:max-w-full">
        <div className="text-xl font-medium text-gray-900 max-md:max-w-full">
          Items
        </div>
        <div className="flex gap-5 justify-between mt-6 max-md:flex-wrap max-md:max-w-full">
          <div className="flex-auto text-base leading-6 text-gray-900">
            Platform maintenance fee ({plan?.label})
          </div>
          <div className="text-lg font-medium leading-6 text-gray-500">
            ${plan?.price}
          </div>
        </div>
      </div>

      <PurchasingAdditionalCardModal
        isOpen={isPurchasingAdditionalCardModalOpen}
        setIsOpen={setIsPurchasingAdditionalCardModalOpen}
      />
    </div>
  );
}
