import {
  ArrowRightIcon,
  CheckIcon,
  PencilIcon,
  DotsHorizontalIcon,
  ClockIcon,
} from '@heroicons/react/outline';

const STAGE = {
  order: {
    new: 'new',
    awaiting_client: 'awaiting_client',
    design_edits: 'design_edits',
    ready_for_print: 'ready_for_print',
    fulfilled: 'fulfilled',
    in_print: 'in_print',
    on_hold: 'on_hold',
    all_orders: 'all_orders',
  },
  order_stage_name: {
    new: 'New',
    awaiting_client: 'With Client',
    design_edits: 'Edit',
    ready_for_print: 'Ready for Print',
    fulfilled: 'Fulfilled',
    in_print: 'In Print',
    on_hold: 'On Hold',
    all_orders: 'All Orders',
  },
  designStatus: [
    {
      id: 'awaiting_client',
      label: 'Approval needed',
      color: 'amber',
      icon: <ArrowRightIcon className="w-3 h-3" />,
    },
    {
      id: 'fulfilled',
      label: 'Completed',
      color: 'emerald',
      icon: <CheckIcon className="w-3 h-3" />,
    },
    {
      id: 'new',
      label: 'In process',
      color: 'red',
      icon: <ClockIcon className="w-3 h-3" />,
    },
    {
      id: 'ready_for_print',
      label: 'Approved',
      color: 'emerald',
      icon: <CheckIcon className="w-3 h-3" />,
    },
    {
      id: 'design_edits',
      label: 'Proof pending',
      color: 'gray',
      icon: <DotsHorizontalIcon className="w-3 h-3" />,
    },
    {
      id: 'design_needed',
      label: 'Design needed',
      color: 'red',
      icon: <PencilIcon className="w-3 h-3" />,
    },
  ],
  fulfillmentStatus: [
    {
      id: 'new',
      label: 'Unfulfilled',
      color: 'red',
    },
    {
      id: 'design_edits',
      label: 'Partially fulfilled',
      color: 'amber',
    },
    {
      id: 'fulfilled',
      label: 'Fulfilled',
      color: 'emerald',
    },
    {
      id: 'awaiting_client',
      label: 'Unfulfilled',
      color: 'red',
    },
    {
      id: 'ready_for_print',
      label: 'Partially fulfilled',
      color: 'amber',
    },
  ],
  order_tag: [
    {
      name: 'setup',
      value: 'setup',
    },
    {
      name: 'ready',
      value: 'ready',
    },
    {
      name: 'awaiting',
      value: 'awaiting',
    },
    {
      name: 'on_hold',
      value: 'on_hold',
    },
  ],
};

export { STAGE as default, STAGE };
