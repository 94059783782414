import { useEffect, ChangeEvent, useRef } from 'react';
import { PaperClipIcon, SearchIcon } from '@heroicons/react/solid';
import Spinner from '@/components/Icons/Spinner';
import classNames from '@/helpers/classNames';
import Button, { BUTTON_KIND, BUTTON_SIZE } from '../Button';

type CsvFileUploadComponentProps = {
  buttonTitle?: string;
  url?: string;
  onFileSelected: (file: File) => void;
  sizeError?: boolean;
  setSizeError?: React.Dispatch<React.SetStateAction<boolean>>;
  fileError?: boolean;
  loading?: boolean;
  file: any;
  setFile: React.Dispatch<any>;
  fileSize?: number;
};

const CsvFileUploadComponent = ({
  buttonTitle,
  url,
  onFileSelected,
  sizeError,
  setSizeError,
  fileError,
  loading,
  file,
  setFile,
  fileSize = 25_214_400,
}: CsvFileUploadComponentProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (sizeError || fileError) {
      setFile(undefined);
    }
  }, [sizeError, fileError]);

  function onFileUpload(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > fileSize && setSizeError) {
        setSizeError?.(true);
        return;
      }
      setSizeError?.(false);
      onFileSelected(e.target.files[0]);
      setFile(e.target.files[0]);
    }
  }

  return (
    <div className="pt-6">
      <div className="relative flex flex-col w-full h-auto transition border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none bg-gray-100">
        <span className="flex flex-col items-center justify-center w-full h-full py-6 font-medium border border-transparent">
          {loading ? (
            <Spinner className="mr-2 text-brand-500" />
          ) : (
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M6.66666 26.6667V28.3334C6.66666 29.6595 7.19345 30.9313 8.13113 31.8689C9.06881 32.8066 10.3406 33.3334 11.6667 33.3334H28.3333C29.6594 33.3334 30.9312 32.8066 31.8689 31.8689C32.8065 30.9313 33.3333 29.6595 33.3333 28.3334V26.6667M26.6667 13.3334L20 6.66675M20 6.66675L13.3333 13.3334M20 6.66675V26.6667"
                  stroke="#9CA3AF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          )}
          <div className="text-gray-500 text-center text-sm font-normal leading-[150%]">
            Click to upload or drag and drop
          </div>
          <div className="flex flex-col items-center justify-center pt-2 space-y-1">
            <span className="text-gray-500 text-center text-xs font-medium leading-[150%]">
              Max. File Size: 25MB
            </span>
            <span className="text-gray-500 text-center text-xs font-normal leading-[150%]">
              File type: CSV, .numbers, .xlxs{' '}
            </span>
            <Button
              size={BUTTON_SIZE.SMALL}
              buttonText="Browse File"
              icon={<SearchIcon />}
              kind={BUTTON_KIND.PRIMARY}
              className="!mt-3 py-[0.438rem] px-[0.563rem] pr-[0.688rem]"
            />
          </div>
        </span>
        <input
          ref={inputRef}
          className="absolute top-0 left-0 w-full h-full opacity-0"
          type="file"
          accept=".csv,application/x-iwork-numbers-sffnumbers,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={onFileUpload}
        />
        {sizeError && (
          <span
            className={classNames(
              'text-sm block mb-3 text-center',
              sizeError ? 'text-red-500' : 'text-gray-500',
            )}
          >
            {sizeError && 'File size is too big'}
          </span>
        )}
        {fileError && (
          <span
            className={classNames(
              'text-sm block mb-3 text-center',
              fileError ? 'text-red-500' : 'text-gray-500',
            )}
          >
            {fileError && 'File is invalid.'}
          </span>
        )}
      </div>
      {file !== undefined && (
        <div className="inline-flex mt-6">
          <a
            href={url}
            download
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-start text-sm leading-5 break-all border border-transparent"
          >
            {loading ? (
              <Spinner className="mr-2 text-brand-500" />
            ) : (
              <PaperClipIcon
                className="flex-shrink-0 w-5 h-5 mr-1 text-brand-500"
                aria-hidden="true"
              />
            )}
            {loading ? 'Uploading ...: ' : buttonTitle || ''}
            {file.name}
          </a>
          <button
            className="h-auto ml-4 text-sm font-normal leading-4 text-gray-500 underline appearance-none"
            onClick={() => setFile(undefined)}
            type="button"
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
};

export default CsvFileUploadComponent;
