import { useEffect, useState } from 'react';
import Layout from '@/components/Layout';
import useAuth from '@/hooks/useAuth';
import BlogCardImage from '@/components/BlogCardImage';
import CTAwithCards from '@/components/CTAwithCards';
import Button from '@/components/Button';
import ListWithLink from '@/components/ListWithLink';
import Feed from '@/components/Feed';
import Resources from '@/components/Resources';
import accounts from '@/api/accounts';
import { useQuery } from 'react-query';
import profilesAPI from '@/api/profiles';
import Skeleton from 'react-loading-skeleton';

export default function HomePage() {
  const { userScope, user, orgID } = useAuth();
  const isSuperUser = userScope === 'admin';
  const hasPremiumPlan = !isSuperUser && true;

  const [shopSsoUrl, setShopSsoUrl] = useState('');

  useQuery('listProfiles', listProfiles, {
    enabled: orgID !== undefined,
  });

  const [profileCount, setProfileCount] = useState<number | undefined>(
    undefined,
  );

  async function listProfiles() {
    const { data: profiles } = await profilesAPI.listProfiles({ orgID });

    setProfileCount(profiles.paging.total_entries);
  }

  useEffect(() => {
    accounts.getShopifySsoLink().then((res) => {
      const ssoRedirect = encodeURIComponent(
        '?redirect_endpoint=/collections/all',
      );
      setShopSsoUrl(`${res.data.data.url}${ssoRedirect}`);
    });
  }, []);

  return (
    <Layout pageName="HomePage" showPageName={false}>
      <div className="pt-8 pb-10 md:pt-[2rem] large-screen:max-w-[96.063rem] large-screen:w-full large-screen:mx-auto">
        <div className="flex flex-col justify-between pb-6 lg:flex-row sm:mb-7">
          <p className="block text-4xl font-medium text-gray-900 font-poppins">
            Welcome back{' '}
            <span className="text-brand-500">{user?.first_name}!</span>
          </p>
          <div className="items-center hidden mt-4 lg:justify-end lg:mt-0 sm:flex">
            <Button
              buttonText="Shop"
              className="ml-3"
              href={shopSsoUrl}
              external
              externalBlankTarget
            />
          </div>
        </div>
        <div className="grid h-full grid-cols-1 gap-6 sm:gap-7 xl:grid-cols-[60%_auto] 2xl:grid-cols-[70%_auto]">
          <div className="flex flex-col w-full h-full">
            <BlogCardImage />
            {/* recent updates */}
            <div className="grid grid-cols-1 gap-7 lg:grid-cols-[62%_auto] 2xl:grid-cols-[3.7fr_2fr] h-full">
              <div className="p-6 border border-gray-200 rounded-lg md:pr-2 bg-white">
                <div className="relative">
                  <Feed isRestricted={!hasPremiumPlan} />
                </div>
              </div>
              <div className="block px-6 py-5 border border-gray-200 rounded-lg sm:p-6 bg-white">
                <Resources />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full h-full">
            {profileCount === undefined ? (
              <div className="mb-7">
                <Skeleton width="100%" height={150} />
              </div>
            ) : (
              <div className="hidden p-6 border border-gray-200 rounded-lg sm:block bg-white mb-7">
                <div className="relative">
                  <ListWithLink isRestricted={!hasPremiumPlan} />
                </div>
              </div>
            )}
            <div className="border border-gray-200 rounded-lg p-6 h-full bg-white">
              <CTAwithCards />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
