import organisationsAPI from '@/api/organisations';
import Filter, { GroupFilterOption } from '@/components/Filter';
import Layout from '@/components/Layout';
import useAuth from '@/hooks/useAuth';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { DEFAULT_GROUP_OPTION } from './ProfileListPage';
import profilesAPI from '@/api/profiles';
import Pagination from '@/components/Pagination';
import LoadingAnimation from '@/components/LoadingAnimation';
import MESSAGES from '@/constants/messages-en';
import clsx from 'clsx';
import ProfileListItem from '@/components/ProfileListItem';
import InputCheckbox from '@/components/InputCheckbox';
import useAppState from '@/hooks/useAppState';
import SuccessAlert from '@/components/SuccessAlert';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import ErrorAlert from '@/components/ErrorAlert';
import ProfilesGroupsPseudoTabs from '@/components/ProfilesGroupsPseudoTabs';

function ProfileListGroupPage() {
  const { user, orgID } = useAuth();
  const { selectProfiles } = useAppState();

  const [page, setPage] = useState(1);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [selectedGroup, setSelectedGroup] =
    useState<GroupFilterOption>(DEFAULT_GROUP_OPTION);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [appInviteError, setAppInviteError] = useState<string | undefined>(
    undefined,
  );
  const [selectAll, setSelectAll] = useState(false);
  const [recentlyInvitedIds, setRecentlyInvitedIds] = useState<number[]>([]);
  const [filterOptions, setFilterOptions] = useState<GroupFilterOption[]>([]);

  const editorsGroup = filterOptions
    .filter((group) => group.id !== 0)
    .map((group) => group.id);

  const {
    data: groups,
    isError: errorGroups,
    isFetching: isGroupsFetching,
  } = useQuery('listGroups', listGroups, {
    enabled: orgID !== undefined && user !== undefined,
  });

  const { data: profiles, isError: errorProfiles } = useQuery(
    [
      'listProfilesInGroup',
      page,
      pageSize,
      sort,
      order,
      search,
      editorsGroup,
      selectedGroup.id,
    ],
    () => {
      return listProfilesInGroups();
    },
    {
      enabled: groups !== undefined,
    },
  );

  const error = errorGroups || errorProfiles;

  async function listGroups() {
    if (orgID === undefined || user === undefined) {
      return;
    }

    const { data: groups } = await organisationsAPI.listOrganisationGroups({
      orgID,
      page: 1,
      pageSize: 500,
    });

    const groupList: GroupFilterOption[] = [];

    groups.data.forEach((group) => {
      if (group.editors.length > 0) {
        group.editors.forEach((editor) => {
          if (editor.user.id === user.id) {
            groupList.push({ id: group.id, name: group.name });
          }

          if (groupList.length === 0 && editor.user.id === user.id) {
            setSelectedGroup({ name: group.name, id: group.id });
          }
        });
      }
    });

    setFilterOptions([DEFAULT_GROUP_OPTION, ...groupList]);

    return groups;
  }

  async function listProfilesInGroups(
    newPage: number = page,
    newPageSize: number = pageSize,
    newSort: string = sort,
    newOrder: string = order,
    newSearch: string = search,
    groupIDs: number[] = editorsGroup,
    groupID: number = selectedGroup.id,
  ) {
    if (editorsGroup.length === 0) {
      return {
        data: [],
        paging: {
          page_number: 1,
          page_offset: 0,
          page_size: 20,
          total_entries: 0,
          total_pages: 0,
        },
      };
    }

    if (selectedGroup.id === 0) {
      const { data: profiles } = await profilesAPI.listProfilesInGroups({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
        groupIDs,
      });

      return profiles;
    } else {
      const { data: profiles } = await profilesAPI.listProfilesInGroup({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
        groupID,
      });

      return profiles;
    }
  }

  function handleCheckItem(profileID: number) {
    let arr = checkedItems;

    if (checkedItems.includes(profileID)) {
      arr = arr.filter((e) => e !== profileID);
    } else {
      arr = [...checkedItems, profileID];
    }

    setCheckedItems(arr);
    selectProfiles(arr);
  }

  if (error) {
    return (
      <Layout pageName="My Profiles" className="bg-gray-50">
        <ErrorAlert message={MESSAGES.error.generic} />
      </Layout>
    );
  }

  return (
    <Layout pageName="My Profiles" className="bg-gray-50">
      <ProfilesGroupsPseudoTabs activeTab="profiles" />
      {success && (
        <SuccessAlert
          className="p-4 rounded-md bg-emerald-50"
          message={success}
        />
      )}
      {appInviteError && <ErrorAlert message={appInviteError} />}
      <div className="pt-4 flex space-y-3 space-x-3 items-end justify-end flex-wrap sm:space-y-0 sm:items-center">
        <Search
          id={`ProfileListGroupSearch-${page}-${pageSize}-${sort}-${order}-${search}-${selectedGroup.id}`}
          search={search}
          setSearch={setSearch}
          fetchQuery={(searchQuery) =>
            listProfilesInGroups(
              page,
              pageSize,
              sort,
              order,
              searchQuery,
              editorsGroup,
              selectedGroup.id,
            )
          }
          fetchEnabled={groups !== undefined && !isGroupsFetching}
        />
        <Filter
          id={`profile-list-group-filter-${page}-${pageSize}-${sort}-${order}-${search}-${selectedGroup.id}`}
          filter="groups"
          selected={selectedGroup}
          setSelected={setSelectedGroup}
          data={filterOptions}
        />
        <Sort
          id={`profile-list-group-sort-${page}-${pageSize}-${sort}-${order}-${search}-${selectedGroup.id}`}
          options={[
            {
              sort: 'date',
              order: 'desc',
              label: 'Date created (Newest first)',
            },
            {
              sort: 'date',
              order: 'asc',
              label: 'Date created (Oldest first)',
            },
            {
              sort: 'first_name',
              order: 'asc',
              label: 'Name (A-Z)',
            },
            {
              sort: 'first_name',
              order: 'desc',
              label: 'Name (Z-A)',
            },
          ]}
          sort={sort}
          setSort={setSort}
          order={order}
          setOrder={setOrder}
          fetchQuery={(sortQuery, orderQuery) =>
            listProfilesInGroups(
              page,
              pageSize,
              sortQuery,
              orderQuery,
              search,
              editorsGroup,
              selectedGroup.id,
            )
          }
          fetchQueryEnabled={groups !== undefined && !isGroupsFetching}
        />
      </div>
      <div className="py-3 xl:py-8">
        {profiles ? (
          profiles.data.length > 0 ? (
            <div className="flex flex-col relative shadow-sm xl:border border-gray-200 min-h-8 overflow-hidden">
              <ul
                className={clsx('divide-y divide-gray-200', {
                  'opacity-40': isPaginationLoading,
                })}
              >
                <li key="ProfileListGroupHeader">
                  <div className="bg-gray-100 hidden xl:flex items-center py-2 px-6 min-w-0 flex-1">
                    <div className="w-7">
                      <InputCheckbox
                        id="select-all"
                        label=""
                        value={selectAll}
                        onChange={(value) => {
                          setSelectAll(value);

                          if (value) {
                            const arr = profiles.data.map(({ id }) => id);
                            setCheckedItems(arr);
                            selectProfiles(arr);
                          } else {
                            setCheckedItems([]);
                            selectProfiles([]);
                          }
                        }}
                      />
                    </div>
                    <div className="hidden min-w-full pr-4 lg:grid lg:grid-cols-6 xl:grid-cols-9 uppercase">
                      <p className="text-sm font-medium text-gray-900 xl:col-span-2 xl:pl-4 lg:block text-start">
                        Name &amp; profile id
                      </p>
                      <p className="text-sm font-medium text-gray-900 xl:col-span-2 lg:block text-start">
                        Contact information
                      </p>
                      <p className="text-sm font-medium text-gray-900 xl:col-span-2 lg:block text-start">
                        Group
                      </p>
                      <p className="text-sm font-medium text-gray-900 xl:col-span-1 lg:block text-start">
                        App access
                      </p>
                      <p className="text-sm font-medium text-gray-900 xl:col-span-1 lg:block text-start">
                        Edit profile
                      </p>
                      <p className="text-sm font-medium text-gray-900 xl:col-span-1 lg:block text-start">
                        View profile
                      </p>
                    </div>
                  </div>
                  <div className="bg-gray-100 flex xl:hidden flex-row border border-gray-300 rounded-md divide-x divide-gray-300">
                    <div className="pl-6 md:px-6 py-4">
                      <InputCheckbox
                        id="select-all"
                        label=""
                        value={selectAll}
                        onChange={(value) => {
                          setSelectAll(value);

                          if (value) {
                            const arr = profiles.data.map(({ id }) => id);
                            setCheckedItems(arr);
                            selectProfiles(arr);
                          } else {
                            setCheckedItems([]);
                            selectProfiles([]);
                          }
                        }}
                      />
                    </div>
                    <div className="font-medium pl-4 md:px-6 py-4 uppercase">
                      Name, profile id, app access, contact information &amp;
                      group
                    </div>
                  </div>
                </li>
                {profiles.data.map((profile, index) => (
                  <ProfileListItem
                    key={index}
                    profile={profile}
                    isLoading={false}
                    recentlyInvitedIds={recentlyInvitedIds}
                    setRecentlyInvitedIds={setRecentlyInvitedIds}
                    selected={checkedItems.includes(profile.id)}
                    checkItem={() => handleCheckItem(profile.id)}
                    setSuccess={setSuccess}
                    setAppInviteError={setAppInviteError}
                    refreshProfiles={listProfilesInGroups}
                  />
                ))}
              </ul>
            </div>
          ) : (
            <div className="py-32">
              <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                {MESSAGES.profile.list.empty.heading}
              </h3>
              <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                {MESSAGES.profile.list.empty.description}{' '}
                <a
                  href="https://tapt.io/pages/how-to-use"
                  rel="noreferrer"
                  target="_blank"
                >
                  Learn more.
                </a>
              </p>
            </div>
          )
        ) : (
          <div className="flex flex-col justify-center min-h-screen py-6 px-6 lg:px-8">
            <div>
              <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
            </div>
          </div>
        )}
      </div>
      <Pagination
        id={`profile-list-group-page-${page}-${pageSize}-${sort}-${order}-${search}-${selectedGroup.id}`}
        className="bg-gray-50"
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setIsLoading={setIsPaginationLoading}
        fetchQuery={(pageQuery, pageSizeQuery) =>
          listProfilesInGroups(
            pageQuery,
            pageSizeQuery,
            sort,
            order,
            search,
            editorsGroup,
            selectedGroup.id,
          )
        }
        fetchQueryEnabled={groups !== undefined && !isGroupsFetching}
      />
    </Layout>
  );
}

export default ProfileListGroupPage;
