import ModalFullWidth from '@/components/ModalFullWidth';
import { BUTTON_KIND } from '@/components/Button';
import classNames from '@/helpers/classNames';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import React from 'react';

const DISCLAIMERS = [
  {
    header: 'Dashboard access',
    type: 'warning',
    bullet_points: ['You will no longer be able to access the Tapt dashboard to centrally manage your organisation\'s business cards.'],
  },
  {
    header: 'Tapt card remains functional',
    type: 'info',
    bullet_points: ['All cards will remain functional, and will still be able to send and receive information, which will be accessible to each individual cardholder through the Tapt app.'],
  },
  {
    header: 'Team management',
    type: 'warning',
    bullet_points: [
      'You will no longer be able to access the Tapt dashboard to centrally manage your organisation\'s business cards.',
      'Contacts received through Tapt cards will no longer be accessible through the central database on the Tapt dashboard.',
    ],
  },
  {
    header: 'Profile editing via the tapt app',
    type: 'info',
    bullet_points: [
      'Editing of any cardholder details will be restricted to the Tapt app, which each employee will have to manage individually.',
      'Each cardholder will be able to access the Tapt app to edit their profiles and manage contacts received.',
    ],
  },
  {
    header: 'Premium Features',
    type: 'warning',
    bullet_points: [
      'Larger organisations will be unable to group profiles by region or office.',
      'You will no longer be able to access integrations with your CRM software, for any contacts gathered through the Tapt card.',
      'You will no longer be able to automatically update cardholder details through active directory integration.',
      'You will no longer have access to any analytics on card use.'
    ],
  }
];

interface ModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CancellationPolicyModal({ isOpen, setIsOpen }: ModalProps): JSX.Element {
  return (
    <ModalFullWidth
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle=''
      onSuccess={() => {
        setIsOpen(false);
      }}
      onCancel={() => {
        setIsOpen(false);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      hideSuccessButton={true}
      successButtonText='Close'
      cancelButtonText='Close'
    >
      <>
        <div className='border-b border-gray-200 pb-5'>
          <h3 className='text-2xl text-gray-900 font-medium mb-2'>
            Cancellation Policy
          </h3>
          <p className='font-normal text-gray-500 text-base'>
            If the platform utilisation fee is not paid, your Tapt card will remain functional. However, you will lose
            access to the Tapt dashboard, through which you can centrally manage all profiles, contacts, and analytics.
            Carefully read the list below to see what features will become unavailable to your organisation should you
            decide not to continue utilising the platform.
          </p>
        </div>
        <ul className='flex flex-row flex-wrap w-full'>
          {DISCLAIMERS.map((disclaimer, index) => (
            <li
              key={`clear-profile-disclaimer-${index}`}
              className={classNames(
                'flex border-b border-gray-200 pb-2 mt-5',
                index % 2 === 0 ? 'ml-5' : '',
                index === DISCLAIMERS.length - 1 ? 'w-1/2' : 'w-5/12 flex-grow'
              )}
            >
              {disclaimer.type === 'warning' && (
                <XIcon
                  className='h-6 w-6 flex-shrink-0 text-red-500'
                  aria-hidden='true'
                />
              )}

              {disclaimer.type === 'info' && (
                <CheckIcon
                  className='h-6 w-6 flex-shrink-0 text-emerald-500'
                  aria-hidden='true'
                />
              )}
              <div className='ml-3'>
                  <span className='text-gray-900 font-medium'>
                    {disclaimer.header}
                  </span>
                <ul className='list-disc text-gray-500 text-xs leading-5 font-normal'>
                  {disclaimer.bullet_points.map((bullet_point, index) => (
                    <li
                      key={`clear-profile-disclaimer-bullet-point-${index}`}
                      className='ml-6'
                    >
                      {bullet_point}
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </>
    </ModalFullWidth>
  );
}