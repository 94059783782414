import { Dispatch, SetStateAction, useState } from 'react';
import Moment from 'react-moment';
import { Box } from '@material-ui/core';
import { filesize } from 'filesize';
import IFile from '@/types/IFile';
import adminAPI from '@/api/admin';
import Modal from '@/components/Modal';
import { BUTTON_KIND } from '@/components/Button';

interface ITaptComment {
  participant_name: string;
  comment_time: string;
  comment: string;
  comment_file: [] | IFile[];
  updateCommentValue: string | undefined;
  setUpdateCommentValue: Dispatch<SetStateAction<string | undefined>>;
  changeRequestId: number;
  orderId: number;
  isRerender: boolean;
  setIsReRender: Dispatch<SetStateAction<boolean>>;
  setUpdateCommentId: Dispatch<SetStateAction<number | undefined>>;
}

const A_WEEK_IN_MILLISECONDS = 604800000;

export default function TaptComment({
  participant_name,
  comment_time,
  comment,
  comment_file,
  updateCommentValue,
  setUpdateCommentValue,
  changeRequestId,
  orderId,
  isRerender,
  setIsReRender,
  setUpdateCommentId,
}: ITaptComment): JSX.Element {
  const [isDeleteComment, setIsDeleteComment] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  return (
    <Box className="flex justify-end items-end w-full ">
      <Box className="w-1/5 flex justify-center items-end gap-3">
        <Box
          onClick={() => {
            setUpdateCommentId(changeRequestId);
            setUpdateCommentValue(comment);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
          >
            <path
              d="M6.29427 2.5415H2.7526C2.37688 2.5415 2.01655 2.69075 1.75087 2.95643C1.48519 3.22211 1.33594 3.58244 1.33594 3.95816V11.7498C1.33594 12.1256 1.48519 12.4859 1.75087 12.7516C2.01655 13.0172 2.37688 13.1665 2.7526 13.1665H10.5443C10.92 13.1665 11.2803 13.0172 11.546 12.7516C11.8117 12.4859 11.9609 12.1256 11.9609 11.7498V8.20816M10.9594 1.53991C11.09 1.40461 11.2464 1.29668 11.4192 1.22244C11.592 1.14819 11.7779 1.10911 11.966 1.10748C12.1541 1.10584 12.3407 1.14168 12.5148 1.21292C12.6889 1.28415 12.8471 1.38934 12.9801 1.52235C13.1131 1.65537 13.2183 1.81354 13.2895 1.98765C13.3607 2.16175 13.3966 2.34829 13.395 2.5364C13.3933 2.7245 13.3542 2.9104 13.28 3.08324C13.2058 3.25608 13.0978 3.4124 12.9625 3.54308L6.88077 9.62483H4.8776V7.62166L10.9594 1.53991Z"
              stroke="#9CA3AF"
              strokeWidth="1.41667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        <Box
          className="cursor-pointer"
          onClick={() => setIsDeleteComment(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
          >
            <path
              d="M5.58594 6.79167V11.0417M8.41927 6.79167V11.0417M1.33594 3.95833H12.6693M11.9609 3.95833L11.3468 12.5589C11.3214 12.9163 11.1614 13.2508 10.8992 13.495C10.637 13.7392 10.292 13.875 9.93369 13.875H4.07152C3.7132 13.875 3.36819 13.7392 3.10598 13.495C2.84377 13.2508 2.68384 12.9163 2.6584 12.5589L2.04427 3.95833H11.9609ZM9.1276 3.95833V1.83333C9.1276 1.64547 9.05298 1.4653 8.92014 1.33247C8.7873 1.19963 8.60713 1.125 8.41927 1.125H5.58594C5.39808 1.125 5.21791 1.19963 5.08507 1.33247C4.95223 1.4653 4.8776 1.64547 4.8776 1.83333V3.95833H9.1276Z"
              stroke="#9CA3AF"
              strokeWidth="1.41667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      </Box>
      <Box className="w-4/5">
        <Box className="flex py-3 gap-6 w-full font-poppins text-sm font-medium text-gray-400 items-center">
          <p className="flex-1 text-brand-500">Tapt</p>
          <p className="cursor-default">
            <Moment
              local
              format="HH:mm DD/MM/YY"
              fromNowDuring={A_WEEK_IN_MILLISECONDS}
              withTitle
              titleFormat="HH:mm DD/MM/YY"
            >
              {comment_time}
            </Moment>
          </p>
          <p>{participant_name}</p>
        </Box>
        <Box className="border rounded-lg px-4 pt-3 pb-5 bg-white">
          <pre className="break-words text-sm leading-5 font-normal text-gray-900 whitespace-pre-wrap font-poppins">
            {comment}
          </pre>
          {comment_file.length > 0 && (
            <>
              <Box className="hidden sm:block" aria-hidden="true">
                <Box className="py-2 md:py-3">
                  <Box className="border-t border-gray-200" />
                </Box>
              </Box>
              <Box className="text-sm leading-5 font-medium font-poppins">
                <p>Files</p>
                <Box className="flex flex-wrap justify-start items-center gap-3 mt-4 text-brand-500">
                  {comment_file.map((item) => (
                    <Box
                      className="flex justify-start items-center gap-3 text-brand-500 cursor-pointer"
                      onClick={() => window.open(item.original_url, '_blank')}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M1.33594 11.333V12.1663C1.33594 12.8294 1.59933 13.4653 2.06817 13.9341C2.53701 14.4029 3.1729 14.6663 3.83594 14.6663H12.1693C12.8323 14.6663 13.4682 14.4029 13.937 13.9341C14.4059 13.4653 14.6693 12.8294 14.6693 12.1663V11.333M11.3359 7.99967L8.00261 11.333M8.00261 11.333L4.66927 7.99967M8.00261 11.333V1.33301"
                          stroke="#873CFF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p>{item.name}</p>
                      <p>
                        {filesize(item.file_size, {
                          base: 2,
                          standard: 'jedec',
                        }).toString()}
                      </p>
                    </Box>
                  ))}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>

      <Modal
        isOpen={isDeleteComment}
        setIsOpen={setIsDeleteComment}
        dialogTitle="Are you sure you want to delete this message?"
        successButtonText="Yes, delete message"
        successButtonKind={BUTTON_KIND.WHITE_BG_LIGHT_RED_TEXT}
        onSuccess={() => {
          adminAPI
            .deleteChangeRequest(orderId, changeRequestId)
            .then(() => {
              setIsloading(false);
              setIsDeleteComment(false);
              setIsReRender(!isRerender);
            })
            .catch((err) => console.log(err));
        }}
        isLoading={isLoading}
      />
    </Box>
  );
}
