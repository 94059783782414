const Pencil = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.6933 4.36003L15.64 7.3067M13.9433 3.11003C14.3341 2.71928 14.8641 2.49976 15.4167 2.49976C15.9693 2.49976 16.4992 2.71928 16.89 3.11003C17.2808 3.50078 17.5003 4.03076 17.5003 4.58336C17.5003 5.13597 17.2808 5.66595 16.89 6.0567L5.41667 17.53H2.5V14.5534L13.9433 3.11003Z"
        stroke="#D1D5DB"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Pencil;
