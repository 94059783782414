import { Fragment, useState } from 'react';
import {
  ExclamationCircleIcon,
  EyeIcon,
  PencilIcon,
} from '@heroicons/react/outline';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { useLocalStorage } from 'usehooks-ts';
import { stringTruncate } from '@/helpers/strings';
import Button, { BUTTON_KIND } from '@/components/Button';
import InputCheckbox from '@/components/InputCheckbox';
import Copy from '@/components/Icons/Copy';
import MobileDeviceIconRed from './Icons/MobileDeviceIconRed';
import MobileDeviceIconGreen from './Icons/MobileDeviceIconGreen';
import IProfile, { IProfileAppAccess } from '@/types/IProfile';
import MobileDeviceIconYellow from './Icons/MobileDeviceIconYellow';
import profilesAPI from '@/api/profiles';
import Modal from './Modal';
import validateEmail from '@/helpers/validateEmail';
import { Link } from 'react-router-dom';
import { ReactComponent as BlankProfileAvatar } from '@/assets/icons/default_avatar.svg';
import { Menu, Transition } from '@headlessui/react';
import { AddProfilesToGroupModal } from './ProfilePage/AddProfilesToGroupModal';
import { QRCodeFileSelectionModal } from './QRCode';

interface IProfileListItem {
  profile: IProfile;
  selected: boolean;
  checkItem: () => void;
  setSuccess: (value: string | undefined) => void;
  setAppInviteError: (value: string | undefined) => void;
  setRecentlyInvitedIds: React.Dispatch<React.SetStateAction<number[]>>;
  recentlyInvitedIds: number[];
  isLoading: boolean;
  refreshProfiles: () => void;
}

const appAccessIcon = {
  [IProfileAppAccess.pending]: <MobileDeviceIconYellow />,
  [IProfileAppAccess.connected]: <MobileDeviceIconGreen />,
  [IProfileAppAccess.not_connected]: <MobileDeviceIconRed />,
} as const;

function EditProfileWarningMobile() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dialogTitle="Please design cards before editing profiles."
        dialogDescription="Profile editing is disabled until you submit your card design."
        cancelButtonText="Close"
        onSuccess={() => {}}
      />
      <button
        type="button"
        className="flex flex-row items-center justify-center p-4 text-sm font-medium text-gray-700 w-1/2"
        onClick={() => setIsOpen(true)}
      >
        <PencilIcon className="w-5 h-5 text-gray-900" aria-hidden="true" />
        <span className="ml-2">Edit profile</span>
      </button>
    </>
  );
}

export default function ProfileListItem({
  selected,
  checkItem,
  setSuccess,
  setAppInviteError,
  recentlyInvitedIds,
  setRecentlyInvitedIds,
  isLoading,
  profile,
  refreshProfiles,
}: IProfileListItem) {
  const {
    id,
    profile_hash: profileHash,
    email: emailAddress,
    first_name: firstName,
    last_name: lastName,
    group_name: groupName,
    group_subheading: groupSubheading,
    editable,
    job_title: jobTitle,
    status,
    app_access: appAccess,
    organisation_id: orgID,
  } = profile;
  const photoUrl = profile.photo?.thumb_url;

  const [hideAppInviteModal, setHideAppInviteModal] = useLocalStorage(
    `hideAppInviteModal-${orgID}`,
    false,
  );

  const isRecentlyInvited = recentlyInvitedIds.includes(id);
  const isInvited =
    isRecentlyInvited || appAccess !== IProfileAppAccess.not_connected;

  const [clipboardCopy, setClipboardCopy] = useState(false);
  const [isAppInviteOpen, setIsAppInviteOpen] = useState(false);
  const [checkbox, setCheckbox] = useState(false);

  const viewProfile = `/view-profile/${profileHash}`;
  const editProfile = `/edit-profile/${id}`;

  async function handleAccessButtonClick() {
    if (emailAddress === null || !validateEmail(emailAddress)) {
      setIsAppInviteOpen(false);
      setAppInviteError(
        'Unable to invite as there is no email associated with this profile. Please add an email and try again.',
      );
    }
    if (isInvited) {
      return;
    }
    try {
      await profilesAPI.inviteUsers(orgID, [id]);
      setSuccess('App invite successfully sent');
      setRecentlyInvitedIds((state) => [...state.filter((i) => i !== id), id]);
      setAppInviteError(undefined);
      setIsAppInviteOpen(false);
      if (!hideAppInviteModal) {
        setHideAppInviteModal(checkbox);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function copyToClipboard(value: string) {
    await navigator.clipboard.writeText(value);

    setClipboardCopy(true);
    setTimeout(() => {
      setClipboardCopy(false);
    }, 3000);
  }

  return (
    <li key={id}>
      <div className="hidden xl:block bg-white hover:bg-gray-50">
        <div className="relative flex items-center px-6 py-4 cursor-pointer">
          <a onClick={checkItem} className="after:absolute after:inset-0" />
          <div className="self-start md:self-center">
            <InputCheckbox
              id={`Checkbox-${status}-${id}`}
              label=""
              value={selected}
              onChange={checkItem}
            />
          </div>
          {/* ---------------------------------------FULL SCREEN ---------------------------------------*/}
          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div className="flex-1 min-w-0 lg:grid lg:grid-cols-7 xl:grid-cols-10">
              <div className="hidden lg:block lg:col-span-1 xl:col-span-2 relative">
                <div className="flex flex-col md:flex-row relative items-start md:items-center">
                  <div className="flex-shrink-0 ml-4">
                    {photoUrl ? (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={photoUrl}
                        alt="profile photo"
                      />
                    ) : (
                      <BlankProfileAvatar />
                    )}{' '}
                  </div>
                  <div className="min-w-0 ml-12 md:ml-4">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {firstName} {lastName}
                    </p>
                    <p className="flex items-center text-sm font-medium text-gray-500">
                      ID:&nbsp;
                      <button
                        type="button"
                        className="appearance-none text-brand-600 text-sm font-medium flex items-center"
                        onClick={() => copyToClipboard(profileHash)}
                      >
                        {clipboardCopy
                          ? 'Copied!'
                          : stringTruncate(profileHash)}
                        <Copy className="w-4 h-4 ml-1" />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div className="hidden md:mt-0 lg:ml-1 lg:col-span-2 xl:flex flex-col">
                <p className="text-sm text-gray-900 truncate">{jobTitle}</p>
                {emailAddress && (
                  <p className="text-sm text-gray-500 truncate">
                    {emailAddress}
                  </p>
                )}
              </div>
              <div className="hidden lg:block lg:col-span-1 xl:col-span-2">
                <div className="flex flex-col md:flex-row relative items-start md:items-center">
                  <div className="min-w-0 ml-2 mt-0 md:mt-0">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {groupName}
                    </p>
                    <p className="text-sm text-gray-500 truncate">
                      {groupSubheading}
                    </p>
                  </div>
                </div>
              </div>
              {/* ------- APP Access ------ */}
              <div className="flex-col hidden col-span-1 2xl:block md:mt-0 xl:flex items-center pl-4">
                <div className="flex-col hidden col-span-2 xl:block lg:flex">
                  <div className="flex justify-center items-center">
                    <Button
                      className="border-none disabled:!opacity-100"
                      buttonText=""
                      kind={BUTTON_KIND.COLOR_OMITTED}
                      size="xsmall"
                      onClick={() =>
                        hideAppInviteModal
                          ? handleAccessButtonClick()
                          : setIsAppInviteOpen(true)
                      }
                      disabled={isInvited}
                      icon={
                        appAccessIcon[
                          isRecentlyInvited
                            ? IProfileAppAccess.pending
                            : appAccess
                        ]
                      }
                    />
                  </div>
                </div>
              </div>
              {/* ------- Edit profile ------ */}
              <div className="flex-col hidden col-span-1 xl:block md:mt-0 lg:flex items-center group">
                <div className="flex justify-center ml-4 items-center space-x-2 h-full tooltipcode">
                  <Button
                    kind={BUTTON_KIND.COLOR_OMITTED}
                    icon={<PencilIcon color="#000" />}
                    href={editable ? editProfile : undefined}
                    disabled={!editable}
                  />
                  {!editable && (
                    <div className="hidden absolute z-100 -translate-x-[58%] bg-brand-100 tooltipcontent group-hover:flex p-3 rounded-md text-brand-900 flex-row items-center shadow-lg max-h-16 w-max">
                      <div className="flex flex-row gap-2">
                        <div className="flex-shrink-0">
                          <ExclamationCircleIcon className="w-5 h-5 text-brand-500" />
                        </div>
                        <div className="text-sm space-y-1">
                          <div className="font-medium">
                            Please design cards before editing profiles.
                          </div>
                          <div>
                            Profile editing is disabled until you submit your
                            card design.
                          </div>
                        </div>
                      </div>
                      <div className="w-10 h-10 -mr-4 rotate-45 bg-brand-100" />
                    </div>
                  )}
                </div>
              </div>
              {/* ------- View Profile ------ */}
              <div className="flex-col hidden col-span-1 xl:block lg:flex items-center">
                <div className="flex justify-center items-center h-full ml-4">
                  <Button
                    className="pl-5"
                    buttonText=""
                    kind={BUTTON_KIND.COLOR_OMITTED}
                    size="xsmall"
                    icon={<EyeIcon color="#000" />}
                    href={viewProfile}
                    external
                  />
                </div>
              </div>

              <div className="flex items-center justify-center ml-4">
                <MoreOptionsMenu
                  profile={profile}
                  refreshProfiles={refreshProfiles}
                  setSuccess={setSuccess}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------MOBILE SCREEN ---------------------------------------*/}
      <div className="border border-gray-300 bg-white rounded-md xl:hidden my-4">
        <div className="flex flex-col divide-y divide-gray-300">
          <div className="flex flex-row items-center justify-between px-6 py-4">
            <span className="self-start">
              <InputCheckbox
                id={`Checkbox-${status}-${id}`}
                label=""
                value={selected}
                onChange={checkItem}
              />
            </span>
            <div className="flex flex-col w-3/4 px-2">
              <div className="flex flex-row space-x-3">
                <div className="flex-shrink-0">
                  {photoUrl ? (
                    <img
                      className="h-10 w-10 rounded-full"
                      src={photoUrl}
                      alt="Profile photo"
                    />
                  ) : (
                    <BlankProfileAvatar className="rounded-full" />
                  )}
                </div>
                <div className="flex flex-col flex-grow min-w-0">
                  <span className="truncate font-medium">
                    {firstName} {lastName}
                  </span>
                  <span className="truncate flex flex-row items-center text-gray-500">
                    ID:&nbsp;
                    <button
                      type="button"
                      className="appearance-none text-brand-600 text-sm font-medium items-center flex flex-row"
                      onClick={() => copyToClipboard(profileHash)}
                    >
                      {clipboardCopy ? 'Copied!' : stringTruncate(profileHash)}
                      <Copy className="w-4 h-4 ml-1" />
                    </button>
                  </span>
                </div>
              </div>
              <span className="truncate font-medium pt-2">{jobTitle}</span>
              {emailAddress && (
                <span className="text-gray-500 truncate">{emailAddress}</span>
              )}
              {groupName && (
                <span className="truncate font-medium pt-2">{groupName}</span>
              )}
              {groupSubheading && (
                <span className="text-gray-500 truncate">
                  {groupSubheading}
                </span>
              )}
            </div>
            <span className="self-start">
              <Button
                className="border-none disabled:!opacity-100"
                kind={BUTTON_KIND.COLOR_OMITTED}
                size="xsmall"
                onClick={() =>
                  hideAppInviteModal
                    ? handleAccessButtonClick()
                    : setIsAppInviteOpen(true)
                }
                disabled={isInvited}
                icon={
                  appAccessIcon[
                    isRecentlyInvited ? IProfileAppAccess.pending : appAccess
                  ]
                }
              />
            </span>
          </div>
          <div className="flex flex-row divide-x divide-gray-300">
            {editable ? (
              <Link
                to={editProfile}
                className="flex flex-row items-center justify-center p-4 text-sm font-medium text-gray-700 w-1/2"
              >
                <PencilIcon
                  className="w-5 h-5 text-gray-900"
                  aria-hidden="true"
                />
                <span className="ml-2">Edit profile</span>
              </Link>
            ) : (
              <EditProfileWarningMobile />
            )}
            <Link
              to={viewProfile}
              className="flex flex-row items-center justify-center p-4 text-sm font-medium text-gray-700 w-1/2"
            >
              <EyeIcon className="w-5 h-5 text-gray-900" aria-hidden="true" />
              <span className="ml-2">View profile</span>
            </Link>
            <div className="flex items-center px-2">
              <MoreOptionsMenu
                profile={profile}
                refreshProfiles={refreshProfiles}
                setSuccess={setSuccess}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isAppInviteOpen && !hideAppInviteModal}
        setIsOpen={setIsAppInviteOpen}
        dialogTitle="Send an app invite"
        dialogDescription={
          <>
            Pressing this button will send an email invite to members of your
            organisation, inviting them to download and join the tapt app.
            <br />
          </>
        }
        onSuccess={handleAccessButtonClick}
        successButtonText="Send"
        checkboxDescription="Don't show me again"
        isLoading={isLoading}
        checkbox={checkbox}
        setCheckbox={setCheckbox}
      />
    </li>
  );
}

type MoreOptionsMenuProps = {
  profile: IProfile;
  refreshProfiles: IProfileListItem['refreshProfiles'];
  setSuccess: IProfileListItem['setSuccess'];
};

function MoreOptionsMenu({
  profile,
  refreshProfiles,
  setSuccess,
}: MoreOptionsMenuProps) {
  const [isAddToGroupModalOpen, setIsAddToGroupModalOpen] = useState(false);
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);

  const { id } = profile;

  return (
    <>
      <Menu as="div" className="relative inline-block items-center">
        <div className="flex items-center">
          <Menu.Button className="appearance-none">
            <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
            <Menu.Item>
              <button
                type="button"
                className="text-gray-700 w-full text-left px-4 py-2 text-sm block"
                onClick={() => setIsAddToGroupModalOpen(true)}
              >
                Add to group
              </button>
            </Menu.Item>
            <Menu.Item>
              <button
                className="text-gray-700 w-full text-left block px-4 py-2 text-sm"
                onClick={() => setIsQRCodeModalOpen(true)}
              >
                Export QR Code
              </button>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
      <AddProfilesToGroupModal
        isOpen={isAddToGroupModalOpen}
        setIsOpen={setIsAddToGroupModalOpen}
        checkedProfiles={[profile]}
        onSuccessCallback={() => {
          refreshProfiles();
          setSuccess('Profile was added to group successfully');
        }}
      />
      <QRCodeFileSelectionModal
        checkedProfiles={[id]}
        isOpen={isQRCodeModalOpen}
        setIsOpen={setIsQRCodeModalOpen}
      />
    </>
  );
}
