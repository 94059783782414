const ProfileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8.68408 13.342C8.88608 12.938 9.00008 12.482 9.00008 12C9.00008 11.518 8.88608 11.062 8.68408 10.658M8.68408 13.342C8.38178 13.9464 7.88428 14.431 7.27217 14.7174C6.66007 15.0037 5.96921 15.075 5.31152 14.9197C4.65384 14.7644 4.06785 14.3916 3.64849 13.8617C3.22914 13.3318 3.00098 12.6758 3.00098 12C3.00098 11.3242 3.22914 10.6682 3.64849 10.1383C4.06785 9.60841 4.65384 9.2356 5.31152 9.0803C5.96921 8.92499 6.66007 8.99629 7.27217 9.28264C7.88428 9.56899 8.38178 10.0536 8.68408 10.658M8.68408 13.342L15.3161 16.658M8.68408 10.658L15.3161 7.342M15.3161 16.658C14.9602 17.3698 14.9016 18.1939 15.1533 18.9489C15.4049 19.704 15.9462 20.3281 16.6581 20.684C17.3699 21.0399 18.194 21.0985 18.949 20.8468C19.704 20.5951 20.3282 20.0538 20.6841 19.342C21.04 18.6302 21.0986 17.8061 20.8469 17.0511C20.5952 16.296 20.0539 15.6719 19.3421 15.316C18.9896 15.1398 18.6059 15.0347 18.2128 15.0067C17.8197 14.9788 17.425 15.0286 17.0511 15.1532C16.2961 15.4049 15.672 15.9462 15.3161 16.658ZM15.3161 7.342C15.4923 7.6944 15.7362 8.00864 16.0339 8.26678C16.3316 8.52491 16.6772 8.72189 17.051 8.84646C17.4248 8.97103 17.8195 9.02075 18.2125 8.99279C18.6055 8.96483 18.9892 8.85973 19.3416 8.6835C19.694 8.50727 20.0082 8.26335 20.2664 7.96567C20.5245 7.66799 20.7215 7.32239 20.846 6.94858C20.9706 6.57478 21.0203 6.1801 20.9924 5.78708C20.9644 5.39407 20.8593 5.0104 20.6831 4.658C20.3272 3.94629 19.7031 3.40512 18.9482 3.15354C18.1932 2.90196 17.3693 2.96058 16.6576 3.3165C15.9459 3.67242 15.4047 4.29649 15.1531 5.05142C14.9015 5.80634 14.9602 6.63029 15.3161 7.342Z"
        stroke="#873CFF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProfileIcon;
