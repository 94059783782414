import { IIcon } from '@/types/IIcon';

const DownloadCsv = ({
  color = '#D1D5DB',
  width = '27',
  height = '27',
}: IIcon): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33334 13.3333V14.1666C3.33334 14.8296 3.59674 15.4655 4.06558 15.9344C4.53442 16.4032 5.1703 16.6666 5.83335 16.6666H14.1667C14.8297 16.6666 15.4656 16.4032 15.9345 15.9344C16.4033 15.4655 16.6667 14.8296 16.6667 14.1666V13.3333M13.3334 9.99992L10 13.3333M10 13.3333L6.66668 9.99992M10 13.3333V3.33325"
        stroke="#873CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadCsv;
