import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

enum SettingsTab {
  GENERAL,
  ORGANISATION,
  PROFILE,
  CONTACT_EXCHANGE,
  PROFILE_EDITING,
  MANAGE_PLAN,
}

export interface AppContextType {
  multipleCards: number[];
  multipleProfiles: number[];
  profileStatus: number;
  profileType: number;
  cardStatus: number;
  settingsTab: number;
  shopTab: number;
  integrationsTab: number;
  expandedNavItems: string[];
  selectCards: (cardIDs: number[]) => void;
  selectProfiles: (profileIDs: number[]) => void;
  selectProfileStatus: (index: number) => void;
  selectProfileType: (index: number) => void;
  selectCardStatus: (index: number) => void;
  selectSettingsTab: (index: SettingsTab) => void;
  selectShopTab: (index: number) => void;
  selectIntegrationsTab: (index: number) => void;
  setExpandedNavItems: React.Dispatch<React.SetStateAction<string[]>>;
}

const StateContext = createContext<AppContextType>({} as AppContextType);

export function StateProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [multipleCards, setMultipleCards] = useState<number[]>([]);
  const [multipleProfiles, setMultipleProfiles] = useState<number[]>([]);
  const [profileStatus, setProfileStatus] = useState<number>(0);
  const [profileType, setProfileType] = useState<number>(0);
  const [cardStatus, setCardStatus] = useState<number>(0);
  const [settingsTab, setSettingsTab] = useState<SettingsTab>(
    SettingsTab.GENERAL,
  );
  const [shopTab, setShopTab] = useState<number>(0);
  const [integrationsTab, setIntegrationsTab] = useState<number>(0);
  const [expandedNavItems, setExpandedNavItems] = useState<string[]>([]);
  function selectCards(cardIDs: number[]) {
    setMultipleCards(cardIDs);
  }

  function selectProfiles(profileIDs: number[]) {
    setMultipleProfiles(profileIDs);
  }

  function selectProfileStatus(index: number) {
    setProfileStatus(index);
  }

  function selectProfileType(index: number) {
    setProfileType(index);
  }

  function selectCardStatus(index: number) {
    setCardStatus(index);
  }

  function selectSettingsTab(tabIndex: SettingsTab) {
    setSettingsTab(tabIndex);
  }

  function selectShopTab(index: number) {
    setShopTab(index);
  }

  function selectIntegrationsTab(index: number) {
    setIntegrationsTab(index);
  }

  const memoedValue = useMemo(
    () => ({
      multipleCards,
      multipleProfiles,
      profileStatus,
      profileType,
      cardStatus,
      settingsTab,
      shopTab,
      integrationsTab,
      expandedNavItems,
      selectCards,
      selectProfiles,
      selectProfileStatus,
      selectProfileType,
      selectCardStatus,
      selectSettingsTab,
      selectShopTab,
      selectIntegrationsTab,
      setExpandedNavItems,
    }),
    [
      multipleCards,
      multipleProfiles,
      profileStatus,
      profileType,
      cardStatus,
      settingsTab,
      shopTab,
      integrationsTab,
      expandedNavItems,
    ],
  );

  return (
    <StateContext.Provider value={memoedValue}>
      {children}
    </StateContext.Provider>
  );
}

export { SettingsTab };

export default function useAppState(): AppContextType {
  return useContext(StateContext);
}
