import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ErrorAlert from '@/components/ErrorAlert';
import useAuth from '@/hooks/useAuth';
import validateEmail from '@/helpers/validateEmail';
import validateComplexPassword from '@/helpers/validateComplexPassword';
import TandCs from '@/assets/documents/Website Terms and Conditions - Tapt By Hatch.pdf';
import Input from '@/components/Input';
import InputCheckbox from '@/components/InputCheckbox';
import Button from '@/components/Button';
import { searchParamsQuery } from '@/helpers/urlHandler';
import organisationsAPI from '@/api/organisations';
import LayoutStandardNotSticky from '@/components/LayoutStandardNotSticky';
import MESSAGES from '@/constants/messages-en';

interface ISignUpPage {
  location: {
    state: {
      activationKey: string;
    };
  };
}

function SignUpPage({ location }: ISignUpPage) {
  const { signUp, login, loading, authError, user, orgID } = useAuth();
  const history = useHistory();
  const activationKey = location.state?.activationKey;
  const { invite, inviteEmail, type } = searchParamsQuery(
    window.location.search,
  );

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [organisationName, setOrganisationName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [tandc, setTandc] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [organisationNameError, setOrganisationNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [tandcError, setTandcError] = useState(false);

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  function onSignUp() {
    // set errors back to false
    setEmailError(false);
    setFirstNameError(false);
    setLastNameError(false);
    setOrganisationNameError(false);
    setPasswordError(false);
    setConfirmPasswordError(false);
    setTandcError(false);

    if (!validateEmail(email)) {
      setEmailError(true);
    } else if (firstName === '') {
      setFirstNameError(true);
    } else if (lastName === '') {
      setLastNameError(true);
    } else if (organisationName === '') {
      setOrganisationNameError(true);
    } else if (!validateComplexPassword(password)) {
      setPasswordError(true);
    } else if (password !== confirmPassword) {
      setConfirmPasswordError(true);
    } else if (!tandc) {
      setTandcError(true);
    } else {
      signUp(
        email,
        capitalize(firstName),
        capitalize(lastName),
        organisationName === ''
          ? [firstName, lastName].join(' ')
          : organisationName,
        password,
        confirmPassword,
        tandc,
        activationKey,
      );
    }
  }

  const acceptOrganisationInvite = () => {
    // set errors back to false
    setEmailError(false);
    setFirstNameError(false);
    setLastNameError(false);
    setPasswordError(false);
    setConfirmPasswordError(false);
    setTandcError(false);

    // Ignore email validation. because they're done server side
    if (firstName === '') {
      setFirstNameError(true);
    } else if (lastName === '') {
      setLastNameError(true);
    } else if (!validateComplexPassword(password)) {
      setPasswordError(true);
    } else if (password !== confirmPassword) {
      setConfirmPasswordError(true);
    } else if (!tandc) {
      setTandcError(true);
    } else {
      const params = {
        invite_code: invite,
        invite: {
          first_name: firstName,
          last_name: lastName,
          password,
          password_confirmation: confirmPassword,
        },
        type,
      };
      organisationsAPI
        .acceptUserInvite(params)
        .finally(() => login({ email: inviteEmail, password }));
    }
  };

  function isIOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  // redirect if user is already logged in
  useEffect(() => {
    if (user && orgID) {
      history.push('/', { activationKey });
    }
  }, [activationKey, history, orgID, user]);

  return (
    <LayoutStandardNotSticky heading="Create your Tapt account">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 border border-gray-100 shadow sm:rounded-lg sm:px-10">
          {authError && <ErrorAlert message={MESSAGES.error.auth} />}
          {emailError && <ErrorAlert message={MESSAGES.error.email} />}
          {firstNameError && <ErrorAlert message={MESSAGES.error.firstName} />}
          {lastNameError && <ErrorAlert message={MESSAGES.error.lastName} />}
          {organisationNameError && (
            <ErrorAlert message={MESSAGES.error.organisationName} />
          )}
          {passwordError && <ErrorAlert message={MESSAGES.error.password} />}
          {confirmPasswordError && (
            <ErrorAlert message={MESSAGES.error.passwordConfirm} />
          )}
          {tandcError && <ErrorAlert message={MESSAGES.error.tandc} />}

          <div className="space-y-6">
            <Input
              label="First name"
              type="text"
              value={firstName}
              required
              onChange={setFirstName}
              error={firstNameError}
            />
            <Input
              label="Last name"
              type="text"
              value={lastName}
              required
              onChange={setLastName}
              error={lastNameError}
            />

            {invite && inviteEmail ? (
              <Input
                label="Email address"
                type="email"
                value={inviteEmail}
                required
                onChange={setEmail}
                disabled
              />
            ) : (
              <>
                <Input
                  label="Email address"
                  type="email"
                  value={email}
                  required
                  onChange={setEmail}
                  onFocus={() => {
                    if (organisationName === '') {
                      setOrganisationName([firstName, lastName].join(' '));
                    }
                  }}
                  error={emailError}
                />
                <Input
                  label="Organisation name"
                  type="text"
                  value={organisationName}
                  required
                  onChange={setOrganisationName}
                  onFocus={() => {
                    if (organisationName === '') {
                      setOrganisationName([firstName, lastName].join(' '));
                    }
                  }}
                  error={organisationNameError}
                />
              </>
            )}

            <Input
              label="Password"
              type="password"
              value={password}
              required
              message="Min 8 chars. Min 1 uppercase letter. Min 1 number."
              onChange={setPassword}
              error={passwordError}
            />
            <Input
              label="Confirm password"
              type="password"
              value={confirmPassword}
              required
              onChange={setConfirmPassword}
              error={confirmPasswordError}
            />

            <div>
              <div className="relative flex items-start justify-center">
                <InputCheckbox
                  id="tandc"
                  label={
                    <div className="text-xs">
                      I have read and accept the{` `}
                      <a
                        href={TandCs}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-medium text-xs"
                      >
                        Terms &amp; conditions
                      </a>
                      .
                    </div>
                  }
                  value={tandc}
                  onChange={setTandc}
                />
              </div>
            </div>

            <div>
              <Button
                buttonText="Create account"
                onClick={() => {
                  if (!loading) {
                    if (!invite) {
                      if (organisationName === '') {
                        setOrganisationName([firstName, lastName].join(' '));
                      }
                      onSignUp();
                    } else {
                      acceptOrganisationInvite();
                    }
                  }
                }}
                fullWidth
                loading={loading}
              />
            </div>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">
                    Already have an account?
                  </span>
                </div>
              </div>
              <div className="mt-4 text-center">
                <Link to="/login">Log in to Tapt</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutStandardNotSticky>
  );
}

export default SignUpPage;
