import { PaperClipIcon, PlusIcon } from '@heroicons/react/solid';
import { ChangeEvent, useEffect, useState } from 'react';
import Spinner from '@/components/Icons/Spinner';
import { fileHelper } from '@/helpers/file';

export interface IPreviewFile {
  id: number;
  error?: string | undefined;
  file: File;
  loading?: boolean;
}

const FileUploadMuntiButtonComponent = ({
  id,
  title,
  buttonTitle,
  bottomText,
  fileFormat,
  onFileSelected,
  multiple,
  values,
  setArrFile,
  message,
  setError,
  fileSize = 134217728,
}: {
  id?: string;
  title?: string;
  buttonTitle?: string;
  bottomText?: string;
  fileFormat?: string;
  onFileSelected: (file: IPreviewFile) => Promise<any>;
  multiple?: boolean;
  values?: IPreviewFile[];
  message?: string;
  setArrFile: React.Dispatch<React.SetStateAction<IPreviewFile[]>>;
  setError?: React.Dispatch<React.SetStateAction<string>>;
  fileSize?: number;
}): JSX.Element => {
  const [loadingUpload, setLoadingUpload] = useState<{
    id: number;
    load: boolean;
  }>();
  const [previewFiles, setPreviewFiles] = useState<IPreviewFile[]>(
    values || [],
  );
  const [previewFile, setPreviewFile] = useState<IPreviewFile>();

  useEffect(() => {
    let index = previewFiles.findIndex((el) => el.id === previewFile?.id) || -1;

    if (index > -1) {
      previewFiles.splice(index, 1);
    }
    previewFile && previewFiles.push(previewFile);
    index && setPreviewFiles([...previewFiles]);

    return () => {
      index = -1;
    };
  }, [previewFile]);

  const getExtension = (filename: string) => {
    const parts = filename.split('.');
    return `.${parts[parts.length - 1]}`;
  };

  const updatePreviewFiles = (item: IPreviewFile) => {
    setPreviewFile({ ...item });
  };

  return (
    <div className="col-span-6 sm:col-span-3">
      <div className="block text-sm font-medium text-gray-700">{title}</div>
      {previewFiles.map((item, index) => (
        <div
          key={index}
          className={`${
            item.error ? 'text-red-450' : 'text-brand-500'
          } flex items-center `}
        >
          <div
            className="flex cursor-pointer items-center"
            onClick={() =>
              fileHelper.downloadFile(item.file, {
                onError: () => {
                  setError &&
                    setError('Something went wrong. Please try again later');
                },
              })
            }
          >
            <PaperClipIcon
              className={`${
                item.error ? 'text-red-450' : 'text-brand-500'
              } h-5 w-5 mr-2 flex-shrink-0`}
              aria-hidden="true"
            />
            <p className="text-sm leading-5 py-2.5 my-1 border border-transparent break-all">
              {item.file?.name}
              {item.error && (
                <span className="block text-red-450 text-xs">{item.error}</span>
              )}
            </p>
          </div>
          {item.id === loadingUpload?.id && loadingUpload?.load ? (
            <Spinner className="text-brand-500 ml-auto" />
          ) : (
            <button
              type="button"
              onClick={() => {
                setArrFile((files) => {
                  const newFiles = files.filter((e) => {
                    return e.id !== item.id;
                  });

                  return [...newFiles];
                });

                setPreviewFiles((files) => {
                  const newFiles = files.filter((e) => {
                    return e.id !== item.id;
                  });

                  return [...newFiles];
                });
              }}
              className="ml-auto appearance-none text-sm font-medium whitespace-nowrap"
            >
              Remove file
            </button>
          )}
        </div>
      ))}

      <div className="my-1 flex items-center relative gap-2">
        <label
          htmlFor={id || 'file-upload'}
          className="cursor-pointer text-brand-500 text-sm leading-5 whitespace-nowrap w-full"
        >
          <span className="flex font-medium w-full h-full py-2.5 border border-transparent items-center justify-start ">
            {loadingUpload?.load ? (
              <Spinner className="text-brand-500 mr-2" />
            ) : (
              <PlusIcon className="h-5 w-5 mr-2" />
            )}
            {loadingUpload?.load
              ? 'Uploading ...'
              : buttonTitle || 'Upload file'}
            <span className="ml-3 text-gray-400 text-xs leading-5 font-normal whitespace-normal">
              {message}
            </span>
          </span>
          <input
            disabled={loadingUpload?.load}
            id={id || 'file-upload'}
            name={id || 'file-upload'}
            type="file"
            accept={fileFormat}
            multiple={multiple}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.files) {
                const { files } = e.target;

                for await (const element of Object.values(files)) {
                  const id = Math.random();

                  if (
                    !fileFormat
                      ?.replaceAll(' ', '')
                      ?.split(',')
                      .includes(getExtension(element.name))
                  ) {
                    updatePreviewFiles({
                      id,
                      file: element,
                      error: 'File is invalid.',
                    });
                  } else if (element.size > fileSize) {
                    updatePreviewFiles({
                      id,
                      file: element,
                      error: 'File size is too big',
                    });
                  } else {
                    try {
                      updatePreviewFiles({ id, file: element, loading: true });

                      setLoadingUpload({ id, load: true });

                      await onFileSelected({
                        id,
                        file: element,
                        loading: true,
                      });
                    } catch (error: any) {
                      updatePreviewFiles({
                        id,
                        file: element,
                        error: 'Something went wrong. Please try again later!',
                      });
                    } finally {
                      setLoadingUpload({ id, load: false });
                    }
                  }
                }
              }
            }}
            className="absolute top-0 left-0 opacity-0 w-full h-full "
          />
        </label>
      </div>
      {bottomText && <p className="mt-2 text-sm text-gray-500">{bottomText}</p>}
    </div>
  );
};

export default FileUploadMuntiButtonComponent;
