import React from 'react';
import clsx from 'clsx';
import { IIcon } from '@/types/IIcon';

const CheckIcon = ({ className, color }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      className={clsx(className, color)}
    >
      <path
        d="M5.5 13L9.5 17L19.5 7"
        stroke={!color ? '#34D399' : undefined}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
