import { Fragment, useState } from 'react';
import Datepicker from 'react-datepicker';
import { CalendarIcon } from '@heroicons/react/outline';
import { Menu, Transition } from '@headlessui/react';
import { subDays, subMonths, subWeeks, subYears } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import './react-datepicker.css';
import Badge, { BADGE_KIND } from '../Badge';
import { formatDateToLocalISOString } from '@/helpers/strings';

type Props = {
  fetchData?: (from?: string, to?: string) => void;
};

const DateFilter: React.FC<Props> = ({ fetchData }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedPeriodLabel, setSelectedPeriodLabel] =
    useState('Filter by date');

  const getDate = (date?: Date | null) => {
    return date?.toISOString()?.split('T')?.[0];
  };

  const onChange = (dates: Array<Date | null>) => {
    const [start, end] = dates;

    if (start !== null && end !== null) {
      setShowCalendar(false);
      fetchData?.(
        formatDateToLocalISOString(start),
        formatDateToLocalISOString(end),
      );
      setSelectedPeriodLabel('Custom');
    }

    setStartDate(start);
    setEndDate(end);
  };

  const showingAllTime = startDate === null && endDate === null;
  const dateFilterButtonText = showingAllTime
    ? 'All Time'
    : [
        startDate?.toLocaleDateString('en-AU') || '∞',
        endDate?.toLocaleDateString('en-AU') || '∞',
      ].join(' - ');

  const today = new Date();
  const oneWeekAgo = subWeeks(today, 1);
  const oneMonthAgo = subMonths(today, 1);
  const ninetyDaysAgo = subDays(today, 90);
  const oneYearAgo = subYears(today, 1);

  const dateFilterOptions = {
    Today: [today, today],
    'Last 7 days': [oneWeekAgo, today],
    'Last 30 days': [oneMonthAgo, today],
    'Last 90 days': [ninetyDaysAgo, today],
    'Last 12 months': [oneYearAgo, today],
  };

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left lg:mt-0"
        style={{
          zIndex: 11,
        }}
      >
        <div>
          <Menu.Button
            className="inline-flex justify-center items-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:ring-brand-500 focus:border-brand-500"
            onClick={() => {}}
          >
            <CalendarIcon className="w-4 h-4 mr-2" />

            {selectedPeriodLabel}
            <Badge
              kind={BADGE_KIND.GRAY}
              className="ml-2"
              text={dateFilterButtonText}
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-hidden">
            <div className="py-1">
              <Menu.Item>
                <div
                  className="flex items-center py-2 pl-3 pr-4 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    setStartDate(null);
                    setEndDate(null);
                    fetchData?.();
                    setSelectedPeriodLabel('All time');
                  }}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex={0}
                >
                  <input
                    type="radio"
                    className="h-4 w-4 text-brand-500 focus:ring-brand-500 border-gray-300"
                    checked={startDate === null && endDate === null}
                    readOnly
                  />
                  <span className="ml-3 block text-sm text-gray-700">
                    All time
                  </span>
                </div>
              </Menu.Item>
              {Object.entries(dateFilterOptions).map(([label, dates]) => (
                <Menu.Item key={label}>
                  <div
                    className="flex items-center py-2 pl-3 pr-4 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      setSelectedPeriodLabel(label);
                      setStartDate(dates[0]);
                      setEndDate(dates[1]);

                      fetchData?.(
                        formatDateToLocalISOString(dates[0]),
                        formatDateToLocalISOString(dates[1]),
                      );
                    }}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    <input
                      type="radio"
                      className="h-4 w-4 text-brand-500 focus:ring-brand-500 border-gray-300"
                      checked={
                        startDate === null || endDate === null
                          ? false
                          : formatDateToLocalISOString(startDate) ===
                              formatDateToLocalISOString(dates[0]) &&
                            formatDateToLocalISOString(endDate) ===
                              formatDateToLocalISOString(dates[1])
                      }
                      readOnly
                    />
                    <span className="ml-3 block text-sm text-gray-700">
                      {label}
                    </span>
                  </div>
                </Menu.Item>
              ))}
              <Menu.Item>
                <div
                  className="flex items-center py-2 pl-3 pr-4 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    setShowCalendar(true);
                  }}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex={0}
                >
                  <input
                    type="radio"
                    className="h-4 w-4 text-brand-500 focus:ring-brand-500 border-gray-300"
                    checked={false}
                    readOnly
                  />
                  <span className="ml-3 block text-sm text-gray-700">
                    Custom
                  </span>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {showCalendar && (
        <div className="absolute top-20 z-20">
          <Datepicker
            placeholderText="All Time"
            calendarClassName="!font-poppins drop-shadow-lg !border-0"
            monthClassName={() => '!text-gray-100 !font-normal'}
            dayClassName={() => ''}
            popperClassName="!z-50"
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            monthsShown={2}
            inline
            showPopperArrow={false}
            onClickOutside={() => {
              setShowCalendar(false);
            }}
            excludeDateIntervals={[
              {
                start: new Date(1970, 1, 1),
                end: new Date(2022, 11, 31),
              },
            ]}
          />
        </div>
      )}
    </>
  );
};

export default DateFilter;
