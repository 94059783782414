export default function validateUrl(candidateUrl: string) {
  const protocolRegex = /^[a-zA-Z]+:\/\//;
  if (!protocolRegex.test(candidateUrl)) {
    candidateUrl = `https://${candidateUrl}`;
  }

  try {
    new URL(candidateUrl);
    return true;
  } catch {
    return false;
  }
}
