import React, { useEffect, useState } from 'react';
import listData from './data';
import { AppStoreLink, PlayStoreLink } from '@/constants/apps';
import isIOS from '@/helpers/isIos';
import TopRightIcon from '../Icons/TopRightIcon';

const Resources = () => {
  const [appLink, setAppLink] = useState<string>('/');

  useEffect(() => {
    setAppLink(isIOS() ? AppStoreLink : PlayStoreLink);
  }, []);
  return (
    <div>
      <h4 className="text-2xl font-medium leading-8 text-grey-900">
        Resources
      </h4>
      <ul className="pr-6 mt-3 overflow-auto scrolledDiv h-[26.5rem]">
        {listData.map((list, index) => (
          <li key={index} className="relative py-4 border-b border-gray-200">
            <div>
              <span className="inline-flex p-3 rounded-full bg-brand-100">
                {list.icon}
              </span>
            </div>
            <div className="mt-3">
              <h3 className="text-base font-medium leading-7 text-gray-900 sm:text-sm">
                {list.title}
              </h3>
            </div>
            <a
              className="absolute right-0 z-50 cursor-pointer top-4 "
              href={list.id === 1 ? appLink : list.url}
              target="_blank"
              rel="noreferrer"
            >
              <TopRightIcon />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Resources;
