import { AxiosResponse } from 'axios';
import axiosApiService from '@/services/axiosApiService';
import IUser from '@/types/IUser';
import { BASE_URL } from '../config';
import { IListCards } from './cards';
import IOrganisation, {
  IOrganisationGroup,
  IPilot,
  IPlatformSubscription,
  ISubscription,
} from '@/types/IOrganisation';
import ICard from '@/types/ICard';
import IList, { IListPaging } from '@/types/IList';
import IProfile from '@/types/IProfile';
import IActivationKey from '@/types/IActivationKey';
import IContacts from '@/types/IContacts';
import { IAnalyticsAdmin } from '@/types/IAnalytics';
import ISalesChannel from '@/types/ISalesChannel';
import { domain } from 'process';
import { IBrandSettings } from '@/types/IBrandSettings';

const adminAPI = {
  listOrganisations: ({
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    search = '',
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IOrganisation[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/organisations?page=${page}&page_size=${pageSize}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      }`,
    );
  },
  createOrganisation: (body: {
    organisation: {
      name: string;
      domain_name: string;
    };
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(`${BASE_URL}/admin/api/organisations`, body);
  },
  updateOrganisation: (
    orgID: number,
    body: {
      organisation: {
        saas_enabled?: boolean;
      };
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/admin/api/organisations/${orgID}`,
      body,
    );
  },
  deleteOrganisation: (orgID: number): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(
      `${BASE_URL}/admin/api/organisations/${orgID}`,
    );
  },
  updateOrganisationRole: (
    roleID: number,
    body: {
      organisation_role: {
        organisation_id: number;
      };
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/admin/api/organisation_roles/${roleID}`,
      body,
    );
  },
  listUsers: (
    page = 1,
    pageSize = 20,
    params: Record<string, string>,
  ): Promise<AxiosResponse<{ data: IUser[]; paging: IListPaging }>> => {
    const query = new URLSearchParams(params);
    return axiosApiService.get(
      `${BASE_URL}/admin/api/users?page=${page}&page_size=${pageSize}&${query.toString()}`,
    );
  },
  inviteUser: (body: {
    invite: {
      email: string;
      role: string;
    };
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(`${BASE_URL}/admin/api/invites`, body);
  },
  deleteUser: (userID: number): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(`${BASE_URL}/admin/api/users/${userID}`);
  },
  listCards: ({
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    status = 'unprogrammed',
    search = '',
  }: Partial<IListCards>): Promise<
    AxiosResponse<{ data: ICard[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/cards?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        status !== 'all' ? `&status=${status}` : ''
      }${search !== '' ? `&search=${search}` : ''}`,
    );
  },
  createCard: (body: {
    card: {
      design_id?: number;
      profile_id?: number;
      organisation_id?: number;
      type: string;
    };
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(`${BASE_URL}/admin/api/cards`, body);
  },
  createCards: (body: {
    count: number;
    card: {
      design_id?: number;
      profile_id?: number;
      organisation_id?: number;
      type: string;
      sales_channel_id?: number;
    };
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(`${BASE_URL}/admin/api/cards`, body);
  },
  updateCard: (
    cardID: number,
    body: {
      card: {
        status?: string;
        profile_id?: number | null;
        design_id?: number;
        admin_note?: string;
      };
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(`${BASE_URL}/admin/api/cards/${cardID}`, body);
  },
  updateCardsShared: (body: {
    ids: number[];
    card: {
      status?: string;
      profile_id?: number | null;
      design_id?: number;
      admin_note?: string;
    };
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(`${BASE_URL}/admin/api/cards/shared`, body);
  },
  deleteCard: (cardID: number): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(`${BASE_URL}/admin/api/cards/${cardID}`);
  },
  deleteCardsShared: (body: {
    ids: number[];
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(`${BASE_URL}/admin/api/cards/shared`, {
      data: body,
    });
  },
  exportCardsCSV: (ids: number[]): Promise<AxiosResponse<BlobPart>> => {
    return axiosApiService.get(`${BASE_URL}/admin/api/cards/export_csv`, {
      params: { ids },
    });
  },
  importCardsCSV: (formData: FormData): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(
      `${BASE_URL}/admin/api/cards/csv_import`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  listProfiles: ({
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    status = '',
    search = '',
    type = '',
    orgID = -1,
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IProfile[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/profiles?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        status !== '' ? `&status=${status}` : ''
      }${search !== '' ? `&search=${search}` : ''}${
        type !== '' && type !== 'all' ? `&type=${type}` : ''
      }${orgID !== -1 ? `&organisation_id=${orgID}` : ''}`,
    );
  },
  showProfile: (
    profileID: number,
  ): Promise<AxiosResponse<{ data: IProfile }>> => {
    return axiosApiService.get(`${BASE_URL}/admin/api/profiles/${profileID}`);
  },
  updateProfilesShared: (body: {
    ids: number[];
    profile: Partial<IProfile>;
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(`${BASE_URL}/admin/api/profiles/shared`, body);
  },
  deleteProfile: (profileID: number): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(
      `${BASE_URL}/admin/api/profiles/${profileID}`,
    );
  },
  deleteProfilesShared: (body: {
    ids: number[];
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(`${BASE_URL}/admin/api/profiles/shared`, {
      data: body,
    });
  },
  exportProfilesCSV: (ids: number[]): Promise<AxiosResponse<BlobPart>> => {
    return axiosApiService.get(`${BASE_URL}/admin/api/profiles/export_csv`, {
      params: { ids },
    });
  },

  exportProfilesViaOrderCSV: (
    order_numbers: string[],
  ): Promise<AxiosResponse<BlobPart>> => {
    return axiosApiService.get(`${BASE_URL}/admin/api/orders/export_csv`, {
      params: { order_numbers },
    });
  },

  exportOrderProfilesCSV: (
    order_number: string,
  ): Promise<AxiosResponse<BlobPart>> => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/v2/export_order_profiles?order_number=${order_number}`,
    );
  },

  listCardActivations: ({
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    type = '',
    search = '',
    status = '',
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IActivationKey[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/card_activations?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        type !== '' ? `&type=${type}` : ''
      }${search !== '' ? `&search=${search}` : ''}${
        status !== '' ? `&status=${status}` : ''
      }`,
    );
  },
  createActivationKey: (body: {
    count: number;
    card_activation: {
      email?: string;
      no_of_profiles: number;
      type: string;
    };
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(`${BASE_URL}/admin/api/card_activations`, body);
  },
  deleteActivationKey: (
    activationKeyID: number,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(
      `${BASE_URL}/admin/api/card_activations/${activationKeyID}`,
    );
  },
  deleteActivationKeysShared: (body: {
    ids: number[];
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(
      `${BASE_URL}/admin/api/card_activations/shared`,
      {
        data: body,
      },
    );
  },
  updateOrderOrganisation: (
    activationKeyID: number,
    body: {
      card_activation: {
        organisation_id: number;
      };
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.patch(
      `${BASE_URL}/admin/api/card_activations/${activationKeyID}`,
      body,
    );
  },
  exportKeysCSV: (ids: number[]): Promise<AxiosResponse<BlobPart>> => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/card_activations/export_csv`,
      {
        params: { ids },
      },
    );
  },
  listContacts: ({
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    search = '',
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IContacts[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/contacts?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      }`,
    );
  },
  exportContactsCSV: (): Promise<AxiosResponse<BlobPart>> => {
    return axiosApiService.get(`${BASE_URL}/admin/api/contacts/export_csv`);
  },
  listAnalytics: ({
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    search = '',
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IAnalyticsAdmin[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/analytics?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      }`,
    );
  },
  listSalesChannels: (): Promise<AxiosResponse<{ data: ISalesChannel[] }>> => {
    return axiosApiService.get(`${BASE_URL}/admin/api/sales_channels`);
  },
  listPilots: ({
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    search = '',
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IPilot[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/pilots?page=${page}&page_size=${pageSize}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      }`,
    );
  },
  createPilot: (body: {
    organisation_id: number;
    plan_id: string;
  }): Promise<AxiosResponse<{ data: IPlatformSubscription }>> => {
    return axiosApiService.post(`${BASE_URL}/admin/api/pilots`, body);
  },
  deletePilot: (subscriptionId: number): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(
      `${BASE_URL}/admin/api/pilots/${subscriptionId}`,
    );
  },
  cancelSubscription: (
    subscriptionId: number,
  ): Promise<AxiosResponse<{ data: IPlatformSubscription }>> => {
    return axiosApiService.post(
      `${BASE_URL}/admin/api/pilots/${subscriptionId}/cancel`,
    );
  },
  createOrdersUsers: (body: {
    order_id: number;
    user_id: number;
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(`${BASE_URL}/admin/api/orders_users`, {
      orders_users_params: body,
    });
  },
  deleteOrdersUsers: (
    order_id: number,
    user_id: number,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(
      `${BASE_URL}/admin/api/orders_users/orders/${order_id}/users/${user_id}`,
    );
  },
  updateChangeRequestComment: (
    order_id: number,
    change_request_id: number,
    body: {
      note: string;
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.patch(
      `${BASE_URL}/admin/api/v2/orders/${order_id}/change_requests/${change_request_id}`,
      body,
    );
  },
  deleteChangeRequest: (
    order_id: number,
    change_request_id: number,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(
      `${BASE_URL}/admin/api/v2/orders/${order_id}/change_requests/${change_request_id}`,
    );
  },
  listOrganisationGroups: ({
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    search = '',
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IOrganisationGroup[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/organisation_groups?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      }`,
    );
  },
  getBrandSettings: (domain: string): Promise<AxiosResponse<IBrandSettings>> => {
    return axiosApiService.get(`${BASE_URL}/admin/api/sample_profile/${domain}`);
  },
};

export default adminAPI;
