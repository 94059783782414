import React from 'react';

const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M13.5196 5.57373L20.2337 12.2879M20.2337 12.2879L13.5196 19.002M20.2337 12.2879H2.96875"
        stroke="white"
        strokeWidth="2.30199"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRight;
