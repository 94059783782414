import { useState } from 'react';
import { cx } from '@emotion/css';
import ReactGA from 'react-ga4';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import Input from '@/components/Input';
import Button, { BUTTON_KIND, BUTTON_SIZE } from '@/components/Button';
import profilesAPI from '@/api/profiles';
import validateEmail from '@/helpers/validateEmail';
import ErrorAlert from '@/components/ErrorAlert';
import Check from '@/components/Icons/Check';
import MESSAGES from '@/constants/messages-en';
import { IOrganisationSettingsContactExchange } from '@/types/IOrganisation';
import InputCheckbox from '../InputCheckbox';
import IFile from '@/types/IFile';
import classNames from '@/helpers/classNames';

interface IProfilePageShareDetails {
  contactExchange: IOrganisationSettingsContactExchange;
  profileHashID: string;
  profileName: string | null;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  styles: Record<string, string>;
  consentStatement: IFile | undefined;
  consentMessage: string | undefined;
}

const ProfilePageShareDetails: React.FC<IProfilePageShareDetails> = ({
  contactExchange,
  profileHashID,
  profileName,
  styles,
  setIsOpen,
  consentStatement,
  consentMessage,
}) => {
  const [firstName, setFirstName] = useState<string>(
    localStorage.getItem('firstName') || '',
  );
  const [lastName, setLastName] = useState<string>(
    localStorage.getItem('lastName') || '',
  );
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(
    localStorage.getItem('email') || '',
  );
  const [emailError, setEmailError] = useState<boolean>(false);
  const [consentError, setConsentError] = useState<boolean>(false);
  const [mobileNumber, setMobileNumber] = useState<string>(
    localStorage.getItem('mobileNumber') || '',
  );
  const [companyName, setCompanyName] = useState<string>(
    localStorage.getItem('companyName') || '',
  );
  const [checkedConsent, setCheckedConsent] = useState<boolean>(false);
  const [position, setPosition] = useState<string>(
    localStorage.getItem('position') || '',
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [checkItem, setCheckItem] = useState<boolean>(false);

  const {
    company_name: companyNameToggle,
    email: emailToggle,
    job_title: jobTitleToggle,
    mobile_number: mobileNumberToggle,
    name: nameToggle,
    company_consent: consentToggle,
  } = contactExchange;

  const handleClick = () => {
    setSuccess(false);
    setError(false);
    setFirstNameError(false);
    setEmailError(false);

    if (firstName === '') {
      setFirstNameError(true);
    } else if (emailToggle && email !== '' && !validateEmail(email)) {
      setEmailError(true);
      // } else if (consentToggle && !checkedConsent) {
      //   setConsentError(true);
    } else {
      setIsLoading(true);
      profilesAPI
        .shareDetails(profileHashID, {
          contact: {
            company_name: companyName,
            email,
            first_name: firstName,
            job_title: position,
            last_name: lastName,
            phone_number: mobileNumber,
          },
        })
        .then(() => {
          setSuccess(true);
          // GA Success event
          ReactGA.event({
            category: 'View Profile',
            action: 'Share Contact',
          });
        })
        .then(() => {
          localStorage.setItem('firstName', firstName);
          localStorage.setItem('lastName', lastName);
          localStorage.setItem('email', email);
          localStorage.setItem('mobileNumber', mobileNumber);
          localStorage.setItem('companyName', companyName);
          localStorage.setItem('position', position);
        })
        .catch(() => setError(true))
        .finally(() => setIsLoading(false));
    }
  };

  const renderConsentMessageLabel = () => {
    if (consentMessage) {
      return (
        <ReactMarkdown
          linkTarget="_blank"
          className="text-sm leading-5 font-medium"
        >
          {consentMessage}
        </ReactMarkdown>
      );
    }

    return (
      <div className="text-sm leading-5 font-medium">
        I accept the{' '}
        <a
          className={classNames(styles.textColor, 'underline')}
          href={consentStatement?.original_url}
          rel="noreferrer"
          target="_blank"
        >
          consent statement
        </a>
      </div>
    );
  };

  if (success) {
    return (
      <div className={cx(styles.background, styles.textColor)}>
        <div className="px-4 sm:px-6 py-48 text-center">
          <Check
            className="mx-auto mb-6 h-12 w-12 border-2 rounded-full p-3"
            aria-hidden="true"
          />
          <h2 className="text-2xl leading-8 font-medium">Thank you</h2>
          <p className="mt-2 mb-12">Your details have been submitted</p>
          <Button
            buttonText="Close"
            className={styles.button}
            fullWidth
            kind={BUTTON_KIND.COLOR_OMITTED}
            onClick={() => setIsOpen(false)}
            size={BUTTON_SIZE.XLARGE}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={cx(styles.background, styles.textColor)}>
      <button
        type="button"
        onClick={() => setIsOpen(false)}
        className="appearance-none text-sm leading-5 px-4 pt-2 sm:pt-6 sm:py-4"
      >
        Cancel
      </button>
      <div className="p-4 pt-2 sm:p-6 sm:pt-6 w-full">
        <h2 className="text-center text-2xl leading-6 sm:leading-8 font-medium">
          {profileName ? `Connect with ${profileName}` : 'Share your details'}
        </h2>
        {profileName && (
          <p className="text-center">Share your contact details</p>
        )}
        <div className="mt-2 sm:mt-8 space-y-2 sm:space-y-6 ">
          {error && <ErrorAlert message={MESSAGES.error.generic} />}
          {nameToggle && (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
              <Input
                className={styles.textColor}
                label="First name"
                onChange={setFirstName}
                type="text"
                value={firstName}
                required
                error={firstNameError}
                message={firstNameError ? MESSAGES.error.firstName : undefined}
                autocomplete="given-name"
              />
              <Input
                className={styles.textColor}
                label="Last name"
                onChange={setLastName}
                type="text"
                value={lastName}
                autocomplete="family-name"
              />
            </div>
          )}
          {emailToggle && (
            <Input
              className={styles.textColor}
              label="Email"
              onChange={setEmail}
              type="email"
              value={email}
              error={emailError}
              message={emailError ? MESSAGES.error.email : undefined}
              autocomplete="email"
            />
          )}
          {mobileNumberToggle && (
            <Input
              className={styles.textColor}
              label="Phone number"
              onChange={setMobileNumber}
              type="text"
              value={mobileNumber}
              autocomplete="tel"
            />
          )}
          {jobTitleToggle && (
            <Input
              className={styles.textColor}
              label="Position"
              onChange={setPosition}
              type="text"
              value={position}
              autocomplete="organization-title"
            />
          )}
          {companyNameToggle && (
            <Input
              className={styles.textColor}
              label="Company"
              onChange={setCompanyName}
              type="text"
              value={companyName}
              autocomplete="organization"
            />
          )}
          <div className="flex justify-center">
            {consentToggle && (
              <InputCheckbox
                id="conset-checkbox"
                label={renderConsentMessageLabel()}
                value={checkItem}
                onChange={setCheckItem}
              />
            )}
          </div>
          <div className="pt-2 sm:pt-0">
            {!checkItem && consentToggle && (
              <Button
                buttonText="Submit"
                className={styles.button}
                fullWidth
                kind={BUTTON_KIND.COLOR_OMITTED}
                loading={isLoading}
                onClick={() => handleClick()}
                size={BUTTON_SIZE.XLARGE}
                disabled
              />
            )}
            {checkItem && consentToggle && (
              <Button
                buttonText="Submit"
                className={styles.button}
                fullWidth
                kind={BUTTON_KIND.COLOR_OMITTED}
                loading={isLoading}
                onClick={() => handleClick()}
                size={BUTTON_SIZE.XLARGE}
              />
            )}
            {!consentToggle && (
              <Button
                buttonText="Submit"
                className={styles.button}
                fullWidth
                kind={BUTTON_KIND.COLOR_OMITTED}
                loading={isLoading}
                onClick={() => handleClick()}
                size={BUTTON_SIZE.XLARGE}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProfilePageShareDetails as default };
