import React, { useEffect, useState } from 'react';
import accountsAPI from '@/api/accounts';
import Modal from '@/components/Modal';
import Button, { BUTTON_KIND } from '@/components/Button';
import { SparklesIcon } from '@heroicons/react/outline';
import { IPlatformSubscription, SubscriptionTypes } from '@/types/IOrganisation';
import useAuth from '@/hooks/useAuth';
import { generateCheckoutBaseUrl, getTierByTotalProfiles } from '@/helpers/sealSubscriptions';
import PlanDetail from '@/components/SettingsPage/ManagePlan/PlanDetail';
import FreePlanExistingOrgDetail from '@/components/SettingsPage/ManagePlan/FreePlanExistingOrgDetail';
import SubscriptionStatus from '@/types/SubscriptionStatus';
import InactivePlanDetail from '@/components/SettingsPage/ManagePlan/InactivePlanDetail';
import PrepaidPlanDetail from '@/components/SettingsPage/ManagePlan/PrepaidPlanDetail';

interface ManagePlanTabProps {
  organisationId: number | undefined;
  isPlanRequiredModalOpen: boolean;
  setIsPlanRequiredModalOpen: (value: boolean) => void;
  checkoutUrl: string;
  setCheckoutUrl: (value: string) => void;
}

export default function ManagePlanTab(props: ManagePlanTabProps): JSX.Element {
  const { organisationId, isPlanRequiredModalOpen, setIsPlanRequiredModalOpen, checkoutUrl, setCheckoutUrl } = props;
  const { user, platformSubscriptionStatus } = useAuth();
  const hasFreePlanForExistingOrg = platformSubscriptionStatus === SubscriptionStatus.FREE;
  const hasInactivePlan = platformSubscriptionStatus === SubscriptionStatus.INACTIVE;
  const [subscription, setSubscription] = useState<IPlatformSubscription | null>(null);
  const [totalProfiles, setTotalProfiles] = useState<number>(0);

  useEffect(() => {
    if (!organisationId) return;

    if (!hasFreePlanForExistingOrg) {
      accountsAPI.getSubscription(organisationId).then((response) => {
        setSubscription(response.data.subscription);
        setTotalProfiles(response.data.total_profiles);

        if (response.data.subscription === null) {
          setIsPlanRequiredModalOpen(true);
          setCheckoutUrl(generateCheckoutUrl(response.data.total_profiles));
        }
      });
    }
  }, [organisationId, hasFreePlanForExistingOrg]);

  const generateCheckoutUrl = (totalProfiles: number) => {
    const tier = getTierByTotalProfiles(totalProfiles);
    if (!tier || !user) return '';

    const attributes = {
        "checkout[email]": user.email,
        "checkout[billing_address][first_name]": user.first_name,
        "checkout[billing_address][last_name]": user.last_name,
    };

    return generateCheckoutBaseUrl(tier.variant_id, attributes);
  };

  return (
    <div className='mt-9 max-md:max-w-full'>
      {subscription && subscription.type !== SubscriptionTypes.PREPAID && (
        <PlanDetail organisationId={organisationId} subscription={subscription} />
      )}
      {subscription && subscription.type === SubscriptionTypes.PREPAID && (
        <PrepaidPlanDetail/>
      )}

      {hasFreePlanForExistingOrg && (
        <FreePlanExistingOrgDetail/>
      )}
      {!subscription && hasInactivePlan && (
        <InactivePlanDetail totalProfiles={totalProfiles}/>
      )}
      <Modal
        isOpen={isPlanRequiredModalOpen}
        onClose={() => setIsPlanRequiredModalOpen(false)}
        cancelButtonText={'Cancel'}
        onCancel={() => setIsPlanRequiredModalOpen(false)}
        successButtonText={'Join now!'}
        successButton={
          <Button
            icon={<SparklesIcon />}
            kind={BUTTON_KIND.PRIMARY}
            buttonText={'Join now!'}
            external
            externalBlankTarget
            href={checkoutUrl}
          />
        }
        cancelButton={
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText={'Support'}
            external
            externalBlankTarget
            href={'https://help.tapt.io'}
          />
        }
        onSuccess={() => setIsPlanRequiredModalOpen(false)}
      >
        <div className='text-2xl not-italic leading-8 text-gray-900 max-md:max-w-full'>
          Invalid plan
        </div>
        <div className='mt-3 text-sm not-italic leading-5 text-gray-600 max-md:max-w-full'>
          You currently do not have an active plan to access the Tapt Management
          Dashboard. You can continue to use your Tapt cards, including editing
          profiles through the Tapt app, but you cannot access management
          features through the Dashboard. Your annual cost to access the
          Dashboard is:
        </div>
        <div
          className='flex justify-center items-center px-16 mt-6 whitespace-nowrap leading-[100%] max-md:px-5 max-md:max-w-full'>
          <div className='flex gap-1.5 font-brown font-bold'>
            <div className='grow text-6xl not-italic text-gray-700 max-md:text-4xl'>
              ${getTierByTotalProfiles(totalProfiles)?.price}
            </div>
            <div className='grow self-end mt-9 text-sm not-italic text-gray-600'>
              for {totalProfiles} profiles/year
            </div>
          </div>
        </div>
        <div className='mt-6 text-sm not-italic leading-5 text-gray-600 max-md:max-w-full'>
          If you would like access to management features, activate your plan
          below.
        </div>
      </Modal>
    </div>
  );
}