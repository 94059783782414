import qs from 'qs';

export interface Data {
  id: number;
  attributes: {
    [key: string]: unknown;
  };
}

interface ResponseJSON {
  data: Data & Data[];
}

/**
 * Get full Strapi URL from path
 * @param {string} path Path of the URL
 * @returns {string} Full Strapi URL
 */
export function getStrapiURL(path = '') {
  return `${
    (import.meta.env.VITE_STRAPI_API_URL as string) || 'http://localhost:1337'
  }${path}`;
}

/**
 * Helper to make GET requests to Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {Object} options Options passed to fetch
 * @returns Parsed API call response
 */
export async function fetchAPI(path = '', urlParamsObject = {}, options = {}) {
  // Merge default and user options
  const mergedOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };

  // Build request URL
  const queryString = qs.stringify(urlParamsObject);
  const requestUrl = `${getStrapiURL(
    `/api${path}${queryString ? `?${queryString}` : ''}`,
  )}`;

  // Trigger API call
  const response: Response = await fetch(requestUrl, mergedOptions);

  // Handle response
  if (!response.ok) {
    // console.error(`An error occured please try again: ${response.statusText}`);
  }

  return response.json().then((json: ResponseJSON) => {
    return json;
  });
}
