import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { Disclosure } from '@headlessui/react';
import menuIconOpen from '@/assets/icons/navbar-minimal-menu-open.svg';
import menuIconClose from '@/assets/icons/navbar-minimal-menu-close.svg';
import logo from '@/assets/logos/navbar-minimal-logo.svg';
import logoPurple from '@/assets/logos/navbar-minimal-logo-purple.svg';
import classNames from '@/helpers/classNames';
import MESSAGES from '@/constants/messages-en';
import Modal from '../Modal';
import ActivationKeySearch from '../ActivationKeySearch';
import cardsAPI from '@/api/cards';
import { BUTTON_KIND } from '../Button';
import ErrorAlert from '../ErrorAlert';

interface INavbarMinimal {
  menuData: {
    href: string;
    id: string;
    text: string;
    title: string;
  }[];
  profileHashID: string;
}

const NavbarMinimal: React.FC<INavbarMinimal> = (props) => {
  const { menuData, profileHashID } = props;
  const [isActivationOpen, setIsActivationOpen] = useState(false);
  const [activationKey, setActivationKey] = useState<string>('');
  const [activationKeyType, setActivationKeyType] = useState('');
  const [error, setError] = useState<string>();

  const LOGO_ALT =
    MESSAGES.profile.view.header.logoAlt ||
    'MESSAGES.profile.view.header.logoLinkTitle';
  const LOGO_TITLE =
    MESSAGES.profile.view.header.logoLinkTitle ||
    'MESSAGES.profile.view.header.logoLinkTitle';

  const showActivatationKeyType = useCallback(
    async (newActivationKey: string = activationKey) => {
      const res = await cardsAPI.showActivatationKeyType(newActivationKey);

      if (res.data?.data?.type && activationKeyType !== res.data.data.type) {
        setActivationKeyType(res.data.data.type);
      }
    },
    [activationKey, activationKeyType],
  );

  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <div
            className={classNames(
              'max-w-7xl mx-auto px-4 sm:px-4 lg:px-6',
              open ? 'bg-white' : '',
            )}
          >
            <div className="flex items-center justify-between py-3 md:py-6">
              <div className="flex items-center">
                <div className="flex flex-shrink-0">
                  <a href="/" title={LOGO_TITLE} className="inline-flex">
                    <img
                      className="mx-auto"
                      src={open ? logoPurple : logo}
                      alt={LOGO_ALT}
                    />
                  </a>
                </div>
              </div>
              <div className="-mr-2 flex">
                <Disclosure.Button className="inline-flex items-center justify-center">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <img className="mx-auto" src={menuIconClose} alt="Tapt" />
                  ) : (
                    <img className="mx-auto" src={menuIconOpen} alt="Tapt" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel>
            <div
              className={classNames(
                'px-2 pt-2 pb-3 space-y-1',
                open ? 'bg-white shadow-sm' : '',
              )}
            >
              <div className="flex w-full justify-center">
                <Modal
                  buttonClassName="appearance-none w-full text-gray-900 block px-3 py-2 text-base font-medium text-center"
                  buttonTitle="Link another product"
                  buttonKind={BUTTON_KIND.COLOR_OMITTED}
                  dialogTitle="Link a card or sticker"
                  dialogDescription={
                    <>
                      Enter the activation key for your new card or sticker. You
                      can see this by tapping the card or sticker on your phone.
                      <br />
                      <a
                        href="https://tapt.io/pages/how-to-use"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Learn more.
                      </a>
                    </>
                  }
                  isOpen={isActivationOpen}
                  setIsOpen={setIsActivationOpen}
                  onSuccess={() => {
                    setError(undefined);
                    cardsAPI
                      .activateCardsWithProfileHashID(
                        activationKey,
                        profileHashID,
                      )
                      .then(() => {
                        setIsActivationOpen(false);
                        window.location.reload();
                      })
                      .catch((e: AxiosError<{ errors: string[] }>) => {
                        setError(
                          e.response?.data?.errors?.[0] ||
                            'Activation key is invalid',
                        );
                      });
                  }}
                  successButtonText="Activate"
                >
                  <div className="mt-6 space-y-6">
                    {error && <ErrorAlert message={error} />}
                    <ActivationKeySearch
                      id={`ActivationKey-${activationKey}`}
                      label="Card activation key"
                      search={activationKey}
                      setSearch={setActivationKey}
                      fetchQuery={(searchQuery) =>
                        showActivatationKeyType(searchQuery)
                      }
                    />
                  </div>
                </Modal>
              </div>
              {menuData.map((menuItem) => {
                const { href, text, title, id } = menuItem;
                return (
                  <a
                    className="text-gray-900 block px-3 py-2 text-base font-medium text-center"
                    href={href}
                    key={id}
                    title={title}
                  >
                    {text}
                  </a>
                );
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export { NavbarMinimal as default };
