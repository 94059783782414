import { useEffect, useMemo, useState } from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import EventsChart from './Charts/Events';
import { AnalyticsResponse } from '@/types/IAnalytics';
import MostActiveProfiles from './Charts/MostActiveProfiles';
import TopReferrers from './Charts/TopReferrers';
import TopSocialLinks from './Charts/TopSocialLinks';
import EventsByGeo from './Charts/EventsByGeo';
import ProfileTypes from './Charts/ProfileTypes';
import 'react-datepicker/dist/react-datepicker.css';
import './react-datepicker.css';
import DateFilter from './DateFilter';
import ProfileFilter from './ProfileFilter';
import type { Props as OrganisatonFilterProps } from './OrganisationFilter';
import type { Props as ProfileFilterProps } from './ProfileFilter';
import Modal from '../Modal';
import ICollection from '@/types/ICollection';
import CollectionFilter from './CollectionFilter';
import Button, { BUTTON_KIND, BUTTON_SIZE } from '../Button';
import Input from '../Input';
import FileUploadComponent from '../FileUploadComponent';
import collectionsAPI from '@/api/collections';
import OrganisationFilter from './OrganisationFilter';
import CardFilter from './CardFilter';

type Props = {
  data: AnalyticsResponse;
  collections?: ICollection[];
  refetchData?: (
    from?: string,
    to?: string,
    ordIds?: number[],
    profileIds?: number[],
    collectionId?: number,
  ) => Promise<void>;
  listProfiles: ProfileFilterProps['listProfiles'];
  listOrgs: OrganisatonFilterProps['listOrganisations'];
};

const CollectionAnalyticsDashboard: React.FC<Props> = ({
  data: {
    profile_views: profileViews,
    contacts_sent: contactsSent,
    contacts_received: contactsReceived,
    profile_types: profileTypes,
    most_active_profiles: mostActiveProfiles,
    top_referrers: topReferrers,
    top_social_links: topSocialLinks,
    events_by_geo: eventsByGeo,
  },
  collections,
  refetchData,
  listProfiles,
  listOrgs,
}) => {
  const [collectionId, setCollectionId] = useState<number | undefined>();
  const [newCollectionModalOpen, setNewCollectionModalOpen] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState('');
  const [file, setFile] = useState<File | undefined>();
  const [csvSizeError, setCSVSizeError] = useState(false);
  const [csvFileError, setCSVFileError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const currentCollection = useMemo(
    () =>
      collections?.find((collection) => collection.id === collectionId) || {
        organisations: [],
        profiles: [],
        cards: [],
      },
    [collections, collectionId],
  );
  const [from, setFrom] = useState<string | undefined>();
  const [to, setTo] = useState<string | undefined>();
  const [profileIds, setProfileIds] = useState<number[]>();
  const [orgIds, setOrgIds] = useState<number[]>();
  const [selectedCardIds, setSelectedCardIds] = useState<number[]>([]);

  useEffect(() => {
    const updateOrgIds = () => {
      setOrgIds((ids) => [
        ...new Set([
          ...(ids || []),
          ...(currentCollection?.organisations?.map((org) => org.id) || []),
        ]),
      ]);
    };
    const updateProfileIds = () => {
      setProfileIds((ids) => [
        ...new Set([
          ...(ids || []),
          ...(currentCollection?.profiles?.map((org) => org.id) || []),
        ]),
      ]);
    };

    const updateCards = () => {
      setSelectedCardIds((ids) => [
        ...new Set([
          //...(ids || []),
          ...(currentCollection?.cards?.map((card) => card.id) || []),
        ]),
      ]);
    };

    const collectionIdFromURL = parseInt(
      new URLSearchParams(window.location.search).get('collectionId') || '0',
      10,
    );

    if (collectionIdFromURL === 0) return;

    setCollectionId(collectionIdFromURL);
    updateOrgIds();
    updateProfileIds();
    updateCards();
  }, [
    currentCollection.organisations,
    currentCollection.profiles,
    currentCollection.cards,
  ]);

  const goToNewCollectionURL = (id: number) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('collectionId', `${id}`);
    const modifiedURL = `${window.location.origin}${
      window.location.pathname
    }?${searchParams.toString()}`;
    window.location.replace(modifiedURL);
  };

  return (
    <>
      {collectionId ? (
        <div className="bg-white fixed bottom-0 right-0 w-full md:w-[calc(100%-16rem)] h-16 z-20 flex border-t-gray-200 border-t-[1px] items-center px-5 justify-end">
          <div className="text-gray-500 mr-4">
            {(orgIds?.length || 0) +
              (profileIds?.length || 0) +
              (selectedCardIds?.length || 0)}{' '}
            selected
          </div>
          <Button
            buttonText="Update collection"
            kind={BUTTON_KIND.WHITE}
            onClick={async () => {
              if (collectionId === undefined) return;

              await collectionsAPI.updateCollection(
                collectionId,
                profileIds || [],
                orgIds || [],
                selectedCardIds || [],
              );

              window.location.reload();
            }}
          />
        </div>
      ) : null}
      <Modal
        isOpen={newCollectionModalOpen}
        onCancel={() => setNewCollectionModalOpen(false)}
        onClose={() => setNewCollectionModalOpen(false)}
        onSuccess={async () => {
          if (file === undefined) return;

          const newFile = await collectionsAPI.createCollection(
            newCollectionName,
            new File([file], file.name),
          );

          setFile(undefined);
          setNewCollectionName('');
          setNewCollectionModalOpen(false);

          goToNewCollectionURL(newFile.data.id);
        }}
        dialogTitle="Create a collection"
        dialogDescription={
          <>
            <div>
              Create a collection to view analytics for a selection of profiles.
              Upload a .csv with the desired profiles to compare their
              analytics.
            </div>
            <div className="mt-2">
              <a href="/collection.csv">Download .csv template</a>
            </div>
          </>
        }
        successButtonText="Create"
        isDisabled={newCollectionName === '' || file === undefined}
      >
        <div className="mt-4">
          <Input
            id="collection-name"
            label="Collection name"
            description=""
            placeholder="Example collection name"
            type="text"
            autocomplete="off"
            value={newCollectionName}
            onChange={setNewCollectionName}
          />
        </div>
        <div>
          <FileUploadComponent
            onFileSelected={() => {}}
            fileFormat=".csv"
            fileFormatMessage="(.csv only)"
            loading={isLoading}
            sizeError={csvSizeError}
            fileError={csvFileError}
            file={file}
            setFile={setFile}
            setSizeError={setCSVSizeError}
          />
        </div>
      </Modal>
      <div className="mb-8 md:mb-0">
        <div className="-mt-20 mb-10 flex justify-end flex-col items-end">
          <div className="grid gap-2 grid-flow-col items-center text-center mb-4">
            <Button
              buttonText="Create collection"
              icon={<UsersIcon />}
              size={BUTTON_SIZE.XSMALL}
              onClick={() => setNewCollectionModalOpen(true)}
            />
          </div>
          <div className="grid gap-2 grid-flow-col items-center text-center">
            <CollectionFilter
              fetchData={(id) => {
                setCollectionId(id);
                refetchData?.(from, to, orgIds, profileIds, id);
              }}
              collections={collections}
              setCollectionId={setCollectionId}
            />
            <OrganisationFilter
              fetchData={(ids) => {
                setOrgIds(ids);
                refetchData?.(from, to, ids, profileIds, collectionId);
              }}
              listOrganisations={listOrgs}
              collection={currentCollection}
            />
            <ProfileFilter
              fetchData={(ids) => {
                setProfileIds(ids);
                refetchData?.(from, to, orgIds, ids, collectionId);
              }}
              listProfiles={listProfiles}
              collection={currentCollection}
            />
            <CardFilter
              cards={currentCollection.cards}
              selectedCardIds={selectedCardIds}
              setSelectedCardIds={setSelectedCardIds}
            />
            <DateFilter
              fetchData={(fromDate, toDate) => {
                setFrom(fromDate);
                setTo(toDate);
                refetchData?.(
                  fromDate,
                  toDate,
                  orgIds,
                  profileIds,
                  collectionId,
                );
              }}
            />
          </div>
        </div>
        <div className="bg-white rounded-lg p-4 md:p-8 mt-2 md:mt-0">
          <h2 className="text-gray-900 font-poppins font-medium text-2xl md:text-2xl">
            Domain Analytics
          </h2>
          <h3 className="text-gray-500 mt-2 hidden md:show">
            Profiles with the most activity (profile views, contacts sent and
            contacts received)
          </h3>
          <hr className="h-px mt-4 mb-8 bg-gray-200 border-0 " />
          <div className="grid md:grid-cols-4 gap-8 md:gap-10 w-full">
            <div className="flex">
              <EventsChart
                title="Total profile views"
                total={profileViews.total}
                percent={profileViews.change}
                data={{
                  labels: Object.keys(profileViews.entries),
                  series: [
                    {
                      name: 'Views',
                      data: Object.values(profileViews.entries),
                    },
                  ],
                }}
                dateFilter={{ from, to }}
              />
            </div>
            <div>
              <EventsChart
                title="Total contacts sent"
                total={contactsSent.total}
                percent={contactsSent.change}
                data={{
                  labels: Object.keys(contactsSent.entries),
                  series: [
                    {
                      name: 'Views',
                      data: Object.values(contactsSent.entries),
                    },
                  ],
                }}
                dateFilter={{ from, to }}
              />
            </div>
            <div>
              <EventsChart
                title="Total contacts recieved"
                total={contactsReceived.total}
                percent={contactsReceived.change}
                data={{
                  labels: Object.keys(contactsReceived.entries),
                  series: [
                    {
                      name: 'Views',
                      data: Object.values(contactsReceived.entries),
                    },
                  ],
                }}
                dateFilter={{ from, to }}
              />
            </div>
            <ProfileTypes data={profileTypes} />
          </div>
        </div>
        <div className="grid grid-cols-5 gap-4">
          <div className="bg-white rounded-lg p-4 md:p-8 mt-4 col-span-5 md:col-span-5">
            <MostActiveProfiles
              data={mostActiveProfiles}
              title="Most Active Profiles"
              subtitle="Profiles with the most activity (profile views, contacts sent and contacts received)"
              mobileSubtitle="Profiles with the most activity"
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div className="bg-white rounded-lg p-4 md:p-8 md:mt-4 flex items-center col-span-4 md:col-span-2">
            <TopReferrers
              data={topReferrers}
              dateFilter={{ from, to }}
              title="Top Referrers"
              subtitle="Last month website stats"
              mobileSubtitle="Website statistics"
            />
          </div>
          <div className="bg-white rounded-lg p-4 md:p-8 mt-4 col-span-4 md:col-span-2 min-w-[470px]">
            <TopSocialLinks
              data={topSocialLinks}
              title="Social Links"
              subtitle="Most clicked social media"
            />
          </div>
        </div>
        <div className="bg-white rounded-lg p-4 md:p-8 md:mt-4 flex col-span-12 md:col-span-12 w-full">
          <EventsByGeo
            data={eventsByGeo}
            title="Tap Geolocation"
            subtitle="Location of profile views, contacts sent and contacts recieved"
            mobileSubtitle="Location of profile views"
          />
        </div>
      </div>
    </>
  );
};

export default CollectionAnalyticsDashboard;
