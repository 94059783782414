import { useEffect, ChangeEvent } from 'react';
import { PaperClipIcon } from '@heroicons/react/solid';
import Spinner from '@/components/Icons/Spinner';
import classNames from '@/helpers/classNames';

const FileUploadComponent = ({
  buttonTitle,
  url,
  fileFormat,
  fileFormatMessage = '',
  onFileSelected,
  sizeError,
  setSizeError,
  fileError,
  loading,
  file,
  setFile,
  fileSize = 134217728,
}: {
  buttonTitle?: string;
  url?: string;
  fileFormat?: string;
  fileFormatMessage?: string;
  onFileSelected: (file: File) => void;
  sizeError?: boolean;
  setSizeError?: React.Dispatch<React.SetStateAction<boolean>>;
  fileError?: boolean;
  loading?: boolean;
  file: any;
  setFile: (file: File | undefined) => void;
  fileSize?: number;
}) => {
  useEffect(() => {
    if (sizeError || fileError) {
      setFile(undefined);
    }
  }, [sizeError, fileError, setFile]);

  return (
    <div>
      {file !== undefined ? (
        <div className="inline-flex justify-center items-center w-full">
          <a
            href={url}
            download
            target="_blank"
            rel="noreferrer"
            className="text-sm leading-5 py-2.5 my-1 border border-transparent flex items-center justify-start break-all"
          >
            {loading ? (
              <Spinner className="text-brand-500 mr-2" />
            ) : (
              <PaperClipIcon
                className="text-brand-500 h-5 w-5 mr-2 flex-shrink-0"
                aria-hidden="true"
              />
            )}
            {loading ? 'Uploading ...: ' : buttonTitle || 'Upload file: '}

            {file.name}
          </a>
          <button
            className="ml-4 appearance-none text-brand-500 text-sm font-medium"
            onClick={() => {
              setFile(undefined);
            }}
          >
            Remove File
          </button>
        </div>
      ) : (
        <div className="relative w-full h-32 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none mt-4">
          <span className="flex font-medium w-full h-full py-2.5 border border-transparent items-center justify-start">
            {loading ? (
              <Spinner className="text-brand-500 mr-2" />
            ) : (
              <span className="flex items-center justify-center space-x-2 w-full h-full ">
                <svg
                  width="44"
                  height="43"
                  viewBox="0 0 44 43"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 31.5V34C2 35.9891 2.79018 37.8968 4.1967 39.3033C5.60322 40.7098 7.51088 41.5 9.5 41.5H34.5C36.4891 41.5 38.3968 40.7098 39.8033 39.3033C41.2098 37.8968 42 35.9891 42 34V31.5M32 11.5L22 1.5M22 1.5L12 11.5M22 1.5V31.5"
                    stroke="#9CA3AF"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            )}
          </span>
          <input
            className="absolute top-0 left-0 opacity-0 w-full h-full"
            type="file"
            accept={fileFormat}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.files && e.target.files[0]) {
                if (e.target.files[0].size > fileSize && setSizeError) {
                  setSizeError(true);
                  return;
                }
                onFileSelected(e.target.files[0]);
                setFile(e.target.files[0]);
              }
            }}
          />

          <div
            className={classNames(
              'text-sm mt-4',
              sizeError || fileError ? 'text-red-500' : 'text-gray-500',
            )}
          >
            {sizeError && 'File size is too big'}
            {fileError && 'File is invalid.'}
            {!sizeError &&
              !fileError &&
              `Maximum file size 1 GB ${fileFormatMessage}`}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadComponent;
