import { IIcon } from '@/types/IIcon';

const Close = ({ className }: IIcon): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M6 6.68799L18 18.688M6 18.688L18 6.68799L6 18.688Z"
        stroke="#873CFF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Close;
