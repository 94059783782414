import { Link } from 'react-router-dom';
import { MenuAlt2Icon } from '@heroicons/react/outline';
import Logo from '@/assets/logos/primary.svg';

interface IHeader {
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<IHeader> = ({ setSidebarOpen }) => {
  return (
    <div className="lg:hidden px-2 py-2 sm:px-3 sm:py-3 flex items-center shadow z-9 sticky top-0">
      <button
        className="h-10 w-10 inline-flex items-center justify-center rounded-md focus:outline-none"
        onClick={() => setSidebarOpen(true)}
        type="button"
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt2Icon className="h-6 w-6 text-gray-900" aria-hidden="true" />
      </button>
      <div className="flex-shrink-0 inline-flex items-center">
        <Link to="/">
          <img className="h-7 w-auto" src={Logo} alt="Tapt" />
        </Link>
      </div>
    </div>
  );
};

export { Header as default };
