import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PrinterIcon } from '@heroicons/react/outline';
import SuccessAlert from '@/components/SuccessAlert';
import ErrorAlert from '@/components/ErrorAlert';
import MESSAGES from '@/constants/messages-en';
import Modal from '@/components/Modal';
import ModalNoCancel from '@/components/ModalNoCancel';
import MemoizedPdfPreView from '@/components/PdfPreviewComponent';
import filesAPI from '@/api/files';
import TextAreaMessage from '@/components/TextAreaMessage';
import ordersAPI from '@/api/ordersV2';
import Layout from '@/components/Layout';
import Button, { BUTTON_KIND } from '@/components/Button';
import InfoPanelFooter from '@/components/InfoPanelFooter';

import FileUploadMuntiButtonComponent from '@/components/FileUploadMultiButton';
import classNames from '@/helpers/classNames';

const DesignReviewContainer = styled.div({
  height: 'calc(100% - 210px)',
  transition: 'height .3',
});

export default function PreviewPage({
  match,
}: {
  match: { params: { order_uuid: string } };
}): JSX.Element {
  const orderUUID = match.params?.order_uuid;
  const [orderID, setOrderID] = useState<number>(1);
  const [orderNumber, setOrderNumber] = useState<number>(0);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [isChanges, setIsChanges] = useState<boolean>(false);
  const [isReadyForPrint, setIsReadyForPrint] = useState<boolean>(false);
  const [isSentCardToPrint, setIsSentCardToPrint] = useState<boolean>(false);
  const [isUpdateProof, setIsUpdateProof] = useState<boolean>(false);
  const [changeMessage, setChangeMessage] = useState<string>('');
  const [designFileID, setDesignFileID] = useState<number>(1);
  const [designFile, setDesignFile] = useState<number>(1);
  const [designOrganisationId, setDesignOrganisationId] = useState<number>(1);
  const [arrFile, setArrFile] = useState<any>([]);
  const [previewStatus, setPreviewStatus] = useState<boolean>(false);
  const [destopWidth, setDestopWidth] = useState<boolean>(
    window.innerWidth >= 1024,
  );

  const [headerHeight, setHeaderHeight] = useState(0);

  const [changeRequestMessageError, setchangeRequestMessageError] =
    useState<string>('');
  const [mobileWidth, setTabletWidth] = useState<boolean>(
    window.innerWidth <= 768,
  );

  useEffect(() => {
    ordersAPI
      .showOrder(orderUUID)
      .then((res) => {
        const order = res?.data?.data;
        if (order.order_stage === 'awaiting_client') {
          setPreviewStatus(true);
        } else {
          setPreviewStatus(false);
        }
        setDesignFileID(order.design_file.id);
        setDesignFile(order.design_file);

        setOrderID(order?.id);
        setOrderNumber(order?.order_number);

        setDesignOrganisationId(order?.organisation_id);
      })
      .catch(() => {
        setError(true);
      });

    function handleResize() {
      if (window.innerWidth >= 1024) {
        setDestopWidth(true);
      } else {
        setDestopWidth(false);
      }
      window.innerWidth <= 768 ? setTabletWidth(true) : setTabletWidth(false);
    }
    window.addEventListener('resize', handleResize);
  }, []);

  const getHeaderHeight = (headerHeightPram: number) => {
    setHeaderHeight(headerHeightPram);
  };

  return (
    <Layout
      getHeaderHeight={getHeaderHeight}
      pageName="Design Review"
      className="h-full text-2xl"
      wrapClassName="h-full"
      contentClassName="h-full"
      descriptionClassName="max-w-full xl:w-5/6 my-5 md:mt-5 md:mb-0 text-xs md:text-base leading-6"
      rightTitle={
        <div className="text-base lg:text-2xl leading-10 font-medium text-gray-900">
          Order{' '}
          <span className=" text-base lg:text-2xl leading-10 font-medium text-brand-500">
            #{orderNumber}
          </span>
        </div>
      }
      description={
        destopWidth
          ? 'Please see below a design proof for your consideration and approval. Please let us know if these cards are ready for print or if you require further design edits.'
          : 'Please see below a design proof for your consideration and approval.'
      }
    >
      {success !== '' && <SuccessAlert message={success} />}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      {/* {userRole === 'org_admin' && ( */}
      <>
        <DesignReviewContainer
          style={{
            ...(headerHeight && {
              height: `calc(100% - ${64 + headerHeight}px)`,
            }),
          }}
          className="h-full flex flex-col justify-between box-border overflow-hidden"
        >
          <MemoizedPdfPreView
            orderId={orderID}
            setError={setError}
            setSuccess={setSuccess}
            designFile={designFile}
          />
        </DesignReviewContainer>
        <Modal
          isOpen={isChanges}
          setIsOpen={setIsChanges}
          dialogTitle="What changes do you need made?"
          dialogTitleClassName="text-base text-left md:text-2xl md:text-left leading-8 font-medium text-gray-900"
          footerButtonContainerClassName="w-full flex justify-end space-x-4"
          dialogDescription=""
          successButtonText={
            destopWidth ? 'Submit design change request' : 'Submit request'
          }
          buttonClassName="w-2/4 md:w-max text-xs"
          onSuccess={() => {
            if (!changeMessage && !arrFile.length) {
              setchangeRequestMessageError(
                'Please upload file(s) or leave a change request message!',
              );
              return;
            }
            setIsChanges(false);
            setIsUpdateProof(true);
          }}
          wider
          cancelButtonText="Cancel"
        >
          <div className="mt-1">
            {changeRequestMessageError && (
              <ErrorAlert message={changeRequestMessageError} />
            )}
          </div>
          <div className="my-4">
            <TextAreaMessage
              id={`TextArea${changeMessage}`}
              placeholder="What changes do you need made?..."
              message={changeMessage}
              setMessage={setChangeMessage}
            />
          </div>

          <div className="">
            <div className="mt-5 font-medium ">Upload any relevant files</div>
            <FileUploadMuntiButtonComponent
              buttonTitle="Choose file"
              id="design-upload-preview"
              fileFormat=".svg, .eps, .ai, .indd, .pdf"
              message={
                destopWidth
                  ? 'Maximum file size 1 GB (.svg, .eps, .ai, .indd, or .pdf only)'
                  : 'Maximum file size 1 GB'
              }
              multiple
              values={[...arrFile]}
              setArrFile={setArrFile}
              setError={setchangeRequestMessageError}
              onFileSelected={async (file) => {
                const res = await filesAPI.createAndUploadOrderFile(
                  designOrganisationId,
                  new File([file.file], file.file.name),
                );
                arrFile.push({
                  id: file.id,
                  file: res.data.data,
                });
                setArrFile([...arrFile]);
                return res;
              }}
            />
          </div>
        </Modal>
        <ModalNoCancel
          isOpen={isUpdateProof}
          setIsOpen={setIsUpdateProof}
          successButtonText="Finished!"
          wider
          buttonClassName="w-full md:w-max text-xs"
          onSuccess={() => {
            setIsUpdateProof(false);
            setPreviewStatus(false);

            try {
              ordersAPI.orderChangeRequest(
                orderUUID,
                changeMessage,
                arrFile.map((el: any) => el.file?.id),
              );
              setSuccess(
                'Thanks for your feedback. We will revert back with an updated design proof shortly.',
              );
            } catch (error) {
              setError(true);
            }
          }}
        >
          <div className=" text-center md:text-left w-full mt-5 font-medium text-base md:text-2xl leading-8 ">
            We will send an updated proof shortly!
          </div>
          <div className="text-center md:text-left w-full text-gray-500 my-2 text-sm font-normal italic">
            Please note: included in the purchase of your Tapt Card/s are 2
            design edits.
          </div>
        </ModalNoCancel>
        <Modal
          isOpen={isReadyForPrint}
          setIsOpen={setIsReadyForPrint}
          successButtonText="Yep, ready to go!"
          onSuccess={() => {
            setIsSentCardToPrint(true);
            setIsReadyForPrint(false);
            setPreviewStatus(false);
            ordersAPI.acceptDesign(orderID);
          }}
          wider
          buttonClassName="w-2/4 md:w-max text-xs"
          cancelButtonText="Cancel"
          onCancel={() => {
            setIsReadyForPrint(false);
          }}
        >
          <div className="text-center md:text-left w-full font-medium text-base md:text-2xl text-gray-900 leading-8 mb-8">
            Are you sure these cards are ready for print and delivery?
          </div>
        </Modal>
        <ModalNoCancel
          isOpen={isSentCardToPrint}
          setIsOpen={setIsSentCardToPrint}
          wider
          successButtonText="Close"
          onSuccess={() => {
            setIsSentCardToPrint(false);
          }}
          buttonClassName="w-full md:w-auto md:px-11 text-xs"
        >
          <div className="flex justify-center items-center">
            <PrinterIcon className="stroke-1 text-brand-400 w-14 h-14 md:w-20 md:h-20 " />
          </div>
          <div className="text-center text-2xl w-full text-gray-900 mt-5 font-medium  pl-3 leading-8 ">
            We have sent your card to print!
          </div>
          <div className="text-center text-xl w-full text-gray-800 my-2 pl-3">
            Looking forward to getting your cards to you soon!
          </div>
        </ModalNoCancel>
        {/* {previewStatus && ( */}
        <InfoPanelFooter
          wrapClassName="
              fixed left-0 bottom-0 w-full bg-white border-t border-gray-300 shadow-sm transition transition-bottom overflow-x-auto md:w-footer border-l md:left-64
            "
        >
          <div
            className={classNames(
              previewStatus ? 'md:justify-end' : 'md:justify-between',
              'flex items-center  justify-center',
            )}
          >
            {!previewStatus && !mobileWidth && (
              <div className="font-poppins text-gray-900 text-base font-normal md:justify-start">
                {destopWidth && (
                  <>
                    <p>
                      The Tapt design team is currently reviewing the design
                      change request that has been submitted.
                    </p>
                    <p>
                      If you would like to submit another request, please
                      contact support.
                    </p>
                  </>
                )}
              </div>
            )}
            <div className="flex items-center  justify-center gap-3">
              <Button
                buttonText="Make design changes"
                onClick={() => (previewStatus ? setIsChanges(true) : '')}
                kind={BUTTON_KIND.WHITE}
                disabled={!previewStatus}
              />
              <Button
                buttonText="Proceed to printing"
                onClick={() => (previewStatus ? setIsReadyForPrint(true) : '')}
                disabled={!previewStatus}
                kind={BUTTON_KIND.PRIMARY}
              />
            </div>
          </div>
        </InfoPanelFooter>
      </>
      {/* )} */}
    </Layout>
  );
}
