import { useState } from 'react';
import { Link } from 'react-router-dom';
import feedData from './data';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment-timezone';
import withRestriction from '@/helpers/withRestriction';
import FeedRestricted, {
  getIcon,
  getMessageText,
  getNameText,
} from './FeedRestricted';
import ArrowRightIcon from '../Icons/ArrowRightIcon';
import useAuth from '@/hooks/useAuth';
import organisationsAPI from '@/api/organisations';
import {
  IOrganisationUpdates,
  IOrganisationUpdateEvent,
} from '@/types/IOrganisation';
import { useQuery } from 'react-query';
import clsx from 'clsx';

function FeedSkeleton() {
  return (
    <div className="pr-6 mt-3 overflow-auto h-[25.938rem] scrolledDiv">
      {Array.from({ length: 10 }).map((_, i) => (
        <div
          key={i}
          className="relative flex items-start justify-between py-4 border-b border-gray-200"
        >
          <Skeleton
            width={40}
            height={40}
            style={{
              borderRadius: '999px',
            }}
          />
          <div className="flex-1 pl-4">
            <Skeleton
              width={75}
              height={12}
              style={{
                borderRadius: '999px',
              }}
            />
            <Skeleton width={225} height={30} />
          </div>
          <div className="flex self-end">
            <ArrowRightIcon />
          </div>
        </div>
      ))}
    </div>
  );
}

function Feed() {
  const { user, orgID, userRole } = useAuth();

  const [editorsGroup, setEditorsGroup] = useState<number[]>([]);

  const {
    isFetched: isGroupsReady,
    isFetching: isGroupsFetching,
    isFetchedAfterMount: isGroupsFetchedAfterMount,
  } = useQuery('listGroups', fetchGroups, {
    enabled: orgID !== undefined && user !== undefined,
  });

  const {
    data: recentUpdates,
    isFetching: isUpdatesFetching,
    isFetchedAfterMount: isUpdatesFetchedAfterMount,
  } = useQuery(['recentUpdates', editorsGroup], listRecentUpdates, {
    enabled: orgID !== undefined && isGroupsReady,
  });

  const isFetching = isGroupsFetching || isUpdatesFetching;
  const isFetchedAfterMount =
    isGroupsFetchedAfterMount && isUpdatesFetchedAfterMount;

  async function fetchGroups() {
    if (orgID === undefined || user === undefined) {
      return;
    }

    const { data: groups } = await organisationsAPI.listOrganisationGroups({
      orgID,
    });

    const groupList: number[] = [];

    groups.data.forEach((group) => {
      if (group.editors.length > 0) {
        group.editors.forEach((editor) => {
          if (editor.user.id === user.id) {
            groupList.push(group.id);
          }
        });
      }
    });

    setEditorsGroup(groupList);
  }

  async function listRecentUpdates() {
    if (orgID === undefined) {
      return;
    }

    return await organisationsAPI.listRecentUpdates(orgID, editorsGroup);
  }

  function getTimeDifference(insertedAt: string) {
    const insertedTime = moment(insertedAt);
    const timeDifference = moment
      .utc(insertedTime)
      .local()
      .startOf('seconds')
      .fromNow();

    return `${timeDifference}`;
  }

  function transformUpdates(updates: IOrganisationUpdates[]) {
    return updates.reduce((previous, current, index) => {
      if (
        index < updates.length - 1 &&
        current.event === IOrganisationUpdateEvent.received_contact &&
        updates[index + 1].event === IOrganisationUpdateEvent.sent_contact
      ) {
        previous.push({
          ...current,
          event: IOrganisationUpdateEvent.details_exchange,
          sender: updates[index + 1]?.profile,
          receiver: current.profile,
        });
        return previous;
      }
      if (
        current.event === IOrganisationUpdateEvent.sent_contact &&
        updates[index - 1]?.event === IOrganisationUpdateEvent.received_contact
      ) {
        return previous;
      }
      previous.push(current);

      return previous;
    }, [] as IOrganisationUpdates[]);
  }

  const isEditorAssignedGroup =
    (editorsGroup.length > 0 && userRole === 'org_editor') ||
    userRole === 'org_admin';

  const updates = isEditorAssignedGroup
    ? recentUpdates
      ? transformUpdates(recentUpdates.data.data.updates)
      : undefined
    : feedData;

  return (
    <div>
      <h4 className="text-2xl font-medium leading-8 text-grey-900">
        Recent updates
      </h4>
      {(isFetching && !isFetchedAfterMount) ||
      updates === undefined ||
      user === undefined ? (
        <FeedSkeleton />
      ) : updates.length > 0 ? (
        <ul
          className={clsx(
            'pr-6 mt-3 h-[25.938rem] scrolledDiv',
            isEditorAssignedGroup ? 'overflow-auto' : 'overflow-y-hidden',
          )}
        >
          <div
            className={clsx(
              'absolute z-10 flex-1 backdrop-blur-sm w-[95%] h-[91%] items-center justify-center font-medium',
              isEditorAssignedGroup ? 'hidden' : 'flex',
            )}
          >
            No data available.
          </div>
          {updates.map((update, index) => (
            <li key={index}>
              <Link
                to="/"
                className="relative flex items-end justify-between py-4 border-b border-gray-200"
              >
                <div className="relative flex items-start space-x-4">
                  <span className="px-0.5 py-px">{getIcon(update.event)}</span>
                  <div className="flex items-center flex-1 min-w-0">
                    <div>
                      <span className="text-xs font-normal text-right text-gray-400">
                        {getTimeDifference(update.inserted_at)}
                      </span>

                      <div className="text-sm font-normal leading-5 text-gray-500">
                        <span className="font-medium text-gray-900">
                          {getNameText(update)}
                        </span>
                        {getMessageText(update)}
                        {user.first_name && (
                          <span className="font-medium text-gray-900">
                            {update.event === 'edit:profile'
                              ? ''
                              : user.first_name}{' '}
                            {update.event === 'details:exchange' &&
                              user.last_name.charAt(0)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <ArrowRightIcon />
                </div>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <div className="flex items-center justify-center w-full bg-white py-28 sm:py-36 lg:py-52">
          <span className="text-xl font-medium leading-6 text-gray-500">
            No recent updates
          </span>
        </div>
      )}
    </div>
  );
}

export default withRestriction(Feed, FeedRestricted);
