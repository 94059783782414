import { useCallback, useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import Layout from '@/components/Layout';
import TabList from '@/components/TabList';
import Pagination from '@/components/Pagination';
import Sort from '@/components/Sort';
import Search from '@/components/Search';
import Modal from '@/components/Modal';
import ProfileListAdminItem from '@/components/ProfileListAdminItem';
import Button, { BUTTON_KIND } from '@/components/Button';
import InputCheckbox from '@/components/InputCheckbox';
import useAppState from '@/hooks/useAppState';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import IProfile from '@/types/IProfile';
import IOrganisation from '@/types/IOrganisation';
import SuccessAlert from '@/components/SuccessAlert';
import ErrorAlert from '@/components/ErrorAlert';
import adminAPI from '@/api/admin';
import classNames from '@/helpers/classNames';
import MESSAGES from '@/constants/messages-en';
import LoadingAnimation from '@/components/LoadingAnimation';

const tabs = [
  {
    name: 'Unconnected',
    value: 'unconnected',
  },
  {
    name: 'Connected',
    value: 'connected',
  },
  {
    name: 'All profiles',
    value: 'all',
  },
];

export default function ProfileListAdminPage(): JSX.Element {
  const { profileType, selectProfileType } = useAppState();

  const [initialLoading, setInitialLoading] = useState(true);
  const [data, setData] = useState<IProfile[]>();
  const [unconnectedCount, setUnconnectedCount] = useState<number>();
  const [connectedCount, setConnectedCount] = useState<number>();
  const [allCount, setAllCount] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [type, setType] = useState(tabs[profileType].value);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [orgSearch, setOrgSearch] = useState('');
  const [orgID, setOrgID] = useState(-1);
  const [showOptions, setShowOptions] = useState(false);
  const [orgData, setOrgData] = useState<IOrganisation[]>();
  const [moveOrgSearch, setMoveOrgSearch] = useState('');
  const [moveOrgID, setMoveOrgID] = useState(-1);
  const [moveShowOptions, setMoveShowOptions] = useState(false);
  const [profileMoveIsOpen, setProfileMoveIsOpen] = useState(false);

  const listOrganisations = useCallback(async (newOrgSearch: string) => {
    const res = await adminAPI.listOrganisations({
      pageSize: 5,
      search: newOrgSearch,
    });

    setOrgData(res.data?.data?.filter((item: IOrganisation) => item.name));

    return res.data;
  }, []);

  function handleCheckItem(profileID: number) {
    let arr = checkedItems;
    if (checkedItems.includes(profileID)) {
      arr = arr.filter((e) => e !== profileID);
    } else {
      arr = [...checkedItems, profileID];
    }
    setCheckedItems(arr);
  }

  const listProfiles = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      newType: string = type,
      initial = false,
    ) => {
      const response = await adminAPI.listProfiles({
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
        type: newType,
        orgID,
      });

      if (!initial) {
        setData(response.data?.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }

      if (newType === 'unconnected') {
        setUnconnectedCount(response.data?.paging.total_entries);
      } else if (newType === 'connected') {
        setConnectedCount(response.data?.paging.total_entries);
      } else if (newType === 'all') {
        setAllCount(response.data?.paging.total_entries);
      }

      return response.data;
    },
    [order, page, pageSize, search, sort, type, orgID],
  );

  function handleDelete(profileID: number) {
    setIsLoading(true);
    setSuccess('');
    setError(false);
    adminAPI
      .deleteProfile(profileID)
      .then(() => setSuccess(MESSAGES.profile.delete.success(profileID)))
      .catch(() => setError(true))
      .then(() => setIsLoading(false))
      .then(() => listProfiles(1, pageSize))
      .finally(() => {});
  }

  const exportCSV = useCallback(
    async (arr?: number[]) => {
      if (arr || checkedItems) {
        const response = await adminAPI.exportProfilesCSV(arr || checkedItems);
        const blob = new Blob([response.data]);
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = 'admin_profiles.csv';
        a.click();
      }
    },
    [checkedItems],
  );

  useEffect(() => {
    if (initialLoading) {
      tabs.map(async (item) => {
        await listProfiles(1, 20, 'date', 'desc', '', item.value, true).then(
          ({ paging }) => {
            if (paging) {
              if (item.value === 'unconnected') {
                setUnconnectedCount(paging.total_entries);
              } else if (item.value === 'connected') {
                setConnectedCount(paging.total_entries);
              } else if (item.value === 'all') {
                setAllCount(paging.total_entries);
              }
            }
          },
        );
      });
      setInitialLoading(false);
    }
  }, [listProfiles, initialLoading]);

  return (
    <Layout pageName="Profiles">
      {success !== '' && <SuccessAlert message={success} />}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      <Tab.Group
        defaultIndex={profileType}
        onChange={(index) => {
          setPage(1);
          setError(false);
          setSearch('');
          setType(tabs[index].value);
          selectProfileType(index);
          setSelectAll(false);
          setCheckedItems([]);
          listProfiles(
            1,
            pageSize,
            'date',
            'desc',
            '',
            tabs[index].value,
          ).finally(() => setIsLoading(false));
        }}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:border-b border-gray-200">
          <div className="pt-4 md:pt-0 w-full md:w-auto">
            <TabList
              tabs={tabs.map((item) => {
                let count;
                if (item.value === 'unconnected') count = unconnectedCount;
                else if (item.value === 'connected') count = connectedCount;
                else if (item.value === 'all') count = allCount;
                return {
                  ...item,
                  count,
                };
              })}
              value={type}
            />
          </div>
        </div>
        <Tab.Panels>
          {tabs.map((tab) => (
            <Tab.Panel key={tab.name} className="outline-none">
              <div className="py-8">
                <div className="pb-8 flex flex-col space-y-3 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-end">
                  <div className="flex flex-shrink-0 space-x-3">
                    <div className="relative">
                      <Search
                        id={`OrganisationList-1-20-date-desc--${orgSearch}`}
                        placeholder="Search for organisations"
                        search={orgSearch}
                        setSearch={(value) => {
                          if (value !== orgSearch) {
                            setOrgID(-1);
                            setShowOptions(true);
                          }
                          setOrgSearch(value);
                        }}
                        fetchQuery={(newOrgSearch) =>
                          listOrganisations(newOrgSearch)
                        }
                      />
                      {orgSearch && showOptions && (
                        <div className="origin-top-left absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                          <div className="py-1">
                            {orgData && orgData?.length ? (
                              orgData?.map((item: IOrganisation) => (
                                <button
                                  type="button"
                                  key={item.id}
                                  className="appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-left"
                                  onClick={() => {
                                    setOrgID(item.id);
                                    setShowOptions(false);
                                    setOrgSearch(`#${item.id} ${item.name}`);
                                  }}
                                >
                                  #{item.id}{' '}
                                  <span className="text-gray-500">
                                    {item.name}
                                  </span>
                                </button>
                              ))
                            ) : (
                              <li className="px-3 py-2 text-center">
                                No matching items found
                              </li>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <Search
                      id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}-${type}-${orgID}`}
                      placeholder="Search for profiles"
                      search={search}
                      setSearch={setSearch}
                      fetchQuery={(searchQuery) =>
                        listProfiles(
                          page,
                          pageSize,
                          sort,
                          order,
                          searchQuery,
                          type,
                        )
                      }
                    />
                    <Sort
                      id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}-${type}-${orgID}`}
                      options={[
                        {
                          sort: 'date',
                          order: 'desc',
                          label: 'Date created (Newest first)',
                        },
                        {
                          sort: 'date',
                          order: 'asc',
                          label: 'Date created (Oldest first)',
                        },
                        {
                          sort: 'first_name',
                          order: 'asc',
                          label: 'Name (A-Z)',
                        },
                        {
                          sort: 'first_name',
                          order: 'desc',
                          label: 'Name (Z-A)',
                        },
                      ]}
                      sort={sort}
                      setSort={setSort}
                      order={order}
                      setOrder={setOrder}
                      fetchQuery={(sortQuery, orderQuery) =>
                        listProfiles(
                          page,
                          pageSize,
                          sortQuery,
                          orderQuery,
                          search,
                          type,
                        )
                      }
                    />
                  </div>
                </div>
                {data && data.length > 0 ? (
                  <div className="flex flex-col">
                    <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                      <div className="block w-full lg:px-8">
                        <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8">
                          <div className="lg:hidden flex items-center px-4 py-2 sm:px-6 bg-white border-b border-gray-200">
                            <InputCheckbox
                              id="select-all"
                              label=""
                              value={selectAll}
                              onChange={(value) => {
                                setSelectAll(value);
                                if (value) {
                                  const arr = data.map(({ id }) => id);
                                  setCheckedItems(arr);
                                } else {
                                  setCheckedItems([]);
                                }
                              }}
                            />
                          </div>
                          <ul
                            className={classNames(
                              'divide-y divide-gray-200',
                              isLoading ? 'opacity-40' : '',
                            )}
                          >
                            <li
                              key="ProfileListAdminHeader"
                              className="hidden md:block"
                            >
                              <div className="bg-gray-50">
                                <div className="flex items-center px-4 py-2 sm:px-6">
                                  <div className="min-w-0 flex-1 flex items-start md:items-center">
                                    <div className="w-7">
                                      <InputCheckbox
                                        id="select-all"
                                        label=""
                                        value={selectAll}
                                        onChange={(value) => {
                                          setSelectAll(value);
                                          if (value) {
                                            const arr = data.map(
                                              ({ id }) => id,
                                            );
                                            setCheckedItems(arr);
                                          } else {
                                            setCheckedItems([]);
                                          }
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={classNames(
                                        'min-w-0 flex-1 md:grid md:grid-cols-4 xl:grid-cols-6 md:gap-4 pr-4',
                                        type === 'unconnected'
                                          ? 'xl:grid-cols-6'
                                          : '',
                                        type === 'connected'
                                          ? 'xl:grid-cols-7'
                                          : '',
                                        type === 'all' ? 'xl:grid-cols-8' : '',
                                      )}
                                    >
                                      <p className="text-sm font-medium text-gray-900">
                                        PROFILE NAME &amp; ID
                                      </p>
                                      <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-1 text-sm font-medium text-gray-900">
                                        ORGANISATION
                                      </p>
                                      <p className="mt-2 md:mt-0 xl:col-span-1 text-sm font-medium text-gray-900">
                                        STATUS
                                      </p>
                                      {type !== 'unconnected' && (
                                        <p className="mt-2 md:mt-0 hidden xl:block text-sm font-medium text-gray-900">
                                          LINKED CARD ID
                                        </p>
                                      )}
                                      <p className="mt-2 md:mt-0 hidden xl:block text-sm font-medium text-gray-900">
                                        ACTIVATION KEY
                                      </p>
                                      {type === 'all' && (
                                        <p className="mt-2 md:mt-0 hidden xl:block text-sm font-medium text-gray-900">
                                          STATE
                                        </p>
                                      )}
                                      <p className="mt-2 md:mt-0 xl:col-span-2 text-sm font-medium text-gray-900" />
                                    </div>
                                  </div>
                                  <div className="w-5" />
                                </div>
                              </div>
                            </li>
                            {data?.map((item: IProfile) => (
                              <ProfileListAdminItem
                                key={item.id}
                                id={item.id}
                                firstName={item.first_name || ''}
                                lastName={item.last_name || ''}
                                orgID={item.organisation_id}
                                orgName={item.organisation_name}
                                cardActivation={item.linked_card_activation}
                                cardID={item.linked_card_id}
                                type={type}
                                status={item.status || ''}
                                selected={checkedItems.includes(item.id)}
                                checkItem={() => handleCheckItem(item.id)}
                                handleDelete={() => handleDelete(item.id)}
                                handleExport={() => exportCSV([item.id])}
                                handleMove={() => {
                                  setProfileMoveIsOpen(true);
                                  if (
                                    checkedItems.includes(item.id) === false
                                  ) {
                                    handleCheckItem(item.id);
                                  }
                                }}
                                profilePageURL={item.profile_page_url}
                                viewProfile={`/edit-profile/${item.id}`}
                              />
                            ))}
                          </ul>
                          {isLoading && (
                            <div className="absolute text-gray-500 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                              <LoadingAnimation className="h-16 w-16 text-brand-500 mx-auto" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="py-32">
                    <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                      {MESSAGES.profile.list.empty.heading}
                    </h3>
                    <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                      {MESSAGES.profile.list.empty.description}
                    </p>
                  </div>
                )}
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      <Pagination
        id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}-${type}-${orgID}`}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(pageQuery, pageSizeQuery) =>
          listProfiles(pageQuery, pageSizeQuery, sort, order, search, type)
        }
        setIsLoading={setIsLoading}
      />
      <div className="block" aria-hidden="true">
        <div className="py-8" />
      </div>
      <Modal
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
        dialogTitle={MESSAGES.profile.delete.headingMultiple}
        dialogDescription={MESSAGES.profile.delete.descriptionMultiple}
        successButtonText="Delete profiles"
        successButtonKind={BUTTON_KIND.RED}
        onSuccess={() => {
          setIsLoading(true);
          setSuccess('');
          setError(false);
          adminAPI
            .deleteProfilesShared({
              ids: checkedItems,
            })
            .then(() => setSuccess(MESSAGES.profile.delete.successMultiple))
            .catch(() => setError(true))
            .then(() => setIsLoading(false))
            .then(() => setCheckedItems([]))
            .then(() => listProfiles(1, pageSize))
            .finally(() => setIsDeleteOpen(false));
        }}
        isLoading={isLoading}
      />
      <Modal
        isOpen={profileMoveIsOpen}
        setIsOpen={setProfileMoveIsOpen}
        dialogTitle={
          checkedItems.length === 1
            ? MESSAGES.profile.organisation.heading
            : MESSAGES.profile.organisation.headingMultiple
        }
        dialogDescription={
          checkedItems.length === 1
            ? MESSAGES.profile.organisation.description
            : MESSAGES.profile.organisation.descriptionMultiple
        }
        successButtonText={
          checkedItems.length === 1
            ? MESSAGES.profile.organisation.heading
            : MESSAGES.profile.organisation.headingMultiple
        }
        onSuccess={() => {
          setIsLoading(true);
          setSuccess('');
          setError(false);
          adminAPI
            .updateProfilesShared({
              ids: checkedItems,
              profile: {
                organisation_id: moveOrgID,
              },
            })
            .then(() =>
              setSuccess(
                checkedItems.length === 1
                  ? MESSAGES.profile.organisation.success
                  : MESSAGES.profile.organisation.successMultiple,
              ),
            )
            .catch(() => setError(true))
            .then(() => setIsLoading(false))
            .then(() => setCheckedItems([]))
            .then(() => listProfiles(1, pageSize))
            .finally(() => setProfileMoveIsOpen(false));
        }}
        onCancel={() => setCheckedItems([])}
        isDisabled={moveOrgID === -1}
        isLoading={isLoading}
      >
        <div className="pt-4 relative">
          <Search
            id={`OrganisationList-1-20-date-desc--${moveOrgSearch}`}
            placeholder="Search for organisations"
            search={moveOrgSearch}
            setSearch={(value) => {
              if (value !== moveOrgSearch) {
                setMoveOrgID(-1);
                setMoveShowOptions(true);
              }
              setMoveOrgSearch(value);
            }}
            fetchQuery={(newOrgSearch) => listOrganisations(newOrgSearch)}
          />
          {moveOrgSearch && moveShowOptions && (
            <div className="origin-top-left absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
              <div className="py-1">
                {orgData && orgData?.length ? (
                  orgData?.map((item: IOrganisation) => (
                    <button
                      type="button"
                      key={item.id}
                      className="appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-left"
                      onClick={() => {
                        setMoveOrgID(item.id);
                        setMoveShowOptions(false);
                        setMoveOrgSearch(`#${item.id} ${item.name}`);
                      }}
                    >
                      #{item.id}{' '}
                      <span className="text-gray-500">{item.name}</span>
                    </button>
                  ))
                ) : (
                  <li className="px-3 py-2 text-center">
                    No matching items found
                  </li>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
      <InfoPanelFooter
        className={checkedItems?.length > 1 ? '' : '!-bottom-28'}
      >
        <p className="text-sm leading-5 text-gray-500 mb-1 sm:hidden">{`${checkedItems.length} selected`}</p>
        <div className="flex items-center flex-nowrap justify-end space-x-4">
          <p className="text-sm leading-5 text-gray-500 hidden sm:block">{`${checkedItems.length} selected`}</p>
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Assign profiles to another organisation"
            onClick={() => setProfileMoveIsOpen(true)}
          />
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Export selected profiles"
            onClick={() => {
              setIsLoading(true);
              setSuccess('');
              setError(false);
              exportCSV()
                .then(() => setSuccess(MESSAGES.profile.export.success))
                .catch(() => setError(true))
                .then(() => setIsLoading(false))
                .then(() => listProfiles(1, pageSize))
                .finally(() => setCheckedItems([]));
            }}
          />
          {type === 'unconnected' && (
            <Button
              kind={BUTTON_KIND.RED}
              buttonText="Delete profiles"
              onClick={() => setIsDeleteOpen(true)}
            />
          )}
        </div>
      </InfoPanelFooter>
    </Layout>
  );
}
