import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import organisationsAPI from '@/api/organisations';
import InfoPanelContainer from '../InfoPanelContainer';
import InfoPanelDivider from '../InfoPanelDivider';
import InputInline from '../InputInline';
import Spinner from '../Icons/Spinner';
import SuccessAlert from '../SuccessAlert';
import ErrorAlert from '../ErrorAlert';
import { MESSAGES } from '@/constants/messages-en';

interface IColourSettings {
  organisationSettingID: number | undefined;
  organisationColorSetting: string | undefined;
}
const colourSettingValue = [
  {
    title: 'CMYK',
    value: 'CMYK',
  },
  {
    title: 'RGB',
    value: 'RGB',
  },
];

const ColourSettings: React.FC<IColourSettings> = ({
  organisationSettingID,
  organisationColorSetting,
}) => {
  const [colourSettings, setColourSettings] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState(false);

  useEffect(() => {
    setColourSettings(organisationColorSetting || 'CMYK');
  }, [organisationColorSetting]);

  return (
    <>
      <InfoPanelDivider />
      {success !== '' && <SuccessAlert message={success} />}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}

      <InfoPanelContainer
        title="Colour settings"
        information="Please select which colour mode you would like us to print your physical cards."
      >
        <Box className="flex justify-between items-center w-ful h-full">
          <p className="text-sm leading-5 font-medium font-poppins text-gray-900">
            Printing setting
          </p>
          {!loading ? (
            <InputInline
              type="radio"
              data={colourSettings}
              setData={setColourSettings}
              divideText="or"
              wrapperClassName="flex items-center gap-5"
              onChange={(data: any, { error, complate }) => {
                setLoading(true);
                organisationsAPI
                  .updateOrganisationAdminSettings({
                    organisationSettingID,
                    body: {
                      colour: data,
                    },
                  })
                  .then(() => setSuccess('Success updated colour setting'))
                  .catch((e) => {
                    setError(true);
                    setColourSettings(organisationColorSetting || 'CMYK');
                  })
                  .finally(() => setLoading(false));
              }}
              setting={colourSettingValue}
            />
          ) : (
            <Spinner />
          )}
        </Box>
      </InfoPanelContainer>
      <InfoPanelDivider />
    </>
  );
};

export default ColourSettings;
