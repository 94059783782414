import { Menu, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { UsersIcon } from '@heroicons/react/outline';
import { Fragment, useState } from 'react';
import ICollection from '@/types/ICollection';
import Badge, { BADGE_KIND } from '@/components/Badge';
import Modal from '../Modal';
import { BUTTON_KIND } from '../Button';
import collectionsAPI from '@/api/collections';

export type Props = {
  collections?: ICollection[];
  setCollectionId: (collectionId: number) => void;
  fetchData?: (id: number | undefined) => void;
  onClear?: () => void;
};

const CollectionFilter: React.FC<Props> = ({
  collections,
  fetchData,
  onClear,
}) => {
  const currentCollectionID = parseInt(
    new URLSearchParams(window.location.search).get('collectionId') || '0',
    10,
  );
  const [selectedValue, setSelectedValue] = useState<number | undefined>(
    currentCollectionID === 0 ? undefined : currentCollectionID,
  );
  const [showCollectionDeleteModal, setShowCollectionDeleteModal] =
    useState(false);
  const [collectionToBeDeletedID, setCollectionToBeDeletedID] = useState<
    string | undefined
  >();

  const setCollectionIdURL = (collectionId: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set('collectionId', collectionId);
    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  const removeCollectionIdURL = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete('collectionId');
    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  if (!collections) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={showCollectionDeleteModal}
        onCancel={() => setShowCollectionDeleteModal(false)}
        onClose={() => setShowCollectionDeleteModal(false)}
        onSuccess={async () => {
          if (!collectionToBeDeletedID) return;

          await collectionsAPI.deleteCollection(collectionToBeDeletedID);

          setCollectionToBeDeletedID(undefined);
          setShowCollectionDeleteModal(false);

          const url = new URL(window.location.href);
          url.searchParams.delete('collectionId');
          window.location.href = url.toString();
        }}
        dialogTitle="Are you sure you want to delete this collection?"
        dialogDescription="Once you delete this collection, you cannot undo this action."
        successButtonText="Delete"
        successButtonKind={BUTTON_KIND.RED}
      />

      <Menu
        as="div"
        className="relative inline-block text-left mt-2 lg:mt-0 z-20"
      >
        <div>
          <Menu.Button className="inline-flex items-center justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:ring-brand-500 focus:border-brand-500">
            <UsersIcon className="w-4 h-4 mr-2" />
            Collections
            {selectedValue !== undefined && (
              <>
                <Badge kind={BADGE_KIND.GRAY} className="ml-2" text="1" />

                <XIcon
                  className="-mr-1 ml-2 h-4 w-4 text-gray-400"
                  aria-hidden="true"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClear?.();
                    setSelectedValue(undefined);
                    removeCollectionIdURL();

                    fetchData?.(undefined);
                  }}
                />
              </>
            )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          afterLeave={() => {
            fetchData?.(selectedValue);
          }}
        >
          <Menu.Items
            className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-hidden"
            style={{
              height: collections.length <= 6 ? 'auto' : '250px',
              overflowY: collections.length <= 6 ? 'auto' : 'scroll',
            }}
          >
            <div className="py-1">
              <>
                {(collections || []).length === 0 && (
                  <div>
                    <span className="mx-5 my-2 block text-sm text-gray-700">
                      No collections yet
                    </span>
                  </div>
                )}
              </>
              {(collections || []).map((option) => (
                <Menu.Item key={option.id}>
                  <div className="flex justify-between px-3 hover:bg-gray-100 cursor-pointer">
                    <div>
                      <div
                        key={option.id}
                        className="flex items-center py-2 pr-4"
                        onClick={(e) => {
                          e.preventDefault(); // NOTE: This is needed to prevent the menu from closing
                          setSelectedValue(option.id);

                          setCollectionIdURL(option.id.toString());
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                      >
                        <input
                          type="radio"
                          className="h-4 w-4 text-brand-500 focus:ring-brand-500 border-gray-300 rounded"
                          checked={selectedValue === option.id}
                          readOnly
                          key={option.id}
                        />
                        <span className="ml-3 block text-sm text-gray-700">
                          {option.name}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <button
                        type="button"
                        onClick={() => {
                          setCollectionToBeDeletedID(option.id.toString());
                          setShowCollectionDeleteModal(true);
                        }}
                      >
                        <XIcon
                          className="-mr-1 ml-2 h-4 w-4 text-red-450"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default CollectionFilter;
