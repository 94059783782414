import useAuth from '@/hooks/useAuth';
import { IOrganisationInvite, IOrganisationUser } from '@/types/IOrganisation';
import { useState } from 'react';
import { useQuery } from 'react-query';
import SuccessAlert from '../SuccessAlert';
import Button, { BUTTON_KIND } from '../Button';
import EditorsModal from './EditorsModal';
import Skeleton from 'react-loading-skeleton';
import organisationsAPI from '@/api/organisations';
import ErrorAlert from '../ErrorAlert';
import MESSAGES from '@/constants/messages-en';

type GroupEditingAccessProps = {
  groupID: number | undefined;
  editors: IOrganisationUser[] | undefined;
  createGroupInvites?: IOrganisationInvite[];
  newInvitees: string[];
  callback?: () => void;
  createGroupCallback?: (
    users: IOrganisationUser[],
    invites: IOrganisationInvite[],
    invitees: string[],
  ) => void;
};

export function GroupEditingAccess({
  groupID,
  editors,
  callback,
  newInvitees,
  createGroupInvites,
  createGroupCallback,
}: GroupEditingAccessProps) {
  const { orgID } = useAuth();

  const [editorSuccess, setEditorSuccess] = useState<string | undefined>(
    undefined,
  );
  const [editorError, setEditorError] = useState(false);
  const [isEditorsModalOpen, setIsEditorsModalOpen] = useState(false);
  const [isRemovingManager, setIsRemovingManager] = useState<
    number | undefined
  >(undefined);
  const [isAddingToGroup, setIsAddingToGroup] = useState(false);

  const { data: orgUsers, isFetching: isOrgUsersLoading } = useQuery(
    ['listOrganisationRoles', isEditorsModalOpen],
    listOrganisationRoles,
    {
      enabled: orgID !== undefined,
    },
  );
  const { data: invites, isFetching: isInvitesLoading } = useQuery(
    ['listInvites', isEditorsModalOpen, editorSuccess],
    listOrganisationInvites,
    {
      enabled: orgID !== undefined,
      select(data) {
        return data?.data.filter(
          (invite) =>
            invite.role === 'org_editor' &&
            groupID &&
            invite.group_ids.includes(groupID),
        );
      },
    },
  );

  async function listOrganisationRoles() {
    if (orgID === undefined) {
      return;
    }

    const { data: orgUsers } = await organisationsAPI.listOrganisationRoles({
      orgID,
      page: 1,
      pageSize: 100,
    });

    return orgUsers;
  }

  async function listOrganisationInvites() {
    if (orgID === undefined) {
      return;
    }

    const { data: invites } = await organisationsAPI.listInvites({
      orgID,
      page: 1,
      pageSize: 100,
    });

    return invites;
  }

  async function deleteEditorFromGroup(roleID: number) {
    if (groupID === undefined) {
      if (editors) {
        createGroupCallback?.(
          editors.filter((e) => e.id !== roleID),
          createGroupInvites || [],
          newInvitees,
        );
      }
      return;
    }

    try {
      setIsRemovingManager(roleID);

      await organisationsAPI.deleteGroupEditor(roleID, groupID);

      setEditorSuccess('Editor removed from group');
      callback?.();
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setIsRemovingManager(roleID);
    }
  }

  async function deleteInviteFromGroup(invite: IOrganisationInvite) {
    if (groupID === undefined) {
      if (createGroupInvites) {
        createGroupCallback?.(
          editors || [],
          createGroupInvites.filter((i) => i.id !== invite.id),
          newInvitees.filter((i) => i !== invite.email),
        );
      }
    } else {
      if (orgID === undefined) {
        return;
      }

      setIsRemovingManager(invite.id);

      await organisationsAPI.updateInvite(
        orgID,
        invite.id,
        invite.group_ids.filter((group_id) => group_id !== groupID),
      );

      setEditorSuccess('Group manager invite revoked for group');
      setIsRemovingManager(undefined);
    }
  }

  async function addEditorsToGroup(
    roles: IOrganisationUser[],
    invites: IOrganisationInvite[],
    invitees: string[],
  ) {
    if (groupID === undefined) {
      createGroupCallback?.(roles, invites, invitees);
    } else {
      try {
        setIsAddingToGroup(true);

        const editorPromises = roles.map((role) =>
          organisationsAPI.createGroupEditor(groupID, {
            editor: {
              role_id: role.id,
            },
          }),
        );
        await Promise.all(editorPromises);

        if (orgID === undefined) {
          return;
        }

        const invitePromises = invites.map((invite) =>
          organisationsAPI.updateInvite(orgID, invite.id, [
            ...invite.group_ids,
            groupID,
          ]),
        );
        await Promise.all(invitePromises);

        if (editorError) {
          setEditorError(false);
        }

        if (editorPromises.length > 0) {
          setEditorSuccess(
            `Group managers have been added${
              invitePromises.length > 0 ? ' and invited' : ''
            } to the group.`,
          );
        } else if (invitePromises.length > 0) {
          setEditorSuccess(`Group managers have been invited to the group.`);
        }
        callback?.();
      } catch (error) {
        setEditorError(true);
      } finally {
        setIsAddingToGroup(false);
      }
    }
  }

  const availableEditors =
    orgUsers?.data.filter(
      (user) =>
        user.role === 'org_editor' &&
        (groupID === undefined ||
          !user.groups.map((g) => g.id).includes(groupID)),
    ) || undefined;

  return (
    <ul className="divide-y divide-gray-200">
      {orgUsers === undefined || isOrgUsersLoading ? (
        <EditingListItemLoading role="org_admin" />
      ) : (
        orgUsers.data
          .filter((user) => user.role === 'org_admin')
          .map((admin, index) => (
            <li key={index}>
              <div className="flex items-center justify-between py-4">
                <div className="min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {admin.user.first_name}&nbsp;
                    {admin.user.last_name}
                  </p>
                  <p className="flex items-center text-sm text-gray-500 truncate">
                    {admin.user.email}
                  </p>
                </div>
                <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center rounded-full px-3 py-0.5 bg-gray-100">
                  <p className="flex items-center text-xs font-medium text-gray-900 truncate">
                    Admin
                  </p>
                </div>
              </div>
            </li>
          ))
      )}
      {editors === undefined ? (
        <EditingListItemLoading role="org_editor" />
      ) : (
        editors.map((editor, index) => (
          <li key={index}>
            <div className="block bg-white">
              <div className="flex flex-row items-center justify-between py-4">
                <div className="min-w-0 self-start">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {editor.user.first_name}&nbsp;
                    {editor.user.last_name}
                  </p>
                  <p className="flex text-sm text-gray-500 truncate">
                    {editor.user.email}
                  </p>
                </div>
                <div className="flex flex-col xl:flex-row items-end xl:items-center space-y-4 xl:space-y-0 xl:space-x-2">
                  <div className="bg-gray-100 px-3 py-0.5 rounded-full text-xs font-medium">
                    Group manager
                  </div>
                  <Button
                    buttonText="Remove"
                    kind={BUTTON_KIND.WHITE}
                    loading={isRemovingManager === editor.id}
                    onClick={() => deleteEditorFromGroup(editor.id)}
                  />
                </div>
              </div>
            </div>
          </li>
        ))
      )}
      {invites &&
        invites.map((invite, index) => (
          <li key={index}>
            <InviteItem
              invite={invite}
              isButtonLoading={isRemovingManager}
              removeInvite={deleteInviteFromGroup}
            />
          </li>
        ))}
      {createGroupInvites &&
        createGroupInvites.map((invite, index) => (
          <div key={index}>
            <InviteItem
              invite={invite}
              isButtonLoading={undefined}
              removeInvite={deleteInviteFromGroup}
            />
          </div>
        ))}
      {newInvitees.map((invitee, index) => (
        <div key={index}>
          <InviteItem
            invite={{
              id: index,
              email: invitee,
              role: 'org_editor',
              group_ids: [],
            }}
            isButtonLoading={undefined}
            removeInvite={() => {
              createGroupCallback?.(
                editors || [],
                createGroupInvites || [],
                newInvitees.filter((email) => email !== invitee),
              );
            }}
          />
        </div>
      ))}
      <li key="add-editors-butn">
        {editorError && <ErrorAlert message={MESSAGES.error.generic} />}
        {editorSuccess && <SuccessAlert message={editorSuccess} />}
        <div className="flex justify-end pt-4">
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Add group manager"
            onClick={() => setIsEditorsModalOpen(true)}
            loading={isAddingToGroup}
          />
        </div>
      </li>
      <EditorsModal
        groupID={groupID}
        users={availableEditors}
        invitees={newInvitees}
        isOpen={isEditorsModalOpen}
        setIsOpen={setIsEditorsModalOpen}
        onSuccess={addEditorsToGroup}
      />
    </ul>
  );
}

function EditingListItemLoading({
  role,
  pending = false,
}: {
  role: 'org_admin' | 'org_editor';
  pending?: boolean;
}) {
  return (
    <div className="flex items-center justify-between py-4">
      <div className="min-w-max self-start">
        <Skeleton width={165} height={20} style={{ borderRadius: '999px' }} />
        <Skeleton width={190} height={20} style={{ borderRadius: '999px' }} />
      </div>
      <div className="inline-flex flex-col xl:flex-row lg:space-x-2 items-end lg:items-center space-y-4 xl:space-y-0">
        <div className="flex flex-row space-x-2">
          {pending && (
            <p className="flex items-center text-xs font-medium text-amber-800 px-3 py-0.5 rounded-full bg-amber-50 w-max">
              Pending
            </p>
          )}
          <p className="flex items-center text-xs font-medium text-gray-900 px-3 py-0.5 rounded-full bg-gray-100 w-max">
            {role === 'org_admin' ? 'Admin' : 'Group manager'}
          </p>
        </div>
        {role === 'org_editor' && (
          <Button
            className="self-end"
            buttonText="Remove"
            kind={BUTTON_KIND.WHITE}
            disabled
          />
        )}
      </div>
    </div>
  );
}

type InviteItemProps = {
  invite: IOrganisationInvite;
  removeInvite: (invite: IOrganisationInvite) => void;
  isButtonLoading: number | undefined;
};

function InviteItem({
  invite,
  removeInvite,
  isButtonLoading,
}: InviteItemProps) {
  return (
    <div className="bg-white">
      <div className="flex flex-row items-center justify-between py-4">
        <p className="text-sm font-medium text-gray-900 truncate self-start xl:self-center">
          {invite.email}
        </p>
        <div className="flex flex-col xl:flex-row items-end xl:items-center space-y-4 xl:space-y-0 xl:space-x-2">
          <span className="flex flex-row space-x-2">
            <div className="bg-amber-50 px-3 py-0.5 rounded-full text-xs font-medium text-amber-800">
              Pending
            </div>
            <div className="bg-gray-100 px-3 py-0.5 rounded-full text-xs font-medium">
              Group manager
            </div>
          </span>
          <Button
            buttonText="Remove"
            kind={BUTTON_KIND.WHITE}
            loading={isButtonLoading === invite.id}
            onClick={() => removeInvite(invite)}
          />
        </div>
      </div>
    </div>
  );
}
