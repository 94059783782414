import { ReactComponent as CardIcon } from '@/assets/icons/card.svg';
import { ReactComponent as ArrowRightIcon } from '@/assets/icons/arrow_right.svg';
import useAuth from '@/hooks/useAuth';
import { cx } from '@emotion/css';

type Props = {
  className?: string;
  short?: boolean;
};

const DesignBar: React.FC<Props> = ({ className, short = false }) => {
  const { user } = useAuth();

  if (user?.last_design_order?.total === 0) return null;

  return (
    <div
      className={cx(
        className,
        'flex flex-row p-2 bg-brand-50 border border-brand-200 rounded-md w-full lg:w-auto gap-2',
      )}
    >
      <CardIcon className="w-5 h-5 mr-2" />
      <div className="flex flex-col lg:flex-row gap-2">
        <div className="font-medium text-sm text-brand-900 truncate">
          {short
            ? 'You have cards waiting design.'
            : 'You have cards waiting for design approval and/or design creation.'}
        </div>
        <a
          className="flex truncate"
          target="_blank"
          href={`${import.meta.env.VITE_CUSTOMISER_BASE_URL}/order/${
            user?.last_design_order.uuid
          }`}
        >
          Design now!&nbsp;
          <ArrowRightIcon />
        </a>
      </div>
    </div>
  );
};

export default DesignBar;
