const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M8.33333 16.1454L2.5 10.3121M2.5 10.3121L8.33333 4.47876M2.5 10.3121H17.5"
        stroke="#374151"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRight;
