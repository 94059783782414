import React from 'react';

const ArrowOutside = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M8.3335 5.07694H5.00016C4.55814 5.07694 4.13421 5.25254 3.82165 5.5651C3.50909 5.87766 3.3335 6.30158 3.3335 6.74361V15.0769C3.3335 15.519 3.50909 15.9429 3.82165 16.2555C4.13421 16.568 4.55814 16.7436 5.00016 16.7436H13.3335C13.7755 16.7436 14.1995 16.568 14.512 16.2555C14.8246 15.9429 15.0002 15.519 15.0002 15.0769V11.7436M11.6668 3.41028H16.6668M16.6668 3.41028V8.41028M16.6668 3.41028L8.3335 11.7436"
        stroke="#873CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowOutside;
