import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import {
  EyeIcon,
  EyeOffIcon,
  DownloadIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import moment from 'moment-timezone';
import designsAPI from '@/api/designs';
import IDesign from '@/types/IDesign';
import InputCheckbox from '../../InputCheckbox';
import Badge from '../../Badge';
import filesAPI from '@/api/files';
import IFile from '@/types/IFile';
import Spinner from '../../Icons/Spinner';
import Button, { BUTTON_KIND, BUTTON_SIZE } from '../../Button';
import DesignTemplate from '@/assets/documents/Tapt Design Template.pdf';
import SuccessAlert from '../../SuccessAlert';
import ErrorAlert from '../../ErrorAlert';
import { MESSAGES } from '@/constants/messages-en';
import { CustomScroll } from '../../../styleds/CustomScroll';
import classNames from '@/helpers/classNames';

interface ICardDesigns {
  orgID: number;
  selectedDesign: IDesign | undefined;
  setSelectedDesign: React.Dispatch<React.SetStateAction<IDesign | undefined>>;
  initial: boolean;
  setInitial: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardDesigns: React.FC<ICardDesigns> = ({
  orgID,
  selectedDesign,
  setSelectedDesign,
  initial,
  setInitial,
}) => {
  const [designFiles, setDesignFiles] = useState<IDesign[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [initialRender, setInitialRender] = useState<boolean>(true);

  const handleCheckItem = useCallback(
    (design: IDesign) => {
      if (selectedDesign?.id === design.id) {
        setSelectedDesign(undefined);
      } else {
        setSelectedDesign(design);
      }
    },
    [selectedDesign, setSelectedDesign],
  );

  useEffect(() => {
    designsAPI.showDesigns(orgID).then((res) => {
      setDesignFiles(res.data.data);
    });
  }, [orgID]);

  useEffect(() => {
    if (initialRender && designFiles[0] && !selectedDesign) {
      handleCheckItem(designFiles[0]);
      setInitialRender(false);
    }
  }, [designFiles, handleCheckItem, initialRender, selectedDesign]);

  const download = async (designFile: IFile) => {
    setIsDownloading(true);

    try {
      const response = await filesAPI.downloadFile(designFile.original_url);

      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([response.data]));
      a.download = designFile.name;
      a.click();

      setSuccess(
        `File ${designFile?.file?.file_name} downloaded successfully!`,
      );
    } catch (error) {
      setError(true);
    } finally {
      setIsDownloading(false);
    }
  };
  const handleDownloadDesignTemplate = () => {
    const link = document.createElement('a');
    link.href = DesignTemplate;
    link.download = 'design_template.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleUploadNewDesignFile = (files: FileList | null) => {
    setIsUploading(true);
    const promises: Promise<void>[] = [];
    files &&
      Array.from(files).forEach((file) => {
        const promise = filesAPI
          .createAndUploadFile(orgID, new File([file], file.name))
          .then((res) => {
            designsAPI.createDesign({
              orgID,
              body: {
                design: {
                  organisation_id: orgID,
                  design_file_id: res.data.data.id,
                },
              },
            });
          });
        promises.push(promise);
      });
    Promise.all(promises).then(() => {
      setInitial(!initial);
      setIsUploading(false);
    });
  };

  return (
    <>
      {success !== '' && (
        <SuccessAlert
          message={success}
          className="rounded-md bg-emerald-50 p-4 mb-6"
        />
      )}
      {error && (
        <ErrorAlert
          message={MESSAGES.error.generic}
          className="rounded-md bg-red-50 p-4 mb-6"
        />
      )}
      {designFiles.length !== 0 && (
        <Box className="border border-gray-200 rounded-lg">
          <Box
            className="w-full flex justify-center items-center border-b border-gray-100"
            style={{ height: '32px' }}
          >
            <Box className="w-11 flex justify-center items-center "> </Box>
            <Box className="min-w-0 flex-1 grid grid-cols-12 text-sm leading-5 font-medium text-gray-900">
              <Box
                className="col-span-6 lg:col-span-4 xl:col-span-2 flex justify-start"
                style={{ paddingLeft: '10px' }}
              >
                Order
              </Box>
              <Box className="col-span-6 lg:col-span-4 xl:col-span-3 flex justify-center">
                Date of order
              </Box>
              <Box className="col-span-6 ml-3 lg:col-span-4 xl:col-span-4 flex justify-start">
                File name
              </Box>
              <Box className="col-span-1 hidden lg:flex justify-center items-center">
                View
              </Box>
              <Box className="col-span-3 hidden lg:flex xl:col-span-2 justify-center items-center">
                Download
              </Box>
            </Box>
          </Box>
          <CustomScroll
            style={{ height: '210px', overflow: 'scroll' }}
            className="outside-scroll"
          >
            {designFiles.length !== 0 ? (
              designFiles.map((item, index) => {
                return (
                  <Box
                    className="w-full flex border-b border-gray-100"
                    key={index}
                    sx={{ height: '53px' }}
                  >
                    <Box className=" w-11 flex justify-center items-center">
                      <InputCheckbox
                        id={`Checkbox-${item.id}}`}
                        // labelClassName="ml-0"
                        label=""
                        value={selectedDesign?.id === item.id}
                        onChange={() => handleCheckItem(item)}
                      />
                    </Box>
                    <Box className="min-w-0 flex-1 grid grid-cols-12 text-sm leading-5 font-normal">
                      <Box className="col-span-6 lg:col-span-4 xl:col-span-2  flex justify-start items-center ">
                        {item.order && (
                          <Badge
                            text={`Order #${item.order.order_number}`}
                            kind="gray"
                          />
                        )}
                      </Box>
                      <Box className="col-span-6 lg:col-span-4 xl:col-span-3 flex justify-center items-center">
                        <div>{moment(item.inserted_at).format('DD/MM/YY')}</div>
                      </Box>
                      <Box className="col-span-6 flex justify-start items-center ml-3 text-gray-500 lg:col-span-4 xl:col-span-4">
                        <p className=" truncate ">{item.design_file?.name}</p>
                      </Box>
                      <Box className="col-span-1  hidden  justify-center items-center lg:flex">
                        {item.design_file ? (
                          <EyeIcon
                            className="stroke-1.3 text-gray-500 group-hover:text-brand-400 cursor-pointer"
                            style={{ width: '24px', height: '24px' }}
                            onClick={() =>
                              window.open(
                                `${item.design_file?.original_url}`,
                                '_blank',
                              )
                            }
                          />
                        ) : (
                          <EyeOffIcon
                            className="stroke-1.3 text-gray-500 group-hover:text-brand-400 cursor-pointer"
                            style={{ width: '24px', height: '24px' }}
                          />
                        )}
                      </Box>
                      <Box className="col-span-3 hidden justify-center items-center lg:flex xl:col-span-2 ">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {!isDownloading ? (
                          item.design_file ? (
                            <DownloadIcon
                              className="cursor-pointer"
                              width="24"
                              height="24"
                              color="#9CA3AF"
                              onClick={() => {
                                item.design_file && download(item.design_file);
                              }}
                            />
                          ) : (
                            <XCircleIcon
                              className="cursor-pointer"
                              width="24"
                              height="24"
                              color="#9CA3AF"
                            />
                          )
                        ) : (
                          <Spinner />
                        )}
                      </Box>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <span className="text-center appearance-none px-3 py-2 cursor-pointer  w-full text-grey-500 flex items-center justify-center h-full">
                No items found
              </span>
            )}
          </CustomScroll>
        </Box>
      )}

      <Box
        className={classNames(
          designFiles.length !== 0 ? 'justify-end mt-5' : 'justify-center',
          'flex items-center  gap-3 ',
        )}
      >
        {isUploading ? (
          <Spinner />
        ) : (
          <Box className="relative">
            <Button
              buttonText="Upload new file"
              kind={BUTTON_KIND.WHITE}
              size={BUTTON_SIZE.XSMALL}
              className="cursor-pointer"
            />
            <input
              type="file"
              className="absolute top-0 left-0 opacity-0 w-full h-full cursor-pointer"
              multiple
              accept=".pdf, .ai"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleUploadNewDesignFile(e.target.files);
              }}
            />
          </Box>
        )}
        <Button
          buttonText="Download design template"
          onClick={handleDownloadDesignTemplate}
          kind={BUTTON_KIND.PRIMARY}
          size={BUTTON_SIZE.XSMALL}
        />
      </Box>
    </>
  );
};

export default CardDesigns;
