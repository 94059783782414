import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import organisationsAPI from '@/api/organisations';
import InfoPanelContainer from '../InfoPanelContainer';
import ToggleField from '../ToggleField';
import Spinner from '../Icons/Spinner';
import SuccessAlert from '../SuccessAlert';
import { MESSAGES } from '@/constants/messages-en';
import ErrorAlert from '../ErrorAlert';

interface IPaymentTerms {
  organisationSettingID: number | undefined;
  organisationPaymentTerms: boolean | undefined;
}

const PaymentTerms: React.FC<IPaymentTerms> = ({
  organisationSettingID,
  organisationPaymentTerms,
}) => {
  const [paymentTerms, setPaymentTerms] = useState(
    organisationPaymentTerms || false,
  );
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPaymentTerms(organisationPaymentTerms || false);
  }, [organisationPaymentTerms]);

  return (
    <>
      {success !== '' && <SuccessAlert message={success} />}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      <InfoPanelContainer
        title="Payment terms"
        information="Activate payment terms"
      >
        <Box className="flex justify-between items-center">
          <p className=" font-poppins text-sm leading-5 font-medium text-gray-900 ">
            Permissions allowed
          </p>
          {!loading ? (
            <ToggleField
              label=""
              lableFont="semibold"
              enabled={paymentTerms}
              setter={() => setPaymentTerms((e) => !e)}
              className="flex-row-reverse gap-2"
              spaceX={0}
              onChange={(enabled) => {
                setLoading(true);
                organisationsAPI
                  .updateOrganisationAdminSettings({
                    organisationSettingID,
                    body: {
                      payment_term: enabled,
                    },
                  })
                  .then(() => {
                    setSuccess('Success Updated Payment Term');
                  })
                  .catch(() => {
                    setError(true);
                    setPaymentTerms((e) => !e);
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
            />
          ) : (
            <Spinner />
          )}
        </Box>
      </InfoPanelContainer>
    </>
  );
};

export default PaymentTerms;
