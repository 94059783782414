import { BADGE_KIND } from '@/components/Badge';
import STAGE from '@/constants/stage';
import { OrderTag } from '@/types/IOrderV2';

const orderHandler = {
  stageTabHandler: (stage: string, colValue: string | undefined) => {
    const handleStage = (stage: string, currentStage: string[]) => {
      return currentStage.includes(stage) ? '' : 'lg:hidden hidden';
    };

    const stageMapping: { [key: string]: string[] } = {
      'ORDER TYPE': [
        STAGE.order.new,
        STAGE.order.design_edits,
        STAGE.order.all_orders,
      ],
      TAG: [STAGE.order.new],
      NOTIFICATIONS: [STAGE.order.awaiting_client],
      WARNING: [STAGE.order.awaiting_client],
      CHANGES: [STAGE.order.design_edits],
      'PAUSED BY': [STAGE.order.on_hold],
      'SHOPIFY LINK': [
        STAGE.order.fulfilled,
        // STAGE.order.in_print,
        STAGE.order.on_hold,
      ],
      SHIPPING: [STAGE.order.fulfilled],
      'Define Guidelines': [],
      'EXPORT PROFILES FOR AWAITING CLIENT': [
        STAGE.order.awaiting_client,
        STAGE.order.new,
      ],
      'ORDER DETAILS': [STAGE.order.on_hold],
      PROOF: [STAGE.order.in_print, STAGE.order.ready_for_print],
      LABEL: [STAGE.order.in_print],
      // 'SUBMISSION DATE': [STAGE.order.in_print],
      STAGE: [STAGE.order.ready_for_print, STAGE.order.in_print],
      PLACED: [STAGE.order.ready_for_print, STAGE.order.in_print],
      'UPLOAD DESIGN': [
        STAGE.order.new,
        STAGE.order.awaiting_client,
        STAGE.order.design_edits,
        STAGE.order.fulfilled,
        STAGE.order.all_orders,
      ],
      'DESIGN FILE': [
        STAGE.order.awaiting_client,
        STAGE.order.design_edits,
        STAGE.order.fulfilled,
        STAGE.order.all_orders,
        STAGE.order.on_hold,
      ],
      'QR CODE': [STAGE.order.ready_for_print],
    };

    const currentStage = stageMapping[colValue ?? ''] ?? [];

    return handleStage(stage, currentStage);
  },
  orderRequiredBadge: {
    getOrderRequiredBadge: (
      stage: string,
      order_design_spec_setup: string,
      design_spec_setup_id: string,
    ) => {
      const isDisplayed =
        stage === STAGE.order.awaiting_client ||
        stage === STAGE.order.new ||
        stage === STAGE.order.ready_for_print;

      const isMatched = order_design_spec_setup === design_spec_setup_id;

      const className = isDisplayed && isMatched ? '' : 'lg:hidden hidden';
      const kind = isDisplayed && !isMatched ? 'white' : 'red';
      const text = isDisplayed && isMatched ? 'Setup' : '';

      return { className, kind, text };
    },
  },
  orderBadge: {
    getOrderBadge: (
      stage: string,
      order_design_spec_setup: string,
      design_spec_setup_id: string,
      color: string,
    ) => {
      const isDisplayed =
        stage === STAGE.order.awaiting_client ||
        stage === STAGE.order.new ||
        stage === STAGE.order.ready_for_print;

      const isMatched = order_design_spec_setup === design_spec_setup_id;

      const className = isDisplayed ? 'lg:hidden hidden' : '';
      const kind = isDisplayed && !isMatched ? 'white' : color;
      const text = isDisplayed && isMatched ? 'Setup' : '';

      return { className, kind, text };
    },
    handleTagBadge: {
      tagText: (tagValue: OrderTag | undefined) => {
        switch (tagValue) {
          case 'setup':
            return 'Setup';
          case 'ready':
            return 'Ready';
          case 'awaiting':
            return 'Awaiting';
          case 'on_hold':
            return 'On hold';
          case 'design':
            return 'Design';
          default:
            return '';
        }
      },
      tagKindColor: (tagValue: OrderTag) => {
        return {
          setup: BADGE_KIND.RED,
          ready: BADGE_KIND.GREEN,
          awaiting: BADGE_KIND.YELLOW,
          on_hold: BADGE_KIND.GRAY,
          design: BADGE_KIND.RED,
        }[tagValue];
      },
    },
  },
  orderDetailUpdateStatus: () => [
    {
      name: STAGE.order_stage_name.new,
      order_status: STAGE.order.new,
      kind: BADGE_KIND.RED,
    },
    {
      name: STAGE.order_stage_name.awaiting_client,
      order_status: STAGE.order.awaiting_client,
      kind: BADGE_KIND.ORANGE,
    },
    {
      name: STAGE.order_stage_name.design_edits,
      order_status: STAGE.order.design_edits,
      kind: BADGE_KIND.YELLOW,
    },
    {
      name: STAGE.order_stage_name.ready_for_print,
      order_status: STAGE.order.ready_for_print,
      kind: BADGE_KIND.GREEN,
    },
    {
      name: STAGE.order_stage_name.in_print,
      order_status: STAGE.order.in_print,
      kind: BADGE_KIND.IN_PRINT,
    },
    {
      name: STAGE.order_stage_name.fulfilled,
      order_status: STAGE.order.fulfilled,
      kind: BADGE_KIND.FULLFILED,
    },
    {
      name: STAGE.order_stage_name.on_hold,
      order_status: STAGE.order.on_hold,
      kind: BADGE_KIND.GRAY,
    },
  ],
};

export { orderHandler as default, orderHandler };
