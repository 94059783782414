import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/solid';
import Input from '@/components/Input';
import { IProfileSocialLink } from '@/types/IProfile';
import ToggleField from './ToggleField';

interface IEditSocialLink {
  setProfileSocialLinks: React.Dispatch<
    React.SetStateAction<IProfileSocialLink[]>
  >;
  profileSocialLinks: IProfileSocialLink[];
  socialLink: IProfileSocialLink;
  index: number;
  disabled?: boolean;
  shared?: boolean;
  error?: boolean;
}

const swapOrder = (
  result: IProfileSocialLink[],
  index: number,
  order: number,
) => {
  const newArray = result.map((item: IProfileSocialLink) => {
    if (item.order === order - 1) {
      return {
        ...item,
        order,
      };
    }
    if (item.order === order) {
      return {
        ...item,
        order: order - 1,
      };
    }
    return item;
  });
  const temp = newArray[index];
  newArray[index] = newArray[index - 1];
  newArray[index - 1] = temp;
  return newArray;
};

export default function EditSocialLink({
  setProfileSocialLinks,
  profileSocialLinks,
  socialLink,
  index,
  disabled,
  shared = false,
  error,
}: IEditSocialLink): JSX.Element {
  return (
    <li
      key={`ProfileSocialLink-${index}`}
      className="bg-white border border-gray-300 overflow-hidden sm:rounded-md"
    >
      <div className="flex flex-col sm:flex-row">
        <div className="bg-gray-50 p-3 flex flex-row sm:flex-col justify-end space-x-2 sm:space-x-0 sm:justify-between order-2 sm:order-1">
          <button
            type="button"
            disabled={!!disabled || index === 0}
            onClick={() =>
              setProfileSocialLinks((result: IProfileSocialLink[]) =>
                swapOrder(result, index, socialLink.order),
              )
            }
            className="bg-white border border-gray-300 rounded-md text-gray-500 transition hover:bg-gray-50 relative inline-flex items-center p-2 text-sm font-medium disabled:opacity-40"
          >
            <ArrowUpIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            disabled={!!disabled || index === profileSocialLinks.length - 1}
            onClick={() =>
              setProfileSocialLinks((result: IProfileSocialLink[]) =>
                swapOrder(result, index + 1, socialLink.order + 1),
              )
            }
            className="bg-white border border-gray-300 rounded-md text-gray-500 transition hover:bg-gray-50 relative inline-flex items-center p-2 text-sm font-medium disabled:opacity-40"
          >
            <ArrowDownIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="flex-grow order-1 sm:order-2">
          <div className="py-4 px-4 sm:px-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor={`"profileSocialLinkType-${index}`}
                  className="block text-sm font-medium text-gray-700"
                >
                  Social channel
                  <div className="mt-1">
                    <select
                      id={`"profileSocialLinkType-${index}`}
                      name={`"profileSocialLinkType-${index}`}
                      value={profileSocialLinks[index].type}
                      onChange={(e) => {
                        setProfileSocialLinks((result: IProfileSocialLink[]) =>
                          result.map((item: IProfileSocialLink) => {
                            if (item.order === socialLink.order) {
                              return {
                                ...item,
                                type: e.target.value,
                              };
                            }
                            return item;
                          }),
                        );
                      }}
                      className="max-w-lg block focus:ring-brand-500 focus:border-brand-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md disabled:bg-white disabled:text-gray-400"
                      disabled={disabled}
                    >
                      <option value="facebook">Facebook</option>
                      <option value="twitter">Twitter</option>
                      <option value="linkedin">LinkedIn</option>
                      <option value="instagram">Instagram</option>
                      <option value="pinterest">Pinterest</option>
                      <option value="tiktok">TikTok</option>
                      <option value="youtube">YouTube</option>
                      <option value="whatsapp">WhatsApp</option>
                      <option value="wechat">WeChat</option>
                      <option value="googlemybusiness">
                        Google My Business
                      </option>
                      <option value="threads">Threads</option>
                      <option value="discord">Discord</option>
                      <option value="twitch">Twitch</option>
                      <option value="paypal">Paypal</option>
                      <option value="behance">Behance</option>
                    </select>
                  </div>
                </label>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <Input
                  id={`"profileSocialLinkValue-${index}`}
                  label="Social URL"
                  type="text"
                  value={profileSocialLinks[index].value}
                  onChange={(value) => {
                    setProfileSocialLinks((result: IProfileSocialLink[]) =>
                      result.map((item: IProfileSocialLink) => {
                        if (item.order === socialLink.order) {
                          return {
                            ...item,
                            value,
                          };
                        }
                        return item;
                      }),
                    );
                  }}
                  message="Clicking the icon will go here"
                  disabled={disabled}
                  error={error}
                />
              </div>
            </div>
            {!disabled && (
              <div className="pt-4">
                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={() => {
                      if (shared) {
                        setProfileSocialLinks((result: IProfileSocialLink[]) =>
                          result.map((item: IProfileSocialLink) => {
                            if (item.order === socialLink.order) {
                              return {
                                ...item,
                                order: -1,
                              };
                            }
                            return item;
                          }),
                        );
                      } else {
                        setProfileSocialLinks((result: IProfileSocialLink[]) =>
                          result.filter(
                            (item: IProfileSocialLink) =>
                              item.order !== socialLink.order,
                          ),
                        );
                      }
                    }}
                    className="appearance-none text-brand-500 text-sm font-medium"
                    disabled={disabled}
                  >
                    Remove link
                  </button>
                  <div>
                    <ToggleField
                      key={`"profileSocialLinkPersonal-${index}`}
                      label="Personal Link"
                      enabled={profileSocialLinks[index].personal}
                      lableColor="gray-400"
                      setter={(value) => {
                        setProfileSocialLinks((result: IProfileSocialLink[]) =>
                          result.map((item: IProfileSocialLink) => {
                            if (item.order === socialLink.order) {
                              return {
                                ...item,
                                personal: value,
                              };
                            }
                            return item;
                          }),
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}
