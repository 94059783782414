import WalletIcon from '@/assets/icons/apple_wallet.svg';
import WalletPassesIcon from '@/assets/icons/walletpasses.svg';
import { BASE_URL } from '../../config';
import isIOS from '@/helpers/isIos';

interface IAddToWallet {
  profileHash: string;
}

const AddToWallet: React.FC<IAddToWallet> = ({ profileHash }) => {
  const url = `${BASE_URL}/api/profiles/${profileHash}/wallet`;

  return (
    <a href={url}>
      {isIOS() && (
        <img
          width={161}
          className="h-auto"
          src={WalletIcon}
          alt="Add to Apple Wallet"
        />
      )}
      {!isIOS() && (
        <img
          width={161}
          className="h-auto"
          src={WalletPassesIcon}
          alt="Add to WalletPasses"
        />
      )}
    </a>
  );
};

export default AddToWallet;
