import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PlusIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';
import Layout from '@/components/Layout';
import { IProfileQuickFileLink, IProfileSocialLink } from '@/types/IProfile';
import IFile from '@/types/IFile';
import ErrorAlert from '@/components/ErrorAlert';
import profilesAPI from '@/api/profiles';
import designsAPI from '@/api/designs';
import validateEmail from '@/helpers/validateEmail';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import EditSocialLink from '@/components/EditSocialLink';
import EditQuickLink from '@/components/EditQuickLink';
import EditFileLink from '@/components/EditFileLink';
import Modal from '@/components/Modal';
import ModalNoSuccess from '@/components/ModalNoSuccess';
import ToggleField from '@/components/ToggleField';
import useAuth from '@/hooks/useAuth';
import Button, { BUTTON_KIND } from '@/components/Button';
import CardDesign from '@/components/CardDesign';
import DesignTemplate from '@/assets/documents/Tapt Design Template.pdf';
import PersonalInfoForm from '@/containers/PersonalInfoForm';
import CompanyInfoForm from '@/containers/CompanyInfoForm';
import MESSAGES from '@/constants/messages-en';
import validateUrl from '@/helpers/validateUrl';
import SuccessAlert from '@/components/SuccessAlert';
import ClearProfileWarningModal from '@/components/EditProfilePage/ClearProfileWarningModal';
import DeleteProfileWarningModal from '@/components/EditProfilePage/DeleteProfileWarningModal';
import { PencilIcon } from '@heroicons/react/outline';

interface IEditProfilePage {
  match: {
    params: {
      profile_id: number;
    };
  };
}

const EditProfilePage: React.FC<IEditProfilePage> = (props) => {
  const { match } = props;
  const { orgID } = useAuth();
  const profileID = match.params.profile_id;
  const history = useHistory();

  const [photo, setPhoto] = useState<IFile>();
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [pronouns, setPronouns] = useState('');
  const [pronounsError, setPronounsError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [email, setEmail] = useState('');
  const [extraEmails, setExtraEmails] = useState<string[]>([]);
  const [extraMobileNumbers, setExtraMobileNumbers] = useState<string[]>([]);
  const [emailError, setEmailError] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [jobTitle, setJobTitle] = useState('');
  const [jobTitleError, setJobTitleError] = useState(false);
  const [profileGroupId, setProfileGroupId] = useState<number | undefined>(
    undefined,
  );
  const [note, setNote] = useState('');
  const [socialLinkErrorIndex, setSocialLinkErrorIndex] = useState(-1);
  const [companyName, setCompanyName] = useState('');
  const [companyNameError, setCompanyNameError] = useState(false);
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyWebsiteError, setCompanyWebsiteError] = useState(false);
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
  const [companyPhoneNumberError, setCompanyPhoneNumberError] = useState(false);
  const [extraCompanyPhoneNumbers, setExtraCompanyPhoneNumbers] = useState<
    string[]
  >([]);
  const [country, setCountry] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [streetAddressError, setStreetAddressError] = useState(false);
  const [city, setCity] = useState('');
  const [stateProvince, setStateProvince] = useState('');
  const [postCode, setPostCode] = useState('');
  const [profileSocialLinks, setProfileSocialLinks] = useState<
    IProfileSocialLink[]
  >([]);
  const [profileQuickFileLinks, setProfileQuickFileLinks] = useState<
    IProfileQuickFileLink[]
  >([]);
  const [status, setStatus] = useState<string>('');
  const [designID, setDesignID] = useState<number>();
  const [nameToggle, setNameToggle] = useState<boolean>(false);
  const [companyNameToggle, setCompanyNameToggle] = useState<boolean>(false);
  const [jobTitleToggle, setJobTitleToggle] = useState<boolean>(false);
  const [mobileNumberToggle, setMobileNumberToggle] = useState<boolean>(false);
  const [companyPhoneNumberToggle, setCompanyPhoneNumberToggle] =
    useState<boolean>(false);
  const [emailToggle, setEmailToggle] = useState<boolean>(false);
  const [companyWebsiteToggle, setCompanyWebsiteToggle] =
    useState<boolean>(false);
  const [streetAddressToggle, setStreetAddressToggle] =
    useState<boolean>(false);
  const [primaryC, setPrimaryC] = useState<string>('');
  const [primaryM, setPrimaryM] = useState<string>('');
  const [primaryY, setPrimaryY] = useState<string>('');
  const [primaryK, setPrimaryK] = useState<string>('');
  const [secondaryC, setSecondaryC] = useState<string>('');
  const [secondaryM, setSecondaryM] = useState<string>('');
  const [secondaryY, setSecondaryY] = useState<string>('');
  const [secondaryK, setSecondaryK] = useState<string>('');
  const [designFile, setDesignFile] = useState<IFile>();
  const [logoFile, setLogoFile] = useState<IFile>();
  const [cardDesign, setCardDesign] = useState<number>(0);
  const [cardDesignError, setCardDesignError] = useState<boolean>(false);
  const [contactExchangeEnabled, setContactExchangeEnabled] =
    useState<boolean>(false);
  const [cardholderEditingEnabled, setCardholderEditingEnabled] =
    useState<boolean>(false);
  const [designNote, setDesignNote] = useState<string>('');
  const [isEnableEditingOpen, setIsEnableEditingOpen] =
    useState<boolean>(false);
  const [isDisplayOpen, setIsDisplayOpen] = useState(false);
  const [profileResetLoading, setProfileResetLoading] = useState(false);
  const [profileResetError, setProfileResetError] = useState(false);
  const [profileResetSuccess, setProfileResetSuccess] = useState(false);
  const [profileDeleteLoading, setProfileDeleteLoading] = useState(false);
  const [profileDeleteError, setProfileDeleteError] = useState(false);
  const [profileDeleteSuccess, setProfileDeleteSuccess] = useState(false);
  const [isClearProfileWarningOpen, setIsClearProfileWarningOpen] =
    useState(false);
  const [isDeleteProfileWarningOpen, setIsDeleteProfileWarningOpen] =
    useState(false);
  const [locationLatLng, setLocationLatLng] =
    useState<google.maps.LatLngLiteral>();
  const [mapEnabled, setMapEnabled] = useState<boolean>(false);

  const photoFileID = photo ? photo.id : null;
  const designFileID = designFile ? designFile.id : null;
  const logoFileID = logoFile ? logoFile.id : null;

  const showProfile = useCallback(() => {
    if (orgID) {
      profilesAPI
        .showProfile(orgID, profileID)
        .then((res) => {
          const data = res?.data;
          const profile = res?.data?.data;

          if (profile?.replacement_profile_id) {
            history.push(`/edit-profile/${profile.replacement_profile_id}`);
          }

          const { design } = profile;
          setFirstName(profile?.first_name || '');
          setMiddleName(profile?.middle_name || '');
          setLastName(profile?.last_name || '');
          setPronouns(profile?.pronouns || '');
          setEmail(profile?.email || '');
          setExtraEmails(profile?.extra_emails || []);
          setMobileNumber(profile?.mobile_number || '');
          setExtraMobileNumbers(profile?.extra_mobile_numbers || []);
          setJobTitle(profile?.job_title || '');
          setNote(profile?.note || '');
          setCompanyName(profile?.company_name || '');
          setCompanyWebsite(profile?.company_website || '');
          setCompanyPhoneNumber(profile?.company_phone_number || '');
          setExtraCompanyPhoneNumbers(
            profile?.extra_company_phone_numbers || [],
          );
          setCountry(profile?.country || '');
          setStreetAddress(profile?.street_address || '');
          setCity(profile?.city || '');
          setStateProvince(profile?.state || '');
          setPostCode(profile?.post_code || '');
          setPhoto(profile?.photo || null);
          setContactExchangeEnabled(profile?.contact_exchange_enabled || false);
          setCardholderEditingEnabled(profile?.is_editable || false);
          setStatus(profile?.status || '');
          setProfileSocialLinks(
            data.data.profile_social_links.map((item, index) => ({
              ...item,
              order: index,
            })),
          );
          setProfileGroupId(profile?.group_id || undefined);

          if (data?.data?.location)
            setLocationLatLng({
              lat: data.data.location[0],
              lng: data.data.location[1],
            });
          setMapEnabled(data?.data?.map_enabled);

          let newLinksArray: IProfileQuickFileLink[] = [];
          if (data?.data?.profile_quick_links?.length > 0) {
            newLinksArray = data.data.profile_quick_links;
          }

          if (data?.data?.profile_file_links?.length > 0) {
            newLinksArray = [...newLinksArray, ...data.data.profile_file_links];
          }

          newLinksArray.sort(
            (a: IProfileQuickFileLink, b: IProfileQuickFileLink) => {
              return a.order - b.order;
            },
          );
          setProfileQuickFileLinks(
            newLinksArray.map((item: IProfileQuickFileLink, index: number) => ({
              ...item,
              order: index,
            })),
          );

          if (design) {
            setDesignID(design?.id);
            setDesignFile(design?.design_file || undefined);
            if (design?.primary_color) {
              setPrimaryC(design?.primary_color[0].toString());
              setPrimaryM(design?.primary_color[1].toString());
              setPrimaryY(design?.primary_color[2].toString());
              setPrimaryK(design?.primary_color[3].toString());
            } else {
              setPrimaryC('');
              setPrimaryM('');
              setPrimaryY('');
              setPrimaryK('');
            }
            if (design?.secondary_color) {
              setSecondaryC(design?.secondary_color[0].toString());
              setSecondaryM(design?.secondary_color[1].toString());
              setSecondaryY(design?.secondary_color[2].toString());
              setSecondaryK(design?.secondary_color[3].toString());
            } else {
              setSecondaryC('');
              setSecondaryM('');
              setSecondaryY('');
              setSecondaryK('');
            }

            setNameToggle(design?.flags?.name || false);
            setEmailToggle(design?.flags?.email || false);
            setCompanyNameToggle(design?.flags?.company_name || false);
            setJobTitleToggle(design?.flags?.job_title || false);
            setMobileNumberToggle(design?.flags?.mobile_number || false);
            setCompanyPhoneNumberToggle(
              design?.flags?.company_phone_number || false,
            );
            setCompanyWebsiteToggle(design?.flags?.company_website || false);
            setStreetAddressToggle(design?.flags?.street_address || false);
            setDesignNote(design?.description || '');
            setLogoFile(design?.logo_file || undefined);

            if (
              !design.design_file &&
              (design.logo_file ||
                design.primary_color ||
                design.secondary_color)
            )
              setCardDesign(2);
          } else {
            setCardDesign(1);
          }
        })
        .finally(() => {});
    }
  }, [history, orgID, profileID]);

  const updateProfile = useCallback(
    (submitForPrint: boolean, emailProfileEditing?: boolean) => {
      if (orgID) {
        let profileStatus = status;
        if (
          cardholderEditingEnabled &&
          (status === 'active' || (status === 'unprinted' && submitForPrint))
        ) {
          profileStatus = 'editing';
        } else if (submitForPrint) {
          profileStatus = 'active';
        }
        profilesAPI
          .updateProfile({
            orgID,
            profileID,
            body: {
              email_profile_editing: emailProfileEditing,
              profile: {
                first_name: firstName,
                middle_name: middleName,
                last_name: lastName,
                pronouns,
                email,
                extra_emails: extraEmails.filter((item) => !!item),
                mobile_number: mobileNumber,
                extra_mobile_numbers: extraMobileNumbers.filter(
                  (item) => !!item,
                ),
                photo_file_id: photoFileID,
                job_title: jobTitle,
                note,
                company_name: companyName,
                company_website: companyWebsite,
                company_phone_number: companyPhoneNumber,
                extra_company_phone_numbers: extraCompanyPhoneNumbers.filter(
                  (item) => !!item,
                ),
                country,
                street_address: streetAddress.trim(),
                city,
                state: stateProvince,
                post_code: postCode,
                contact_exchange_enabled: contactExchangeEnabled,
                is_editable: cardholderEditingEnabled,
                profile_social_links: profileSocialLinks,
                profile_quick_links: profileQuickFileLinks.filter(
                  (item) => !!item.value,
                ),
                profile_file_links: profileQuickFileLinks.filter(
                  (item) => !!item.file_id,
                ),
                status: profileStatus,
                location:
                  locationLatLng?.lat && locationLatLng?.lng
                    ? [locationLatLng?.lat, locationLatLng?.lng]
                    : null,
                map_enabled: mapEnabled,
              },
            },
          })
          .then(() => {
            const design = {
              organisation_id: orgID,
              ...(primaryC || primaryM || primaryY || primaryK
                ? {
                    primary_color: [
                      primaryC ? Number(primaryC) : Number('0'),
                      primaryM ? Number(primaryM) : Number('0'),
                      primaryY ? Number(primaryY) : Number('0'),
                      primaryK ? Number(primaryK) : Number('0'),
                    ],
                  }
                : {}),
              ...(secondaryC || secondaryM || secondaryY || secondaryK
                ? {
                    secondary_color: [
                      secondaryC ? Number(secondaryC) : Number('0'),
                      secondaryM ? Number(secondaryM) : Number('0'),
                      secondaryY ? Number(secondaryY) : Number('0'),
                      secondaryK ? Number(secondaryK) : Number('0'),
                    ],
                  }
                : {}),
              description: designNote,
              design_file_id: designFileID,
              logo_file_id: logoFileID,
              flags: {
                company_name: companyNameToggle,
                company_phone_number: companyPhoneNumberToggle,
                company_website: companyWebsiteToggle,
                email: emailToggle,
                name: nameToggle,
                job_title: jobTitleToggle,
                mobile_number: mobileNumberToggle,
                street_address: streetAddressToggle,
              },
            };

            if (designID) {
              designsAPI
                .updateDesign({
                  orgID,
                  designID,
                  body: {
                    design,
                  },
                })
                .then(() =>
                  history.push('/', {
                    success: MESSAGES.profile.edit.success,
                    setupInfo: true,
                  }),
                )
                .catch(() => setError(true))
                .finally(() => {});
            } else {
              designsAPI
                .createDesign({
                  orgID,
                  body: {
                    design,
                  },
                })
                .then((res) => {
                  profilesAPI
                    .updateProfile({
                      orgID,
                      profileID,
                      body: {
                        profile: {
                          design_id: res.data?.data?.id,
                        },
                      },
                    })
                    .finally(() => {});
                })
                .then(() =>
                  history.push('/', {
                    success: MESSAGES.profile.edit.success,
                    setupInfo: true,
                  }),
                )
                .catch(() => setError(true))
                .finally(() => {});
            }
          })
          .catch(() => setError(true))
          .finally(() => {});
      }
    },
    [
      orgID,
      status,
      cardholderEditingEnabled,
      profileID,
      firstName,
      middleName,
      lastName,
      pronouns,
      email,
      extraEmails,
      mobileNumber,
      extraMobileNumbers,
      photoFileID,
      jobTitle,
      note,
      companyName,
      companyWebsite,
      companyPhoneNumber,
      extraCompanyPhoneNumbers,
      country,
      streetAddress,
      city,
      stateProvince,
      postCode,
      mapEnabled,
      locationLatLng,
      contactExchangeEnabled,
      profileSocialLinks,
      profileQuickFileLinks,
      primaryC,
      primaryM,
      primaryY,
      primaryK,
      secondaryC,
      secondaryM,
      secondaryY,
      secondaryK,
      designNote,
      designFileID,
      logoFileID,
      companyNameToggle,
      companyPhoneNumberToggle,
      companyWebsiteToggle,
      emailToggle,
      nameToggle,
      jobTitleToggle,
      mobileNumberToggle,
      streetAddressToggle,
      designID,
      history,
    ],
  );

  useEffect(() => {
    showProfile();
  }, [showProfile]);

  const handleClick = () => {
    // set errors back to false
    setEmailError(false);
    setSocialLinkErrorIndex(-1);

    if (email !== '' && !validateEmail(email)) {
      setEmailError(true);
    } else if (
      profileSocialLinks.findIndex((item) => !validateUrl(item.value)) !== -1
    ) {
      setSocialLinkErrorIndex(
        profileSocialLinks.findIndex((item) => !validateUrl(item.value)),
      );
    } else {
      updateProfile(false);
    }
  };

  const handleSubmitForPrint = () => {
    // set errors back to false
    setEmailError(false);
    setFirstNameError(false);
    setLastNameError(false);
    setPronounsError(false);
    setJobTitleError(false);
    setMobileNumberError(false);
    setSocialLinkErrorIndex(-1);
    setCompanyNameError(false);
    setCompanyWebsiteError(false);
    setCompanyPhoneNumberError(false);
    setStreetAddressError(false);
    setCardDesignError(false);

    if ((emailToggle || email !== '') && !validateEmail(email)) {
      setEmailError(true);
    } else if (nameToggle && firstName === '') {
      setFirstNameError(true);
    } else if (nameToggle && lastName === '') {
      setLastNameError(true);
    } else if (jobTitleToggle && jobTitle === '') {
      setJobTitleError(true);
    } else if (mobileNumberToggle && mobileNumber === '') {
      setMobileNumberError(true);
    } else if (
      profileSocialLinks.findIndex((item) => !validateUrl(item.value)) !== -1
    ) {
      setSocialLinkErrorIndex(
        profileSocialLinks.findIndex((item) => !validateUrl(item.value)),
      );
    } else if (companyNameToggle && companyName === '') {
      setCompanyNameError(true);
    } else if (companyWebsiteToggle && companyWebsite === '') {
      setCompanyWebsiteError(true);
    } else if (companyPhoneNumberToggle && companyPhoneNumber === '') {
      setCompanyPhoneNumberError(true);
    } else if (streetAddressToggle && streetAddress === '') {
      setStreetAddressError(true);
    } else if (
      status === 'unprinted' &&
      ((cardDesign === 0 && !designFile) ||
        (cardDesign === 2 &&
          !(
            logoFile ||
            primaryC !== '' ||
            primaryM !== '' ||
            primaryY !== '' ||
            primaryK !== '' ||
            secondaryC !== '' ||
            secondaryM !== '' ||
            secondaryY !== '' ||
            secondaryK !== ''
          )))
    ) {
      setCardDesignError(true);
    } else {
      updateProfile(true);
    }
  };

  const handleAddSocialLinkClick = () => {
    setProfileSocialLinks([
      ...profileSocialLinks,
      {
        order: profileSocialLinks.length
          ? profileSocialLinks[profileSocialLinks.length - 1].order + 1
          : 1,
        type: 'facebook',
        value: '',
        personal: false,
      },
    ]);
  };

  const handleAddQuickLinkClick = () => {
    setProfileQuickFileLinks([
      ...profileQuickFileLinks,
      {
        order: profileQuickFileLinks.length
          ? profileQuickFileLinks[profileQuickFileLinks.length - 1].order + 1
          : 1,
        title: '',
        value: '',
      },
    ]);
  };

  const handleAddFileLinkClick = () => {
    setProfileQuickFileLinks([
      ...profileQuickFileLinks,
      {
        order: profileQuickFileLinks.length
          ? profileQuickFileLinks[profileQuickFileLinks.length - 1].order + 1
          : 1,
        title: '',
        file_id: 1,
      },
    ]);
  };

  const handleClearProfile = () => {
    if (!orgID) return;

    setProfileResetLoading(true);
    setProfileResetError(false);

    profilesAPI
      .resetProfile(orgID, profileID)
      .then((res) => {
        setSuccess(MESSAGES.profile.clear.success);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        const profile = res?.data?.data;
        profile?.id && history.push(`/edit-profile/${profile.id}`);
      })
      .catch((err) => {
        setProfileResetError(true);
      })
      .finally(() => {
        setProfileResetLoading(false);
      });
  };

  const handleDeleteProfile = () => {
    if (!orgID) return;

    setProfileDeleteLoading(true);
    setProfileDeleteError(false);

    profilesAPI
      .deleteProfile(orgID, profileID)
      .then(() => {
        history.push('/', {
          success: MESSAGES.profile.delete.successWithName(
            `${firstName} ${lastName}`,
          ),
        });
      })
      .catch(() => {
        setProfileDeleteError(true);
      })
      .finally(() => {
        setProfileDeleteLoading(false);
      });
  };

  return (
    <Layout
      pageName={status === 'unprinted' ? 'Set up profile' : 'Edit Profile'}
      description={MESSAGES.profile.edit.heading}
    >
      <div className="bg-brand-100 text-brand-900 flex flex-row p-3 rounded-md mb-8 space-x-3">
        <div className="flex-shrink-0">
          <PencilIcon className="w-5 h-5 text-brand-500" />
        </div>
        <div className="flex flex-col space-y-2">
          <span className="text-brand-900 font-medium">
            Do you want to customise the profile appearance?
          </span>
          <span className="text-sm">
            Profile appearance, including background colours and company banner,
            is applied {profileGroupId ? 'group' : 'organisation'}-wide.
            Customise {profileGroupId ? 'this' : 'your'} profile design
            {profileGroupId ? ' ' : ' in '}
            <span className="font-semibold">
              {profileGroupId ? 'within the Group' : 'Settings'}.
            </span>
          </span>
          <a
            href={
              profileGroupId ? `/edit-group/${profileGroupId}` : '/settings'
            }
            className="flex flex-row"
          >
            Design in {profileGroupId ? 'Group' : 'Settings'}
            <ArrowNarrowRightIcon className="ml-1 w-5 h-5" />
          </a>
        </div>
      </div>
      {success && (
        <SuccessAlert
          className="rounded-md bg-emerald-50 p-4 mb-4"
          message={success}
        />
      )}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      <InfoPanelContainer
        title="Personal Information"
        information={MESSAGES.profile.edit.personalInfo}
      >
        <PersonalInfoForm
          photo={photo}
          firstName={firstName}
          firstNameError={firstNameError}
          middleName={middleName}
          lastName={lastName}
          lastNameError={lastNameError}
          pronouns={pronouns}
          pronounsError={pronounsError}
          email={email}
          extraEmails={extraEmails}
          emailError={emailError}
          mobileNumber={mobileNumber}
          mobileNumberError={mobileNumberError}
          extraMobileNumbers={extraMobileNumbers}
          jobTitle={jobTitle}
          jobTitleError={jobTitleError}
          note={note}
          setPhoto={setPhoto}
          setFirstName={setFirstName}
          setMiddleName={setMiddleName}
          setLastName={setLastName}
          setPronouns={setPronouns}
          setEmail={setEmail}
          setExtraEmails={setExtraEmails}
          setMobileNumber={setMobileNumber}
          setExtraMobileNumbers={setExtraMobileNumbers}
          setJobTitle={setJobTitle}
          setNote={setNote}
        />
      </InfoPanelContainer>

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Social Media Links"
        information={MESSAGES.profile.edit.social}
      >
        {socialLinkErrorIndex !== -1 && (
          <ErrorAlert message={MESSAGES.error.url} />
        )}
        {orgID && profileSocialLinks.length > 0 && (
          <ul
            key={`ProfileSocialLinks-${profileSocialLinks.length}`}
            className="space-y-3 pb-3"
          >
            {profileSocialLinks.map((socialLink, index) => (
              <EditSocialLink
                key={`SocialLink-${socialLink.order}`}
                setProfileSocialLinks={setProfileSocialLinks}
                profileSocialLinks={profileSocialLinks}
                socialLink={socialLink}
                index={index}
                error={index === socialLinkErrorIndex}
              />
            ))}
          </ul>
        )}
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-4">
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Add new social link"
              icon={<PlusIcon />}
              onClick={() => handleAddSocialLinkClick()}
            />
          </div>
        </div>
      </InfoPanelContainer>

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Company Information"
        information=""
        hint={MESSAGES.profile.edit.company}
      >
        <CompanyInfoForm
          companyName={companyName}
          companyNameError={companyNameError}
          companyWebsite={companyWebsite}
          companyWebsiteError={companyWebsiteError}
          companyPhoneNumber={companyPhoneNumber}
          companyPhoneNumberError={companyPhoneNumberError}
          extraCompanyPhoneNumbers={extraCompanyPhoneNumbers}
          country={country}
          streetAddress={streetAddress}
          streetAddressError={streetAddressError}
          city={city}
          stateProvince={stateProvince}
          postCode={postCode}
          mapEnabled={mapEnabled}
          locationLatLng={locationLatLng}
          setCompanyName={setCompanyName}
          setCompanyWebsite={setCompanyWebsite}
          setCompanyPhoneNumber={setCompanyPhoneNumber}
          setExtraCompanyPhoneNumbers={setExtraCompanyPhoneNumbers}
          setCountry={setCountry}
          setStreetAddress={setStreetAddress}
          setCity={setCity}
          setStateProvince={setStateProvince}
          setPostCode={setPostCode}
          setLocationLatLng={setLocationLatLng}
          setMapEnabled={setMapEnabled}
        />
      </InfoPanelContainer>

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Quick Links and Files"
        information={MESSAGES.profile.edit.links.heading}
        hint={MESSAGES.profile.edit.links.description}
      >
        {orgID && profileQuickFileLinks?.length > 0 && (
          <ul
            key={`ProfileQuickFileLinks-${profileQuickFileLinks.length}`}
            className="space-y-3 pb-3"
          >
            {profileQuickFileLinks.map((quickFileLink, index) => {
              if (quickFileLink.file_id) {
                return (
                  <EditFileLink
                    key={`FileLink-${quickFileLink.order}`}
                    orgID={orgID}
                    setProfileFileLinks={setProfileQuickFileLinks}
                    profileFileLinks={profileQuickFileLinks}
                    fileLink={quickFileLink}
                    index={index}
                  />
                );
              }
              return (
                <EditQuickLink
                  key={`QuickLink-${quickFileLink.order}`}
                  setProfileQuickLinks={setProfileQuickFileLinks}
                  profileQuickLinks={profileQuickFileLinks}
                  quickLink={quickFileLink}
                  index={index}
                />
              );
            })}
          </ul>
        )}
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-4 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Add new quick link"
              icon={<PlusIcon />}
              onClick={() => handleAddQuickLinkClick()}
            />
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Add new File link"
              icon={<PlusIcon />}
              onClick={() => handleAddFileLinkClick()}
            />
          </div>
        </div>
      </InfoPanelContainer>

      <InfoPanelDivider />
      {/*  -------------------------------FULL SCREEN------------------------------- */}
      <div className="hidden lg:block">
        <InfoPanelContainer
          title="Contact exchange form"
          information={MESSAGES.profile.edit.contact}
          alert={
            <div>
              <h3 className="text-sm leading-5 font-medium text-gray-900">
                {MESSAGES.contact.trial.heading}
              </h3>
              <p className="mt-2 text-sm leading-5 text-gray-500">
                {MESSAGES.contact.trial.description}
              </p>
              <a
                href="https://help.tapt.io/en/articles/8449601-editing-and-enabling-two-way-contact-exchange-form"
                target="_blank"
                rel="noreferrer"
                className="mt-2 text-sm leading-5 font-medium text-brand-500 flex w-full"
              >
                Learn more
                <ArrowNarrowRightIcon className="ml-2 w-5 h-5" />
              </a>
            </div>
          }
        >
          <ToggleField
            label="Enable contact exchange form"
            enabled={contactExchangeEnabled}
            setter={setContactExchangeEnabled}
            description="Choose whether you would like to recieve contact details from people."
          />
        </InfoPanelContainer>
      </div>
      {/*  -------------------------------MOBILE------------------------------- */}
      <div className="lg:hidden pt-6">
        <ToggleField
          label="Enable contact exchange form"
          enabled={contactExchangeEnabled}
          setter={setContactExchangeEnabled}
          description="Choose whether you would like to recieve contact details from people."
        />
        <ModalNoSuccess
          isOpen={isDisplayOpen}
          setIsOpen={setIsDisplayOpen}
          buttonTitle="Learn more"
          buttonKind={BUTTON_KIND.COLOR_OMITTED_PURPLE}
          buttonSize="tiny"
          dialogTitle="Contact Exchange Form"
          dialogDescription="We recommend toggling this on. This will allow people you connect with to share their contact details back with you. This will help you capture leads using Tapt!"
        />
      </div>
      <InfoPanelDivider />
      <div>
        {profileResetError && (
          <ErrorAlert message="Something went wrong while resetting the profile" />
        )}
        {profileResetSuccess && (
          <SuccessAlert message="Profile has been reset" />
        )}
        <InfoPanelContainer
          title="Clear Profile"
          border=""
          information={MESSAGES.profile.edit.reset}
          padding="px-4 lg:py-5 flex lg:justify-end"
        >
          <Button
            kind={BUTTON_KIND.RED}
            buttonText="Clear Profile"
            loading={profileResetLoading}
            onClick={() => setIsClearProfileWarningOpen(true)}
          />
        </InfoPanelContainer>
      </div>
      <InfoPanelDivider />
      <div>
        {profileDeleteError && (
          <ErrorAlert message="Something went wrong while deleting the profile" />
        )}
        {profileDeleteSuccess && (
          <SuccessAlert message="Profile has been deleted" />
        )}
        <InfoPanelContainer
          title="Delete Profile"
          border=""
          information={MESSAGES.profile.edit.delete}
          padding="px-4 lg:py-5 pb-12 flex lg:justify-end"
        >
          <Button
            kind={BUTTON_KIND.RED}
            buttonText="Delete Profile"
            loading={profileDeleteLoading}
            onClick={() => setIsDeleteProfileWarningOpen(true)}
          />
        </InfoPanelContainer>
      </div>
      {status === 'unprinted' && (
        <>
          <InfoPanelDivider />

          <InfoPanelContainer
            title="Card Design"
            information={
              <span>
                Choose how you would like to design the card which belongs to
                this profile.
                <br />
                <br />
                <b>Note</b>: Tapt will always email design proofs before we
                start printing.
                <br />
                <br />
                If you would like to have a go at designing your own card
                template, download this Adobe Illustrator file:{' '}
                <a
                  href={DesignTemplate}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download card design template.
                </a>
              </span>
            }
            isRadio
          >
            {cardDesignError && (
              <>
                {cardDesign === 0 && (
                  <ErrorAlert message={MESSAGES.design.errorUpload} />
                )}
                {cardDesign === 2 && (
                  <ErrorAlert message={MESSAGES.design.errorDesign} />
                )}
              </>
            )}
            {orgID && (
              <CardDesign
                orgID={orgID}
                primaryC={primaryC}
                setPrimaryC={setPrimaryC}
                primaryM={primaryM}
                setPrimaryM={setPrimaryM}
                primaryY={primaryY}
                setPrimaryY={setPrimaryY}
                primaryK={primaryK}
                setPrimaryK={setPrimaryK}
                secondaryC={secondaryC}
                setSecondaryC={setSecondaryC}
                secondaryM={secondaryM}
                setSecondaryM={setSecondaryM}
                secondaryY={secondaryY}
                setSecondaryY={setSecondaryY}
                secondaryK={secondaryK}
                setSecondaryK={setSecondaryK}
                designFile={designFile}
                setDesignFile={setDesignFile}
                logoFile={logoFile}
                setLogoFile={setLogoFile}
                cardDesign={cardDesign}
                setCardDesign={setCardDesign}
                designNote={designNote}
                setDesignNote={setDesignNote}
                error={cardDesignError}
              />
            )}
          </InfoPanelContainer>

          <InfoPanelDivider />

          <InfoPanelContainer
            title="Variable fields"
            information={MESSAGES.profile.edit.print}
          >
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div className="mb-4">
                <ToggleField
                  label="First and last Name"
                  enabled={nameToggle}
                  setter={setNameToggle}
                  description="e.g. John Smith"
                />
              </div>
              <div className="mb-4">
                <ToggleField
                  label="Company name"
                  enabled={companyNameToggle}
                  setter={setCompanyNameToggle}
                  description="e.g. ACME Inc."
                />
              </div>
              <div className="mb-4">
                <ToggleField
                  label="Job title/Position"
                  enabled={jobTitleToggle}
                  setter={setJobTitleToggle}
                  description="e.g. Associate Consultant"
                />
              </div>
              <div className="mb-4">
                <ToggleField
                  label="Company website"
                  enabled={companyWebsiteToggle}
                  setter={setCompanyWebsiteToggle}
                  description="e.g. ACME.com"
                />
              </div>
              <div className="mb-4">
                <ToggleField
                  label="Mobile number"
                  enabled={mobileNumberToggle}
                  setter={setMobileNumberToggle}
                  description="e.g. 0400 111 222"
                />
              </div>
              <div className="mb-4">
                <ToggleField
                  label="Company phone number"
                  enabled={companyPhoneNumberToggle}
                  setter={setCompanyPhoneNumberToggle}
                  description="e.g. (03) 9800 5800"
                />
              </div>
              <div className="mb-4">
                <ToggleField
                  label="Email address"
                  enabled={emailToggle}
                  setter={setEmailToggle}
                  description="e.g. John.Smith@ACME.com"
                />
              </div>
              <div className="">
                <ToggleField
                  label="Company street address"
                  enabled={streetAddressToggle}
                  setter={setStreetAddressToggle}
                  description="e.g. 123 Collins Street, Melbourne, 3000"
                />
              </div>
            </div>
          </InfoPanelContainer>
        </>
      )}

      <InfoPanelDivider />
      <InfoPanelFooter>
        <div className="flex justify-end space-x-4">
          <Button
            buttonText="Discard changes"
            kind={BUTTON_KIND.WHITE}
            onClick={() => history.push('/')}
          />
          <Button buttonText="Save and close" onClick={handleClick} />
        </div>
      </InfoPanelFooter>
      <Modal
        isOpen={isEnableEditingOpen}
        setIsOpen={setIsEnableEditingOpen}
        dialogTitle="Do you want to notify the cardholder?"
        dialogDescription="By clicking send email, this person will receive an email notification to edit their profile."
        onSuccess={() => {
          setIsEnableEditingOpen(false);
          updateProfile(false, true);
        }}
        onCancel={() => {
          setIsEnableEditingOpen(false);
          updateProfile(false, false);
        }}
        successButtonText="Send Email"
        cancelButtonText="Don't send"
        buttonKind={BUTTON_KIND.CIRCULAR}
      />

      <ClearProfileWarningModal
        isOpen={isClearProfileWarningOpen}
        setIsOpen={setIsClearProfileWarningOpen}
        onSuccess={handleClearProfile}
      />
      <DeleteProfileWarningModal
        isOpen={isDeleteProfileWarningOpen}
        setIsOpen={setIsDeleteProfileWarningOpen}
        onSuccess={handleDeleteProfile}
      />
    </Layout>
  );
};

export default EditProfilePage;
