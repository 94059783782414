import { useState } from 'react';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/solid';
import Input from '@/components/Input';
import filesAPI from '@/api/files';
import { IProfileQuickFileLink } from '@/types/IProfile';
import FileUploadButtonComponent from '@/components/FileUploadButton';
import classNames from '@/helpers/classNames';

interface IEditFileLink {
  setProfileFileLinks: React.Dispatch<
    React.SetStateAction<IProfileQuickFileLink[]>
  >;
  profileFileLinks: IProfileQuickFileLink[];
  fileLink: IProfileQuickFileLink;
  index: number;
  orgID: number;
  disabled?: boolean;
  shared?: boolean;
}

const swapOrder = (
  result: IProfileQuickFileLink[],
  index: number,
  order: number,
) => {
  const newArray = result.map((item: IProfileQuickFileLink) => {
    if (item.order === order - 1) {
      return {
        ...item,
        order,
      };
    }
    if (item.order === order) {
      return {
        ...item,
        order: order - 1,
      };
    }
    return item;
  });
  const temp = newArray[index];
  newArray[index] = newArray[index - 1];
  newArray[index - 1] = temp;

  return newArray;
};

export default function EditFileLink({
  setProfileFileLinks,
  profileFileLinks,
  fileLink,
  index,
  orgID,
  disabled,
  shared = false,
}: IEditFileLink) {
  const [sizeError, setSizeError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  return (
    <li
      key={`ProfileFileLink-${index}`}
      className={classNames(
        'bg-white border overflow-hidden sm:rounded-md',
        sizeError ? 'border-red-500' : 'border-gray-300',
      )}
    >
      <div className="flex flex-col sm:flex-row">
        <div className="bg-gray-50 p-3 flex flex-row sm:flex-col justify-end space-x-2 sm:space-x-0 sm:justify-between order-2 sm:order-1">
          <button
            type="button"
            disabled={!!disabled || index === 0}
            onClick={() =>
              setProfileFileLinks((result) =>
                swapOrder(result, index, fileLink.order),
              )
            }
            className="bg-white border border-gray-300 rounded-md text-gray-500 transition hover:bg-gray-50 relative inline-flex items-center p-2 text-sm font-medium disabled:opacity-40"
          >
            <ArrowUpIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            disabled={!!disabled || index === profileFileLinks.length - 1}
            onClick={() =>
              setProfileFileLinks((result) =>
                swapOrder(result, index + 1, fileLink.order + 1),
              )
            }
            className="bg-white border border-gray-300 rounded-md text-gray-500 transition hover:bg-gray-50 relative inline-flex items-center p-2 text-sm font-medium disabled:opacity-40"
          >
            <ArrowDownIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="flex-grow order-1 sm:order-2">
          <div className="py-4 px-4 sm:px-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <Input
                  id={`"profileFileLinkTitle-${index}`}
                  label="File link title"
                  type="text"
                  value={profileFileLinks[index].title}
                  onChange={(title) => {
                    setProfileFileLinks((result: IProfileQuickFileLink[]) =>
                      result.map((item: IProfileQuickFileLink) => {
                        if (item.order === fileLink.order) {
                          return {
                            ...item,
                            title,
                          };
                        }
                        return item;
                      }),
                    );
                  }}
                  message="This will appear as a button on your profile"
                  disabled={disabled}
                />
              </div>
              <FileUploadButtonComponent
                fileFormat=".gif, .jpg, .jpeg, .png, .txt, .tiff, .tif, .mp3, .mpeg, .mpg, .wav, .pdf, .doc, .docx, .xls, .xlsx"
                title="Linked file"
                url={fileLink.file?.original_url}
                filename={fileLink.file?.file?.file_name}
                bottomText="Clicking the button will download this file"
                sizeError={sizeError}
                setSizeError={setSizeError}
                fileError={fileError}
                onFileSelected={(file) => {
                  setSizeError(false);
                  setFileError(false);
                  setFileLoading(true);
                  filesAPI
                    .createAndUploadFile(orgID, new File([file], file.name))
                    .then((res) => {
                      if (res?.data?.data.id) {
                        setProfileFileLinks((result: IProfileQuickFileLink[]) =>
                          result.map((item: IProfileQuickFileLink) => {
                            if (item.order === fileLink.order) {
                              return {
                                ...item,
                                file_id: res?.data?.data.id,
                                file: res?.data?.data,
                              };
                            }
                            return item;
                          }),
                        );
                      }
                    })
                    .catch(() => setFileError(true))
                    .finally(() => setFileLoading(false));
                }}
                loading={fileLoading}
                disabled={disabled}
              />
            </div>
            {!disabled && (
              <div className="pt-4">
                <button
                  type="button"
                  onClick={() => {
                    if (shared) {
                      setProfileFileLinks((result: IProfileQuickFileLink[]) =>
                        result.map((item: IProfileQuickFileLink) => {
                          if (item.order === fileLink.order) {
                            return {
                              ...item,
                              order: -1,
                            };
                          }
                          return item;
                        }),
                      );
                    } else {
                      setProfileFileLinks((result: IProfileQuickFileLink[]) =>
                        result.filter(
                          (item: IProfileQuickFileLink) =>
                            item.order !== fileLink.order,
                        ),
                      );
                    }
                  }}
                  className="appearance-none text-brand-500 text-sm font-medium"
                  disabled={disabled}
                >
                  Remove link
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}
