import { copyToClipboard } from '@/helpers/strings';
import { DuplicateIcon, LinkIcon } from '@heroicons/react/outline';
import { useState } from 'react';

type CopyableTextProps = {
  value: string;
  copy: string;
};

export function CopyableTextInput({ value, copy }: CopyableTextProps) {
  const [isCopied, setIsCopied] = useState(false);

  function copyValue() {
    copyToClipboard(copy, () => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 4000);
    });
  }

  return (
    <div className="mt-1 flex rounded-md shadow-sm">
      <div className="relative flex flex-grow items-stretch focus-within:z-10 rounded-l-md border border-gray-300">
        <div className="h-full flex items-center pl-2">
          <LinkIcon className="w-5 self-center text-gray-400" />
        </div>
        <input
          type="text"
          name="entity_id"
          id="entity_id"
          className="block w-full border-none rounded-none focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={value}
          disabled
        />
      </div>
      <button
        type="button"
        className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 p-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
        onClick={copyValue}
        disabled={isCopied}
      >
        {isCopied ? (
          'Copied!'
        ) : (
          <DuplicateIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        )}
      </button>
    </div>
  );
}
