import { useCallback, useEffect, useState } from 'react';
import { InformationCircleIcon, EyeIcon } from '@heroicons/react/outline';
import { Tab, Transition } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/solid';
import Layout from '@/components/Layout';
import TabList from '@/components/TabList';
import InputCheckbox from '@/components/InputCheckbox';
import Button, { BUTTON_KIND } from '@/components/Button';
import useAuth from '@/hooks/useAuth';
import useShopify from '@/hooks/useShopify';
import IProfile from '@/types/IProfile';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import profilesAPI from '@/api/profiles';
import MESSAGES from '@/constants/messages-en';
import classNames from '@/helpers/classNames';
import Pagination from '@/components/Pagination';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import ReorderMultistepPage from './OrderV2Page/ReorderExperience/ReorderMultistepPage';
import Copy from '@/components/Icons/Copy';
import { stringTruncate } from '@/helpers/strings';
import OrderMoreTab from '@/components/ShopPage/OrderMoreTab';
import OrdersTab from '@/components/ShopPage/OrdersTab';
import useAppState from '@/hooks/useAppState';
import LoadingAnimation from '@/components/LoadingAnimation';

function TierWarning({ shopUrl }: { shopUrl: string }) {
  return (
    <div className="flex items-start">
      <div className="flex-shrink-0">
        <InformationCircleIcon
          className="h-5 w-5 text-brand-500"
          aria-hidden="true"
        />
      </div>
      <div className="ml-3">
        <h3 className="text-sm text-brand-900">
          {MESSAGES.shop.order_more.description}{' '}
          <a
            href={`${shopUrl}/pages/pricing`}
            target="_blank"
            className="underline font-medium text-brand-500"
            rel="noreferrer"
          >
            here
          </a>
          .
        </h3>
      </div>
    </div>
  );
}

const tabs = [
  {
    name: 'Orders',
    value: 'orders',
    current: true,
  },
  {
    name: 'Order More',
    value: 'order-more',
    current: false,
  },
  {
    name: 'Replacement Cards',
    value: 'replacement-cards',
    current: false,
  },
];

export default function ShopPage() {
  const { shopTab, selectShopTab } = useAppState();
  const { orgID } = useAuth();
  const { taptReplacement, proceedToCheckout } = useShopify();

  const [tab, setTab] = useState(tabs[0].value);
  const [initialLoading, setInitialLoading] = useState(true);
  const [clipboardCopy, setClipboardCopy] = useState(false);
  const [profiles, setProfiles] = useState<IProfile[] | undefined>(undefined);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [isAddDetails, setIsAddDetails] = useState(false);
  const [units, setUnits] = useState('1');
  const [totalProfiles, setTotalProfiles] = useState(0);
  const [selectedProfiles, setSelectedProfiles] = useState<IProfile[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(
    tabs[shopTab] ? shopTab : 0,
  );

  const taptReplacementBasePrice = taptReplacement
    ? Number(taptReplacement.variants[0].priceV2.amount)
    : 49.95;
  const shopUrl =
    (import.meta.env.VITE_SHOP_BASE_URL as string) || 'https://tapt.test:3001';

  const goToCheckout = () => {
    if (!taptReplacement) return;

    const attributes = selectedProfiles.map((profile) => ({
      first_name: profile.first_name,
      last_name: profile.last_name,
      url: `${window.location.origin}/view-profile/${profile.profile_hash}`,
    }));

    proceedToCheckout(taptReplacement.variants[0].id, selectedProfiles.length, [
      {
        key: '_cardProfiles',
        value: JSON.stringify(attributes),
      },
    ]);
  };

  const listAllProfiles = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      initial = false,
    ) => {
      const res = await profilesAPI.listProfiles({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
      });

      if (!initial) {
        setProfiles(res.data.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }

      if (initial) {
        setTotalProfiles(res.data.paging.total_entries);
      }

      return res.data;
    },
    [order, orgID, page, pageSize, search, sort],
  );

  function handleCheckItem(profile: IProfile) {
    if (selectedProfiles.includes(profile)) {
      setSelectedProfiles(selectedProfiles.filter((e) => e !== profile));
    } else {
      setSelectedProfiles([...selectedProfiles, profile]);
    }
  }

  useEffect(() => {
    if (initialLoading && orgID) {
      listAllProfiles(page, pageSize, sort, order, search, true);
    }
    setInitialLoading(false);
  }, [
    initialLoading,
    listAllProfiles,
    order,
    orgID,
    page,
    pageSize,
    search,
    sort,
  ]);

  useEffect(() => {
    setTab(tabs[shopTab].value);
    setSelectedIndex(shopTab);
  }, [shopTab]);

  return (
    <Layout
      pageName={isAddDetails ? 'Reorder ' : 'Shop'}
      rightTitle={
        <>
          {tab === 'replacement-cards' && (
            <div className="rounded-md bg-brand-100 text-white p-3 md:mt-0 absolute right-0 w-2/3 hidden lg:block">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-brand-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-brand-900">
                    {MESSAGES.shop.info.heading}
                  </h3>
                  <p className="mt-2 text-sm leading-5 text-brand-900">
                    {MESSAGES.shop.info.description}
                  </p>
                </div>
              </div>
            </div>
          )}
          {tab === 'order-more' && (
            <div className="rounded-md bg-brand-100 text-white p-3 md:mt-0 absolute right-0 w-2/3 hidden lg:block">
              <TierWarning shopUrl={shopUrl} />
            </div>
          )}
        </>
      }
    >
      <Transition show={!isAddDetails}>
        <Tab.Group
          selectedIndex={selectedIndex}
          onChange={(index: number) => {
            setSelectedIndex(index);
            setTab(tabs[index].value);
            selectShopTab(index);
          }}
        >
          <div className="flex flex-col items-start md:items-center justify-between w-full lg:border-b border-gray-200 space-y-4 mt-2 lg:mt-0">
            {tab === 'order-more' && (
              <div className="rounded-md bg-brand-100 text-white p-3 md:mt-0 right-0 lg:hidden">
                <TierWarning shopUrl={shopUrl} />
              </div>
            )}
            {tab === 'replacement-cards' && (
              <div className="rounded-md bg-brand-100 text-white p-3 md:mt-0 right-0 lg:hidden">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon
                      className="h-5 w-5 text-brand-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-brand-900">
                      {MESSAGES.shop.info.heading}
                    </h3>
                    <p className="mt-2 text-sm leading-5 text-brand-500">
                      {MESSAGES.shop.info.description}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="pt-4 md:pt-0 w-full md:w-auto self-start">
              <TabList tabs={tabs} value={tab} />
            </div>
          </div>
          <Tab.Panels>
            <Tab.Panel className="outline-none">
              <OrdersTab />
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              <OrderMoreTab />
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              <div className="py-8">
                <div className="pb-8 flex flex-col space-y-3 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-between">
                  <div className="text-sm leading-5" />
                  {orgID && orgID !== -1 && (
                    <div className="flex flex-shrink-0 space-x-3">
                      <Search
                        id={`ProfileList-${page}-${pageSize}-${sort}-${order}-active-${search}`}
                        search={search}
                        setSearch={setSearch}
                        fetchQuery={(searchQuery) =>
                          listAllProfiles(
                            page,
                            pageSize,
                            sort,
                            order,
                            searchQuery,
                          )
                        }
                      />
                      <Sort
                        id={`ProfileList-${page}-${pageSize}-${sort}-${order}-active-${search}`}
                        options={[
                          {
                            sort: 'date',
                            order: 'desc',
                            label: 'Date created (Newest first)',
                          },
                          {
                            sort: 'date',
                            order: 'asc',
                            label: 'Date created (Oldest first)',
                          },
                          {
                            sort: 'first_name',
                            order: 'asc',
                            label: 'Name (A-Z)',
                          },
                          {
                            sort: 'first_name',
                            order: 'desc',
                            label: 'Name (Z-A)',
                          },
                        ]}
                        sort={sort}
                        setSort={setSort}
                        order={order}
                        setOrder={setOrder}
                        fetchQuery={(sortQuery, orderQuery) =>
                          listAllProfiles(
                            page,
                            pageSize,
                            sortQuery,
                            orderQuery,
                            search,
                          )
                        }
                      />
                    </div>
                  )}
                </div>
                {profiles && profiles.length > 0 ? (
                  <div className="flex flex-col">
                    <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                      <div className="block w-full lg:px-8">
                        <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8 overflow-hidden">
                          <div className="lg:hidden flex items-center px-4 py-2 sm:px-6 bg-white border-b border-gray-200">
                            <InputCheckbox
                              id="select-all"
                              label=""
                              value={selectAll}
                              onChange={(value) => {
                                setSelectAll(value);
                                if (value) {
                                  setSelectedProfiles(profiles || []);
                                } else {
                                  setSelectedProfiles([]);
                                }
                              }}
                            />
                          </div>
                          <ul
                            className={classNames(
                              isLoading ? 'opacity-40' : '',
                              'divide-y divide-gray-200',
                            )}
                          >
                            <li
                              key="ProfileListAdminHeader"
                              className="hidden lg:block"
                            >
                              <div className="bg-gray-50">
                                <div className="flex items-center px-4 py-2 sm:px-6">
                                  <div className="min-w-0 flex-1 flex items-start md:items-center">
                                    <div className="w-4">
                                      <InputCheckbox
                                        id="select-all"
                                        label=""
                                        value={selectAll}
                                        onChange={(value) => {
                                          setSelectAll(value);
                                          if (value) {
                                            setSelectedProfiles(profiles || []);
                                          } else {
                                            setSelectedProfiles([]);
                                          }
                                        }}
                                      />
                                    </div>

                                    <div className="min-w-0 flex-1 md:grid md:grid-cols-[1fr_2fr_4fr_1fr_auto] md:gap-4 pl-8">
                                      <p className="text-sm md:col-span-2 font-medium text-gray-900">
                                        NAME & ID
                                      </p>
                                      <p className="mt-2 md:mt-0 md:col-span-2 hidden xl:block text-sm font-medium text-gray-900">
                                        TITLE &amp; EMAIL
                                      </p>
                                      <p className="mt-2 md:mt-0 text-sm font-medium text-gray-900">
                                        PROFILE
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            {profiles.map((item) => (
                              <li key={item.id}>
                                <div className="block bg-white hover:bg-gray-50">
                                  <div className="flex items-center px-4 py-4 sm:px-6">
                                    <div className="min-w-0 flex-1 flex items-start md:items-center">
                                      <div className="w-4">
                                        <InputCheckbox
                                          id={`Checkbox-${item.id}`}
                                          label=""
                                          value={selectedProfiles.includes(
                                            item,
                                          )}
                                          onChange={() => handleCheckItem(item)}
                                        />
                                      </div>
                                      {/* ---------------------------------------FULL SCREEN ---------------------------------------*/}
                                      <div className="min-w-0 flex-1 md:grid md:grid-cols-[1fr_2fr_4fr_1fr_auto] md:gap-4 pl-8">
                                        <div className="hidden lg:block col-span-4 md:col-span-2 relative">
                                          <div className="flex flex-col md:flex-row relative items-start md:items-center">
                                            <div className="flex-shrink-0">
                                              {item.photo?.thumb_url ? (
                                                <img
                                                  className="h-10 w-10 rounded-full"
                                                  src={item.photo?.thumb_url}
                                                  alt=""
                                                />
                                              ) : (
                                                <div className="hidden lg:block h-10 w-10 rounded-full bg-gray-200 overflow-hidden text-center">
                                                  <UserIcon className="h-10 w-10 mt-1 rounded-full text-gray-600" />
                                                </div>
                                              )}
                                            </div>
                                            <div className="min-w-0 mt-0 ml-12 md:mt-0 md:ml-4">
                                              <p className="text-sm font-medium text-gray-900 truncate">
                                                {item.first_name}{' '}
                                                {item.last_name}
                                              </p>
                                              <p className="flex items-center text-sm font-medium text-gray-500">
                                                ID:&nbsp;
                                                <button
                                                  type="button"
                                                  className="appearance-none text-brand-600 text-sm font-medium flex items-center"
                                                  onClick={() => {
                                                    if (navigator?.clipboard) {
                                                      navigator.clipboard
                                                        .writeText(
                                                          item.profile_hash,
                                                        )
                                                        .then(() =>
                                                          setClipboardCopy(
                                                            true,
                                                          ),
                                                        )
                                                        .finally(() =>
                                                          setTimeout(() => {
                                                            setClipboardCopy(
                                                              false,
                                                            );
                                                          }, 2000),
                                                        );
                                                    } else {
                                                      document.execCommand(
                                                        'copy',
                                                        true,
                                                        item.profile_hash,
                                                      );
                                                      setClipboardCopy(true);
                                                      setTimeout(() => {
                                                        setClipboardCopy(false);
                                                      }, 2000);
                                                    }
                                                  }}
                                                >
                                                  {clipboardCopy
                                                    ? 'Copied!'
                                                    : stringTruncate(
                                                        item.profile_hash,
                                                      )}
                                                  <Copy className="h-4 w-4 ml-1" />
                                                </button>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="md:mt-0 col-span-4 xl:col-span-2 xl:flex flex-col justify-center">
                                          <p className="hidden lg:block text-sm text-gray-900 truncate">
                                            {item.job_title}
                                          </p>
                                          <p className="hidden lg:block text-sm text-gray-500 truncate">
                                            {item.email}
                                          </p>
                                        </div>

                                        {/* ---------------------------------------MOBILE SCREEN ---------------------------------------*/}
                                        <div className="lg:hidden flex flex-col md:flex-row relative items-start">
                                          <div className="mt-0 flex space-x-1 md:mt-0 md:ml-4">
                                            <div className="flex-shrink-0">
                                              {item.photo?.thumb_url ? (
                                                <img
                                                  className="h-10 w-10 rounded-full"
                                                  src={item.photo?.thumb_url}
                                                  alt=""
                                                />
                                              ) : (
                                                <div className="h-10 w-10 rounded-full bg-gray-200 overflow-hidden text-center">
                                                  <UserIcon className="h-10 w-10 mt-1 rounded-full text-gray-600" />
                                                </div>
                                              )}
                                            </div>
                                            <div className="min-w-0 mt-0 ml-12 md:mt-0">
                                              <p className="text-sm font-medium text-gray-900 ml-4 truncate">
                                                {item.first_name}{' '}
                                                {item.last_name}
                                              </p>
                                              <p className="text-sm text-gray-900 truncate ml-4">
                                                {item.job_title}
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        {/* ---------------------------------------BUTTONS ---------------------------------------*/}
                                        <div className="mt-2 lg:mt-0 col-span-1">
                                          <div className="flex">
                                            <Button
                                              className="pl-5"
                                              buttonText=""
                                              kind={BUTTON_KIND.COLOR_OMITTED}
                                              size="xsmall"
                                              icon={
                                                <EyeIcon className="text-gray-900" />
                                              }
                                              href={`/view-profile/${item.profile_hash}`}
                                              external
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                          {isLoading && (
                            <div className="absolute text-gray-500 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                              <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="py-32">
                    <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                      {MESSAGES.profile.list.empty.heading}
                    </h3>
                    <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                      {MESSAGES.profile.list.empty.description}
                    </p>
                  </div>
                )}
              </div>
              {orgID && orgID !== -1 && (
                <Pagination
                  id={`ProfileList-${page}-${pageSize}-${sort}-${order}-active-${search}`}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  fetchQuery={(pageQuery, pageSizeQuery) => {
                    return listAllProfiles(
                      pageQuery,
                      pageSizeQuery,
                      sort,
                      order,
                      search,
                    );
                  }}
                  setIsLoading={setIsLoading}
                />
              )}

              <div className="block" aria-hidden="true">
                <div className="py-8" />
              </div>
              <InfoPanelFooter
                className={selectedProfiles.length > 0 ? '' : '!-bottom-28'}
              >
                <p className="text-sm leading-5 text-gray-500 mb-1 sm:hidden">
                  {`${selectedProfiles.length}x replacement`} $
                  {(selectedProfiles.length * taptReplacementBasePrice).toFixed(
                    2,
                  )}
                </p>
                <div className="flex flex-row-reverse flex-nowrap space-x-4">
                  <Button
                    onClick={() => goToCheckout()}
                    buttonText="Order Selection"
                  />
                </div>
              </InfoPanelFooter>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Transition>
      <ReorderMultistepPage
        totalProfiles={totalProfiles}
        isOpen={isAddDetails}
        setIsOpen={setIsAddDetails}
        orgID={orgID}
        units={units}
        setUnits={setUnits}
      />
    </Layout>
  );
}
