import filesAPI from '@/api/files';
import IFile from '@/types/IFile';
import { BASE_URL } from '../config';

const fileHelper = {
  downloadFile: async (
    file: IFile | File | undefined | null,
    callback?: {
      onSuccess?: () => void;
      onError?: () => void;
      finally?: () => void;
    },
  ) => {
    try {
      const a = document.createElement('a');

      if (file) {
        if (file instanceof File) {
          const blob = new Blob([file]);
          a.href = window.URL.createObjectURL(blob);
          a.download = file?.name || 'file';
        } else {
          const response = await filesAPI.downloadFile(
            `${BASE_URL}${file?.original_url}`,
          );
          a.href = window.URL.createObjectURL(response.data);
          a.download = file?.file?.file_name || 'file';
        }
        a.click();
        callback?.onSuccess && callback.onSuccess();
      } else {
        callback?.onError && callback.onError();
      }
    } catch (e) {
      callback?.onError && callback.onError();
    } finally {
      callback?.finally && callback.finally();
    }
  },
};

function downloadBlob(blob: Blob, filename: string) {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
}

function parseContentDisposition(
  contentDisposition: string,
  defaultFilename: string,
) {
  const [, rawFilename] = contentDisposition.split('filename=');
  const filename = (rawFilename || defaultFilename)
    .trim()
    .replace(/^"|"$/g, '');

  return filename;
}

export {
  fileHelper as default,
  fileHelper,
  downloadBlob,
  parseContentDisposition,
};
