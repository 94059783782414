import React from 'react';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '../Icons/ArrowRightIcon';
import profileData from './data';

const ListWithLinkRestricted = () => {
  return (
    <div>
      <div className="blur-filter">
        <h4 className="block pb-3 text-2xl font-medium leading-8 text-grey-900">
          Top performing profile
        </h4>
        <div className="divide-y divide-gray-100 md:pt-4">
          <div>
            <Link to="/" className="relative flex justify-between gap-x-6">
              <div className="flex gap-x-4">
                <img
                  className="flex-none w-12 h-12 rounded-full bg-gray-50"
                  src={profileData.image}
                  alt=""
                />
                <div className="flex flex-col justify-center">
                  <span className="block text-sm font-medium text-gray-900">
                    {profileData.name}
                  </span>
                  <span className="text-sm font-normal text-gray-500">
                    {profileData.role}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-x-4">
                <ArrowRightIcon />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <span className="absolute inset-0 flex items-center justify-center w-full h-full text-xl font-medium leading-6 text-pdfview-text">
        <span className="text-brand-500">tapt+</span> feature only
      </span>
    </div>
  );
};

export default ListWithLinkRestricted;
