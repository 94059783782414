import {
  adjustToLocalTime,
  formatDateToLocalISOString,
} from '@/helpers/strings';
import { cx } from '@emotion/css';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/outline';
import type { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

const defaultOptions: ApexOptions = {
  chart: {
    sparkline: {
      enabled: true,
    },
    toolbar: {
      show: false,
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      shadeIntensity: 1,
    },
  },
  plotOptions: {
    area: {
      fillTo: 'end',
    },
  },
  theme: {
    monochrome: {
      enabled: true,
      color: '#873CFF',
    },
  },
  tooltip: {
    style: {
      fontSize: '14px',
    },
  },
};

const defaultHeight = 70;

export const formatNumber = (num: number) => {
  const formatter = new Intl.NumberFormat('en-AU');
  return formatter.format(num);
};

export const formatDateFilter = (from: string, to: string) => {
  try {
    const fromDate = new Date(from);
    const toDate = new Date(to);

    if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
      throw new Error('Invalid date');
    }

    if (from === to) {
      fromDate.setHours(0, 0);
      toDate.setHours(23, 59);

      return {
        from: [
          formatDateToLocalISOString(fromDate),
          fromDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }),
        ].join(' '),
        to: [
          formatDateToLocalISOString(toDate),
          toDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }),
        ].join(' '),
      };
    }

    return {
      from: formatDateToLocalISOString(fromDate),
      to: formatDateToLocalISOString(toDate),
    };
  } catch {
    return { from, to };
  }
};

export type EventPlotData = {
  total: number;
  change: number;
  series: ApexOptions['series'];
  labels: ApexOptions['labels'];
};

type EventProps = {
  dateFilter: { from: string | undefined; to: string | undefined };
  height?: number;
  title: string;
  total?: number;
  percent?: number;
  data: {
    series: EventPlotData['series'];
    labels: EventPlotData['labels'];
  };
};

function Events({
  data: { series = [], labels = [] },
  height,
  title,
  dateFilter: { from = 'start', to = 'end' },
  total,
  percent,
}: EventProps) {
  const dataPresent =
    typeof series[0] === 'object' && Array.isArray(series[0].data)
      ? series[0].data.length > 1 && labels.length > 1
      : false;

  const { from: placeholderFrom, to: placeholderTo } = formatDateFilter(
    from,
    to,
  );

  const placeholderData: EventPlotData = {
    change: 0,
    labels: [placeholderFrom, placeholderTo],
    series: [{ data: [0, 0] }],
    total: 0,
  };

  const currentTotal = dataPresent ? total : placeholderData.total;
  const currentPercent = dataPresent ? percent : placeholderData.change;

  const adjustedLabels = labels.map((label) => adjustToLocalTime(label));

  return (
    <div className="relative">
      <div className="flex flex-col min-h-full justify-between">
        <div className="flex flex-wrap">
          <div className="flex min-w-full justify-between items-center flex-wrap">
            <div className="font-poppins text-gray-500 md:text-gray-700">
              {title}
            </div>
            {currentPercent !== undefined && (
              <div>
                <div
                  className={cx(
                    'px-2.5 py-0.5 font-medium flex flex-row items-center text-xs rounded-full mr-2 md:mr-0',
                    currentPercent > 0
                      ? 'bg-emerald-100 text-emerald-800'
                      : currentPercent === 0
                      ? 'bg-gray-100 text-gray-900'
                      : 'bg-red-100 text-red-800',
                  )}
                >
                  {currentPercent !== 0 && (
                    <span
                      className={cx(
                        'pr-1',
                        currentPercent > 0
                          ? 'text-emerald-500'
                          : 'text-red-500',
                      )}
                    >
                      {currentPercent > 0 ? (
                        <ArrowUpIcon width={10} height={10} strokeWidth={4} />
                      ) : (
                        <ArrowDownIcon width={10} height={10} strokeWidth={4} />
                      )}
                    </span>
                  )}
                  {currentPercent}%
                </div>
              </div>
            )}
          </div>
          {currentTotal !== undefined && (
            <div className="font-poppins text-gray-700 font-medium text-2xl">
              {formatNumber(currentTotal)}
            </div>
          )}
        </div>
        {dataPresent ? (
          <Chart
            height={height || defaultHeight}
            options={{ ...defaultOptions, labels: adjustedLabels }}
            series={series}
            type="area"
          />
        ) : (
          <Chart
            height={height || defaultHeight}
            options={{
              ...defaultOptions,
              labels: placeholderData.labels,
              tooltip: {
                ...defaultOptions.tooltip,
                enabled: false,
              },
            }}
            series={placeholderData.series}
            type="area"
          />
        )}
      </div>
    </div>
  );
}

export default Events;
