import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { UserGroupIcon } from '@heroicons/react/outline';
import SearchableInput, { Item } from '../SearchableInput';
import { IOrganisationGroup } from '@/types/IOrganisation';
import { IListPaging } from '@/types/IList';

export type Props = {
  fetchData?: (groupIds: number[]) => void;
  listGroups: ({
    search,
    orgID,
  }: {
    search?: string;
    orgID?: number;
  }) => Promise<
    AxiosResponse<{
      data: IOrganisationGroup[];
      paging: IListPaging;
    }>
  >;
};

const GroupFilter: React.FC<Props> = ({ fetchData, listGroups }) => {
  const [groups, setGroups] = useState<Item[] | undefined>(undefined);

  const mapGroupsToItems = useCallback(
    (remoteGroups: IOrganisationGroup[]): Item[] => {
      return remoteGroups.map(({ id, name }) => {
        const title = name === null ? 'No name' : name.trim();

        return {
          id,
          title,
        };
      });
    },
    [],
  );

  useEffect(() => {
    const getProfiles = async () => {
      const {
        data: { data: remoteGroups },
      } = await listGroups({});

      setGroups(mapGroupsToItems(remoteGroups));
    };

    getProfiles();
  }, [mapGroupsToItems, listGroups]);

  const searchItems = async (query: string) => {
    const {
      data: { data: remoteGroups },
    } = await listGroups({ search: query });

    return mapGroupsToItems(remoteGroups);
  };

  return (
    <SearchableInput
      key="group-filter-analytics"
      icon={<UserGroupIcon className="w-4 h-4 mr-2" />}
      title="Groups"
      items={groups}
      fetchData={fetchData}
      searchItems={searchItems}
      emptyMessage="No groups available"
    />
  );
};

export default GroupFilter;
