const MESSAGES = {
  account: {
    delete: {
      heading: 'Are you sure you want to delete this account?',
      description: (email: string): string =>
        `${email} will no longer have access to your Tapt organisation.`,
      descriptionEditor: (email: string): string =>
        `${email} will no longer have access to their groups and this dashboard.`,
      success: 'The account was deleted successfully',
    },
    reset: {
      heading: 'Account security',
      description: 'This will send a password reset email to the user.',
      success: 'The reset password email was sent successfully',
    },
    settings: {
      success: 'Account settings were updated successfully',
    },
    organisation: {
      heading: 'Move account',
      description: 'This will move the account to the selected organisation.',
      success: 'The account was moved successfully',
    },
  },
  activationKey: {
    create: {
      heading: 'Create custom keys',
      success: 'Keys were added successfully',
    },
    delete: {
      heading: 'Are you sure you want to delete this activation key?',
      headingMultiple: 'Are you sure you want to unlink these activation keys?',
      success: (activationKeyID: number): string =>
        `Key #${activationKeyID} was deleted successfully`,
      successMultiple: 'Activation keys were deleted successfully',
    },
    list: {
      empty: {
        heading: 'No results',
        description:
          'There are no activation keys which match your search. Make sure you are entering the full key string or card ID.',
      },
    },
  },
  analytics: {
    list: {
      empty: {
        heading: 'No results',
        description:
          'Start using your Tapt cards to see analytics for your profiles.',
      },
    },
  },
  card: {
    create: {
      heading: 'Create new cards',
      success: 'Cards were added successfully',
    },
    delete: {
      heading: 'Are you sure you want to delete this card?',
      headingMultiple: 'Are you sure you want to delete these cards?',
      description:
        'This card ID will no longer be valid. If this card ID is programmed into an NFC chip, then the NFC will need to be reprogrammed.',
      descriptionMultiple:
        'The selected card IDs will no longer be valid. If these card IDs are programmed into an NFC chip, then the NFC will need to be reprogrammed.',
      success: (cardID: number): string =>
        `Card #${cardID} was deleted successfully`,
      successMultiple: 'Cards were deleted successfully',
    },
    export: {
      success: 'Cards were exported successfully',
    },
    import: {
      heading: 'Import a CSV to bulk update card records',
      description:
        'This is primarily used to bulk-connect profiles to cards. This cannot be used to generate new Card IDs. Any unmatched Card IDs will be ignored from the import.',
      success: 'CSV file was imported successfully',
    },
    list: {
      empty: {
        heading: 'No results',
        description:
          'There are no cards in this category or matching your search query.',
      },
    },
    unlink: {
      heading: 'Are you sure you want to unlink this card?',
      description:
        'This card ID will no longer link to this cardholder profile. You can always re-link the card by connecting it to a new profile.',
    },
    update: {
      success: (cardID: number): string =>
        `Card #${cardID} was updated successfully`,
      successMultiple: 'Cards updated successfully',
    },
  },
  contact: {
    exchange: {
      description:
        'What information would you like to collect from your new connections?',
    },
    sms: {
      description:
        'Give your new contacts a live link back to your digital profile.',
    },
    list: {
      empty: {
        heading: 'No results',
        description:
          'You can activate a lead-generation form on the card profile settings. Contacts collected via that form will be stored here.',
      },
    },
    trial: {
      heading: 'We recommend toggling this on ',
      description:
        'This will allow the people to share their contact details back with you. This will help you capture leads using Tapt!',
    },
    info: {
      heading: 'Ensure you have enabled contact exchange.',
      description:
        'These are the contacts you have received if contact exchange is enabled on your profile.',
    },
  },
  design: {
    errorUpload: 'Please upload a logo or design file',
    errorDesign: 'Please provide some design specifcations for your design',
    existing:
      "If you have already sent a design file to us, let us know and we'll reuse it",
    help: "Give us some design specifications and we'll design a card for you",
    note: 'I want the front of the card to be the primary colour and the back of the card to be the secondary colour',
    specifications:
      'This information will be used by our Tapt designers to design your card. Provide as little or as much information as you can. We will send you a design proof before printing.',
    upload:
      "Upload an existing business card design file and we'll do the rest",
  },
  error: {
    auth: 'Invalid email or password',
    email: 'Please enter a valid email',
    companyName: 'Please enter a company name',
    companyPhoneNumber: 'Please enter a company phone number',
    companyWebsite: 'Please enter a company website',
    firstName: 'Please enter a first name',
    fullName: 'Please enter first name and last name',
    generic: 'Something went wrong. Please try again later',
    jobTitle: 'Please enter a job title',
    lastName: 'Please enter a last name',
    mobileNumber: 'Please enter a mobile number',
    organisationName: 'Please enter an organisation name',
    password: 'Please enter a valid password',
    passwordConfirm: 'The passwords do not match',
    streetAddress: 'Please enter a street address',
    groupName: 'Group name cannot be blank.',
    tandc: 'Please accept the Terms & Conditions',
    url: 'Please enter a valid URL',
    appInviteError:
      'Unable to invite as there is no email associated with this profile. Please add an email and try again.',
  },
  organisation: {
    delete: {
      heading: 'Are you sure you want to delete this organisation?',
      description: 'You will no longer have access to your Tapt organisation.',
      success: 'The organisation was deleted successfully',
    },
    invite: {
      error: 'This user has already been invited to this organisation',
      resend: {
        success: (email: string): string =>
          `The invitation for ${email} was resent successfully`,
        error: 'Failed to resend the invitation. Please try again later.',
      },
      delete: {
        heading: 'Are you sure you want to delete this invitation?',
        description: (email: string): string =>
          `${email} will no longer have access to your Tapt organisation.`,
        success: (email: string): string =>
          `The invitation for ${email} was deleted successfully`,
        error: 'Failed to delete the invitation. Please try again later.',
      },
      subtext: {
        admin:
          'Admins have full access to the entire dashboard and all other accounts.',
        editor:
          'Group managers have limited access to relevant data of appointed groups.',
      },
    },
    list: {
      empty: {
        heading: 'No results',
        description: 'There are no organisations which match your search.',
      },
    },
    settings: 'Organisation settings were updated successfully',
    sms: 'SMS settings were updated successfully',
    user: 'The user was invited successfully',
  },
  order: {
    delete: {
      heading: 'Are you sure you want to delete this selected order(s)?',
      description: 'You will no longer have access to your Tapt order(s).',
      success: 'The order(s) was deleted successfully',
    },
    empty: {
      heading: 'No results',
      description:
        'There are no orders which match your search at this time. Please check back later.',
    },
  },
  password: {
    success: 'Your password has been changed, please wait to be redirected',
    error: {
      validation: 'Please ensure your email and verification code is correct',
      complex:
        'Please enter a password at least eight characters long with a capital letter and a number',
      match: 'Please ensure your password confirmation matches your password',
    },
  },
  profile: {
    activate: {
      heading: 'Activate a product',
      description:
        'Enter the activation key from your Shopify order email or on the screen when you tap your physical card on your phone.',
      notFound: 'Invalid activation key.',
      usedKey: 'This activation key has already been used.',
    },
    delete: {
      heading: 'Are you sure you want to delete this profile?',
      headingMultiple: 'Are you sure you want to delete these profiles?',
      description: 'This profile ID will no longer be valid.',
      descriptionMultiple: 'The selected profile IDs will no longer be valid.',
      success: (profileID: number): string =>
        `Profile #${profileID} was deleted successfully`,
      successMultiple: 'Cards were deleted successfully',
      successWithName: (name: string): string =>
        `Profile ${name} was deleted successfully`,
    },
    edit: {
      company:
        "You can manage multiple profiles at once by selecting the given profiles in 'My Profiles' and clicking 'Edit shared info'",
      confirmHeading: 'Are you ready to edit your profile?',
      confirmDescription:
        'You have been granted one time access to edit your contact details. Please make sure you have all relevent information (including any files, custom links and social profiles) ready before proceeding.',
      contact:
        'Choose whether you would like a lead capture form on your profile page. You can edit the form fields in your settings.',
      reset: 'This will clear or delete all details connected to this profile',
      delete: 'Delete this profile from your organisation.',
      enable: {
        heading:
          'Enable cardholder editing to give the cardholder one-time access to edit their contact details.',
        description:
          'After making changes to their profile, their card will be automatically moved to ‘Active’ Cards. Their card can still be used to pass on contact details when cardholder editing is enabled.',
      },
      heading: '',
      links: {
        heading:
          'Custom company links such as lead generation forms or downloadable files are a great use of this feature.',
        description:
          "You can create quick links for multiple profiles by selecting the given profiles in 'My Profiles' and clicking 'Edit shared info'.",
      },
      personalInfo:
        'This information will appear on your profile page when someone taps your card. It will also be referenced when printing personal information onto your physical card.',
      print:
        'Let us know which variable information you would like printed on each card. We will use cardholder profile information to populate these fields when we run the print. Tapt will always email design proofs before printing',
      social: (
        <>
          <p>These will be displayed as icons on your profile.</p>
          <p>
            <b>Hint:</b> You can visually mark social links on your digital
            profile as a personal account by selecting the toggle button.
          </p>
        </>
      ),
      success: 'Profile was updated successfully',
      successMultiple: 'Profiles were updated successfully',
    },
    clear: {
      success: 'Profile was cleared successfully',
    },
    export: {
      success: 'Profiles were exported successfully',
    },
    import: {
      heading: 'Import a CSV file to edit profiles',
      description:
        'Make bulk edits to cardholder profiles by importing a CSV file. Existing details will automatically be replaced by the details in your imported file.',
    },
    list: {
      empty: {
        heading: 'No results',
        description:
          'There are no card profiles in this category or matching your search query.',
      },
    },
    organisation: {
      heading: 'Move profile',
      headingMultiple: 'Move profiles',
      description: 'This will move the profile to the selected organisation.',
      descriptionMultiple:
        'This will move the profiles to the selected organisation.',
      success: 'The profile was moved successfully',
      successMultiple: 'The profiles were moved successfully',
    },
    view: {
      header: {
        logoAlt: 'Tapt logo',
        logoLinkTitle: 'Go back to Tapt Home',
      },
      footer: {
        copyrightMessage: `© ${new Date().getFullYear()} Tapt by Hatch`,
      },
      addToContactsCtaLabel: 'Add to contacts',
      editProfileCtaLabel: 'Edit profile',
      shareYourDetailsCtaLabel: 'Share your details',
    },
  },
  file: {
    error: {
      nofile: 'Please enter a valid file',
    },
  },
  group_editors: {
    list: {
      empty: {
        heading: 'No results',
        description: 'There are no group editors which match your search.',
      },
    },
  },
  groups: {
    list: {
      empty: {
        heading: 'No results',
        description: {
          admin:
            'You can create a group by clicking the \u2018Create Group\u2018 button.',
          editor: 'You have not been made manager of any groups yet.',
        },
      },
    },
  },
  mfa: {
    code: {
      invalid: 'Code is invalid. Please enter a 6 digit code.',
      resend: 'Code has been resent.',
    },
    token: {
      invalid:
        'Verification code has expired. Please go back and log in again.',
    },
  },
  shop: {
    info: {
      heading: 'How to order replacement products.',
      description: 'Select profiles to order replacements cards in bulk.',
    },
    order_more: {
      description:
        'Please be aware that additional Tapt purchases may result in a change to the pricing of your platform fee. Learn more about the platform fee tiers',
    },
  },
  shopify: {
    info: {
      heading: 'Request label for orders with ‘Unavailable’ badge',
      description:
        'Order types: multi-location, international, or 60+ card orders.',
    },
  },
};

export { MESSAGES as default, MESSAGES };
