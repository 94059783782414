import ErrorAlert from '@/components/ErrorAlert';
import Input from '@/components/Input';
import MESSAGES from '@/constants/messages-en';
import Skeleton from 'react-loading-skeleton';

type GroupInfoFormProps = {
  isLoading?: boolean;
  groupName: string;
  groupNameError?: boolean;
  subheading: string;
  disabled?: boolean;
  setGroupName: React.Dispatch<React.SetStateAction<string>>;
  setSubheading: React.Dispatch<React.SetStateAction<string>>;
};

export default function GroupInfoForm({
  groupName,
  groupNameError,
  subheading,
  disabled = false,
  setGroupName,
  setSubheading,
  isLoading = false,
}: GroupInfoFormProps) {
  return (
    <>
      {groupNameError && <ErrorAlert message={MESSAGES.error.groupName} />}
      <div className="grid grid-cols-2 gap-6">
        <div className="col-span-2 sm:col-span-1">
          {isLoading ? (
            <div className="flex flex-col text-sm">
              Group name (e.g. Sales Team)
              <Skeleton height={42} />
            </div>
          ) : (
            <Input
              label="Group name (e.g. Sales Team)"
              type="text"
              value={groupName}
              required
              onChange={setGroupName}
              error={groupNameError}
              disabled={disabled}
              copy={disabled}
              maxLength={50}
            />
          )}
        </div>
        <div className="col-span-2 sm:col-span-1">
          {isLoading ? (
            <div className="flex flex-col text-sm">
              Subheading (e.g. Melbourne Office)
              <Skeleton height={42} />
            </div>
          ) : (
            <Input
              label="Subheading (e.g. Melbourne Office)"
              type="text"
              value={subheading}
              onChange={setSubheading}
              disabled={disabled}
              maxLength={70}
            />
          )}
        </div>
      </div>
    </>
  );
}
