import { Tab } from '@headlessui/react';
import Badge, { BADGE_KIND } from '@/components/Badge';
import type { BadgeKind } from '@/components/Badge';
import classNames from '@/helpers/classNames';

interface ITab {
  name: string;
  value: string;
  href?: string;
  description?: string | JSX.Element;
  count?: number;
  color?: BadgeKind;
  selectedName?: string;
  disabled?: boolean;
}
interface IDropDownTabList {
  tabs: ITab[];
  onDrop?: (
    e: React.DragEvent<HTMLButtonElement>,
    value: string,
    tab_value: string,
  ) => void;
  onDragOver?: (
    e: React.DragEvent<HTMLButtonElement>,
    tab_value: string,
  ) => void;
  onDragTab?: string;
  setOnDragTab?: React.Dispatch<React.SetStateAction<string | undefined>>;
  badgeClassName?: string;
  badgeTextColor?: string;
  badgeBackgroundColor?: string;
  value: string;
  disabled?: boolean;
}

export default function TabList({
  tabs,
  value,
  onDrop,
  onDragOver,
  onDragTab,
  setOnDragTab,
  badgeClassName,
  disabled = false,
}: IDropDownTabList) {
  return (
    <Tab.List className="flex overflow-x-scroll hide-scrollbar">
      {tabs.map((tab) => (
        <Tab
          key={tab.name}
          name={`Tab-${tab.value}`}
          className="first:pl-0 px-2 outline-none"
          onDragOver={(e: React.DragEvent<HTMLButtonElement>) => {
            if (onDragOver) {
              onDragOver(e, tab.value);
            }
          }}
          onDragLeave={() => {
            if (setOnDragTab) {
              setOnDragTab('');
            }
          }}
          onDrop={(e: React.DragEvent<HTMLButtonElement>) => {
            if (onDrop) {
              onDrop(e, value, tab.value);
            }
          }}
          disabled={disabled || tab.disabled === true}
        >
          {({ selected }) => (
            <div
              className={classNames(
                selected || onDragTab === tab.value
                  ? 'text-gray-800 border-gray-800'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'md:flex-row whitespace-nowrap py-2 xl:py-4 px-1 border-b-2 transition font-normal text-lg leading-6 flex items-center space-x-2',
              )}
            >
              <span
                id={tab.value}
                className={classNames(selected ? 'font-medium' : '')}
              >
                {!!tab.selectedName && !!tab.name && selected
                  ? tab.selectedName
                  : tab.name}
              </span>
              {tab.count !== undefined && (
                <Badge
                  text={tab.count ? String(tab.count) : '0'}
                  kind={tab.color ? tab.color : BADGE_KIND.GRAY}
                  className={badgeClassName}
                />
              )}
            </div>
          )}
        </Tab>
      ))}
    </Tab.List>
  );
}
