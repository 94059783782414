import { Variant } from 'shopify-buy';
import classNames from '@/helpers/classNames';

function Price({ variant }: { variant: Variant }) {
  const currencySymbol = '$';
  const price = Number(variant.priceV2.amount);
  const compareAtPrice = variant?.compareAtPriceV2?.amount
    ? Number(variant.compareAtPriceV2.amount)
    : null;

  return (
    <>
      <span
        className={classNames(compareAtPrice ? 'mr-2.5 text-brand-700' : '')}
      >
        {compareAtPrice ? 'Now ' : ''}
        {currencySymbol}
        {price.toFixed(2)}
      </span>

      {compareAtPrice && (
        <span className="text-base text-grey-500 line-through">
          {currencySymbol}
          {compareAtPrice.toFixed(2)}
        </span>
      )}
    </>
  );
}

export default Price;
