import React from 'react';

const Expand = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.1836 19.6733H15.3469M3.83691 8.16335V4.32669V8.16335ZM3.83691 4.32669H7.67357H3.83691ZM3.83691 4.32669L8.63274 9.12251L3.83691 4.32669ZM19.1836 8.16335V4.32669V8.16335ZM19.1836 4.32669H15.3469H19.1836ZM19.1836 4.32669L14.3877 9.12251L19.1836 4.32669ZM3.83691 15.8367V19.6733V15.8367ZM3.83691 19.6733H7.67357H3.83691ZM3.83691 19.6733L8.63274 14.8775L3.83691 19.6733ZM19.1836 19.6733L14.3877 14.8775L19.1836 19.6733ZM19.1836 19.6733V15.8367V19.6733Z"
        stroke="#D1D5DB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Expand;
